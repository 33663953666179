import React from 'react';

import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { AboutPage } from '../components';

const About: React.FC = () => {
  return (
    <Wrapper>
      <AboutPage />
    </Wrapper>
  );
};

export default About;
