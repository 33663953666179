import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IonImg } from '@ionic/react';
import { CrossButton, Loader } from '../../atoms';

interface Props {
  color?: string;
  heading: string;
  title?: boolean;
  season?: string;
  className?: string;
  paragraph?: string;
  subHeading?: string;
  numberOfCharacters?: number;
  flashing?: boolean;
  information?: string;
  isLoadingParagraph?: boolean;
  imgSrc?: string | null | undefined;
  hasCrossBtn?: boolean;
  crossOnClick?: any;
  ref?: any;
  smallWidth?: boolean;
}

const Wrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .subHeading,
  .paragraph {
    font-size: 15px;
    padding: 0 15px;
  }

  .subHeading {
    font-weight: 350;
    margin-bottom: ${(props: any) => (props.title === 1 ? '5px' : '0px')};
  }

  .image-wrapper {
    width: 100%;
    display: flex;
    margin-top: 15px;
    justify-content: center;

    .image {
      width: 240px;
      height: 240px;
    }
  }

  .paragraph {
    margin-top: 15px;
    text-align: justify;
    white-space: pre-line;
  }

  .small-width {
    text-align: center;
    margin: 15px auto 0;
    width: 86%;
  }

  .information {
    padding: 0 15px;
    font-size: 12px;
    text-align: left;
    margin-top: 0px;
  }

  .season {
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : '#000000')};
  }

  @media (max-width: 360px) {
    .subHeading {
      font-size: 13px;
    }
  }
`;

const Heading: any = styled.span`
  line-height: 35px;
  white-space: break-spaces;
  color: ${(props) => (props.color ? props.color : props.title ? '#4d4d4d' : '#000000')};
  font-size: ${(props) => (props.title ? '36px' : '22px')};
  font-weight: ${(props) => (props.title ? '900' : '700')};

  @media (max-width: 360px) {
    font-size: ${(props) => (props.title ? '38px' : '18px')};
  }
`;

const HeaderWrapper = styled.div<any>`
  position: relative;
  .loader-dot {
    font-size: 8px;
    color: ${(props) => (props.color ? props.color : '#000000')};
  }

  @keyframes blink {
    50% {
      color: transparent;
    }
  }
  .loader-dot {
    margin-left: 1px;
    animation: 1s blink infinite;
  }
  .loader-dot:nth-child(2) {
    animation-delay: 250ms;
  }
  .loader-dot:nth-child(3) {
    animation-delay: 500ms;
  }
  .title-cross-btn {
    position: absolute;
    left: 36px;

    @media (max-width: 370px) {
      left: 20px;
      width: 38px;
      height: 38px;
    }
  }
`;

export const TitleComponent: React.FC<Props> = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation();

  const {
    color,
    title,
    imgSrc,
    season,
    heading,
    className,
    paragraph,
    subHeading,
    information,
    flashing = false,
    numberOfCharacters,
    isLoadingParagraph = false,
    hasCrossBtn = false,
    crossOnClick,
    smallWidth,
  } = props;

  return (
    <Wrapper title={title ? 1 : 0} className={className}>
      {subHeading && (
        <span className="subHeading" ref={ref}>
          {t(subHeading)}
        </span>
      )}

      <HeaderWrapper color={color}>
        {hasCrossBtn && <CrossButton className="title-cross-btn" onClick={crossOnClick} />}
        <Heading title={title ? 1 : 0} color={color}>
          {heading}
        </Heading>
        {flashing && (
          <>
            <span className="loader-dot">■</span>
            <span className="loader-dot">■</span>
            <span className="loader-dot">■</span>
          </>
        )}
      </HeaderWrapper>

      {imgSrc && (
        <div className="image-wrapper">
          <IonImg src={imgSrc} className="image" />
        </div>
      )}

      {season && (
        <div className="season" color={color}>
          {t(season)}
        </div>
      )}
      {paragraph && (
        <Loader isLoading={isLoadingParagraph}>
          <p className={`paragraph ${smallWidth && 'small-width'} `}>
            {numberOfCharacters ? t(paragraph).slice(0, numberOfCharacters) + '...' : t(paragraph)}
          </p>
        </Loader>
      )}
      {information && <p className="information">{information}</p>}
    </Wrapper>
  );
});
