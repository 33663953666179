import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';

import { Divider } from '../../atoms';

const ImageWrapper = styled.div`
  padding: 24px 15px;
`;

const MainFeatures = () => {
  return (
    <div className="neomorphs-shadow-out concept">
      <div>
        <p className="inner-header">主な機能</p>
        <p className="context">
          Faniqueにて、ご利用頂ける、主な機能は
          <br />
          以下となります。尚、機能につきましては、
          <br />
          順次拡張を予定しており、
          <br />
          以下は2023年4月現在となります。
        </p>
      </div>
      <Divider />

      <ImageWrapper>
        <IonImg src="/assets/images/about/top-page-feature.png" />
      </ImageWrapper>
      <Divider />

      <ImageWrapper>
        <IonImg src="/assets/images/about/after-login-feature.png" />
      </ImageWrapper>
    </div>
  );
};

export default MainFeatures;
