import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';

interface Props {
  cardName: 'DIAMOND' | 'PLATINUM' | 'GOLD' | 'SILVER' | 'RED';
  description: string;
  membershipFee: string;
  annualFee?: string;
  color?: string;
  teamId?: any;
  cardId?: string;
  sum?: any;
  salesStopPeriodFrom?: string;
  salesStopPeriodTo: string;
}

const differentRanks: any = {
  diamond: 'black',
  platinum: '#666666',
  gold: '#A67C52',
  silver: '#999999',
  red: '#F00F00',
};

const externalLinks: any = {
  diamond: '/rank-details/diamond',
  platinum: '/rank-details/platinum',
  gold: '/rank-details/gold',
  silver: '/rank-details/silver',
  red: '/rank-details/red',
};

const Container: any = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  alitn-items: center;
  justify-content: space-between;
  border-radius: 50px;
  width: 100%;
  min-height: 335px;

  .name {
    width: 95%;
    margin: 0 auto;
    height: 50px;
    background-color: ${(props: any) => differentRanks[`${props.cardName.toLowerCase()}`]};
    border-radius: 34px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-size: 26px;
      font-weight: 800;
    }
  }

  .blank {
    height: 22px;
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 350;

    .left {
      width: 70%;
      display: flex;
      flex-direction: column;

      p {
        margin: 0px;
        font-size: 15px;
      }

      span {
        font-size: 15px;
      }
    }

    .right {
      .detailBtn {
        font-size: 12px;
        font-weight: 500;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        color: ${(props: any) => (props.color ? props.color : '')};
      }
    }
  }

  .divider {
    border: 1px solid #cccccc;
    filter: blur(1px);
    box-shadow: 0px 1px 1px 1px white;
    margin: 15px 0 !important;
  }

  .bottom {
    font-weight: 350;
    width: 100%;
    margin-bottom: 25px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      p {
        margin: 0;
        font-size: 15px;
      }

      span {
        font-size: 15px;
      }
    }

    .blank {
      height: 22px;
    }
  }
`;

const NotAvailable = styled.div`
  position: relative;
  .white-background {
    position: absolute;
    height: calc(100% + 15px);
    width: calc(100% + 10px);
    left: -5px;
    top: -10px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 14px;
    z-index: 10;
  }

  .board {
    position: absolute;
    width: 180px;
    background-color: #b80629;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);

    p {
      margin: 0;
    }
  }
`;

export const RankCard: React.FC<Props> = ({
  color,
  membershipFee,
  annualFee,
  cardName = 'Diamond',
  description,
  teamId,
  cardId,
  sum,
  salesStopPeriodFrom = '',
  salesStopPeriodTo = '',
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isApplicationClosed, setIsApplicationClosed] = useState(false);

  const openExternalLink = () => {
    history.push({
      pathname: externalLinks[`${cardName.toLowerCase()}`],
      search: `?teamId=${teamId}`,
    });
  };

  const purchaseHandler = () => {
    sessionStorage.setItem('card', cardName.toUpperCase());
    sessionStorage.setItem('price', sum);
    history.push({
      pathname: `/membership-confirmation`,
      search: `?teamId=${teamId}&status=${cardId}&card=${cardName.toUpperCase()}&price=${sum}`,
    });
  };

  const scheduleApplicationClose = () => {
    const currentTime = new Date();
    const currentMonth = currentTime.getMonth() + 1;
    const currentDay = currentTime.getDate();

    const startDateMonth = parseInt(salesStopPeriodFrom?.substring(0, 2));
    const startDateDay = parseInt(salesStopPeriodFrom?.substring(2), 10);
    const endDateMonth = parseInt(salesStopPeriodTo?.substring(0, 2), 10);
    const endDateDay = parseInt(salesStopPeriodTo?.substring(2), 10);

    if (
      (currentMonth > startDateMonth && currentMonth < endDateMonth) ||
      (currentMonth === startDateMonth && currentDay >= startDateDay && currentDay <= endDateDay) ||
      (currentMonth === endDateMonth && currentDay <= endDateDay && currentDay >= startDateDay)
    ) {
      setIsApplicationClosed(true);
    } else {
      setIsApplicationClosed(false);
    }
  };

  useEffect(() => {
    scheduleApplicationClose();
  }, [salesStopPeriodFrom, salesStopPeriodTo]);

  return (
    <Container className="neomorphs-shadow-in" cardName={cardName} color={color}>
      <div className="name">
        <span>{cardName}</span>
      </div>
      <div className="top">
        <div className="left">
          <p>{description} </p>
          {cardName.toLowerCase() === 'diamond' ? (
            <span>{t('※Inspection is required')}</span>
          ) : (
            <span className="blank" />
          )}
        </div>
        <div className="right">
          <Button className="detailBtn" onClick={openExternalLink}>
            {t('Details')}
          </Button>
        </div>
      </div>
      <div className="divider"></div>
      {isApplicationClosed ? (
        <NotAvailable>
          <div className="white-background"></div>
          <div className="board">
            2022-2023
            <br />
            シーズン受付終了
          </div>
          <div className="bottom">
            <div>
              <p>メンバーシップ価格</p>
              <span>{membershipFee}円</span>
            </div>
            <div>
              <p>年会費</p>
              <span>{annualFee}円</span>
            </div>
          </div>
          <div>
            <Button size="large" color={color} onClick={() => purchaseHandler()}>
              {t('Proceed to purchase')}
            </Button>
          </div>
        </NotAvailable>
      ) : (
        <>
          <div className="bottom">
            <div>
              <p>メンバーシップ価格</p>
              <span>{membershipFee}円</span>
            </div>
            <div>
              <p>年会費</p>
              <span>{annualFee}円</span>
            </div>
          </div>
          <div>
            <Button size="large" color={color} onClick={() => purchaseHandler()}>
              {t('Proceed to purchase')}
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};
