import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled(IonItem)<any>`
  font-size: 15px;
  --background-hover-opacity: 0;
  min-height: var(--min-height);
  ion-label,
  ion-text {
    font-size: 13px !important;
    font-weight: 350;
    display: inline-block;
    width: ${(props) => (!props.textarea ? '4.5em' : null)};
    margin-right: 1em;
  }
  ion-input,
  ion-select {
    --padding-start: 1rem;
    --padding-end: 1rem;
    caret-color: black;
    border-radius: 1.3em;
    font-size: 13px;
    height: 2.5em;
    width: 100%;
    margin: 10px 0;
  }
  .native-textarea {
    font-size: 13px;
  }
  ion-select::part(placeholder) {
    padding-right: 1rem;
  }

  ion-select::part(text) {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }

  .white-input {
    caret-color: black;
    background: white;
    border-radius: 0.3em;
    width: 100%;
    font-size: 13px;
    height: fit-content;
    min-height: 24px;
  }
  .white-input .native-input,
  .white-input {
    --padding-start: 4px;
    --padding-end: 4px;
    --padding-top: 1px;
    --padding-bottom: 1px;
  }
  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }
`;

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  onChange: (e: any) => any;
  onBlur?: (e: any) => any;
  value?: string | number;
  mode?: 'white' | 'normal';
  name: string;
  onKeyDown?: (e: any) => any;
  maxlength?: number;
  textarea?: boolean;
  inputMode?:
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  error?: string;
  ref?: any;
}

const MODE = {
  normal: 'neomorphs-shadow-in',
  white: 'white-input',
};

export const TextField: React.FC<Props> = React.forwardRef(
  (
    {
      className,
      label,
      placeholder,
      onChange,
      onBlur,
      name,
      value,
      mode = 'normal',
      onKeyDown,
      maxlength = 100,
      textarea = false,
      // To show numeric keyboard on mobile devices, pass: inputMode = "numeric".
      inputMode,
      error,
    }: Props,
    ref: any
  ) => {
    const { t } = useTranslation();
    if (textarea) {
      return (
        <Wrapper lines="none" textarea={textarea} error={error}>
          <div>
            {label && <IonLabel>{label}</IonLabel>}
            <IonTextarea
              name={name}
              placeholder={placeholder}
              onIonChange={onChange}
              onIonBlur={onBlur}
              className={`${MODE[mode]} ${className}`}
              maxlength={maxlength}
              onKeyDown={onKeyDown}
              rows={5}
              cols={100}
              value={value?.toString()}
            />
          </div>
        </Wrapper>
      );
    }
    return (
      <Wrapper lines="none" className={error && `ion-invalid`}>
        {label && <IonLabel>{label}</IonLabel>}
        <IonInput
          name={name}
          placeholder={placeholder}
          onIonChange={onChange}
          onIonBlur={onBlur}
          className={`${MODE[mode]} ${className}`}
          maxlength={maxlength}
          onKeyDown={onKeyDown}
          value={value}
          ref={ref}
          inputMode={inputMode}
        />
        <IonNote slot="error">{error && t(error)}</IonNote>
      </Wrapper>
    );
  }
);

interface Option {
  value: string | number;
  label: string | number;
}

interface SelectProps extends Props {
  options: Option[];
  mode?: 'normal' | 'white';
  ref?: any;
}

export const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  onChange,
  options,
  value,
  mode = 'normal',
  name,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper lines="none" className={error && `ion-invalid`}>
      {label && <IonText>{label}</IonText>}
      <IonSelect
        placeholder={placeholder}
        onIonChange={onChange}
        name={name}
        className={`${MODE[mode]}`}
        interface="action-sheet"
        interfaceOptions={{ mode: 'ios', cssClass: 'select-sheet' }}
        value={value}
      >
        {(options || []).map((item) => (
          <IonSelectOption value={item.value} key={item.label}>
            {item.label}
          </IonSelectOption>
        ))}
      </IonSelect>
      <IonNote slot="error">{error && t(error)}</IonNote>
    </Wrapper>
  );
};

export const SelectWithRef: React.FC<SelectProps> = React.forwardRef(
  ({ label, placeholder, onChange, options, value, mode = 'normal', name, error }, ref: any) => {
    const { t } = useTranslation();
    return (
      <Wrapper lines="none" className={error && `ion-invalid`}>
        {label && <IonText>{label}</IonText>}
        <IonSelect
          placeholder={placeholder}
          onIonChange={onChange}
          name={name}
          className={`${MODE[mode]}`}
          interface="action-sheet"
          interfaceOptions={{ mode: 'ios', cssClass: 'select-sheet' }}
          value={value}
          ref={ref}
        >
          {(options || []).map((item) => (
            <IonSelectOption value={item.value} key={item.label}>
              {item.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        <IonNote slot="error">{error && t(error)}</IonNote>
      </Wrapper>
    );
  }
);

interface CheckboxProps {
  label: string;
  checked?: boolean;
  name: string;
  disabled?: boolean;
  onChange: (e: any) => void;
}
const CheckboxWrapper = styled(IonItem)`
  --background-hover: none;
  --border-style: none;
  ion-label {
    font-size: 13px;
    margin-top: 14px;
  }
  ion-checkbox {
    --background: white;
    --background-checked: white;
    --background-checked: white;
    margin-right: 5px;
  }
`;

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, name, onChange, disabled }) => {
  return (
    <CheckboxWrapper>
      <IonCheckbox
        slot="start"
        checked={checked}
        onIonChange={onChange}
        name={name}
        disabled={disabled}
      />
      <IonLabel>{label}</IonLabel>
    </CheckboxWrapper>
  );
};

interface RadioProps {
  label: string;
  value: string;
  disabled?: boolean;
}

const RadioWrapper = styled(IonItem)`
  --background-hover: none;
  --border-style: none;

  ion-radio {
    --border-radius: 6px;
    --inner-border-radius: 4px;
    --color: white;
    --color-checked: slategray;
    background-color: white;
    border-radius: 6px;
    margin-right: 5px;
  }
  ion-radio.ios {
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 4px;
  }
  .radio-checked.ios {
    border-color: #6815ec;
  }
`;

export const Radio: React.FC<RadioProps> = ({ label, value, disabled = false }) => {
  return (
    <>
      <RadioWrapper>
        <IonRadio value={value} disabled={disabled}></IonRadio>
        <IonLabel>{label}</IonLabel>
      </RadioWrapper>
    </>
  );
};
