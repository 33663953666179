import { API } from './api';
import { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';
interface ITeamInfo<T> {
  data?: T[] | any;
}
interface ITeamQuery {
  teamId?: string;
  page?: string | undefined;
}

export const fetchTeamDetail = async ({
  queryKey,
}: any): Promise<AxiosResponse<ITeamInfo<any>>> => {
  const params = {} as ITeamQuery;
  params.teamId = queryKey[1];
  return API.get(`/team/info`, { params });
};

export const fetchTeamLists = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/team/list?page=${queryKey?.[1] ?? '1'}&searchWord=${queryKey?.[2]}`);
};

export const fetchAllTeam = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/team/list?page=${queryKey?.[1] ?? '1'}`);
};
