import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import RestrictedRoute from '../withRestrictedRoute';

import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  RedRankDetail,
  GoldRankDetail,
  SilverRankDetail,
  DiamondRankDetail,
  PlatinumRankDetail,
} from '../components';

const CardWrapper = styled.div`
  padding: 10px 20px;
  .space {
    margin-bottom: 35px;
  }
  .card-wrapper {
    padding: 2rem 1rem 2.5rem;
    padding-bottom: 6px;
    border-radius: 50px;
  }
`;

const RankDetails = () => {
  const location = useLocation();

  const card = location.pathname.split('/')[2];

  const renderChild = () => {
    if (card === 'red') {
      return <RedRankDetail />;
    } else if (card === 'silver') {
      return <SilverRankDetail />;
    } else if (card === 'gold') {
      return <GoldRankDetail />;
    } else if (card === 'platinum') {
      return <PlatinumRankDetail />;
    } else {
      return <DiamondRankDetail />;
    }
  };

  return (
    <Wrapper>
      <CardWrapper>{renderChild()}</CardWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(RankDetails);
