import styled from 'styled-components';
import React, { useContext } from 'react';
import PrivateRoute from '../withPrivateRoute';
import { TitleComponent } from '../components';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { OtherInformationData } from '../components/molecules/OtherInformationData';

const ContentWrapper = styled.div`
  padding: 0 20px 20px;
  hr {
    margin: 0;
  }
`;

const OtherInformation: React.FC = () => {
  const { status, teamDetails } = useContext(UserContext);
  const COLOR = teamDetails?.team.teamColor;

  if (status?.statusNameEnglish === 'RED' || status?.statusNameEnglish === 'SILVER') {
    window.location.href = '/my-page';
  }
  return (
    <Wrapper>
      <ContentWrapper>
        <TitleComponent
          title
          heading="Other Information"
          subHeading="Other Information"
          color={COLOR}
        />
        <OtherInformationData color={COLOR}></OtherInformationData>
      </ContentWrapper>
    </Wrapper>
  );
};
export default PrivateRoute(OtherInformation);
