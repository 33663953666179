import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { caretDownOutline } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router';
import { IonIcon, useIonViewWillEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { fetchNotificationDetails } from '../services';
import { Button, Divider, Loader } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { useMembershipExpiration } from '../hooks/useMembershipExpiration';

const Content = styled.div<any>`
  .back-section {
    padding: 7px 2px;
    border-bottom: 2px solid #d4d5d5;
  }
  .back-icon {
    padding-top: 8px;
    height: 35px;
    width: 35px;
    cursor: pointer;
  }
  .notification-detail {
    padding: 3px 25px;
  }
  .title {
    font-weight: 500;
    font-size: 15px;
  }
  .time {
    span {
      font-weight: 350;
      font-size: 13px;
    }
    margin-top: -10px;
    margin-bottom: 18px;
  }
  .details {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    margin-top: 12px;
    white-space: pre-line;

    .urlTitle {
      display: flex;

      .icon {
        font-size: 25px;
      }
    }

    a {
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .loader {
    padding-top: 50%;
  }
  .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    .return-home {
      width: 43%;
      font-size: 12px;
    }
  }
`;

const NotificationPage = () => {
  const history = useHistory();
  const { teamDetails } = useContext(UserContext);
  const [notificationId, setNotificationId] = useState<any>();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { isExpired } = useMembershipExpiration();

  function isJSON(str: string | undefined) {
    try {
      JSON.parse(str as any);
      return true;
    } catch {
      return false;
    }
  }

  const { data: notificationData, isLoading } = useQuery(
    ['fetchNotificationDetails', notificationId],
    fetchNotificationDetails,
    {
      enabled: !!notificationId,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      select: (response: any) => {
        return {
          ...response.data,
          content: isJSON(response?.data?.content)
            ? JSON.parse(response?.data?.content)
            : response?.data?.content?.replaceAll('\\n', '\n'),
        };
      },
    }
  );

  const notificationTime = () => {
    const date = new Date(notificationData?.publishDate);
    const time = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}年${month}月${day}日 ${time}`;
  };

  useEffect(() => {
    const id = window.location.pathname.split('/')[2];
    setNotificationId(id);
  }, [pathname]);

  useIonViewWillEnter(() => {
    if (isExpired) {
      history.push('/membership-card-info');
    }
  }, [isExpired]);

  return (
    <Wrapper authenticated={true}>
      <Content>
        <Loader isLoading={isLoading} className="loader">
          <div className="notification-detail">
            <div>
              <p className="title">{notificationData?.title}</p>
            </div>
            <div className="time">
              <span>{notificationTime()}</span>
            </div>
            <Divider className="divider1" />
            <div>
              {/* <p
                className="details"
                dangerouslySetInnerHTML={{
                  __html:
                    notificationData?.content
                      .replace(
                        'https://scalagrp.notion.site/How-to-ba9e3dd90e3044e48297eba1f63fc9a3',
                        '<a href="https://scalagrp.notion.site/How-to-ba9e3dd90e3044e48297eba1f63fc9a3" target="_blank">https://scalagrp.notion.site/How-to-ba9e3dd90e3044e48297eba1f63fc9a3</a>'
                      )
                      .replace(
                        'https://fanique.io/inquiry',
                        "<a href='https://fanique.io/inquiry' target='_blank'>https://fanique.io/inquiry</a>"
                      ) ?? '',
                }}
              ></p> */}
              <div className="details">
                <p>
                  {notificationData?.content
                    ? notificationData?.content?.content
                      ? notificationData?.content?.content
                      : notificationData?.content
                    : ''}
                </p>
                <div className="urlTitle">
                  {notificationData?.content?.urlTitle ? (
                    <>
                      <IonIcon icon={caretDownOutline} className="icon" />
                      <span>{notificationData?.content?.urlTitle}</span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                {notificationData?.content?.url ? (
                  <a href={notificationData?.content?.url} target="_blank" rel="noreferrer">
                    {notificationData?.content?.url}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="button-container">
              <Button
                className="return-home"
                color={teamDetails?.team?.teamColor || '#B80629'}
                onClick={() => history.push('/home')}
              >
                {t('Go to home page')}
              </Button>
            </div>
          </div>
        </Loader>
      </Content>
    </Wrapper>
  );
};

export default PrivateRoute(NotificationPage);
