import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Divider } from '../../atoms';

const FeatureWrapper = styled.div`
  .title {
    display: flex;
    padding: 0 20px;

    .image-wrapper {
      width: 67px;
      height: 67px;

      ion-img {
        width: 100%;
        height: 100%;
      }
    }

    span {
      margin-left: 25px;
      font-weight: 700;
      font-size: 18px;
      color: #4d4d4d;
      text-align: left;
      white-space: pre-line;
    }
  }

  .content-wrapper {
    display: flex;
    justify-content: center;

    p {
      padding: 0 15px;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    text-align: justify;
    white-space: break-spaces;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;

    .btn {
      width: 140px;
    }
  }

  @media (max-width: 406px) {
    .title {
      padding: 0 3px;
    }
  }
`;

const featureData = [
  {
    id: 1,
    img: '/assets/images/about/support.png',
    title: 'Support & Earn\nリワードプログラム',
    content:
      'チームを応援（試合観戦、グッズ購入など）する\nことでコインを獲得して、メンバーシップを\nステータスアップができる。その結果、チームへの\n影響度を上げることができる。',
  },
  {
    id: 2,
    img: '/assets/images/about/special-experiences.png',
    title: '特別体験の獲得',
    content: '各ステータスに応じて、様々なサービス・特典\nを得られる。',
  },
  {
    id: 3,
    img: '/assets/images/about/digital-collection.png',
    title: 'デジタルコレクション',
    content:
      '試合中の名シーンをデジタルコレクションとして保有\nすることができる。具体的には、NFTカードを販売\nまたは特典で提供する。',
  },
  {
    id: 4,
    img: '/assets/images/about/decision-making.png',
    title: 'チーム経営の意思決定\nへの関与',
    content:
      'グッズデザイン、ユニフォームデザインなどの意思\n決定に参画することが可能。\n(ステータスの高い会員は、チームの意思決定に\nより関与できる)',
  },
  {
    id: 5,
    img: '/assets/images/about/buy-sell.png',
    title: 'NFTメンバーシップが\n売買可能',
    content: 'NFTメンバーシップは、価値を持つ公式アイテム\nとして売買可能。(古参メリット)',
  },
  {
    id: 6,
    img: '/assets/images/about/community-formation.png',
    title: 'コミュニティ形成',
    content:
      'メンバーシップ保有者限定のコミュニティを形成し、\nチーム経営⇄ファン、ファン⇄ファンの\nコミュニケーションが可能。',
  },
];

const Feature = ({ handleClick }: any) => {
  const { t } = useTranslation();

  const renderFeature = () => {
    return featureData.map((data) => {
      return (
        <FeatureWrapper key={data.id}>
          <div className="title">
            <div className="image-wrapper">
              <IonImg src={data.img} />
            </div>
            <span>{data.title}</span>
          </div>
          <div className="content-wrapper">
            <p>{data.content}</p>
          </div>
          {data?.title === 'Support & Earn\nリワードプログラム' && (
            <div className="button-wrapper">
              <Button size="small" className="btn" onClick={handleClick}>
                {t('See details')}
              </Button>
            </div>
          )}
          {data.id !== 6 && <Divider className="divider1 divider" />}
        </FeatureWrapper>
      );
    });
  };

  return (
    <div className="neomorphs-shadow-out concept feature">
      <p className="inner-header">特徴</p>
      <p className="context">
        メンバーシップNFTを活用することで、
        <br />
        従来できなかった「新しい形のファン
        <br />
        クラブ」を提供します。
      </p>
      <Divider className="divider1 divider" />
      {renderFeature()}
    </div>
  );
};

export default Feature;
