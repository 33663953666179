import React from 'react';
import { IonImg } from '@ionic/react';

import { TitleComponent, Divider } from '../../atoms';

const TopSection = () => {
  return (
    <div>
      <div className="heading">
        <p className="header">もっとFaniqueを知る</p>
        <TitleComponent heading="About" title={true} color="#4D4D4D" className="title" />
      </div>

      <div className="top-section">
        <p>
          NFTとファンクラブ機能を合わせた、
          <br />
          新しい形のファンクラブサービスです。
        </p>
        <IonImg src="/assets/images/about/top.png" />
      </div>
      <Divider className="top-divider" />
    </div>
  );
};

export default TopSection;
