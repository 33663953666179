import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TeamCardComponent } from '../../organisms';

interface Props {
  name: string;
  id?: string;
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 900;
    color: #a67c52;
    margin-top: 0px;
  }
`;

export const OwnersCard: React.FC<Props> = ({ name, id }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h1>{t('Owners Card')}</h1>
      <TeamCardComponent rotating={true} name={name} id={id} />
    </Wrapper>
  );
};
