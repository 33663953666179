import styled from 'styled-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

import { Button } from '../../atoms';

interface Props {
  title: string;
  children: React.ReactNode;
}

const AccordionWrapper = styled.div`
  border-radius: 50px;
  padding: 27px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #4d4d4d;
      font-size: 20px;
      font-weight: 700;
    }

    .btn {
      width: 46px;
      height: 46px;
    }
  }

  .children {
    color: #4d4d4d;
    p,
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const MarketAccordion: React.FC<Props> = ({ title, children }) => {
  const { t } = useTranslation();
  const [isClosed, setIsClosed] = useState(false);

  const handleAccordionState = () => {
    setIsClosed(!isClosed);
  };

  return (
    <AccordionWrapper className="neomorphs-shadow-in">
      <div className="title-wrapper">
        <p className="title">{t(title)}</p>
        <Button
          className="btn"
          slot="icon-only"
          icon={isClosed ? chevronDownOutline : chevronUpOutline}
          expand="block"
          onClick={handleAccordionState}
        />
      </div>
      {isClosed ? '' : <div className="children">{children}</div>}
    </AccordionWrapper>
  );
};

export { MarketAccordion };
