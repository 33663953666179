import React from 'react';
import {
  TopSection,
  Goods,
  GoodsData,
  RankDetailsVote,
  RankDetailEvent,
  EventData,
  TicketSection,
} from '../../molecules';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Button } from '../../atoms';

const ticketData = [
  {
    id: 1,
    title: 'シーズンチケット\n先行販売(3次)・限定割引',
    modalContent: 'シーズンチケットをFanique会員価格で先に購入いただけます。',
  },
  // {
  //   id: 2,
  //   title: 'VIP席 エキサイトシート\n購入権利 (3次先行)',
  //   modalContent:
  //     '一般販売されていない、シーズンシートホルダー専用席をVIPのみなさまに特別に購入可能とします。\n\nVIP席 エキサイトシートご購入特典として、駐車場のご利用、選手との写真（1枚）を撮影することが出来ます。\n\n【チケット料金】(税込)\nエキサイトシート  ￥10,000 (Home側4席・Away側4席)\n※各会場フリードリンクサービス付き\n\n【枚数制限】\n1会員様1試合につき3席まで',
  // },
  // {
  //   id: 3,
  //   title: 'VIP席 ボックスシート\n(最大3名まで準備可能)\n購入権利 (3次先行)',
  //   modalTitle: 'VIP席 ボックスシート\n購入権利 (3次先行)',
  //   modalContent:
  //     '一般販売されていない、選手ベンチの横のボックスシート席を特別に購入可能とします。\n\nVIP席 ボックスシートご購入特典として、駐車場のご利用、選手との写真（1枚）を撮影することが出来ます。\n\n【チケット料金】(税込)\nボックス  ￥15,000 (3席セット)\n※各会場フリードリンクサービス付き※HOME側コートエンド：計6ボックス\n\n【枚数制限】\n1会員様1試合につきボックスまで',
  // },
  // {
  //   id: 4,
  //   title: 'コートサイドシート\n購入権利 (3次先行)',
  //   modalContent:
  //     '一般販売されていない、シーズンシートホルダー専用席を特別に購入可能とします。\n\n【枚数制限】\n1会員様1試合につき3枚まで',
  // },
  // {
  //   id: 5,
  //   title: 'コートエンドシート\n購入権利 (3次先行)',
  //   modalContent:
  //     '一般販売されていない、シーズンシートホルダー専用席を特別に購入可能とします。\n\n【枚数制限】1会員様1試合につき3枚まで',
  // },
  {
    id: 6,
    title: 'Fanique Zoneシート\nチケット (毎年5枚)',
    modalContent:
      '予め決められたFanique Zoneから席を選び、観戦できます。\n\nFanique ZoneはFaniqueがとり寄せる、一般販売されないブロックとなっております。Faniqueホルダーであるファンと共に試合をお楽しみください！\n\n※Fanique Zoneは一階指定席の特定位置となります。\n※Fanique Zoneの位置が随時変わることがございますのでご了承ください。\n※1枚のシートチケットにつき1試合の観戦となります。チケットの譲渡を可とし、転売を不可とします。\n※2023年度プレイオフでの使用は不可とします。',
  },
];

const goods1Data: GoodsData[] = [
  {
    heading: '会員証カード',
    image: '/assets/images/rank-details/platinum-card.png',
    subheading:
      'Fanique会員証 (Broncos Platinum Owner)カードをストラップとホルダー付きでプレゼントいたします。',
  },
];

const goods2Data: GoodsData[] = [
  {
    heading: 'ユニフォーム<br/>(Fanique限定デザイン)',
    image: '/assets/images/rank-details/uniform.png',
    subheading:
      'Fanique限定デザインの2023-24SEASONレプリカユニフォーム(ホーム）をプレゼントいたします。<br /> ※画像は昨シーズンのものです。',
  },
  {
    heading: 'マフラータオル',
    image: '/assets/images/rank-details/playoffs.png',
    caption: '▲表デザイン(イメージ)',
    subheading: 'ブロンコスオリジナルのマフラータオルを<br />プレゼントいたします。',
  },
  {
    heading: 'Tシャツ',
    image: '/assets/images/rank-details/tshirt.png',
    subheading: 'ブロンコスオリジナルTシャツを<br />プレゼントいたします。',
  },
  {
    heading: 'ハリセン or スティックバルーン',
    image: '/assets/images/rank-details/wild-power.png',
    subheading: 'ブロンコスオリジナルハリセンもしくはスティックバルーンをプレゼントいたします。',
  },
  {
    heading: 'ポスター',
    image: '/assets/images/rank-details/poster.png',
    caption: '▲デザイン(イメージ)',
    subheading: 'ブロンコスオリジナルポスターを毎年<br />プレゼントいたします。',
  },
  {
    heading: 'シーズン記念グッズ',
    image: '/assets/images/rank-details/yearbook.png',
    subheading:
      'ブロンコスオリジナルイヤーブック等<br />をシーズン終了前後にプレゼントいたします。',
    imageSize: 'sm',
  },
];

const event1Data: EventData[] = [
  {
    heading: '先行入場 (1次先行)',
    subheading:
      '当クラブホームゲームにて会員ご本人様に限り一般開場時間前にご入場いただけます。一足先にアリーナ内へご入場いただき、グッズショップでのお買い物などをお楽しみください。',
  },
  {
    heading: 'ウォーミングアップ<br />オンコート見学<br />(試合開始2時間前)',
    image: '/assets/images/rank-details/court.png',
    subheading:
      '選手たちのウォーミングアップをオンコートで見学いただけます。<br />※エキシビジョンマッチ等により多少の時間の前後が発生いたします。',
  },
  {
    heading: '【抽選】<br />エスコートキッズ入場権',
    // alertHeading: 'Coming soon',
    subheading:
      'エスコートキッズとして選手と入場いただけます。<br />※対象は小中学生とさせて頂きます。<br />※希望者多数の場合は抽選を行います。',
  },
  // {
  //   heading: '【抽選】<br />ウォーミングアップ時の<br />リバウンド体験',
  //   alertHeading: 'Coming soon',
  //   subheading: 'ウォーミングアップの時間中にリバウンド体験をすることができます。',
  // },
  {
    heading: '試合終了後の<br />オンライン選手zoom会<br />(質問可)',
    subheading:
      '試合終了後のオンライン選手zoom会に参加いただけます。<br />※ 選手への質問も可能です。',
  },
  {
    heading: '試合終了後の<br />zoom会LIVE参加<br />(不定期)',
    subheading:
      '試合終了後の選手によるzoom会にLIVE参加いただけます。<br />※不定期での開催となりますのであらかじめご了承ください。',
  },
  {
    heading: '試合後コート解放',
    alertHeading: 'Coming soon',
    subheading:
      '会場準備でき次第、試合後のコートを開放いたします。<br />※ 実施時には対象のメンバー皆様にご連絡させて頂きます。',
  },
  {
    heading: '試合後選手と写真撮影<br />サイン会への参加権利',
    // alertHeading: 'Coming soon',
    image: '/assets/images/rank-details/sign.jpg',
    subheading:
      '試合終了後に選手と写真撮影•直接サインがもらえます<br />※実施時には対象メンバーの皆様へご連絡いたします。',
  },
];

const event2Data: EventData[] = [
  {
    heading: '選手からのバースデー<br />メッセージビデオ',
    // alertHeading: 'Coming soon',
    subheading: '選手からバースデーメッセージビデオをお届けします。',
  },
  {
    heading: 'チームからのバースデーメッセージビデオ',
    image: '/assets/images/rank-details/birthday.png',
    subheading: 'お誕生月にチームからメッセージをお届けいたします。',
  },
  // {
  //   heading: 'チームからの<br />バースデーメッセージ',
  //   subheading: 'お誕生月にチームからメッセージをお届けいたします。',
  // },
  {
    heading: 'シーズンエンドパーティー・<br />ファン感謝デー参加権利',
    image: '/assets/images/rank-details/teamwork.png',
    subheading: 'シーズンエンドパーティーや、ファン感謝デーに参加いただけます。',
  },
  {
    heading: '公開練習参加',
    image: '/assets/images/rank-details/public-practice.png',
    subheading: '選手たちの普段の練習を見学いただけます。',
  },
];

const PlatinumRankDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const goBack = () => {
    history.push({
      pathname: '/membership-rank',
      search: search,
    });
  };
  return (
    <>
      <div className="space">
        <TopSection cardName="Platinum" src="/assets/images/rank-details/platinum.png" />
      </div>
      <div className="space">
        <TicketSection data={ticketData} />
      </div>
      <div className="space">
        <Goods mainHeading="Goods①" mainSubheading="グッズ（入会時）" data={goods1Data} />
      </div>
      <div className="space">
        <Goods mainHeading="Goods②" mainSubheading="グッズ（毎年）" data={goods2Data} />
      </div>
      <div className="space">
        <RankDetailsVote mainHeading="Vote" mainSubheading="投票企画" />
      </div>
      <div className="space">
        <RankDetailEvent
          mainHeading="Event①"
          mainSubheading="イベント (試合当日)"
          data={event1Data}
        />
      </div>
      <div className="space">
        <RankDetailEvent
          mainHeading="Event②"
          mainSubheading="イベント (試合以外)"
          data={event2Data}
        />
      </div>
      <div className="space">
        <Button className="btn" size="large" onClick={() => goBack()}>
          {t('Back to membership list')}
        </Button>
      </div>
    </>
  );
};

export { PlatinumRankDetail };
