import React, { useRef } from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Divider, Button } from '../../atoms';

const RenderTitle = styled.div`
  margin-bottom: 16px;

  .small-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #29c8d2;
    white-space: pre;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    color: #4d4d4d;
    white-space: pre-line;
  }
`;

const SupportAndEarn = React.forwardRef((_, ref: any) => {
  const history = useHistory();

  const firstRef = useRef<HTMLInputElement | null>(null);
  const secondRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (ref: any) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const renderTitle = (title: string, subtitle: string = '') => {
    return (
      <RenderTitle>
        <p className="small-title">{title}</p>
        <span>{subtitle}</span>
      </RenderTitle>
    );
  };

  return (
    <div className="neomorphs-shadow-out concept support-earn">
      <p className="inner-header" ref={ref}>
        Support & Earn
      </p>
      <p className="inner-header">リワードプログラム</p>
      <p className="context">
        試合を観戦してチームを応援することで、
        <br />
        メンバーシップステータスを上げること
        <br />
        ができます。
      </p>
      <Divider className="divider1 divider" />

      <div className="list-container">
        <div className="list-wrapper">
          <div className="title-container">
            <div className="title-wrapper">
              <div className="count">1</div>
              <div className="header">
                観戦を通じて、メンバーシップ
                <br />
                のステータスを上げることが
                <br />
                できる
              </div>
            </div>
            <p className="detail">
              コインを貯めて、レベルアップそして、一定レベル
              <br />
              になったらステータスアップが可能になります。
            </p>
          </div>

          <div>
            {renderTitle('応援する', 'ホームゲームを観戦しよう')}
            <IonImg src="/assets/images/about/team.png" className="support-image" />
          </div>
          <div className="triangle-down" onClick={() => handleClick(firstRef)}></div>

          <div ref={firstRef}>
            {renderTitle('コインを貯める', '来場コインを貯めよう')}
            <IonImg src="/assets/images/about/collect-coin.png" className="support-image" />
          </div>
          <div className="triangle-down" onClick={() => handleClick(secondRef)}></div>

          <div ref={secondRef} className="img-centered-container">
            {renderTitle(
              'レベルアップ・\nステータスアップする',
              '貯めたコインをレベルアップ・\nステータスアップに使おう'
            )}
            <IonImg src="/assets/images/about/level-up.png" className="support-image" />
          </div>
        </div>

        <Divider className="divider1 divider" />

        <div className="list-wrapper">
          <div className="title-container">
            <div className="title-wrapper">
              <div className="count">2</div>
              <div className="header">
                ステータスによって、
                <br />
                1試合当たりの獲得コイン数
                <br />
                が違う
              </div>
            </div>
            <p className="detail">
              ステータスが上がるにつれて、1試合観戦による
              <br />
              獲得コインが多くなります。(ゴールド以上は同じ)
            </p>
          </div>

          <div>
            {renderTitle('コイン獲得方法')}
            <IonImg src="/assets/images/about/how-to-get-coins.png" />
          </div>
        </div>

        <div className="button-wrapper">
          <Button size="small" className="button" onClick={() => history.push('/how-to-use')}>
            {`レベルアップ・\n ステータスアップ方法を見る`}
          </Button>
        </div>
      </div>
    </div>
  );
});

export default SupportAndEarn;
