import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';

import RestrictedRoute from '../withRestrictedRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TeamCard, RankCard, TitleComponent, Button, Loader, ImageGallery } from '../components';
import { useGetTeamDetail } from '../hooks/useGetTeamDetail';

const HomeWrapper = styled.div`
  #title-section {
    margin: 0px 2em;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    text-align: center;
    .title {
      font: Noto Sans Jp Bold;
      font-size: 36px;
    }
  }
  #team-card-section {
    margin: 1rem;
    padding: 1rem;
    padding-bottom: 1.6rem;
    border-radius: 50px;
    .paragraph {
      text-align: center;
      font-weight: bold;
      font-size: 17px;
    }
    .title > span {
      margin: 2px 0px;
    }
  }
  #rank-benefit-image-section {
    padding: 0.5rem 2rem;

    .btn-wrapper {
      display: flex;
      justify-content: center;

      .button {
        margin-bottom: 1rem;
        width: 140px;
      }
    }
  }
  #rank-card-section {
    padding: 1rem;
    > div:not(#rank-card-section > div:last-child) {
      margin-bottom: 2rem;
    }
  }
`;

const PATTERN = ['RED', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND'];

const MembershipRank: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [rankCardList, setRankCardList] = useState([]);

  const searchParams = new URLSearchParams(location.search);

  const teamId = searchParams.get('teamId');
  const {
    data: teamData,
    isLoading: teamLoading,
    isError: isTeamDataError,
  } = useGetTeamDetail(teamId);

  useEffect(() => {
    if (isTeamDataError) {
      history.replace('/404');
    } else if (teamData) {
      let cards: any = [];
      let result: any = [];

      teamData?.status.map((card: any) => {
        cards.push(card);
      });

      for (let i = 0; i <= PATTERN.length; i++) {
        for (let j = 0; j < cards.length; j++) {
          if (
            PATTERN[i]?.toLocaleLowerCase() === cards[j]?.statusNameEnglish?.toLocaleLowerCase()
          ) {
            result.push(cards[j]);
          }
        }
      }

      setRankCardList(result);
    }
  }, [teamData, isTeamDataError]);

  return (
    <Wrapper>
      <Loader isLoading={teamLoading} className="home-loader">
        <HomeWrapper>
          <div id="team-card-section" className="neomorphs-shadow-out">
            <TeamCard team={teamData} />
          </div>
          <div id="title-section">
            <TitleComponent
              heading="Membership Status"
              title={true}
              color={teamData?.color}
              className="title"
              subHeading={t('Select membership rank')}
            />
          </div>
          <div id="rank-card-section">
            {rankCardList?.map(
              ({ id, statusNameEnglish, statusDescription, membershipFee, annualFee }: any) => (
                <RankCard
                  cardName={statusNameEnglish}
                  description={statusDescription}
                  membershipFee={membershipFee.toLocaleString('en-US')}
                  annualFee={annualFee.toLocaleString('en-US')}
                  key={id}
                  cardId={id}
                  color={teamData?.color}
                  teamId={teamId}
                  sum={membershipFee + annualFee}
                  salesStopPeriodFrom={teamData?.salesStopPeriodFrom}
                  salesStopPeriodTo={teamData?.salesStopPeriodTo}
                />
              )
            )}
          </div>
          <div id="rank-benefit-image-section">
            {/* <ImageGallery header="メンバーシップステータス特典一覧" /> */}
            <div className="btn-wrapper">
              <Button
                expand="block"
                className="button"
                size="small"
                onClick={() => history.push(`/team-list/${teamId}`)}
              >
                {t('See team details')}
              </Button>
            </div>
          </div>
        </HomeWrapper>
      </Loader>
    </Wrapper>
  );
};

export default RestrictedRoute(MembershipRank);
