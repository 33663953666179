import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIonViewWillEnter } from '@ionic/react';
import { useLocation, useHistory } from 'react-router';
import React, { useState, useContext, useEffect } from 'react';

import PrivateRoute from '../withPrivateRoute';
import { toVotingPollData } from '../utils/vote';
import { UserContext } from '../utils/UserContext';
import { castVote, fetchVotes } from '../services';
import { useMutation, useQuery } from 'react-query';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { useMembershipExpiration } from '../hooks/useMembershipExpiration';
import { TitleComponent, Button, Pagination, VotingPoll, Loader } from '../components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 35px;
  ion-button {
    width: 140px;
    height: 28px;
    font-size: 12px;
  }
  .button-down {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }

  @media (max-width: 414px) {
    padding: 0 20px;
  }

  @media (max-width: 390px) {
    padding: 0 10px;
  }

  @media (max-width: 370px) {
    padding: 0px;
  }

  @media (max-width: 350px) {
    ion-button {
      width: 45%;
    }
  }
`;

const HomeWrapper = styled.div`
  padding: 0px 25px;
`;

const VotingPollMainWrapper = styled.div`
  margin: 30px 0;
  margin-bottom: 0px;
`;

const EachVotingPollWrapper = styled.div`
  margin-bottom: 30px;
`;

const NoDataWrapper = styled.div`
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  padding-top: 20px;
  p {
    margin: 0px;
  }
`;

const VotingList = () => {
  const { t } = useTranslation();
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { isExpired } = useMembershipExpiration();

  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const listParam = searchParams.get('list');

  const { teamDetails, userProfileData } = useContext(UserContext);

  const {
    data: votesData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['fetchVotes', currentPage, userProfileData?.id, listParam === 'endedVote'],
    fetchVotes,
    {
      enabled: !!userProfileData?.id,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: (response) => {
        return response?.data;
      },
    }
  );

  const { mutate, isLoading: isMutateLoading, isSuccess } = useMutation(castVote);

  useIonViewWillEnter(() => {
    async function fetch() {
      await refetch();
    }
    fetch();
  }, []);

  const handleTabChange = (tab: string) => {
    history.push(`/voting-list?list=${tab}`);
    setCurrentPage(1);
  };

  useEffect(() => {
    setScrollTop(true);
  }, [currentPage]);

  useIonViewWillEnter(() => {
    if (isExpired) {
      history.push('/membership-card-info');
    }
  }, [isExpired]);

  return (
    <Wrapper authenticated={true} scrollTop={scrollTop} setScrollTop={setScrollTop}>
      <HomeWrapper>
        <TitleComponent
          subHeading="ブロンコスに意見を届けよう！"
          heading="Vote"
          color={teamDetails?.team?.teamColor}
          title
        />
        <ButtonWrapper>
          <Button
            color={teamDetails?.team?.teamColor}
            className={listParam !== 'endedVote' ? 'button-down' : ''}
            onClick={() => handleTabChange('ongoingVote')}
          >
            {t('Accepting Vote')}
          </Button>
          <Button
            color={teamDetails?.team?.teamColor}
            className={listParam === 'endedVote' ? 'button-down' : ''}
            onClick={() => handleTabChange('endedVote')}
          >
            {t('Ended Vote')}
          </Button>
        </ButtonWrapper>

        <VotingPollMainWrapper>
          <Loader
            isLoading={isLoading || isFetching || userProfileData?.id === undefined}
            className="home-loader"
          >
            {votesData?.votes && votesData?.votes?.length ? (
              <>
                {votesData?.votes?.map((vote: any) => (
                  <EachVotingPollWrapper key={vote?.voteMaster?.id}>
                    <VotingPoll
                      data={toVotingPollData(
                        vote?.voteMaster?.selections,
                        vote?.voteMaster?.selectionType
                      )}
                      hasVoted={vote?.userVote?.userSelected !== null}
                      avatar={teamDetails?.team?.teamImagePath}
                      title={vote?.voteMaster?.title}
                      subtitle={vote?.voteMaster?.content}
                      deadline={moment(vote?.voteMaster?.closeDate).format('YYYY.MM.DD HH:mm')}
                      id={vote?.userVote?.id}
                      color={teamDetails?.team?.teamColor}
                      allowsMultiple={vote?.voteMaster?.selectionType === 'MultipleSelect'}
                      mutate={mutate}
                      isSuccess={isSuccess}
                      isMutateLoading={isMutateLoading}
                      listParam={listParam}
                    />
                  </EachVotingPollWrapper>
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={votesData?.total ? votesData?.total?.toString() : '1'}
                  setCurrentPage={setCurrentPage}
                />
              </>
            ) : (
              <NoDataWrapper>
                <p>
                  現在、表示可能な投票はございません。
                  <br />
                  次回の投票を楽しみにお待ちください。
                </p>
              </NoDataWrapper>
            )}
          </Loader>
        </VotingPollMainWrapper>
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(VotingList);
