import React from 'react';
import styled from 'styled-components';
import { RankTitleComponent } from '../../atoms';

interface Props {
  mainHeading: string;
  mainSubheading?: string;
  subHeading?: string;
}

const Wrapper = styled.div`
  .vote {
    padding: 0px 16px;
  }
  .heading {
    color: #b80629;
    font-size: 36px;
    font-weight: 900;
    line-height: 26px;
    text-align: center;
  }
  .subheading {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 350;
    line-height: 22px;
    text-align: justify;
    padding-bottom: 20px;
  }
`;

const RankDetailsVote: React.FC<Props> = ({ mainHeading, mainSubheading, subHeading }: Props) => {
  return (
    <Wrapper className="neomorphs-shadow-in card-wrapper">
      <RankTitleComponent heading={mainHeading} subHeading={mainSubheading} />
      <div className="vote">
        <p className="subheading">
          {subHeading || 'グッズデザイン、3rdユニフォームなど全ての投票に参加することができます。'}
        </p>
      </div>
    </Wrapper>
  );
};

export { RankDetailsVote };
