import { useQuery } from 'react-query';
import { fetchTradingHistory } from '../services';
import { useState } from 'react';

const useTradingHistory = (masterId: string, enabled: boolean, currentPage: number) => {
  const [total, setTotal] = useState<string>('1');
  const [purchaseData, setPurchaseData] = useState<any>({});
  const { isLoading, isFetching } = useQuery(
    ['fetchingTrading', masterId, currentPage],
    fetchTradingHistory,
    {
      enabled: enabled,
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess({ data }) {
        setTotal(data.total);
        let tradingHistory: any = [];
        let res = Object.values(data?.history);
        res.forEach((data: any) => {
          let chunk = {
            購入日: data?.createdAt?.split('T')[0].replaceAll('-', '/') ?? '-',
            取引額: data?.price ?? '-',
            CardName: data?.utilityNftMaster?.cardName ?? '-',
            日付: data?.createdAt?.split('T')[0].replaceAll('-', '/') ?? '-',
            取引: data?.secondary === false ? '購入' : '-',
            価格: data?.price ?? '-',
            出品: data?.walletAddress,
          };
          tradingHistory.push(chunk);
        });
        setPurchaseData(tradingHistory);
      },
    }
  );
  return { purchaseData, isLoading, isFetching, total };
};

export { useTradingHistory };
