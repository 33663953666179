import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Button, TitleComponent } from '../../../components';
import { mobileContext } from '../../../utils/MobileContext';

interface Props {
  team: {
    id?: string;
    name: string;
    image: string;
    subHeading?: string;
    detail?: string | null;
    season?: string;
    foregroundImage?: string;
  };
  buttonText?: string;
  nextRoute?: string;
  showShadow?: boolean;
  smallWidth?: boolean;
}

const Wrapper = styled.div<{ isMobile: boolean; nextRoute?: string; showShadow?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-wrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 45px;
    cursor: ${(props) => props.nextRoute && 'pointer'};
    height: 185px;
    position: relative;
    box-shadow: ${(props) => (props.showShadow ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '')};

    .team-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .foreground-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
    }
  }

  .title {
    margin-top: 15px;
  }

  .btn {
    margin-top: 10px;
    width: 100%;
  }

  @media (min-width: 450px) {
    .image-wrapper {
      height: ${(props) => (props.isMobile ? '300px' : '185px')};
      border-radius: ${(props) => (props.isMobile ? '75px' : '45px')};
    }
  }
`;

const TeamCard: React.FC<Props> = ({
  team,
  buttonText,
  nextRoute,
  showShadow = false,
  smallWidth = false,
}) => {
  const history = useHistory();
  const { isMobile } = mobileContext();

  return (
    <Wrapper isMobile={isMobile} nextRoute={nextRoute} showShadow={showShadow}>
      <div className="image-wrapper">
        <IonImg
          src={team?.image}
          className="team-image"
          onClick={() => (nextRoute ? history.push(nextRoute) : '')}
        />
      </div>
      <TitleComponent
        subHeading={team?.subHeading}
        heading={team?.name}
        season={team?.season}
        className="title"
        paragraph={team?.detail ? team?.detail : ''}
        smallWidth={smallWidth}
      />
      {buttonText && (
        <Button className="btn" size="large" onClick={() => nextRoute && history.push(nextRoute)}>
          {buttonText}
        </Button>
      )}
    </Wrapper>
  );
};

export { TeamCard };
