import styled from 'styled-components';
import { IonModal } from '@ionic/react';
import React, { useState } from 'react';
import { Button, CrossButton, Divider } from '../../atoms';
import { FilterSection } from '../../molecules';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  hasCross?: boolean;
  onSearch?: any;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  initialFilters: Filters;
  setIsMainSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

export interface FilterType {
  name?: string;
  value: string | string[];
}

type Filters = FilterType[];

const IonModalComponent = styled(IonModal)`
  --width: 335px;
  --height: 85vh;
  --border-radius: 50px;
  --box-shadow: var(--shadow-down);
  --backdrop-opacity: 0.3;

  @media (max-width: 360px) {
    --width: 315px;
  }
  @media (min-width: 1024px) {
    --width: 356px;
    padding-right: 18px;
  }

  .ion-delegate-host {
    background-color: #efefef;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 27px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }
    p {
      margin: 0;
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      .title {
        font-size: 22px;
        font-weight: 900;
        color: #29c8d2;
      }
    }
    .divider {
      width: 100%;
      border-width: 1px;
      border-color: #ccc;
      opacity: 0.2;
      box-shadow: none;
      margin: 22px 0px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 14px;
  ion-button {
    width: 130px;
    height: 28px;
    font-size: 12px;
    text-transform: capitalize;
  }

  @media (max-width: 350px) {
    ion-button {
      width: 45%;
    }
  }
`;

const option1 = [
  { label: 'Basketball', value: 'バスケ' },
  // { label: 'Soccer', value: 'サッカー' },
  // { label: 'Baseball', value: '野球' },
  // { label: 'Volleyball', value: 'バレーボール' },
  // { label: 'Pro Wrestling', value: 'プロレス' },
  // { label: 'Boxing', value: 'ボクシング' },
  // { label: 'Golf', value: 'ゴルフ' },
  // { label: 'Sumo', value: '相撲' },
];

const option2 = [
  // { label: 'All', value: 'すべて' },
  { label: 'Images', value: 'Image' },
  { label: 'Videos', value: 'Video' },
];

const option3 = [
  { label: 'All', value: 'all' },
  { label: 'Selling', value: 'onSale' },
  { label: 'Sold', value: 'soldOut' },
];

const option4 = [
  // { label: 'All', value: 'すべて' },
  { label: 'Yen', value: '円' },
  // { label: 'ETH', value: 'ETH' },
  // { label: 'MATIC', value: 'MATIC' },
  // { label: 'ASTR', value: 'ASTR' },
];

const option5 = [
  // { label: 'All', value: 'すべて' },
  { label: 'Saitama Broncos', value: 'さいたまブロンコス' },
  // { label: 'Kashima Antlers', value: '鹿島アントラーズ' },
  // { label: 'Urawa Reds', value: '浦和レッズ' },
  // { label: 'Kashiwa Reysol', value: '柏レイソル' },
  // { label: 'FC Tokyo', value: 'FC東京' },
  // { label: 'Kawasaki Frontale', value: '川崎フロンターレ' },
  // { label: 'Yokohama F・Marinos', value: '横浜F・マリノス' },
  // { label: 'Shonan Bellmare', value: '湘南ベルマーレ' },
];

export const FilterModal: React.FC<Props> = ({
  show,
  setShow,
  title,
  hasCross = true,
  filters,
  setFilters,
  initialFilters,
  setIsMainSearch,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  /* 
  Note: We have to set the default filters for radio & checkbox here too because state updates are slower compared to
  radio & checkbox buttons setting using default values at first. 
  */
  // const [filters, setFilters] = useState<Filters>(initialFilters);
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [searchTeam, setSearchTeam] = useState<string>('');

  const [localState, setLocalState] = useState(filters);

  const closeModal = () => {
    setShow(false);
  };

  const filter = () => {
    if (setIsMainSearch) {
      setIsMainSearch(false);
    }
    if (setCurrentPage) {
      setCurrentPage(1);
    }
    setFilters(localState);
    closeModal();
  };

  const handleFilters = (f: FilterType) => {
    if (localState?.length) {
      const foundIndex = localState?.findIndex((d: any) => d?.name === f?.name);
      if (foundIndex !== -1) {
        const updatedArr = localState?.map((d: any, idx: any) =>
          idx === foundIndex ? { ...d, value: f?.value } : d
        );
        setLocalState(updatedArr);
      } else {
        setLocalState([...localState, f]);
      }
    } else {
      setLocalState([...localState, f]);
    }
  };

  const clearFilters = () => {
    setLocalState(initialFilters);
    setResetFilters(true);
  };

  return (
    <IonModalComponent isOpen={show} onDidDismiss={closeModal}>
      <div className="body">
        <div className="header">
          <p className="title">{title}</p>
          {hasCross && <CrossButton onClick={closeModal} />}
        </div>

        <FilterSection
          type="select-multiple"
          title="Sports"
          filterName="sports"
          options={option1}
          // selectedCheckboxValues={localState?.find((d) => d?.name === 'tag')?.value || []}
          selectedCheckboxValues={['バスケ']} // use this direct value for now, will update as API updates
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
        />
        <Divider className="divider" />

        <FilterSection
          type="select-single"
          title="NFT File"
          filterName="nftFileType"
          options={option2}
          selectedRadioValue={localState?.find((d) => d?.name === 'nftFileType')?.value || ''}
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
        />
        <Divider className="divider" />

        <FilterSection
          type="select-single"
          title="Status"
          filterName="saleStatus"
          options={option3}
          selectedRadioValue={localState?.find((d) => d?.name === 'saleStatus')?.value || ''}
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
        />
        <Divider className="divider" />

        <FilterSection
          type="select-single"
          title="Payment currency"
          filterName="paymentCurrency"
          options={option4}
          selectedRadioValue={localState?.find((d) => d?.name === 'paymentCurrency')?.value || '円'}
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
        />
        <Divider className="divider" />

        <FilterSection
          type="select-multiple"
          title="Team"
          filterName="team"
          options={option5}
          hasSearch
          onSearch={setSearchTeam}
          selectedCheckboxValues={localState?.find((d) => d?.name === 'team')?.value || []}
          handleFilters={handleFilters}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
        />
        <Divider className="divider" />

        <FilterSection
          type="no-select"
          title="Players"
          filterName="playerName"
          hasSearch
          closeModal={closeModal}
          resetFilters={resetFilters}
          handleFilters={handleFilters}
          searchedValue={(localState?.find((d) => d?.name === 'playerName')?.value as string) || ''}
          filter={filter}
        />
        <ButtonWrapper>
          <Button onClick={clearFilters}>{t('Clear')}</Button>
          <Button onClick={filter}>{t('Search')}</Button>
        </ButtonWrapper>
      </div>
    </IonModalComponent>
  );
};
