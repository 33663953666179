import axios from 'axios';
import { Auth } from 'aws-amplify';

/**
 * axios instance
 */

axios.defaults.responseType = 'json';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  async (config: any) => {
    try {
      const auth = await Auth.currentSession();
      const token = auth.getIdToken().getJwtToken();

      if (token) {
        config.headers['accessToken'] = token;
        config.headers['Authorization'] = token;
      }
    } catch (err) {
      // Sentry log goes here
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Slack error-log integration

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const errorDetails = {
        status: error.response.status,
        headers: error.response.headers,
        requestBody: error.config.params,
        responseBody: error.response.data,
      };

      const data = {
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: 'API Error',
              emoji: true,
            },
          },
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Status:*\n${errorDetails.status}`,
              },
              {
                type: 'mrkdwn',
                text: `*Headers:*\n${errorDetails.headers}`,
              },
            ],
          },
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Request Body:*\n${JSON.stringify(errorDetails.requestBody)}`,
              },
              {
                type: 'mrkdwn',
                text: `*Response Body:*\n${JSON.stringify(errorDetails.responseBody)}`,
              },
            ],
          },
        ],
      };

      if (errorDetails.responseBody.error !== 'Utility NFT not found with the provided UUID.') {
        try {
          await axios.post(process.env.REACT_APP_SLACK_WEBHOOK_URL as string, JSON.stringify(data)),
            {
              transformRequest: [
                (data: any) => {
                  return data;
                },
              ],
            };
        } catch (slackError) {
          console.error('Error sending message:', slackError);
        }
      }
    }
    return Promise.reject(error);
  }
);

export { API };
