import styled from 'styled-components';
import { useMutation } from 'react-query';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { updateProfile } from '../services';
import { useToast } from '../hooks/useToast';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, PersonalInformationForm, TitleComponent, Toast } from '../components';
import RestrictedRoute from '../withRestrictedRoute';

const StyledWrapper = styled.div`
  .header {
    padding-bottom: 1rem;
  }
  .personal-information-form {
    padding: 1rem 13.5px 25px 13.5px;
    border-radius: 50px;
    margin: 20px;
    font-weight: 350px;
    & ion-row:first-child {
      padding-left: 1rem;
    }
  }
  .bottom-btn-section {
    margin: auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.6rem;

    .prev-btn {
      width: 140px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
    ion-button {
      width: 140px;
    }
  }
`;

const PersonalInformationPage: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useToast(false);
  const { setUserProfileData, teamDetails, userProfileData } = useContext(UserContext);

  const searchParams = new URLSearchParams(location.search);
  const statusId = searchParams.get('status');
  const teamId = searchParams.get('teamId');
  const cardName = searchParams.get('card');
  const price = Number(searchParams.get('price'));

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: (response: any) => {
      setUserProfileData(response.data);
      history.push({
        pathname: `/personal-information-confirmation`,
        search: `?teamId=${teamId}&status=${statusId}&card=${cardName}&price=${price}`,
      });
    },
    onError: () => {
      setShowToast(true);
    },
  });

  return (
    <Wrapper>
      {showToast && <Toast message={t('Something went wrong. Please try again.')} type={'Error'} />}

      <StyledWrapper>
        <div className="header">
          <TitleComponent
            title
            heading={'Personal Data'}
            subHeading={t('Personal & Payment information')}
            color={teamDetails?.team?.teamColor}
          />
        </div>
        <div className="personal-information-form neomorphs-shadow-in">
          <PersonalInformationForm
            userData={userProfileData}
            mutate={mutate}
            isLoading={isLoading}
          />
        </div>
        <div className="button-wrapper">
          <Button
            size="small"
            color={teamDetails?.team?.teamColor}
            onClick={() =>
              history.push({
                pathname: `/membership-confirmation`,
                search: `?teamId=${teamId}&status=${statusId}&card=${cardName}&price=${price}`,
              })
            }
          >
            {t('Go to previous page')}
          </Button>
        </div>
      </StyledWrapper>
    </Wrapper>
  );
};
export default RestrictedRoute(PersonalInformationPage);
