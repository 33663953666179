import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import upbondServices from '../lib/UpbondEmbed';
import { Button, IonPageComponent } from '../components';

const Content = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
  height: 100vh;
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error {
    color: #499aea;
    font-size: 25px;
    font-weight: 700;
  }
  .title {
    margin-top: -15px;
    font-weight: 800;
    font-size: 20px;
  }
  .button-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .return-home {
    width: 50%;
    font-size: 12px;
  }
  .description {
    margin-top: -5px;
    font-weight: 500;
  }
`;

const ClearLocalStorage = () => {
  const { t } = useTranslation();

  const handleClick = async () => {
    await upbondServices.logout();
    window.location.href = '/top';
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <IonPageComponent>
      <Content>
        <div className="message">
          <p className="description">
            {t('Local storage has been cleared.')}
            <br />
            {t('Please login again')}
          </p>
          <div className="button-wrapper">
            <Button className="return-home" size="regular" onClick={() => handleClick()}>
              {t('Go to Top page')}
            </Button>
          </div>
        </div>
      </Content>
    </IonPageComponent>
  );
};

export default ClearLocalStorage;
