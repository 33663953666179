import React from 'react';
import styled from 'styled-components';
import { IonImg } from '@ionic/react';
// The version used is Swiperjs v6. Any newer versions show errors when imported (might be due to conflicts with Ionic).
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

interface dataType {
  id: string;
  imageUrl: string;
  linkUrl: string;
}
interface Props {
  data: dataType[] | undefined;
  noMarginBottom?: boolean;
  hasData?: boolean;
}

const ComponentWrapper = styled.div<any>`
  .container {
    width: 100%;
    position: relative;
    margin-bottom: ${(props: Props) =>
      props?.noMarginBottom ? '0px' : props?.hasData ? '60px' : '-10px'};
    margin-top: 20px;
    .swiper-container {
      height: ${(props) => (props?.hasData ? '220px' : '0')};
    }
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0px;
    }
    .swiper-pagination-bullet {
      margin-right: 16px;
      width: 12px;
      height: 12px;
      background: #29c8d2;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #01a8b4;
    }
    // Overriding Swiperjs's "display: none !important;"
    // Allow showing pagination when only total data/pages is only 1.
    .swiper-pagination-bullet:only-child {
      display: inline-block !important;
    }
  }
`;

const Wrapper = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 185px;
  overflow: hidden;
  cursor: pointer;
  ion-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

export const MarketBannerCarousel: React.FC<Props> = ({ data, noMarginBottom = false }) => {
  return (
    <ComponentWrapper noMarginBottom={noMarginBottom} hasData={data?.length !== 0}>
      <div className="container">
        <Swiper
          speed={400}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          className="mySwiper"
        >
          {data &&
            data?.map((d, idx) => (
              <SwiperSlide key={idx}>
                <Wrapper>
                  <ImageWrapper>
                    <a href={d?.linkUrl} target="_blank" rel="noreferrer">
                      <IonImg src={d?.imageUrl} className="team-image" />
                    </a>
                  </ImageWrapper>
                </Wrapper>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </ComponentWrapper>
  );
};
