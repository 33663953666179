import { t } from 'i18next';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import React, { useContext, useState } from 'react';

import { deleteUser } from '../services';
import { useToast } from '../hooks/useToast';
import { Button, Toast } from '../components';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { AuthContext } from '../utils/AuthContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const ContentWrapper = styled.div`
  padding: 0 20px;
  .custom-heading {
    font-weight: 700;
  }
  .title-wrapper {
    color: #4d4d4d;
    margin-bottom: 30px;
    p {
      text-align: center;
      font-weight: 700;
      font-size: 18px;
    }
    .sub-title {
      margin-top: -12px;
    }
  }
  .box-wrapper {
    border-radius: 50px;
    color: #4d4d4d;
    padding: 20px;
    padding-bottom: 0px;
    .title {
      text-align: center;
      font-weight: 700;
      font-size: 18px;
    }
    ul {
      padding: 0px 55px 40px;
      @media (max-width: 399px) {
        padding: 0px 38px 40px;
      }
      @media (max-width: 361px) {
        padding: 0px 30px 40px;
      }
      @media (max-width: 345px) {
        padding: 0px 25px 40px;
      }
      span {
        font-size: 15px;
        padding-right: 10px;
        font-weight: 350;
      }
    }
  }
  .collection-wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 397px) {
      grid-template-columns: 2fr;
      .each-card {
        p {
          align-self: center;
          margin: 0px;
        }
      }
    }
    .collection-image {
      width: 139px;
      height: 194px;
    }
    .each-card {
      margin-bottom: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      p {
        margin: 0px;
        padding: 0px;
      }
      .paragraph-wrapper {
        align-items: flex-start;
        margin-left: -7px;
        margin-top: 5px;
      }
      .card-subtitle {
        font-size: 9px;
        margin-top: 5px;
        padding: 0px;
        line-height: 10px;
      }
      .card-title {
        font-size: 14px;
      }
    }
  }
  .buttons-wrapper {
    padding: 0 15px;
    margin-bottom: 50px;
    margin-top: 40px;
    .cancel-button {
      margin-bottom: 25px;
    }
  }
`;

const RESPONSE = {
  // data: [
  //   {
  //     id: '98',
  //     image: './assets/images/market-carousel/nft-market-1.png',
  //     title: 'グラント・シットン',
  //     subtitle: '2021-22 SEASON',
  //   },
  //   {
  //     id: '483',
  //     image: './assets/images/market-carousel/nft-market-2.png',
  //     title: 'グラント・シットン',
  //     subtitle: '2021-22 SEASON',
  //   },
  //   {
  //     id: '43',
  //     image: './assets/images/market-carousel/nft-market-3.png',
  //     title: 'グラント・シットン',
  //     subtitle: '2021-22 SEASON',
  //   },
  //   {
  //     id: '12',
  //     image: './assets/images/market-carousel/nft-market-4.png',
  //     title: 'グラント・シットン',
  //     subtitle: '2021-22 SEASON',
  //   },
  // ],
  list: [
    { title: 'ブロンコスコイン', point: '23coin' },
    // { title: '保有デジタルカード', point: '4枚' },
  ],
};

const WithdrawlConfirmationPage = () => {
  const { list } = RESPONSE;
  const { push } = useHistory();

  const [error, setError] = useState<any>();
  const { logout } = useContext(AuthContext);
  const { userProfileData, totalTeamCoin } = useContext(UserContext);
  const [showToast, setShowToast] = useToast(false);

  const { mutate } = useMutation(deleteUser, {
    onSuccess: async () => {
      await logout();
    },
    onError: (err: any) => {
      setError(err.response.data.message);
      setShowToast(true);
    },
  });

  return (
    <Wrapper authenticated>
      {showToast && <Toast message={error} type={'Error'} />}
      <ContentWrapper>
        <div className="title-wrapper">
          <p className="title">{t('Withdrawl confirmation')}</p>
          <p className="sub-title">{t('Are you sure you want to delete?')}</p>
        </div>
        <div className="neomorphs-shadow-in box-wrapper">
          <p className="title">{t('Data that will be deleted')}</p>
          <ul>
            {list.map((each) => (
              <li key={each?.title}>
                <span>{each?.title}</span> <span>{totalTeamCoin}coin</span>
              </li>
            ))}
          </ul>
          {/* <div className="collection-wrapper">
            {data?.map((each) => (
              <div key={each?.id} className="each-card">
                <IonImg src={each?.image} className="collection-image" />
                <div className="paragraph-wrapper">
                  <p className="card-subtitle">{each?.subtitle}</p>
                  <p className="card-title">{each?.title}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>
        <div className="buttons-wrapper">
          <Button size="large" className="cancel-button" onClick={() => push('/profile')}>
            {t('Cancel')}
          </Button>
          <Button size="large" onClick={() => mutate(userProfileData?.id)}>
            {t('Withdrawl')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(WithdrawlConfirmationPage);
