import axios, { AxiosResponse } from 'axios';
import { UploadImagePayload, UploadImageUrlResponse } from '../types/image-upload';
import { API } from './api';
import { QueryFunctionContext } from 'react-query';

export const fetchUserData = async ({ queryKey }: QueryFunctionContext) => {
  const userId = queryKey[1];
  return API.get(`user?userId=${userId}`);
};

export const updateProfile = async ({ userId, details }: any) => {
  return API.put(`/user?userId=${userId}`, details);
};

export const deleteUser = async (values: any) => {
  const userId = values;
  return API.delete(`/user?userId=${userId}`);
};

export const getUploadImageUrl = async (userId: string, fileFormat: string = 'image/png') => {
  return API.get<UploadImageUrlResponse>(
    `/user/getIconUploadUrl?userId=${userId}&fileFormat=${fileFormat}`
  );
};

export const uploadImage = async (payload: UploadImagePayload) => {
  const { url, data } = payload;
  return axios.post(url ?? '', data);
};

export const fetchNFTOwnedUser = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/user/utilitynft/list?userId=${queryKey[1]}&teamId=${queryKey[2]}&page=${queryKey[3]}`);
};
