import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';

interface Props {
  name: string;
  front: string;
  back: string;
  id?: string;
  font?: string;
}

const differentCardFaces: any = {
  diamond: '/assets/images/cards/diamond.png',
  platinum: '/assets/images/cards/platinum.png',
  gold: '/assets/images/cards/gold.png',
  silver: '/assets/images/cards/silver.png',
  red: '/assets/images/cards/red.png',
};

const Container = styled.div<any>`
  width: 100%;
  margin: auto;
  margin-top: 20px;
  color: white;
  height: 190px;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.3));

  @media (max-width: 375px) {
    height: 155px;
  }

  perspective: 1500px;

  .wrapper {
    margin: auto;
    width: 310px;
    position: relative;
  }

  .box-area {
    position: relative;
    transform-style: preserve-3d;
    animation: spin 8s linear infinite;
    transition: transform 1s ease-in-out;
  }

  .box-clicked {
    position: relative;
    transform-style: preserve-3d;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .box {
    position: absolute;
    width: 100%;
  }

  .front {
    height: 220px;
    width: 100%;
  }

  .right,
  .right-top,
  .right-bottom,
  .right-top-cover,
  .right-bottom-cover,
  .left,
  .left-top,
  .left-bottom,
  .left-top-cover,
  .left-bottom-cover {
    width: 5px;
    height: 160px;
    margin-top: 15px;
    transform: rotateY(-270deg) translateZ(305px) translateX(5px);
    transform-origin: 100% 0;
    background-color: #4d4d4d;
  }

  .left {
    background-color: #4d4d4d;
    transform: rotateY(270deg) translateZ(5px) translateX(0px);
  }

  .right-top-cover,
  .left-top-cover {
    height: 10px;
    margin-top: 1px;
    transform: rotateY(-270deg) translateZ(293px) translateX(5px) rotateX(55deg);
  }

  .left-top-cover {
    transform: rotateY(270deg) translateZ(-6px) rotateX(55deg);
  }

  .right-bottom-cover,
  .left-bottom-cover {
    height: 10px;
    margin-top: 182px;
    transform: rotateY(-270deg) translateZ(300px) translateX(5px) rotateX(-60deg);
  }

  .left-bottom-cover {
    transform: rotateY(270deg) translateZ(-1px) rotateX(-58deg);
  }

  .right-top,
  .right-bottom,
  .left-top {
    height: 10px;
    margin-top: 6px;
    transform: rotateY(-270deg) translateZ(301px) translateX(5px) rotateX(25deg);
  }

  .left-top {
    transform: rotateY(270deg) translateZ(1.5px) rotateX(20deg);
  }

  .right-bottom,
  .left-bottom {
    margin-top: 175px;
    transform: rotateY(-270deg) translateZ(304px) translateX(5px) rotateX(-36deg);
  }

  .left-bottom {
    height: 10px;
    transform: rotateY(270deg) translateZ(4.5px) rotateX(-35deg);
  }

  .back {
    transform: rotateY(180deg) translateZ(5px);
    height: 220px;
    width: 100%;

    .name {
      font-family: ${(props: any) => props.fontFace};
      position: absolute;
      top: 48px;
      /* left: 60px; */
      color: black;
      width: 100%;
      text-align: center;

      @media (max-width: 375px) {
        top: 34px;
        /* left: 52px; */
      }
    }
  }

  @media (max-width: 375px) {
    .wrapper {
      width: 250px;
    }

    .left,
    .right {
      height: 125px;
    }

    .right {
      transform: rotateY(-270deg) translateZ(245px) translateX(5px);
    }

    .right-bottom-cover,
    .left-bottom-cover {
      height: 10px;
      margin-top: 140px;
      transform: rotateY(-270deg) translateZ(245px) translateX(5px) rotateX(-40deg);
    }

    .left-bottom-cover {
      transform: rotateY(270deg) translateZ(5.5px) rotateX(-45deg);
    }

    .right-top-cover {
      margin-top: 1px;
      transform: rotateY(-270deg) translateZ(235.5px) translateX(5px) rotateX(55deg);
    }

    .right-top {
      transform: rotateY(-270deg) translateZ(243px) translateX(4.8px) rotateX(10deg);
    }

    .right-bottom,
    .left-bottom {
      margin-top: 147px;
      transform: rotateY(-270deg) translateZ(240px) translateX(5px) rotateX(-65deg);
    }

    .left-bottom {
      height: 10px;
      transform: rotateY(270deg) translateZ(1px) rotateX(-65deg);
    }
  }
`;

export const RotatingCard: React.FC<Props> = ({
  name,
  back,
  front,
  id,
  font = `'Dancing Script', cursive`,
}) => {
  const rotateCard = (deg: string) => {
    if (typeof window !== 'undefined') {
      const body = document.querySelector<HTMLElement>(`#${id}`);

      body!.style.transform = `rotateY(${deg}deg)`;
    }
  };

  return (
    <Container className="showcase" degree fontFace={font}>
      <div className="wrapper">
        <div className="box-area" id={id}>
          <div className="box front" onClick={() => rotateCard('180')}>
            {/* <IonImg src={differentCardFaces[card]} alt="card" className="img" draggable="false" /> */}
            <IonImg src={front} alt="card" className="img" draggable="false" />
          </div>
          <div className="box right-top-cover"></div>
          <div className="box right-top"></div>
          <div className="box right"></div>
          <div className="box right-bottom-cover"></div>
          <div className="box right-bottom"></div>
          <div className="box back" onClick={() => rotateCard('0')}>
            <IonImg src={back} alt="card" className="img" draggable="false" />
            <p className="name">{name}</p>
          </div>
          <div className="box left-top-cover"></div>
          <div className="box left-top"></div>
          <div className="box left"></div>
          <div className="box left-bottom-cover"></div>
          <div className="box left-bottom"></div>
        </div>
      </div>
    </Container>
  );
};
