import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useState } from 'react';
import RestrictedRoute from '../withRestrictedRoute';
import { useHistory, useLocation } from 'react-router';

import { AuthContext } from '../utils/AuthContext';
import { useGetTeamDetail } from '../hooks/useGetTeamDetail';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TeamCard, PurchaseDetail, Divider, Loader } from '../components';

const HomeWrapper = styled.div`
  .section {
    margin: 2rem 0 2rem 0;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin: 35px 0px 20px 0px;
  }
  .purchase-button {
    font-weight: 700;
    font-size: 18px;
    margin: 5px 25px;
  }
  .back-button {
    width: 12em;
  }
  .divider1 {
    margin: 45px 33px 30px;
  }
  .divider2 {
    margin: 30px 33px 45px;
  }
`;

const CardWrapper = styled.div`
  padding: 16px 15px 28px;
  margin: 5px 20px;
  border-radius: 50px;
`;

const Content = styled.div`
  padding: 10px 42px 0;
  width: 85%;
  margin: 0 auto;
  text-align: justify;

  p {
    font-size: 15px;
    color: #000000;
    font-weight: 200;
  }
`;

const MembershipConfirmation: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<any>();

  const [expiryDate, setExpiryDate] = useState('');
  const { authenticated, login } = useContext(AuthContext);

  const searchParams = new URLSearchParams(location.search);
  const teamId = searchParams.get('teamId');
  const status = searchParams.get('status');
  const cardName = searchParams.get('card');
  const price = Number(searchParams.get('price'));

  const {
    data: teamData,
    isLoading: teamLoading,
    isError: isTeamDataError,
  } = useGetTeamDetail(teamId);

  const paymentHandler = async () => {
    if (!authenticated) {
      await login();
    } else {
      history.push({
        pathname: `/personal-information`,
        search: `?teamId=${teamId}&status=${status}&card=${cardName}&price=${price}`,
      });
      sessionStorage.removeItem('redirect_to');
    }
  };

  const backButtonHandler = () => {
    if (sessionStorage.getItem('redirect_to')) {
      sessionStorage.removeItem('redirect_to');
    }

    history.push({
      pathname: `/membership-rank`,
      search: `?teamId=${teamId}&status=${status}`,
    });
  };

  useIonViewDidEnter(() => {
    if (!authenticated) {
      sessionStorage.setItem('redirect_to', window.location.href);
    }

    if (authenticated && sessionStorage.getItem('redirect_to')) {
      sessionStorage.removeItem('redirect_to');
    }
  }, []);

  React.useEffect(() => {
    if (isTeamDataError) {
      history.replace('/404');
    }
  }, [isTeamDataError]);

  React.useEffect(() => {
    if (teamData) {
      let expiryDate = teamData?.seasons?.map((item: any) => {
        if (item?.isCurrentSeason) {
          // Extract year, month, and day
          const year = item?.extendDate.substring(0, 4);
          const month = item?.extendDate.substring(4, 6);
          const day = item?.extendDate.substring(6, 8);

          // Format the date as YYYY-MM-DD
          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        }
      });
      setExpiryDate(expiryDate);
    }
  }, [teamData]);

  return (
    <Wrapper>
      <Loader isLoading={teamLoading} className="home-loader">
        <HomeWrapper>
          <CardWrapper className="neomorphs-shadow-out">
            <TeamCard team={teamData} />
          </CardWrapper>
          <Divider className="divider1" />
          <PurchaseDetail membershipType={cardName} amount={price?.toLocaleString('en-US')} />
          <Content>
            <p>
              {t(
                'When making your first purchase, you will be required to pay the total of the “membership price” and “annual fee” in one lump sum.'
              )}
            </p>
            <p>
              {expiryDate}
              {t('and you will need to renew your annual membership fee at that time.')}
            </p>
          </Content>
          <Divider className="divider2" />
          <Button
            size="large"
            className="purchase-button"
            onClick={() => {
              paymentHandler();
            }}
            color={teamData?.teamColor}
          >
            {t('Personal informations')}
          </Button>
          <div className="button-container">
            <Button size="small" className="back-button" onClick={() => backButtonHandler()}>
              {t('Back to rank list')}
            </Button>
          </div>
        </HomeWrapper>
      </Loader>
    </Wrapper>
  );
};

export default RestrictedRoute(MembershipConfirmation);
