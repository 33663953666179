import { API } from './api';
import { QueryFunctionContext } from 'react-query';

export const fetchVotes = ({ queryKey }: QueryFunctionContext) => {
  const page = queryKey[1];
  const userId = queryKey[2];
  const isClosed = queryKey[3];
  return API.get(`vote/list?page=${page}&userId=${userId}&isClosed=${isClosed}`);
};

export const fetchVotingDistribution = ({ queryKey }: QueryFunctionContext) => {
  const VoteId = queryKey[1];
  const UserId = queryKey[2];

  return API.get(`/vote?voteId=${VoteId}&userId=${UserId}`);
};

export const castVote = (voteDetails: any) => {
  return API.post('/vote', voteDetails);
};
