import styled from 'styled-components';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../utils/AuthContext';
import { Button, IonPageComponent } from '../components';

const Content = styled.div<any>`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 40px;
  height: 100vh;
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error {
    color: #499aea;
    font-size: 25px;
    font-weight: 700;
  }
  .title {
    margin-top: -15px;
    font-weight: 800;
    font-size: 20px;
  }
  .button-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .return-home {
    width: 50%;
    font-size: 12px;
  }
  .description {
    margin-top: -5px;
    font-weight: 500;
  }
`;

const NotFoundPage = () => {
  const { authenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleClick = () => {
    if (authenticated) {
      window.location.href = '/home';
    } else {
      window.location.href = '/top';
    }
  };

  return (
    <IonPageComponent>
      <Content authenticated={authenticated}>
        <div className="message">
          <p className="error">404!</p>
          <p className="description">{t('The Page you are looking for does not exists!')}</p>
          <div className="button-wrapper">
            <Button className="return-home" size="regular" onClick={() => handleClick()}>
              {t('Return Home')}
            </Button>
          </div>
        </div>
      </Content>
    </IonPageComponent>
  );
};

export default NotFoundPage;
