import { IonImg } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { Lightbox } from 'yet-another-react-lightbox';
/* eslint-disable */
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import { useTranslation } from 'react-i18next';
/* eslint enable */

const Wrapper = styled.div<Props>`
  text-align: ${(props: Props) => (props?.textOpener ? 'left' : 'center')};

  ion-img {
    cursor: pointer;
    padding: 0rem 0 2rem 0;
  }
  h5 {
    margin-bottom: 1rem;
    font-weight: 350;
    font-size: 15px;
  }
  .text-link {
    color: #b80629;
    font-size: 12px;
    font-weight: 350;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

interface Props {
  showButton?: boolean;
  header?: string;
  IMAGE_URL?: string;
  enableCaptionButtons?: boolean;
  buttonLink?: string;
  textOpener?: string;
}

export const ImageGallery: React.FC<Props> = ({
  header,
  IMAGE_URL = '/assets/images/membership-benefit.jpg',
  enableCaptionButtons = false,
  buttonLink,
  textOpener = '',
}: Props) => {
  const { t } = useTranslation();
  // Refs
  const captionsRef = React.useRef<any>(null);

  // Open and Close
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Wrapper textOpener={textOpener}>
      {header ? <h5>{header}</h5> : ''}
      {textOpener ? (
        <span onClick={handleOpen} className="text-link">
          {t(textOpener)}
        </span>
      ) : (
        <IonImg src={IMAGE_URL} onClick={handleOpen} />
      )}
      <Lightbox
        plugins={[Zoom, Download, Captions]}
        open={open}
        close={handleClose}
        captions={{ ref: captionsRef }}
        on={{
          zoom: (e: any) => {
            if (e?.zoom > 1) captionsRef?.current?.hide();
            else captionsRef?.current?.show();
          },
        }}
        slides={
          enableCaptionButtons
            ? [
                {
                  src: IMAGE_URL,
                  description: (
                    <>
                      <a href={buttonLink || ''} className="large-btn" target="_blank">
                        アクセス
                      </a>
                      <button className="small-btn" onClick={handleClose}>
                        閉じる
                      </button>
                    </>
                  ),
                },
              ]
            : [{ src: IMAGE_URL }]
        }
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        controller={{ closeOnBackdropClick: true }}
        className={enableCaptionButtons ? 'custom-yarl-has-buttons' : ''}
        carousel={{ finite: true }}
      />
    </Wrapper>
  );
};
