import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import React, { useContext, useEffect, useState } from 'react';

import PrivateRoute from '../withPrivateRoute';
import { fetchMarketFavouriteListAPI, likeMarketCard } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Loader, MarketCard, TitleComponent, Pagination } from '../components';
import { UserContext } from '../utils/UserContext';
import { useTranslation } from 'react-i18next';
import { useIonViewDidEnter } from '@ionic/react';
import { useLikingCardId } from '../hooks/useLikingCardId';

const FavouriteListWrapper = styled.div`
  padding: 37px 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 17px;
  grid-column-gap: 5px;

  ion-card {
    width: 100%;
  }
`;

const Message = styled.div`
  font-weight: 350;
  margin-left: 50%;
  width: 300px;
`;

const PaginationWrapper = styled.div<{ totalPage?: string }>`
  padding: 0px 40px;
  margin-bottom: ${(props) => (props?.totalPage === '1' ? '0' : '35px')};
`;

const MarketFavouriteList = () => {
  const { t } = useTranslation();

  const [totalPage, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { userProfileData, teamDetails } = useContext(UserContext);

  const {
    data: favouriteData,
    isFetching: favouriteFetching,
    refetch,
  } = useQuery(
    ['fetchFavouriteData', userProfileData?.id, teamDetails?.team?.id, currentPage],
    fetchMarketFavouriteListAPI,
    {
      enabled: userProfileData?.id ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  // Like Market Card API
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard, {
    onSuccess: () => {
      setLikingCardId('');
    },
  });

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  useEffect(() => {
    if (favouriteData?.data?.total) {
      setTotalPages(favouriteData?.data?.total?.toString());
    }
  }, [favouriteData]);

  // Refetch to get new likes
  useIonViewDidEnter(() => {
    if (userProfileData?.id) refetch();
  }, []);

  return (
    <Wrapper>
      <Loader isLoading={favouriteFetching} className="home-loader">
        <TitleComponent title heading="Favorite" subHeading="Favorite" color="#29C8D2" />
        <FavouriteListWrapper>
          {favouriteData && favouriteData?.data?.utilityNfts.length == 0 ? (
            <Message>{t('There is no data to display')}</Message>
          ) : (
            favouriteData?.data?.utilityNfts?.map((item: any) => (
              <MarketCard
                data={{
                  image: item?.nft?.templateFileUrl,
                  teamName: teamDetails?.team?.teamName,
                  teamLogo: teamDetails?.team?.primaryLogoImagePath,
                  title: item?.nft?.cardName,
                  price: `${item?.nft?.price?.toLocaleString()}円`,
                  quantity: `${item?.nft?.currentSoldNumber?.toString()}/${item?.nft?.maxSalesNumber?.toString()}`,
                  id: item?.nft?.id,
                  // For favorites, we have to pass userId for like-system to work
                  favorites: [{ userId: item?.userId }],
                  teamId: item?.teamId,
                }}
                key={item?.id}
                onLikeClick={handleLike}
                isLiking={isLiking}
                likingCardId={likingCardId}
                setLikingCardId={setLikingCardId}
                showLikeNumber={false}
              />
            ))
          )}
        </FavouriteListWrapper>
        <PaginationWrapper totalPage={totalPage.toString()}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPage.toString()}
          />
        </PaginationWrapper>
      </Loader>
    </Wrapper>
  );
};

export default PrivateRoute(MarketFavouriteList);
