import React from 'react';
import styled from 'styled-components';
// The version used is Swiperjs v6. Any newer versions show errors when imported (might be due to conflicts with Ionic).
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.min.css';

import { MarketCard } from '../MarketCard';

export type MarketCarouselData = {
  teamName?: string;
  image?: string;
  price?: string;
  title?: string;
  quantity?: string;
  price_tag?: string;
  id?: string;
  likesNumber?: number;
  favorites?: { userId?: string }[];
  teamId?: string;
};
interface MarketCarouselProps {
  data?: MarketCarouselData[];
  color?: string;
  onLikeClick?: (nftMasterId?: string) => void;
  isLiking?: boolean;
  likingCardId?: string;
  setLikingCardId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  showLikeNumber?: boolean;
}

const Wrapper = styled.div<{ singleData?: boolean; doubleData?: boolean }>`
  .swiper-wrapper {
    left: ${(props) => (props?.singleData || props?.doubleData ? '0px' : '-98px')};

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

// Install Swiper modules
SwiperCore.use([Autoplay]);

const MarketCarousel: React.FC<MarketCarouselProps> = ({
  data,
  onLikeClick,
  isLiking,
  likingCardId,
  setLikingCardId,
  showLikeNumber = true,
}) => {
  return (
    <Wrapper singleData={data && data?.length === 1} doubleData={data && data?.length === 2}>
      <Swiper
        slidesPerView={data?.length === 1 ? 3 : data?.length === 2 ? 2 : 3}
        speed={400}
        spaceBetween={data?.length === 2 ? -40 : 0}
        width={data?.length! >= 3 ? 453 : null}
        centeredSlides={data?.length === 2 ? false : true}
        loop={data?.length! <= 2 ? false : true}
        pagination={false}
        grabCursor={true}
        autoplay={data?.length! <= 2 ? false : { delay: 2500, disableOnInteraction: false }}
        className="mySwiper"
        allowTouchMove={data?.length! <= 2 ? false : true}
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <MarketCard
              data={item}
              onLikeClick={onLikeClick}
              isLiking={isLiking}
              likingCardId={likingCardId}
              setLikingCardId={setLikingCardId}
              showLikeNumber={showLikeNumber}
              isSlider
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};

export { MarketCarousel };
