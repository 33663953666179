import styled from 'styled-components';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  Toast,
  Button,
  Loader,
  LevelChange,
  TitleComponent,
  TeamCardComponent,
} from '../components';

const Contain = styled.div`
  padding: 0 35px;

  .img-wrapper {
    margin: 30px 0;
  }

  .loader {
    margin-top: 50%;
  }

  .back-button-wrapper {
    display: flex;
    justify-content: center;

    .back-btn {
      margin-top: 25px;
      width: 140px;
      text-align: center;
    }
  }

  .confirmation-message {
    font-size: 15px;
    font-weight: 350;
    text-align: center;
    margin-bottom: 35px;
  }
  .information-container {
    margin: 10px 0px;
    margin-bottom: 40px;
    font-size: 15px;
    text-align: center;
    text-align: center;
    font-weight: 300;
    padding: 0 10px;
    span {
      font-size: 15px;
    }

    .point,
    .current-balance {
      font-weight: 500;
      font-size: 15px;
      margin-top: -10px;
    }
  }
`;

const LevelUp = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [showToast, setShowToast] = useToast(false);
  const { teamDetails, isLoading, totalTeamCoin } = useContext(UserContext);

  const PREV_LEVEL = teamDetails?.level;
  const NEXT_LEVEL = teamDetails?.level + 1;
  const COLOR = teamDetails?.team?.teamColor;

  const handleLevelUp = () => {
    const localData = localStorage.getItem('levelUpdatedAt');
    const updatedTime = localData && JSON.parse(localData);
    const now = new Date();
    const currentTime = now.getTime();

    if (localData) {
      if (currentTime < updatedTime + 60000) {
        setShowToast(true);
      } else {
        history.push('/level-up-confirmation');
      }
    } else {
      history.push('/level-up-confirmation');
    }
  };

  return (
    <Wrapper authenticated>
      {showToast && (
        <Toast
          message="最後のレベルアップから1分経ってから再度レベルアップできるようになります。"
          type={'Error'}
        />
      )}
      <Contain>
        <Loader isLoading={isLoading} className="loader">
          <TitleComponent heading="Level Up" subHeading="Level up" title color={COLOR} />
          <div className="img-wrapper">
            <TeamCardComponent />
          </div>

          <LevelChange color={COLOR} prev_level={PREV_LEVEL} next_level={NEXT_LEVEL} />
          <div className="information-container">
            <p className="information">
              <span className="point">
                {teamDetails?.requiredToken.toLocaleString('en-US')}coin
              </span>
              <span>{t('points will be deduced from your current point.')}</span>
            </p>
            <p className="current-balance">
              {t('Current owned Points : ')}
              {totalTeamCoin}coin
            </p>
          </div>
          <Button
            size="large"
            color={COLOR}
            onClick={handleLevelUp}
            disabled={teamDetails?.requiredToken > totalTeamCoin}
            // disabled // prod-time
          >
            {t('Level UP')}
          </Button>
          <div className="back-button-wrapper">
            <Button
              className="back-btn"
              size="small"
              color={COLOR}
              onClick={() => history.goBack()}
            >
              {t('Back')}
            </Button>
          </div>
        </Loader>
      </Contain>
    </Wrapper>
  );
};

export default PrivateRoute(LevelUp);
