import React from 'react';
import { useQuery } from 'react-query';

import { getUserGiftList } from '../services';

const useGetUserGiftList = (teamId: string, conditionToEnable: boolean) => {
  const { data: masterId } = useQuery(['fetchGiftData', teamId], getUserGiftList, {
    enabled: conditionToEnable,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: (res) => {
      const result: { uniformMasterId?: string; tshirtMasterId?: string } = {};
      res?.data.gifts.map((items: any) => {
        if (items.name === 'ユニフォーム') {
          result.uniformMasterId = items.id;
        } else if (items.name === 'Tシャツ') {
          result.tshirtMasterId = items.id;
        }
      });
      return result;
    },
  });

  return { masterId };
};

export { useGetUserGiftList };
