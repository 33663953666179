import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import React, { useContext, useState } from 'react';

import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { updateProfile, postGift } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { useGetUserGiftList } from '../hooks/useGetUserGIftList';
import { BackCardGenerator, TitleComponent, VideoCover, Toast } from '../components';

const NFT_GENERATION_VALUES = {
  image: './assets/images/cards/back.png',
  // fontOptions: [
  //   { label: 'Arial', value: `Arial, Helvetica, sans-serif` },
  //   { label: 'Dancing Script', value: `'Dancing Script', cursive` },
  //   { label: 'Times New Roman', value: `'Times New Roman', Times, serif` },
  // ],
};

const HomeWrapper = styled.div`
  margin-bottom: 20px;
  #title-section {
    padding: 1.5rem 2rem 0.5rem 2rem;
    .paragraph {
      text-align: center;
    }
  }
  #card-generation-section {
    padding-bottom: 1rem;
  }
`;
const NftGeneration: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [shirtSize, setShirtSize] = useState<string>('');
  const [uniformDetail, setUniformDetail] = useState<any>({
    size: '',
    number: '',
    name: '',
  });
  const [error, setError] = useState<string>('');
  const [showToast, setShowToast] = useToast(false);

  const { status, teamDetails, update, userProfileData } = useContext(UserContext);

  const { masterId } = useGetUserGiftList(teamDetails?.team?.id, !!teamDetails?.team?.id);

  const { mutate: giftMutate, isLoading: giftLoading } = useMutation(postGift, {
    onSuccess: async () => {
      if (status?.statusNameEnglish === 'GOLD') {
        await update();
        history.push('/welcome');
      } else {
        if (uniformDetail?.name === '') {
          uniformMutate({
            masterId: masterId?.uniformMasterId ?? '',
            userId: userProfileData?.id,
            choice: {
              size: uniformDetail?.size,
              number: uniformDetail?.number,
            },
          });
        } else {
          uniformMutate({
            masterId: masterId?.uniformMasterId ?? '',
            userId: userProfileData?.id,
            choice: {
              size: uniformDetail?.size,
              number: uniformDetail?.number,
              name: uniformDetail?.name,
            },
          });
        }
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  const { mutate: uniformMutate, isLoading: uniformLoading } = useMutation(postGift, {
    onSuccess: async () => {
      await update();
      history.push('/welcome');
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: async () => {
      if (status?.statusNameEnglish !== 'RED' && status?.statusNameEnglish !== 'SILVER') {
        giftMutate({
          masterId: masterId?.tshirtMasterId ?? '',
          userId: userProfileData?.id,
          choice: {
            size: shirtSize,
          },
        });
      } else {
        await update();
        history.push('/welcome');
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  return (
    <Wrapper translate={false}>
      <HomeWrapper translate="no">
        {showToast && <Toast message={t(error)} type={'Error'} />}

        {/* <VideoCover
          src={`${window.location.origin}/assets/video/cover-video.mp4`}
          color={teamDetails?.team?.teamColor}
        /> */}

        <img src="/assets/images/nft-generation.png" />

        <div id="title-section">
          <TitleComponent
            heading="In Progress"
            paragraph={t('Enter the name  on your membership card ※Alphabets only')}
            title={true}
            color={teamDetails?.team?.teamColor}
            flashing={true}
          />
        </div>
        <div id="card-generation-section">
          <BackCardGenerator
            image={NFT_GENERATION_VALUES.image}
            // fontOptions={NFT_GENERATION_VALUES.fontOptions}
            color={teamDetails?.team?.teamColor}
            mutate={mutate}
            isLoading={isLoading || giftLoading || uniformLoading}
            shirtSize={shirtSize}
            setShirtSize={setShirtSize}
            setUniformDetail={setUniformDetail}
            uniformDetail={uniformDetail}
          />
        </div>
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(NftGeneration);
