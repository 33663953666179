import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TitleComponent, Header, Footer, Loader } from '../components';
import { AuthContext } from '../utils/AuthContext';
import { IonPage, useIonViewDidEnter } from '@ionic/react';
import { mobileContext } from '../utils/MobileContext';
import { UserContext } from '../utils/UserContext';
import { useHistory } from 'react-router';
import RestrictedRoute from '../withRestrictedRoute';

interface TermsProps {
  title?: string;
  descriptions?: string;
}

interface Props {
  TermsConditions?: Array<TermsProps>;
}

const Contain = styled.div`
  width: 100vw;
  overflow: auto;
  --ion-background-color: none;
  &.no-scroll {
    --overflow: hidden;
  }
`;

const Container = styled.div<any>`
  max-width: ${(props) => (props.isMobile ? '100%' : '414px')};
  margin: auto;
  position: relative;
  min-height: 100vh;

  background-color: var(--ion-color-primary);

  @media (max-width: 480px) {
    min-width: 100%;
  }
  #sticky-header {
    position: sticky;
    top: 0;
    background-color: #eeeeee;
    z-index: 999;
  }

  .footer-wrapper {
    bottom: -44px !important;
    padding: ${(props) => (props.isAuthenticated ? '3px 0' : null)};
    position: ${(props) => (props.loggedin && props.isFirstStepCompleted ? 'sticky' : null)};
    bottom: ${(props) => (props.isAuthenticated ? '0' : null)};
    width: ${(props) => (props.isAuthenticated ? '414px' : null)};
    @media (max-width: 480px) {
      width: ${(props) => (props.isAuthenticated ? '100%' : null)};
    }
    background: transparent;
    z-index: 100;
  }
`;

const HomeWrapper = styled.div<any>`
  min-height: ${(props) =>
    props.isAuthenticated || (props.loggedin && props.isFirstStepCompleted) ? '79vh' : '53vh'};
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0.5em 1.5em;
  margin: 0.5em 1.4em;
  border-radius: 3rem;
  /* height: 70vh;
  overflow: auto; */
  .details {
    .sub-title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .title {
    margin: 2em 0em;
  }
  .terms-condition {
    text-align: left;
    margin: 2.2em 0em;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      margin-top: -2px;
      text-align: justify;
    }
    ol {
      padding-inline-start: 20px !important;
    }
  }
  .end-title {
    margin: 1em 0em;
  }
`;

const BackButton = styled.div`
  margin: 2em 0em;
  display: flex;
  justify-content: center;
  .back {
    width: 13em;
    background: var(--ion-color-primary);
    box-shadow: var(--shadow-up);
    color: ${(props) => (props.color ? props.color : '#29c8d2')};
    border-radius: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    opacity: 1;

    :active {
      border-radius: 50px;
      color: #cccccc;
      box-shadow: var(--shadow-down);
    }
  }
`;

const EndRight = styled.div`
  text-align: right;
`;

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
`;

const TermsAndConditions: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { isMobile } = mobileContext();
  const { teamDetails, isFirstStepCompleted, isLoading } = useContext(UserContext);

  const { authenticated, loading }: any = useContext(AuthContext);

  useIonViewDidEnter(() => {
    document.title = '利用規約 - Fanique';
    if (contentRef) {
      contentRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (containerRef) {
      containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [window.location.pathname]);

  return (
    <IonPage>
      <Contain ref={containerRef}>
        <Container
          isMobile={isMobile}
          loggedin={authenticated}
          isAuthenticated={authenticated}
          color={teamDetails?.team?.teamColor}
          isFirstStepCompleted={isFirstStepCompleted}
        >
          <LoaderComponent isLoading={loading || isLoading} className="loader">
            <Header
              authenticated={false}
              color={authenticated ? teamDetails?.team?.teamColor : ''}
            />
            <HomeWrapper
              className="neomorphs-shadow-in"
              style={{ height: '70vh', overflow: 'auto' }}
              isAuthenticated={authenticated}
              isFirstStepCompleted={isFirstStepCompleted}
              ref={contentRef}
            >
              <div className="title">
                <TitleComponent heading="Fanique利用規約" color="#4D4D4D" />
              </div>
              <div className="details">
                <p className="description">
                  この利用規約（以下「本規約」といいます。）は、株式会社スカラ（以下「当社」といいます。）が運営するNFTストア「Fanique」上にて提供するサービス（以下「本サービス」といいます。）の利用に関する条件を定めるものです。
                </p>
              </div>

              <div>
                <div className="terms-condition">
                  <h5>第1条（適用）</h5>
                  <ol>
                    <li>
                      本規約は、ユーザー（次条第6号で定義されます。）と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
                    </li>
                    <li>
                      当社が当社ウェブサイト（次条第13号で定義されます。）上で随時掲載する本サービスに関するガイドライン、ポリシー、説明事項、注意事項等は、本規約の一部を構成するものとします。
                    </li>
                    <li>
                      本規約と本サービスに関する個別の約款（以下「個別約款」といいます。）の規定が異なるときは、個別約款の規定が本規約の規定に優先して適用されるものとします
                    </li>
                    <li>
                      本サービスの利用のために当社以外の第三者が提供するサービス（以下「外部サービス」といいます。）の利用が必要となる場合があります。外部サービスに対する責任は、当該外部サービスを提供する第三者が負います。また、ユーザーは、当該外部サービスの利用にあたり、当該外部サービスを提供する第三者が定める利用規約その他の条件を遵守するものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第2条（定義）</h5>
                  <p>本規約上で使用する用語の定義は、次に定めるとおりとします。</p>
                  <ol>
                    <li>
                      「NFT」とは、Non-Fungible
                      Tokenの略称であり、ブロックチェーン上で発行されるTokenのうち、Token自体に固有の値や属性を持たせた代替性のないものをいいます。
                    </li>
                    <li>
                      「メンバーシップ」とは、権利者（第11号で定義されます。以下同じです。）その他当社が定める者から一定の役務の提供等を受けることができる地位を表章したNFTをいいます。
                    </li>
                    <li>
                      <p>「本商品」とは、次に掲げるものをいいます。</p>
                      <ol type="a">
                        <li>メンバーシップ</li>
                        <li>
                          当社所定のキャラクター、ゲームアイテム、アートまたは音楽等を表章するいわゆるコレクタブルNFT
                        </li>
                        <li>
                          当社所定の投票権、特典応募権、チケット先行購入権および特別イベント参加権を表章するNFT
                        </li>
                        <li>前各号のほか、本サービス上で当社が販売するNFT</li>
                      </ol>
                    </li>
                    <li>
                      「本コンテンツ」とは、本商品に紐づく静止画または動画形式のコンテンツ（データのファイル形式を問いません。以下同様です。）をいいます。
                    </li>
                    <li>
                      「デジタルアセット」とは、静止画、動画および音声等のデータ、本商品および本コンテンツならびに第10条（チームコインの取り扱い）で定義するチームコインその他デジタル方式の資産をいいます。
                    </li>
                    <li>
                      「ユーザー」とは、第3条（ユーザー登録）第1項に基づきユーザー登録を行った者をいいます。
                    </li>
                    <li>
                      「ユーザーID」とは、ユーザーの識別および本サービス利用のために、当社または当社が別途指定する第三者が当該ユーザーに付与する英文字、数字または記号を組合せた文字列をいいます。
                    </li>
                    <li>
                      「ウォレット」とは、ブロックチェーン上の台帳を参照するための秘密鍵を保管するシステムをいいます。
                    </li>
                    <li>
                      「本ウォレット」とは、株式会社UPBONDが提供するウォレット「UPBOND
                      Wallet」または別途当社の指定する第三者が提供するウォレットをいいます。
                    </li>
                    <li>
                      「知的財産権等」とは、全世界における、特許権、実用新案権、意匠権、商標権、著作権（翻訳・翻案権および二次的著作物の利用に関する原著作者の権利を含みます。）、その他一切の知的財産権およびそれらの登録、更新または延長等の手続を行う権利ならびに肖像権およびパブリシティ権をいいます。
                    </li>
                    <li>
                      「権利者」とは、当社に対して本商品および本コンテンツの利用（複製、譲渡、改変または公衆送信する権利を含みますが、これらに限られません。）を許諾する正当な権原を有する第三者をいいます。
                    </li>
                    <li>
                      「法令等」とは、法律、政令、通達、規則、条例、裁判所の判決、決定、命令または強制力のある行政処分、ガイドライン、業界団体等における自主規制規則その他の規制の総称をいいます。
                    </li>
                    <li>
                      「当社ウェブサイト」とは、そのドメインが「fanique.io」である当社が運営するウェブサイト（サブドメインを含み、また、理由を問わず当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）をいいます。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第3条（ユーザー登録）</h5>
                  <ol>
                    <li>
                      本サービスの利用を希望する者（以下「利用希望者」といいます。）は、当社所定の方法により、本規約および当社が別途定めるFaniqueプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に同意の上、本規約および当社が定める方法に従い登録手続（以下「ユーザー登録」といいます。）の申請を行うものとします。
                    </li>
                    <li>
                      本サービスでは、次項に基づき発行するユーザーIDに関して、当社が別途指定する外部サービスを使用することから、利用希望者は、前項に基づき申請を行うに際して、あらかじめ当該外部サービスに登録する必要があります。
                    </li>
                    <li>
                      当社は、当社が定める基準および手続に従って、前項に基づくユーザー登録の申請を行った利用希望者の登録の可否を判断します。当社は、当該利用希望者のユーザー登録を認める場合、当該利用希望者についてユーザー登録を行い、当社所定の方法により、当該利用希望者に係るユーザーIDを発行するものとし、かかる発行をもってユーザー登録は完了したものとします。
                    </li>
                    <li>
                      ユーザー登録の完了により当該利用希望者と当社との間で、本規約に定める条件を含む本サービスの利用に関する契約（以下「利用契約」といいます。）が成立するものとします。利用契約の成立により、利用希望者はユーザーとしての地位を得ます。
                    </li>
                    <li>
                      <p>
                        当社は、利用希望者が次の各号のいずれかの事由に該当すると合理的に判断する場合は、ユーザー登録を拒否することがあります。
                      </p>
                      <ol type="a">
                        <li>
                          当社に提供した情報に虚偽、偽造、なりすましまたは仮借名等の疑義がある場合
                        </li>
                        <li>
                          未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人（総称して、以下「法定代理人等」といいます。）の同意等を得ていなかった場合
                        </li>
                        <li>
                          第19条（反社会的勢力の排除）第1項で定義する反社会的勢力に該当する場合
                        </li>
                        <li>
                          本サービスを詐欺等の犯罪行為に利用するおそれがあると合理的に認められる場合
                        </li>
                        <li>
                          本契約に違反した使用方法で本サービスを使用するおそれがあると合理的に認められる場合
                        </li>
                        <li>その他当社がユーザー登録を拒否することが合理的であると判断した場合</li>
                      </ol>
                    </li>
                    <li>
                      未成年者である利用希望者が親権者等の同意を得ていないにもかかわらず、同意があり、または成年であると偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことはできません。また、本サービスの利用開始時に未成年であったユーザーが成年に達した後にも本サービスの利用を継続した場合、当該ユーザーは本サービスに関する一切の法律行為を追認したものとみなされます。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第4条（本ウォレットへの登録）</h5>
                  <ol>
                    <li>
                      ユーザーは、本サービスの利用に関連して、本商品の保管、管理、閲覧その他利用のために、本ウォレットへの登録が必要になります。
                    </li>
                    <li>
                      ユーザーは、本ウォレットの利用に関して、自己の責任において本ウォレットの秘密鍵等を自ら管理するほか、本ウォレットを提供する第三者の定める利用規約等を遵守するものとし、端末機器、本ウォレットへのログインパスワード等の一つでも欠けた場合、本ウォレットを使用することが不可能になる可能性があること、本ウォレットの秘密鍵等が漏えい、滅失または毀損したことにより当該ユーザーに損害が生じたとしても、当社が当該損害を賠償する責任を一切負わないこと、および、当社が当該本ウォレットに関して何らかの救済措置を行うことが不可能であることをあらかじめ了承するものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第5条（転売のみを目的とした本サービスの利用禁止）</h5>
                  <p>
                    当社は、ユーザーがもっぱら転売によって差額の利益を得ることのみを目的（以下「転売目的」といいます。）として本商品を購入することを禁止します。ユーザーによる本サービスの利用態様が転売目的である、またはそのおそれがあると当社が合理的に判断した場合、当社は本規約の定めに従い、当該ユーザーに対する調査および当該ユーザーによる本サービスの利用の拒否、停止、制限等必要かつ合理的な措置を実施する場合があります。なお、この場合、当社は当該ユーザーに対し当該措置を実施したことによる説明義務を負うものではないものとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第6条（ユーザーID等の管理）</h5>
                  <ol>
                    <li>
                      ユーザーは、第3条（ユーザー登録）に基づくユーザー登録の手続において、ユーザーIDの発行を受けるほか、当社所定の方法によりパスワードを設定することができます。ユーザーは、当社所定の方法により、ユーザーIDおよびパスワードを使用して本サービスを利用することができます。
                    </li>
                    <li>
                      ユーザーは、ユーザーID、パスワードその他自身のアカウントに関する情報を、自己の責任において安全に管理・保管し、第三者による不正使用を防止するために必要な措置を講じるものとします。ユーザーID、パスワードに関する情報その他ユーザーが当社に提供した情報（以下「ユーザー情報」といいます。）に変更があった場合、ユーザーは当社に対し、遅滞なく当社が別途指定する方法で変更を通知しなければならないものとします。ユーザー情報に変更があったにもかかわらず、当該通知が行われていない場合、当社は、ユーザー情報に変更がないものとして取り扱うことができます。当該通知があった場合でも、当社は、当該通知前に行われた本サービスの利用との関係では、変更前の情報に依拠することができます。
                    </li>
                    <li>
                      ユーザーは、貸与、共有、譲渡、名義変更その他方法を問わず第三者に自身のユーザーIDを使用させてはなりません。
                    </li>
                    <li>
                      ユーザー情報の管理不十分、使用上の過誤、第三者の使用等によって生じた債務または損害に関する責任はユーザーが負うものとし、当社は一切の責任を負わないものとします。
                    </li>
                    <li>
                      ユーザーは、自らの管理するユーザーID、パスワード等について、何ら権限を有しない第三者が使用することにより不正にログインされ、その他不正に使用された場合またはそのおそれが生じた場合、直ちにその旨を当社に通知するものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第7条（会費）</h5>
                  <ol>
                    <li>
                      ユーザーは、その保有するメンバーシップのステータスに応じて当社が別途定める会費を、当社所定の方法により、当社所定の期限までに当社へ支払うものとします。
                    </li>
                    <li>
                      {' '}
                      第21条（利用契約の解約）に基づきユーザーが利用契約を解約した場合または第22条（利用契約の解除）に基づき利用契約が解除された場合、当社はユーザーが支払った会費について返金しません
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第8条（本商品の一次販売、代金の支払等）</h5>
                  <ol>
                    <li>
                      ユーザーは、当社が別途指定する方法により、本サービスを通じて、当社が販売する本商品を購入することができます。
                    </li>
                    <li>
                      ユーザーが、本サービス上、本商品の販売に係る当社所定の専用ウェブページにおいて、確定的に本商品を購入することを内容とする操作（当社が別途指定する操作をいいます。）を行った時点で、当社と当該ユーザーの間で当該本商品に係る売買契約が成立するものとします。
                    </li>
                    <li>
                      当社は、本商品の購入金額、購入可能な回数その他本商品の購入に関連する事項について一定の制限を設ける場合があり、ユーザーはこれに従うものとします。
                    </li>
                    <li>
                      ユーザーは、第2項の規定に基づき売買契約が成立した後は、民法、消費者契約法その他の法律により認められる場合を除き、当該売買契約の取消しやキャンセル等を行うことはできません。
                    </li>
                    <li>
                      第2項の規定に基づき売買契約が成立した場合、ユーザーは、当社が別途定める方法に従い本商品の代金を支払うものとします。当社は、当該代金の受領後、当社が別途定める方法に従い、本商品を提供するものとします。ただし、ブロックチェーンの動作その他の技術的な理由により、本商品の提供までに一定の時間を要する場合があります。
                    </li>
                    <li>
                      自動ツールの利用、本サービスのシステムエラーまたはバグその他不正な方法により本商品の購入または代金の支払い（以下、本項において「購入等」といいます。）が行われたと当社が合理的に判断した場合、当社は、当該購入等を無効なものとして取り扱います。当社は、当該取り扱いによりユーザーに損害が生じた場合であっても、当社に故意または過失があるときを除き、責任を負わないものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第9条（本商品および本コンテンツの重要事項）</h5>
                  <p>
                    ユーザーは、本サービスを利用するに際して、次の各号に掲げる事項を確認および理解の上、これらに同意するものとします。
                  </p>
                  <ol>
                    <li>
                      当社は、本サービス、本商品または本コンテンツの継続的な提供を保証しません。外部環境の変化（NFTに関する法規制の変更を含みます。）、当社の業務状況の変化その他の理由により、本サービスの提供が継続できなくなった場合、本サービス、本商品または本コンテンツへのアクセスができなくなり、本商品の価値が欠損し、または実質的に無価値化する可能性があります。
                    </li>
                    <li>
                      当社は、NFTの発行・移転等に関して外部サービス（本ウォレットのサービスを含みますがこれに限られません。）の継続的な提供を保証しません。当該外部サービスの終了その他の理由により、本商品または本コンテンツの価値が欠損または実質的に無価値化する可能性があります。
                    </li>
                    <li>
                      当社は、本商品または当該本商品と紐づく本コンテンツの価値の存在または維持を保証しません。本商品の価値は、価格または市況の変動、弊社または当該本商品もしくは本コンテンツに関する否定的な宣伝等その他様々な要因により欠損し、または実質的に無価値化する可能性があります。
                    </li>
                    <li>
                      本商品および本コンテンツの内容およびこれに付随する情報（本コンテンツに関する説明を含みます。）は、当社または権利者からの要請その他の理由により、いつでも変更される場合があります。
                    </li>
                    <li>
                      本商品のうち、当社所定のものについては仕様上、他のアドレス(暗号資産、NFT等を送付または受領するのに必要となる固有の文字列をいいます。以下同じです。）に対する送付その他の第三者へ送付をすることができないものがあります。
                    </li>
                    <li>
                      前条に従い本商品を購入したユーザーは、当社が本規約上で別途認めた場合を除き、本商品を当社または他のユーザーに送付、販売することはできないものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第10条（チームコインの取り扱い）</h5>
                  <ol>
                    <li>
                      ユーザーは、当社が別途定める事項（スポーツチームの試合観戦および本商品の購入を含みますがこれに限られません。）を満たすことで当社が別途定める基準に基づき算出したコイン（以下「チームコイン」といいます。）が付与されます。
                    </li>
                    <li>
                      ユーザーは、保有するチームコインを他のユーザーへ送ることができます。ただし、当社は、その送付先、当該送付可能なチームコインの数量や時期等について、一定の制限を設けるものとします。また、ユーザーは、本サービスの内外を問わず、商品またはサービスその他一切に対する支払手段としてチームコインを利用することはできません。
                    </li>
                    <li>
                      ユーザーは、第1項に基づき付与されたチームコインにより当社が別途定める特典（メンバーシップのステータスの上昇を含みますがこれに限られません。）を得ることができます。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第11条（費用の負担）</h5>
                  <p>
                    ユーザーは、本サービスの利用にあたって必要なあらゆる機器・ソフトウェア、通信手段その他の環境を、自己の責任と費用において適切に整備するものとし、機器・ソフトウェアの購入費用、通信費用、ブロックチェーンのネットワーク手数料（ガス代等）、その他一切の費用は、ユーザー負担とします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第12条（知的財産権等の帰属および使用範囲等）</h5>
                  <ol>
                    <li>
                      <p>
                        本商品を保有するユーザーは、本商品を保有しており、かつ、利用制限等（第16条（利用制限等）で定義されます。）を受けずに当社との間で利用契約を締結している間に限り、当該本商品および当該本商品と紐づいた本コンテンツについて、次の各号に掲げる行為を行うことができます。
                      </p>
                      <ol type="a">
                        <li>
                          本サービスおよび当社が別途指定する外部サービス（本ウォレットのサービスを含みますがこれに限られません。）上で本商品および本コンテンツを閲覧または視聴すること
                        </li>
                        <li>本商品に付帯する権利を行使すること</li>
                        <li>
                          メンバーシップのステータスがダイヤモンドであるユーザー（以下「ダイヤモンド会員」といいます。）が第28条（ダイヤモンド会員のメンバーシップ売買）に基づきそのメンバーシップを売却すること（ただし、第28条
                          第6項が適用される場合を除きます。）
                        </li>
                        <li>前各号のほか、当社が別途定める事項を行うこと</li>
                      </ol>
                    </li>
                    <li>
                      本規約に別段の定めがある場合を除き、本商品および本コンテンツに関する知的財産権等その他の権利は、著作権法その他適用される法令に従い、当社または権利者のいずれかに帰属するものとし、本商品の購入によってユーザーには移転するものではありません。また、ユーザーは、次項に定める場合を除き、当該本商品および本コンテンツについて、複製、配布、転載、転送、公衆送信、改変、翻案その他の二次利用等を行わないものとします。
                    </li>
                    <li>
                      {' '}
                      当社はユーザーに対し、第1項各号に定める行為に必要な範囲で、本商品および本コンテンツに係る知的財産権を非独占的に利用することを、当該本商品および本コンテンツを保有している間に限り、無償で許諾または再許諾するものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第13条（通知）</h5>
                  <ol>
                    <li>
                      当社のユーザーに対する通知その他の連絡は、本規約に別段の定めがある場合を除き、本サービスへの掲載、電子メールその他の当社が定める方法により行うものとします。
                    </li>
                    <li>
                      当社がユーザー情報に含まれる電子メールアドレスその他の連絡先に対し、通知その他の連絡を行った場合、当該ユーザーは、当該連絡を受領したものとみなします。
                    </li>
                    <li>
                      本サービスに関する問い合わせその他ユーザーの当社に対する通知その他の連絡は、本規約に別段の定めがある場合を除き、当社が別途指定する電子メールアドレスに対し電子メールを送付する方法により行うものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第14条（情報の取得および利用）</h5>
                  <p>
                    当社は、ユーザー情報およびその他のユーザーに関する一切の情報（総称して、以下「ユーザーデータ」といいます。）が個人情報の保護に関する法律上の個人情報に該当する場合、本プライバシーポリシーに基づき、当該個人情報を適切に取り扱うものとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第15条（禁止事項）</h5>
                  <ol>
                    <li>
                      <p>
                        ユーザーは、本サービスの利用に関して、自己または第三者をして、次の各号に定める行為を行ってはならないものとします。
                      </p>
                      <ol type="a">
                        <li>本規約または法令等に違反する行為</li>
                        <li>
                          本商品および本コンテンツの全部または一部を本規約の範囲を超えて利用する行為
                        </li>
                        <li>公序良俗に反する行為またはそのおそれのある行為</li>
                        <li>反社会的勢力に対する利益供与その他反社会的勢力に関与する行為</li>
                        <li>法令等に違反する行為またはこれらの行為を援助もしくは助長する行為</li>
                        <li>
                          当社または第三者の知的財産権等、名誉、財産、その他の権利もしくは利益を侵害し、または侵害するおそれのある行為
                        </li>
                        <li>
                          当社または第三者に対する嫌がらせ、誹謗中傷その他これらに類似する行為
                        </li>
                        <li>
                          ユーザー登録に際して虚偽の情報を提供しまたは故意に不正確な情報を提供する行為、または第三者になりすます行為
                        </li>
                        <li>
                          複数のユーザー登録をする行為または複数の本サービスのアカウントを利用する行為
                        </li>
                        <li>他のユーザーまたは第三者になりすまして本サービスを利用する行為</li>
                        <li>
                          前各号のほか、当社、他のユーザーまたは第三者の権利または利益を侵害する行為
                        </li>
                        <li>
                          情報収集ボット、ロボット、スクレーパーその他の自動化された手段を使用して本サービスを利用しもしくは本サービスにアクセスし、または情報収集、情報入力およびユーザー登録その他の情報登録を行う行為
                        </li>
                        <li>
                          当社または他のユーザーに係る情報その他の本サービスに掲載されている情報を改ざんもしくは消去する行為
                        </li>
                        <li>
                          次のいずれかに該当し、または該当すると当社が合理的に判断する情報を本サービスにアップロードし、または当社もしくは第三者に送信し、もしくは受信可能な状態に置く行為
                        </li>
                        <ol>
                          <li>当社または第三者の名誉または信用を毀損する表現を含む情報</li>
                          <li>暴力的または残虐な表現を含む情報</li>
                          <li>わいせつな表現を含む情報</li>
                          <li>差別を助長する表現を含む情報</li>
                          <li>自殺、自傷行為等または薬物の不適切な利用を助長する表現を含む情報</li>
                          <li>違法な勧誘、宣伝等を含む情報</li>
                          <li>他人に不快感を与える表現を含む情報</li>
                          <li>
                            取得、本サービスへのアップロードまたは当社もしくは第三者への送信行為につき適法かつ正当な権利または許諾を得ていない個人情報その他の情報
                          </li>
                          <li>違法な方法で入手した情報</li>
                          <li>
                            コンピューターウィルスその他の有害なコンピュータープログラムを含む情報
                          </li>
                        </ol>
                        <li>
                          本サービスまたは当社ウェブサイトに関連するプログラムを変更、削除、逆コンパイル、逆アセンブルもしくはリバースエンジニアリングする行為、またはネットワーク監視もしくは検出ソフトウェアを使用して当社ウェブサイトのサイトアーキテクチャを決定する行為
                        </li>
                        <li>
                          本サービスのシステムの一貫性や安全性を妨害しもしくは損なう行為または本サービスを実行しているサーバーへの発信もしくは当該サーバーからの発信を解読することを試みる行為
                        </li>
                        <li>
                          本サービスのネットワークまたはシステムに過度な負荷をかける行為、不正アクセスまたはその他これらに準ずる行為
                        </li>
                        <li>
                          本サービスに類似もしくは競合するサービスまたは製品を提供する行為、その準備のために本サービスを利用する行為、またはその他本サービスの目的以外のために本サービスを利用する行為
                        </li>
                        <li>
                          本商品を資金決済法上の暗号資産または前払式支払手段に該当するものとして取り扱う行為
                        </li>
                        <li>
                          ユーザーが、未成年者、成年被後見人、被保佐人、または被補助人のいずれかであり、法定代理人等の同意等を得ずにユーザー登録、および本サービスを利用する行為
                        </li>
                        <li>
                          その他当社が合理的に不適切と判断する行為であって、ユーザーに対して相当期間を定めて事前に通知して解消を求めた行為
                        </li>
                        <li>本商品の価格の変動を図る目的のために行う次に掲げる行為</li>
                        <ol>
                          <li>
                            行為者が直接経験または認識していない合理的な根拠のない事実を不特定多数の者に流布すること
                          </li>
                          <li>
                            他人を錯誤に陥れるような手段を用いて詐欺的な行為を行うことや、徒に他人の射幸心をあおるような言動を行うこと
                          </li>
                          <li>暴行または脅迫を用いること</li>
                        </ol>
                        <li>
                          第三者とその秘密鍵を共有しているウォレットを本サービスに接続する行為または本ウォレットの秘密鍵を第三者と共有する行為
                        </li>
                        <li>本商品の二重譲渡に該当する行為またはこれを試みる行為</li>
                        <li>
                          チームコインを商品またはサービスその他一切に対する支払手段として利用する行為
                        </li>
                        <li>前各号の行為を第三者に指示し、教唆し、もしくは扇動等する行為</li>
                        <li>その他、当社が不適切と合理的に判断する行為</li>
                      </ol>
                    </li>
                    <li>
                      当社は、ユーザーが前項に定める行為を行ったと当社が判断した場合には、当社は、違反行為のあったユーザーに係る情報（ユーザーの氏名もしくは名称を含みます。）の公表または警告措置を講じることができるものとします。
                    </li>
                    <li>
                      当社は、当社の故意または過失に起因する場合を除き、前項に定める措置によりユーザーに生じた損害について責任を負いません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第16条（利用制限等）</h5>
                  <p>
                    当社は、ユーザーまたはユーザーの行為が次の各号のいずれかに該当する場合、当該ユーザーによる本サービスの利用を拒否し、当該ユーザーによる本サービスの全部または一部の利用を停止もしくは制限し、または当該ユーザーのユーザー情報およびユーザーデータ等を制限・停止・削除等（以下「利用制限等」といいます。）できるものとします。
                  </p>
                  <ol>
                    <li>
                      前条の禁止事項を含み、本規約に違反した場合またはそのおそれがあると当社が合理的に判断した場合
                    </li>
                    <li>年会費の支払いを遅延した場合</li>
                    <li>
                      過去に当社からユーザー登録の解除または一時停止処分を受けたことがあることが判明した場合
                    </li>
                    <li>過去に本規約に違反したことがあることが判明した場合</li>
                    <li>
                      ユーザーの行為（本サービス外における作為または不作為を含みます。）によって、本サービスの提供に支障が生じ、もしくは妨害された場合、またはそのおそれがあると当社が合理的に判断した場合
                    </li>
                    <li>
                      当社所定の方法によらない方法、その他不正な方法により本サービスを利用した場合
                    </li>
                    <li>
                      ユーザーが、国連、日本、米国、英国、欧州連合その他いずれかの政府による通商禁止、貿易制裁、経済制裁、金融制裁の対象である国または地域（総称して、以下「制裁対象国等」といいます。）に居住もしくは所在しているか、制裁対象国等の法律に基づいて組織される者によって直接的、間接的に所有または支配されているか、もしくは制裁対象国等の国民、居住者であることが判明した場合、または、いずれかの政府機関（日本および米国の財務省を含みますが、これらに限定されません。）が随時公表する経済制裁の対象となっている個人であることが判明した場合
                    </li>
                    <li>過去に当社から本条に基づく措置を受けたユーザーであることが判明した場合</li>
                    <li>登録情報の全部もしくは一部につき虚偽、誤記または記載漏れがあったとき</li>
                    <li>
                      支払停止もしくは支払不能となり、または、破産手続、民事再生手続その他これらに類する手続の開始の申立てがあった場合
                    </li>
                    <li>ユーザーが死亡したとき</li>
                    <li>当社からの連絡に対し、1ヶ月以上応答がない場合</li>
                    <li>
                      決済手段としてユーザーが当社に届け出たクレジットカードが利用停止となった場合
                    </li>
                    <li>その他、当社が本サービスのユーザーとして不適切と合理的に判断した場合</li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第17条（本サービスの変更等）</h5>
                  <ol>
                    <li>
                      <p>
                        当社は、以下のいずれかの事由が生じた場合には、本サービスの内容の全部もしくは一部を変更、修正もしくは追加（以下「変更等」といいます。）し、または本サービスの全部もしくは一部の提供を一時的に停止もしくは中断することができるものとします。
                      </p>
                      <ol type="a">
                        <li>
                          本サービスに新規機能を追加し、または既存機能もしくは画面レイアウトの変更、停止、非公開化もしくは削除等をする場合
                        </li>
                        <li>
                          本サービスに係るハードウェア、ソフトウェア、通信機器その他関連する機器もしくはシステムの点検または保守作業を行う場合
                        </li>
                        <li>
                          コンピューターまたは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキングその他予期せぬ要因により本サービスの提供が困難となった場合
                        </li>
                        <li>本サービスに関するセキュリティ上の問題が生じた場合</li>
                        <li>
                          天災地変、戦争もしくはそのおそれ、封鎖、通商停止、革命、暴動、伝染病その他の疫病、物資もしくは施設の破壊もしくは損傷、火災、台風、地震または洪水その他の不可抗力（以下「不可抗力」といいます。）
                        </li>
                        <li>
                          法令等もしくはこれらに基づく措置、本サービスに適用される法令等や自主規制規則等の新設もしくはこれらに基づく措置により、本サービスの運営の継続が困難となった場合
                        </li>
                        <li>
                          その他前各号に準じる事由により本サービスの変更等が必要であると当社が判断した場合
                        </li>
                      </ol>
                    </li>
                    <li>
                      前項の規定にかかわらず、当社は、当社がやむを得ないと判断した場合その他当社の都合により、本サービスの内容の全部もしくは一部を変更等しまたは本サービスを終了することができます。
                    </li>
                    <li>
                      当社は、前二項に定める措置を行った場合には、ユーザーに対してその旨を通知するものとします。当該通知は、当該措置によりユーザーに重大な影響がある場合その他当社が必要と認めた場合には事前になされるものとしますが、緊急の場合にはこの限りではありません。
                    </li>
                    <li>
                      当社は、第1項および前項の通知を、変更の内容および時期を本サービスに掲示し、またはユーザー情報に含まれる連絡先に直接通知する方法により行います。
                    </li>
                    <li>
                      当社は、第1項または第2項に定める措置によりユーザーに生じた損害について、当該損害の発生が当社の故意または過失に起因する場合を除き、責任を負いません。
                    </li>
                    <li>
                      ユーザーは、第2項の規定により本サービスが終了する場合、本商品または本コンテンツの使用その他本サービスならびに外部サービスを通じた本商品の利用が一切できなくなることについて、あらかじめ同意するものとします。当社はこれらに起因する損害について一切責任を負いません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第18条（非保証・免責事項）</h5>
                  <ol>
                    <li>
                      当社は、当社が別途明示的に表明する場合を除き、本サービスがユーザーの特定の目的に適合すること、ユーザーが期待する機能・商品的価値・正確性・有用性・完全性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令等に適合すること、継続的に利用できること、セキュリティ上の欠陥、エラー、バグその他の不具合がないこと、本サービスが第三者の権利を侵害しないことを保証しません。
                    </li>
                    <li>
                      当社は、資金決済法、金融商品取引法その他の法令等が、本サービスの提供または利用等につき、重大な影響を与えないことを保証しません。
                    </li>
                    <li>
                      当社は、法令等で求められる場合および当社が別途明示的に表明する場合を除き、ユーザー情報およびユーザーデータその他のいかなる情報も保存する義務を負いません。ユーザーは、これらの情報を自身の責任において保存するものとします。
                    </li>
                    <li>
                      当社は、本サービスと連携する外部サービスその他の第三者が提供するサービスの法令等への適合性を保証しません。
                    </li>
                    <li>
                      当社は、ユーザーが本サービス外で本商品の取引を行った場合において、当該サービスに起因して当該ユーザーに生じた損害について、何らの責任を負わないものとします。
                    </li>
                    <li>
                      本サービスに関連してユーザーと他のユーザーまたは第三者との間において生じた紛争については、ユーザーが自己の責任によって解決するものとします。当社は、中立的な立場で当該紛争の解決に努めますが、当該紛争の解決について何らかの法的な義務を負うものではありません。当社は、当該紛争を解決し、本サービスの健全性を確保するために必要と判断した場合、当該紛争の当事者であるユーザーに事実関係の確認をすることができ、当該ユーザーは当社の事実確認に協力するものとします。
                    </li>
                    <li>
                      本サービスを通じてユーザーに課される公租公課は、ユーザーが自らこれを負担するものとます。
                    </li>
                    <li>
                      <p>
                        当社は、ユーザーに生じた次の各号に定める損害について、債務不履行、不法行為その他原因の如何を問わず、当社に故意または過失がある場合を除き、責任を負いません。
                      </p>
                      <ol type="a">
                        <li>天災地変、騒乱、暴動等の不可抗力に起因する損害</li>
                        <li>
                          本サービスの利用のためのインターネット接続サービスの不具合または性能等の不足その他権利者またはユーザーの接続環境の障害に起因する損害
                        </li>
                        <li>
                          コンピューターウィルスが本サービスの提供に用いられる設備に侵入したことに起因して損害が発生した場合において、当社が導入しているコンピューターウィルス対策ソフトに当該コンピューターウィルスに係るウィルスパターン、ウィルス定義ファイル等が提供されていなかったときにおける当該損害
                        </li>
                        <li>
                          善良な管理者の注意をもってしても防御が困難である、本サービスの提供に用いられる設備への第三者による不正アクセスまたは通信経路上での傍受等に起因する損害
                        </li>
                        <li>
                          当社が定める手順、セキュリティ手段等を権利者またはユーザーが遵守しないことに起因する損害
                        </li>
                        <li>
                          本サービスの提供に用いられる設備のうち第三者が提供するソフトウェア（OS、ミドルウェアおよびDMBSを含みます。）、ハードウェアまたはデータベースに起因する損害
                        </li>
                        <li>電気通信事業者の提供する電気通信役務の不具合に起因する損害</li>
                        <li>
                          刑事訴訟法、犯罪捜査のための通信傍受に関する法律その他の法令等に基づく捜索、差押え、検証その他の強制処分に起因する損害
                        </li>
                        <li>
                          法令等の新設、改廃、解釈の変更等（その効果が過去に遡及する場合を含みます。）に起因する損害
                        </li>
                        <li>
                          本サービスとのリンクの有無を問わず、第三者のウェブサイトまたはNFTもしくはサービスに起因する損害
                        </li>
                        <li>
                          ブロックチェーンのネットワーク手数料（ガス代等）の高騰、ハードフォークの発生等による損失
                        </li>
                        <li>
                          前各号に定めるもののほか、当社の責めに帰することができない事由に起因する損害
                        </li>
                      </ol>
                    </li>
                    <li>
                      前各項を含む本規約の規定にかかわらず、当社が何らかの理由によりユーザーに対する責任を負う場合であっても、当社の故意または重過失に基づく場合を除き、その責任の範囲は、通常かつ直接発生する損害の範囲に限定され、1万円をその上限とし、付随的損害、間接損害、特別損害、将来の損害および逸失利益に係る損害については、賠償する責任を負わないものとします。ただし、当社の故意または重大な過失による損害については、この限りではありません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第19条（反社会的勢力の排除）</h5>
                  <ol>
                    <li>
                      <p>
                        ユーザーは、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下「反社会的勢力」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。
                      </p>
                      <ol type="a">
                        <li>反社会的勢力が経営を支配していると認められる関係を有すること</li>
                        <li>
                          反社会的勢力が経営に実質的に関与していると認められる関係を有すること
                        </li>
                        <li>
                          自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってする等、不当に反社会的勢力を利用していると認められる関係を有すること
                        </li>
                        <li>
                          反社会的勢力に対して資金等を提供し、または便宜を供与する等の関与をしていると認められる関係を有すること
                        </li>
                        <li>反社会的勢力と社会的に非難されるべき関係を有すること</li>
                      </ol>
                    </li>
                    <li>
                      <p>
                        ユーザーは、自らまたは第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。
                      </p>
                      <ol type="a">
                        <li>暴力的な要求行為</li>
                        <li>法的な責任を超えた不当な要求行為</li>
                        <li>取引に関して、脅迫的な言動をし、または暴力を用いる行為</li>
                        <li>
                          風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
                        </li>
                        <li>その他前各号に準ずる行為</li>
                      </ol>
                    </li>
                    <li>
                      当社は、ユーザーが、反社会的勢力もしくは第1項各号のいずれかに該当し、もしくは前項各号のいずれかに該当する行為をし、または第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、ユーザーに対して何らの催告をすることなく利用契約を解除することができます。
                    </li>
                    <li>
                      {' '}
                      当社は、前項により利用契約を解除した場合には、これによりユーザーに損害が生じたとしてもこれを一切賠償する責任はないことを確認し、ユーザーはこれを了承します。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第20条（損害賠償）</h5>
                  <ol>
                    <li>
                      ユーザーによる本規約に違反する行為または本サービスに関連するユーザーの故意または過失に基づく行為により、当社または第三者に損害または損失が発生した場合、ユーザーは、当該損害（合理的な弁護士費用を含みます。）または損失の全額を賠償または補償するものとします。
                    </li>
                    <li>
                      <p>
                        当社は、本サービスの提供に関連して、次の各号に定める事由に起因してユーザーに生じた損害または損失につき、当社の故意または過失に基づく場合を除き、責任を負いません。責任を負う場合であっても、当社の故意または重過失に基づく場合を除き、その責任の範囲は、通常かつ直接発生する損害の範囲に限定され、当社のユーザーに対して負担する損害賠償の上限額は1万円とし、付随的損害、間接損害、特別損害、将来の損害および逸失利益に係る損害については、賠償する責任を負わないものとします。
                      </p>
                      <ol type="a">
                        <li>
                          ユーザーが当社に提供したユーザー情報の虚偽、誤り、脱落または変更漏れ
                        </li>
                        <li>
                          ユーザーによるIDまたはパスワードの管理不十分、使用上の過誤、第三者による使用等
                        </li>
                        <li>
                          ユーザー登録、削除、権限の範囲の設定その他ユーザー情報に関する設定の管理または設定が不十分または不適切であること
                        </li>
                        <li>
                          本サービスにおいて当社または第三者から提供またはアップロードされたコンテンツの内容
                        </li>
                        <li>
                          本サービスのセキュリティ上の欠陥、エラー、バグ、その他の欠陥、またはコンピューターウイルス
                        </li>
                        <li>本規約に基づく本サービス等の提供の一時停止または中断</li>
                        <li>本規約に基づく利用停止等の措置または利用契約の解除</li>
                        <li>
                          ユーザー情報、本コンテンツその他本サービスならびにアップロードされまたは提供された情報またはデータの毀損または滅失
                        </li>
                        <li>
                          本サービスの利用に関連してユーザーと第三者との間で生じた取引、連絡、紛争等
                        </li>
                        <li>その他本サービスまたは本規約に起因しまたは関連する事由</li>
                      </ol>
                    </li>
                    <li>
                      ユーザーは、第三者のサービス（本ウォレットを含みますがこれに限られません。）を自己の責任において利用するものとし、当社は、第三者のサービスの内容およびこれに関連する事項についてユーザーに対していかなる責任も負いません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第21条（利用契約の解約）</h5>
                  <p>
                    ユーザーは、利用契約を解約する場合、当社所定の方法により解約手続を行うこととし、当該解約手続の完了をもって、利用契約が解約されるものとします。この場合、ユーザーは自己の責任において、当社からの解約に関する通知を確認するものとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第22条（利用契約の解除）</h5>
                  <ol>
                    <li>
                      <p>
                        ユーザーが以下の各号のいずれかに該当する場合、当社は、当該ユーザーに通知することにより、直ちに当該ユーザーとの間の利用契約を解除し、それによって当社に生じた損害の賠償をユーザーに請求することができるものとします。
                      </p>
                      <ol type="a">
                        <li>当社への申告、届出内容に虚偽があった場合</li>
                        <li>手形または小切手が不渡りとなった場合</li>
                        <li>
                          差押え、仮差押えもしくは競売の申立があったとき、または公租公課の滞納処分を受けた場合
                        </li>
                        <li>
                          破産、会社更生手続開始または民事再生手続開始の申立があったときその他信用状態に重大な不安が生じた場合
                        </li>
                        <li>監督官庁から営業許可の取消、停止等の処分を受けた場合</li>
                        <li>
                          利用契約に違反し、当社がかかる違反の是正を催告した後合理的な期間内に是正されない場合
                        </li>
                        <li>
                          解散、減資、事業の全部または重要な一部の譲渡その他存続の基礎に影響しうる決議をした場合
                        </li>
                        <li>第19条（反社会的勢力の排除）の定めに違反した場合</li>
                        <li>利用契約を履行することが困難となる事由が生じた場合</li>
                        <li>その他、ユーザーとして不適切と当社が合理的に判断した場合</li>
                      </ol>
                    </li>
                    <li>
                      当社は、当社に故意または過失があるときを除き、前項の規定による解除によりユーザーに生じた損害を賠償する責任を負わないものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第23条（権利義務等の譲渡等）</h5>
                  <ol>
                    <li>
                      ユーザーは、当社の事前の書面による承諾なく、利用契約上の地位または利用契約に基づく権利もしくは義務を、第三者に譲渡し、移転し（合併もしくは会社分割による場合を含みます。）、担保に供し、またはその他の処分をしてはなりません。
                    </li>
                    <li>
                      当社が本サービスに係る事業を第三者に譲渡等（合併、会社分割、事業譲渡その他事業が移転するあらゆる場合を含みます。）した場合、当社は、当該譲渡等に伴い、利用契約に基づく契約上の地位、権利、義務およびユーザー情報、ユーザーデータその他本サービスを通じて当社が取得したユーザーに関する一切の情報を当該譲渡等の譲受人に譲渡することができるものとし、ユーザーは係る譲渡にあらかじめ同意するものとします。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第24条（本規約の変更）</h5>
                  <ol>
                    <li>
                      <p>
                        当社は、以下の各号のいずれかに該当した場合、本規約の全部または一部を変更できるものとます。
                      </p>
                      <ol type="a">
                        <li>本規約の変更が、ユーザーの一般の利益に適合するとき</li>
                        <li>
                          本規約の変更が、利用契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性およびその内容その他の変更に係る事情に照らして合理的なものであるとき。
                        </li>
                      </ol>
                    </li>
                    <li>
                      本規約を変更した場合、当社は、変更があった旨、変更の内容および変更の効力の発生時期を、効力発生時期の2週間前までに、当社ウェブサイト等もしくは本サービス上での告知または電子メールその他の適切な方法でユーザーに通知するとともに、変更後の本規約を本サービスその他の適切な場所に掲載します。
                    </li>
                    <li>
                      本規約の変更に同意しないユーザーは、前項に定める変更の効力発生日の前日までに、当社が別途定める手続により当社に通知することで、利用契約を解約することができるものとします。
                    </li>
                    <li>
                      ユーザーは、本規約の変更の効力の発生後も本サービスの利用を継続することで、かかる変更に同意したものとみなされます。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第25条（分離可能性）</h5>
                  <p>
                    本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約の残りの規約および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。この場合において、当社およびユーザーは、当該無効または執行不能と判断された条項またはその一部を適法とし、執行力をもたせるために必要な範囲で修正し、当該無効または執行不能とされた条項またはその一部の趣旨ならびに法律的および経済的に同等の効果を確保できるように努めるものとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第26条（有効期間）</h5>
                  <p>
                    利用契約の有効期間は、利用契約の成立時から第21条（利用契約の解約）に基づく解約または第22条（利用契約の解除）に基づく解除の時までとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第27条（退会時の取り扱い）</h5>
                  <ol>
                    <li>
                      <p>
                        前条に基づき利用契約が終了した場合、ユーザーは、そのユーザーとしての地位を失うものとします。また、この場合、次に定める本ウォレットに残るデジタルアセットを除いて、ユーザーが保有する本サービス上および本ウォレット上の本商品、当該本商品に付帯する権利および当該本商品と紐づいた本コンテンツを含む一切のデジタルアセット、ならびにユーザー情報は消去されるものとします。
                      </p>
                      <ol type="a">
                        <li>チームコイン（ただし、本サービス外で使用することはできません。)</li>
                        <li>
                          ユーザーのメンバーシップのデータ(ただし、メンバーシップに係る機能の使用および権利の行使ならびにメンバーシップのデータの売買はできません。){' '}
                        </li>
                        <li>その他当社が本ウォレットに残すことを許可したもの</li>
                      </ol>
                    </li>
                    <li>
                      当社は、前項に定める措置によりユーザーに生じた損害について、当該損害の発生が当社の故意または過失に起因する場合を除き、責任を負いません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第28条（ダイヤモンド会員のメンバーシップ売買）</h5>
                  <ol>
                    <li>
                      ダイヤモンド会員は、別途当社の定める方法により、自ら保有するダイヤモンド会員に係るメンバーシップ（以下、本条において「本メンバーシップ」といいます。）を他のユーザー（以下、本条において「購入者」といいます。）との間で売買することができます。
                    </li>
                    <li>
                      当該ダイヤモンド会員は当社に対して、当該タイヤモンド会員および購入者間における本メンバーシップに係る売買契約（以下、本条において「本売買契約」といいます。）に基づく売買代金（以下、本条において「本売買代金」といいます。）を代理受領する権限、および本売買代金の受領業務を当社が別途指定する決済事業者に再委託する権限を、それぞれ付与するものとします（当社および当該決済事業者を総称して、以下「代理受領者」といいます。）。
                    </li>
                    <li>
                      本売買契約が成立した場合、購入者は、当社が別途指定する支払期限までに、当社が別途定める方法により、本売買代金を支払うものとし、代理受領者はこれを受領するものとします。当該受領をもって、当該購入者による支払いは完了し、当該購入者の当該ダイヤモンド会員に対する本売買代金の支払債務は消滅するものとします。
                    </li>
                    <li>
                      代理受領者はダイヤモンド会員に対し、前項において受領した本売買代金から、当社が別途定める手数料を差し引いた金額を、当該ダイヤモンド会員が別途指定する銀行口座に振込む方法により支払うものとします。
                    </li>
                    <li>
                      購入者は、第3項に基づき本売買代金の支払を完了した場合、当社が別途定める方法に従い、本メンバーシップを自らが管理する本ウォレットに送付する方法によりこれを受領することができます。なお、購入者は受領した本メンバーシップを返品することはできません。ただし、当該購入者の責めに帰さない事由により当該本メンバーシップの破損・滅失等が発生した場合にはこの限りではありません。
                    </li>
                    <li>
                      {' '}
                      購入者が、第3項において当社が別途定める支払期限までに本売買代金を支払わない場合、ダイヤモンド会員は本売買契約を、事前の通知、催告等を要することなく直ちに解除することができます。
                    </li>
                    <li>
                      前各項の規定にかかわらず、仕様上、他のアドレスに対する送付その他の第三者への送付ができない本メンバーシップに係るダイヤモンド会員は、当該本メンバーシップを他のユーザーに送付、販売することはできません。
                    </li>
                  </ol>
                </div>
                <div className="terms-condition">
                  <h5>第29条（存続条項）</h5>
                  <p>
                    利用契約が期間満了、解除その他の事由により終了した場合であっても、第12条（知的財産権等の帰属および使用範囲等）、第14条（情報の取得および利用）、第18条（非保証・免責事項）、第19条（反社会的勢力の排除）第4項、第20条（損害賠償）、第23条（権利義務等の譲渡等）、第25条（分離可能性）、第27条（退会時の取り扱い）、第30条（準拠法および合意管轄）および本条の定めは、利用契約の終了後もなお有効に存続するものとします。
                  </p>
                </div>
                <div className="terms-condition">
                  <h5>第30条（準拠法および合意管轄）</h5>
                  <ol>
                    <li>利用契約は、日本法に準拠し、かつ、これに従って解釈されるものとします。</li>
                    <li>
                      {' '}
                      利用契約または本サービスの利用に起因または関連して生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                    </li>
                  </ol>
                </div>
                <div className="end-title">
                  <EndRight>
                    <strong>
                      <small>以上</small>
                    </strong>
                  </EndRight>
                  <div>
                    <strong>
                      <small>制定日：2023年3月16日</small>
                    </strong>
                  </div>
                  <div>
                    <strong>
                      <small>改訂日：2024年5月15日</small>
                    </strong>
                  </div>
                </div>
              </div>
            </HomeWrapper>
            <BackButton>
              <button className="back" onClick={() => history.push('/top')}>
                {t('Go to Top page')}
              </button>
            </BackButton>
            <div className="footer-wrapper">
              <Footer authenticated={authenticated} color={teamDetails?.team?.teamColor} />
            </div>
          </LoaderComponent>
        </Container>
      </Contain>
    </IonPage>
  );
};

export default RestrictedRoute(TermsAndConditions);
