import React from 'react';
import { IonImg } from '@ionic/react';

const Concept = () => {
  return (
    <div className="neomorphs-shadow-out concept">
      <p className="inner-header">コンセプト</p>
      <p className="context">
        Support and Earnをコンセプトにした、
        <br />
        チームや選手を応援することが価値となり、
        <br />
        長く続くファンとのコミュニティ
        <br />構築を目指します。
      </p>
      <IonImg src="/assets/images/about/concept.png" />
    </div>
  );
};

export default Concept;
