import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonRow, IonCol, IonText } from '@ionic/react';
import React, { useState, useRef, useEffect } from 'react';

import { useValidation } from '../../../utils';
import { TitleComponent, TextField, Divider, Button } from '../../atoms';

interface Props {
  isLoading?: boolean;
  onSubmitHandler: any;
  data?: any;
  setData?: any;
}

const FormContainer = styled.div`
  padding: 25px 12px;
  border-radius: 50px;

  hr {
    margin: 0px;
  }

  ion-note {
    margin-top: -10px;
    font-size: 13px;
  }

  ion-input {
    font-size: 13px;
  }

  ion-item {
    --ion-safe-area-right: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }

  .label {
    font-size: 13px;
    font-weight: 350;
  }

  ion-row {
    display: flex;
    align-items: center;
    padding-right: 1rem;
  }

  ion-col > span:before {
    content: '※';
    margin-right: 4px;
    display: inline;
    color: #b80629;
    font-size: 10px;
  }

  .justify-content-end {
    justify-content: end;
  }

  .address {
    align-self: baseline;
    padding-top: 16px;
  }

  .address-row {
    ion-item:last-child {
      margin-top: -20px;
    }
  }

  .required-row {
    color: #b80629;
    padding-top: 12px;

    ion-text {
      font-size: 13px;
    }
  }

  .text {
    ion-text {
      color: #4d4d4d;
      font-size: 15px;
      font-weight: 400;
    }
    margin-bottom: 30px;
  }

  .back-button {
    margin-top: 20px;
  }
`;

const REQUIRED_FIELDS = [
  'teamName',
  'email',
  'companyName',
  'lastName',
  'firstName',
  'primaryAddress',
  'extPhone',
  'firstPhone',
  'secondPhone',
];

export const PublishTeamForm: React.FC<Props> = ({ onSubmitHandler, isLoading, data, setData }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isSubmitActive, setSubmitStatus] = useState(false);

  const ValidationSchemas = [
    {
      name: 'email',
      validate: (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
      },
      message: 'Invalid email address',
    },
  ];

  const errors = useValidation({ schema: ValidationSchemas, value: data });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const textField1 = useRef<any>(null);
  const textField2 = useRef<any>(null);
  const textField3 = useRef<any>(null);

  const phoneChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'extPhone' && e.target.value.length === 3) {
      textField2.current.setFocus();
    } else if (e.target.name === 'firstPhone') {
      if (e.target.value.length === 4) {
        textField3.current.setFocus();
      } else if (e.target.value.length === 0) {
        textField1.current.setFocus();
      }
    } else if (e.target.name === 'secondPhone' && e.target.value.length === 0) {
      textField2.current.setFocus();
    }
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async () => {
    onSubmitHandler &&
      onSubmitHandler({
        teamName: data?.teamName,
        email: data?.email,
        name_sei: data?.lastName,
        name_mei: data?.firstName,
        companyName: data?.companyName,
        address: `${data?.primaryAddress} ${data?.secondaryAddress}`,
        phone: `${data?.extPhone}${data?.firstPhone}${data?.secondPhone}`,
      });
  };

  useEffect(() => {
    let isSubmitActive = true;
    REQUIRED_FIELDS.forEach((item) => {
      if (!data[item]) isSubmitActive = false;
    });
    Object.keys(errors).forEach((key) => {
      if (errors[key]) isSubmitActive = false;
    });
    setSubmitStatus(isSubmitActive);
  }, [data, errors]);

  return (
    <FormContainer className="neomorphs-shadow-in">
      <TitleComponent title heading="Form" subHeading="Apply form" />
      <div className="wrapper">
        <IonRow>
          <IonCol size="3">
            <span className="label">{t('Team name')}</span>
          </IonCol>
          <IonCol>
            <TextField onChange={onChange} name={'teamName'} mode="white" value={data.teamName} />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow>
          <IonCol size="3">
            <span className="label">{t('Email')}</span>
          </IonCol>
          <IonCol>
            <TextField
              onChange={onChange}
              name={'email'}
              mode="white"
              value={data.email}
              error={errors.email}
            />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow>
          <IonCol size="3">
            <span className="label">{t('Representative name')}</span>
          </IonCol>
          <IonCol>
            <TextField onChange={onChange} name={'lastName'} mode="white" value={data.lastName} />
          </IonCol>
          <IonCol>
            <TextField onChange={onChange} name={'firstName'} mode="white" value={data.firstName} />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow>
          <IonCol size="3">
            <span className="label">{t('Company name')}</span>
          </IonCol>
          <IonCol>
            <TextField
              onChange={onChange}
              name={'companyName'}
              mode="white"
              value={data.companyName}
            />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow className="ion-align-items-center justify-content-end address-row">
          <IonCol size="3" className="address">
            <span className="label">{t('Address')}</span>
          </IonCol>
          <IonCol size="9">
            <TextField
              onChange={onChange}
              name={'primaryAddress'}
              mode="white"
              value={data.primaryAddress}
            />
            <TextField
              onChange={onChange}
              name={'secondaryAddress'}
              mode="white"
              value={data.secondaryAddress}
            />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow>
          <IonCol size="3">
            <span className="label">{t('Phone')}</span>
          </IonCol>
          <IonCol size="3">
            <TextField
              onChange={phoneChangeHandler}
              name={'extPhone'}
              mode="white"
              value={data.extPhone}
              maxlength={3}
              ref={textField1}
            />
          </IonCol>
          <IonCol size="3">
            <TextField
              onChange={phoneChangeHandler}
              name={'firstPhone'}
              mode="white"
              value={data.firstPhone}
              maxlength={4}
              ref={textField2}
            />
          </IonCol>
          <IonCol size="3">
            <TextField
              onChange={phoneChangeHandler}
              name={'secondPhone'}
              mode="white"
              value={data.secondPhone}
              maxlength={4}
              ref={textField3}
            />
          </IonCol>
        </IonRow>
        <Divider />

        <IonRow className="required-row">
          <IonCol>
            <IonText>※は必須項目です。</IonText>
          </IonCol>
        </IonRow>

        <IonRow className="text">
          <IonCol>
            <IonText>
              {t(
                '※It may take some time from the time of application as we will review the applications in order.'
              )}
              <br />
              {t(
                '※We do not contact individual applicants regarding the screening status or rejection.'
              )}
            </IonText>
          </IonCol>
        </IonRow>

        <Button
          size="large"
          className="confirm-button"
          disabled={!isSubmitActive || isLoading}
          onClick={() => onSubmit()}
        >
          {t('Proceed to review')}
        </Button>
      </div>
      <Button size="large" className="back-button" onClick={() => history.push('/top')}>
        {t('Back to top')}
      </Button>
    </FormContainer>
  );
};
