import styled from 'styled-components';
import { IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction } from 'react';

import { ModalData } from './TicketSection';
import { Button, Divider } from '../../atoms';

interface Props {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalData?: ModalData | null;
}

const IonModalComponent = styled(IonModal)`
  --width: 375px;
  --height: auto;

  .ion-delegate-host {
    background-color: #000000;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 25px;
    color: #ffffff;

    .title {
      font-weight: 700;
      font-size: 18px;
      margin: 0px;
      white-space: pre-line;
      text-align: center;
    }

    .divide {
      height: 1px;
      background-color: #ffffff;
      width: 100%;
      margin: 33px 0px 10px;
    }

    .content {
      padding: 0 20px;
      margin: 0px;

      p {
        font-weight: 350;
        font-size: 15px;
        white-space: pre-line;
      }
    }

    .btn {
      width: 140px;
      background-color: #eeeeee;
      color: #b80629;
      border-radius: 30px;
    }
  }
`;

const DetailModal: React.FC<Props> = ({ showModal, setShowModal, modalData }) => {
  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <IonModalComponent isOpen={showModal} onDidDismiss={closeModal}>
      <div className="body">
        <p className="title">{modalData?.modalTitle ? modalData?.modalTitle : modalData?.title}</p>
        <Divider className="divide" />
        <div className="content">
          <p>{modalData?.modalContent}</p>
        </div>
        <Button size="small" className="btn" onClick={() => setShowModal(false)}>
          {t('Close')}
        </Button>
      </div>
    </IonModalComponent>
  );
};

export default DetailModal;
