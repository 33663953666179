import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Loader, TitleComponent } from '../../atoms';

interface Props {
  color: string;
  data: any;
  avatar?: string;
  isLoading: boolean;
}

const Wrapper = styled.div<any>`
  display: block;
  position: relative;
  border-radius: 50px;
  padding: 1.5rem 1rem 1rem 1rem;
  width: 100%;
  min-height: 150px;
  .header {
    text-align: center;
  }

  .vote-loader {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translateX(-50%);
  }

  > * {
    color: ${(props: Props) => (props.color ? props.color : 'var(--ion-text-color);')};
  }
`;

const CustomButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: auto;

  background: var(--ion-color-primary);
  box-shadow: var(--shadow-up);
  color: ${(props) => (props.color ? props.color : '#29c8d2')};
  border-radius: 2rem;
  font-size: 18px;
  font-weight: 700;
  margin: 1.75rem 0;

  :hover {
    box-shadow: 1px 1px 2px #c1c1c1, -2px -2px 1px #ffffff, inset -3px -3px 5px -4px #a7a4a4,
      inset 3px 4px 9px -7px #ffffff;
  }

  &.ion-activated,
  :active {
    border-radius: var(2rem);
    color: #cccccc;
    box-shadow: var(--shadow-down);
  }
  &.active-menu {
    border-radius: var(2rem);
    box-shadow: var(--shadow-down);
  }
`;

const Image = styled.div<any>`
  background: ${(props) => `url(${props.avatar})`};
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  background-size: cover;
  box-shadow: 2px 1px 2px white, -1.6px -1px 3px #cdcdcd;
`;

export const Vote: React.FC<Props> = ({ color, data = [], avatar, isLoading }: Props) => {
  const history = useHistory();

  const handleClick = (id: string, userSelected: string | null) => {
    if (userSelected === null) {
      history.push('/voting-list?list=ongoingVote');
    } else {
      history.push(`/voting-distribution?item=${id}&list=ongoingVote`);
    }
  };

  return (
    <Wrapper className="neomorphs-shadow-out" color={color}>
      <TitleComponent title={true} color={color} subHeading="投票受付中" heading="Vote" />
      <Loader isLoading={isLoading} className="vote-loader">
        {data.map((item: any, index: number) => (
          <CustomButton
            key={index}
            onClick={() => handleClick(item?.userVote?.id, item?.userVote?.userSelected)}
            className="button"
            color={color}
          >
            {avatar && <Image slot="start" avatar={avatar} />}
            {item?.userVote?.title}
          </CustomButton>
        ))}
      </Loader>
    </Wrapper>
  );
};
