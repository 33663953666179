import React from 'react';
import styled from 'styled-components';

import { RankTitleComponent } from '../../atoms';

const Wrapper = styled.div`
  .content-wrapper {
    color: #4d4d4d;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .heading {
      font-weight: 700;
      font-size: 18px;
    }

    .paragraph {
      font-weight: 350;
      font-size: 15px;
      text-align: justify;
      margin-top: 15px;
    }
  }

  .img {
    margin: 3px 10px 28px;
    mix-blend-mode: multiply;
  }
`;

const Management = () => {
  return (
    <Wrapper className="neomorphs-shadow-in card-wrapper">
      <RankTitleComponent heading="Management" subHeading="チーム経営" />

      <div>
        <div className="content-wrapper">
          <span className="heading">経営陣との面談</span>
          <p className="paragraph">
            GM・社長と月次で１時間のWeb面談が設
            <br />
            定可能です。好きなテーマ (テーマ例：戦
            <br />
            術、強化、運営等)について、意見や議論
            <br />
            ができます。
          </p>
        </div>
        <div className="content-wrapper">
          <span className="heading">チームスタッツレポート</span>
          <p className="paragraph">
            毎試合の得点、リバウンドやアシスト数
            <br />
            を含むスタッツやチームメンバーの独占
            <br />
            インタビューを含むチームスタッツレ
            <br />
            ポートがもらえます。
          </p>
        </div>
      </div>

      {/* <IonImg src="/assets/images/rank-details/management.png" className="img" /> */}
    </Wrapper>
  );
};

export { Management };
