import React, { createContext, useContext } from 'react';

type ContextProps = {
  isMobile: any;
  ismobileWithIpad: any;
};

export const MobileContext = createContext<Partial<ContextProps>>({});

type MobileProviderProps = {
  isMobile?: any;
  children: React.ReactNode;
};

export const MobileProvider = (props: MobileProviderProps) => {
  const { children } = props;

  const mobileCheck = () => {
    return /Android | webOS | iPhone | iPod | BlackBerry | IEMobile | Opera Mini/i.test(
      window.navigator.userAgent
    );
  };

  const mobileIpadTabletsCheck = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  };

  const isMobile = mobileCheck();
  const ismobileWithIpad = mobileIpadTabletsCheck();

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        ismobileWithIpad,
      }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const mobileContext = () => useContext(MobileContext);
