import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import React, { useContext, useState } from 'react';
import { IonImg, useIonViewDidEnter } from '@ionic/react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { useToast } from '../hooks/useToast';
import { UserContext } from '../utils/UserContext';
import { AuthContext } from '../utils/AuthContext';
import RestrictedRoute from '../withRestrictedRoute';
import { buyUtilityNftCard, utilityMasterDetailData } from '../services';
import { Button, IonPageComponent, Loader, CrossButton, Toast } from '../components';

const StyledLoader = styled(Loader)`
  position: absolute;
  margin: 68% 50%;
  transform: translate(-32%, -50%);
`;

const PurchaseWrapper = styled.div`
  padding: 20px 32px;

  .close-btn {
    display: flex;
    justify-content: flex-end;
  }

  .purchase-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-image {
      width: 80%;
      height: 50%;
      margin: 0 0 33px;
      filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.3));
    }

    p {
      color: #4d4d4d;
      margin-top: 0;
      font-size: 15px;
      font-weight: 400;
    }

    .player-name {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 11px;
      text-align: center;
    }

    .price {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 6px;
      margin-top: 5px;
    }

    .price-subheader {
      font-size: 14px;
    }

    .confirmation-msg {
      font-size: 16px;
      margin: 19px 0 28px;
    }

    .before-msg {
      margin: 5px 0px 18px;
    }

    .buy-button {
      width: 100%;
      margin-bottom: 37px;
    }

    .logo {
      width: 107px;
    }
  }
`;

const MarketPurchaseConfirmationPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useToast(false);
  const [error, setError] = useState<string>('');
  const { id } = useParams<{ id: string; token?: string }>();

  const { userProfileData } = useContext(UserContext);
  const { authenticated, loading } = useContext(AuthContext);

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleCardInfo', id],
    utilityMasterDetailData,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: (response) => {
        return response.data;
      },
    }
  );

  const { mutate, isLoading: mutateLoading } = useMutation(buyUtilityNftCard, {
    onSuccess: ({ data }) => {
      if (data?.message === 'out of stack.') {
        setError(data?.message);
        setShowToast(true);
      } else {
        window.open(data?.url, '_self');
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message);
      setShowToast(true);
    },
  });

  const buyCardHandler = () => {
    if (userProfileData && userProfileData?.membershipStatus?.items?.length === 0) {
      history.push('/team-list');
    } else {
      mutate({ userId: userProfileData?.id, nftMasterId: id });
    }
  };

  useIonViewDidEnter(() => {
    document.title = '購入確認 - Fanique';
    if (sessionStorage.getItem('redirect_to')) {
      sessionStorage.removeItem('redirect_to');
    }
  }, []);

  if (!loading) {
    if (!authenticated) {
      return <Redirect to="/top" />;
    }
  }

  return (
    <IonPageComponent>
      {showToast && (
        <Toast message={error ?? t('Something went wrong. Please try again.')} type={'Error'} />
      )}
      <StyledLoader isLoading={isLoading || isFetching}>
        <PurchaseWrapper>
          <div className="close-btn">
            <CrossButton onClick={() => history.push(`/market/detail/${id}`)} />
          </div>
          <div className="purchase-body">
            <IonImg className="card-image" src={data?.templateFileUrl} />
            <p className="player-name">{data?.cardName}</p>
            <p className="total">
              {t('Total')} : {data?.currentSoldNumber}/{data?.maxSalesNumber}
            </p>
            <p className="price">{data?.price}円</p>
            <p className="price-subheader">{data?.tag}</p>
            <p className="confirmation-msg">{t('Will you buy ?')}</p>
            {userProfileData && userProfileData?.membershipStatus?.items?.length === 0 && (
              <p className="before-msg">メンバーシップ会員のみ購入可能です</p>
            )}
            <Button
              size="large"
              className="buy-button"
              onClick={buyCardHandler}
              disabled={mutateLoading}
            >
              {userProfileData && userProfileData?.membershipStatus?.items?.length === 0
                ? t('Purchase membership')
                : t("Let's buy")}
            </Button>
            <IonImg className="logo" src="/assets/images/logo.png" />
          </div>
        </PurchaseWrapper>
      </StyledLoader>
    </IonPageComponent>
  );
};

export default RestrictedRoute(MarketPurchaseConfirmationPage);
