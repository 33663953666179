import { t } from 'i18next';
import { IonText } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext, useEffect } from 'react';

import { statusUp } from '../services';
import { useMutation } from 'react-query';
import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

import { queryClient } from '../index';
import {
  Button,
  TitleComponent,
  RankTitle,
  Toast,
  TeamCardComponent,
  ImageGallery,
} from '../components';

const HomeWrapper = styled.div`
  margin: 0 35px;
  text-align: center;
  .image {
    filter: drop-shadow(3px 5px 4px rgba(0, 0, 0, 0.4));
    margin: 35px 0;
  }
  .btn-status-up {
    margin-top: 50px;
  }
  .btn-back {
    margin: 30px 0;
    width: 140px;
    font-size: 12px;
    ::part(native) {
      --padding-top: 0.3rem;
    }
  }
  .confirm-text {
    margin: 50px 0;
    display: block;
    font-size: 15px;
    font-weight: 350;
  }

  @media (max-width: 355px) {
    .confirm-text {
      font-size: 14px;
    }
  }
`;

const StatusUpConfirm = () => {
  const history = useHistory();
  const [error, setError] = useToast('');
  const [showToast, setShowToast] = useToast(false);

  const { userProfileData, membershipId, status, teamDetails, update, userDataRefetch } =
    useContext(UserContext);

  const { mutate, isLoading: mutationLoading } = useMutation(statusUp, {
    onSuccess: async () => {
      queryClient.invalidateQueries('fetchUserData');
      await update();
      await userDataRefetch();
      history.push('/status-up-complete');
    },
    onError: (err: any) => {
      setError(err?.response.data.message);
      setShowToast(true);
    },
  });

  const statusUpgrade = () => {
    mutate({
      membershipId: membershipId,
      statusId: status.id,
      userId: userProfileData?.id,
    });
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      history.go(0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Wrapper authenticated={true}>
      {showToast && <Toast message={t(error)} type="Error" />}
      <HomeWrapper>
        <TitleComponent
          title={true}
          heading={t('Status Upgrade')}
          subHeading={t('Status up')}
          color={teamDetails?.team?.teamColor}
        />
        <div className="image">
          <TeamCardComponent />
        </div>
        <RankTitle />
        <IonText className="confirm-text">
          【ステータスアップ時の注意点】
          <br />
          <br />
          グッズ特典は翌シーズンより適用されます
          <br />
          グッズ特典以外はステータスアップ完了後
          <br />
          より適用されます
          <br />
          翌シーズンの年会費はステータスアップ後
          <br />
          の年会費となります
        </IonText>
        <Button
          size="large"
          className="btn-status-up"
          color={teamDetails?.team?.teamColor}
          disabled={mutationLoading}
          onClick={() => statusUpgrade()}
        >
          {t('Status Up')}
        </Button>
        <Button
          size="regular"
          className="btn-back"
          expand="block"
          color={teamDetails?.team?.teamColor}
          onClick={() => history.push('/status-up')}
        >
          {t('Back')}
        </Button>
        <ImageGallery header="メンバーシップステータス一覧" />
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(StatusUpConfirm);
