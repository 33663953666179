import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import React, { createContext, useEffect, useState } from 'react';

import { fetchUserMembership, fetchUserData } from '../services';

export const UserContext = createContext<any>({});

// remove : static value, remove this once dynamic value is sent from wallet

export const UserProvider: React.FC<any> = ({ children }) => {
  const [teamDetails, setTeamDetails] = useState<any>('');
  const [gameInfo, setGameInfo] = useState<string>('');
  const [membershipId, setMembershipId] = useState<string>('');
  const [status, setStatus] = useState<any>({});
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [decodedData, setDecodedData] = useState<any>(null);
  const [expiryDate, setExpiryDate] = useState<any>(null);
  const [userProfileData, setUserProfileData] = useState<any>(null);
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [totalTeamCoin, setTotalTeamCoin] = useState<any>(null);

  const getId = async () => {
    const auth = await Auth.currentSession();
    const token = auth.getIdToken().getJwtToken();

    if (token) {
      setDecodedData(JSON.parse(atob(token.split('.')[1])));
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  };

  const {
    isLoading: isUserProfileDataLoading,
    isFetching: isUserProfileDataFetching,
    refetch,
  } = useQuery(['fetchUserData', decodedData?.sub], fetchUserData, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!decodedData,
    select: (response: any) => {
      return response.data;
    },
    onSuccess(data) {
      if (data?.totalTeamCoin === null) {
        setUserProfileData({ ...data, totalTeamCoin: '{"" : 0}' });
      } else {
        setUserProfileData(data);
      }
    },
  });

  const {
    data: userData,
    isLoading: isMembershipLoading,
    isFetching: isMembershipFetching,
    refetch: userDataRefetch,
  } = useQuery(
    ['fetchUserMembership', userProfileData?.membershipStatus?.items[0]?.id, decodedData?.sub],
    fetchUserMembership,
    {
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: isAuthenticated && !!userProfileData?.membershipStatus?.items[0]?.id,
    }
  );

  const load = () => {
    if (isUserProfileDataLoading || isUserProfileDataFetching) {
      return true;
    }
    if (isMembershipLoading || isMembershipFetching) {
      return true;
    }
    if (userData && userData?.data?.level === undefined) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getId();
    setTeamDetails(userData?.data?.level);
    setMembershipId(userData?.data?.id);
    setStatus(userData?.data?.status);
    setExpiryDate(userData?.data?.exDate);
    setIsLoading(load());
  }, [userData, isUserProfileDataLoading, isMembershipFetching, isMembershipLoading]);

  useEffect(() => {
    if (userProfileData !== null && userProfileData !== undefined) {
      if (userProfileData?.totalTeamCoin) {
        const values: number[] = Object.values(JSON.parse(userProfileData?.totalTeamCoin));
        let balance = values.pop();
        setTotalTeamCoin(balance);
      }
    }

    if (userProfileData !== null && userProfileData !== undefined) {
      if (
        userProfileData?.username !== null &&
        userProfileData?.name_sei !== null &&
        userProfileData?.name_mei !== null
      ) {
        setIsFirstStepCompleted(true);
        return;
      }
    }
    setIsFirstStepCompleted(false);
  }, [userProfileData]);

  return (
    <UserContext.Provider
      value={{
        teamDetails,
        setTeamDetails,
        isLoading,
        setGameInfo,
        gameInfo,
        membershipId,
        status,
        isAuthenticated,
        userProfileData,
        setUserProfileData,
        expiryDate,
        isFirstStepCompleted,
        totalTeamCoin,
        update: refetch,
        userDataRefetch,
        decodedData: decodedData?.sub,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
