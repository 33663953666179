import React from 'react';
import {
  TopSection,
  Goods,
  GoodsData,
  TicketSection,
  RankDetailsVote,
  RankDetailEvent,
  EventData,
} from '../../molecules';
import { Button } from '../../atoms';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

const goods1Data: GoodsData[] = [
  {
    heading: '会員証カード',
    image: '/assets/images/rank-details/red-card.png',
    subheading:
      'Fanique会員証 (Broncos Red Owner)カードをストラップとホルダー付きでプレゼントいたします。',
  },
];

const goods2Data: GoodsData[] = [
  {
    heading: 'ハリセン or スティックバルーン',
    image: '/assets/images/rank-details/wild-power.png',
    subheading: 'ブロンコスオリジナルハリセンもしくはスティックバルーンをプレゼントいたします。',
  },
  {
    heading: 'ポスター',
    image: '/assets/images/rank-details/poster.png',
    caption: '▲デザイン(イメージ)',
    subheading: 'ブロンコスオリジナルポスターを毎年<br />プレゼントいたします。',
  },
  {
    heading: 'シーズン記念グッズ',
    image: '/assets/images/rank-details/yearbook.png',
    subheading:
      'ブロンコスオリジナルイヤーブック等<br />をシーズン終了前後にプレゼントいたします。<br /><br />※お受け取りは原則会場にてお渡しとなり、郵送を希望の場合には郵送料を頂きます。',
    imageSize: 'sm',
  },
];
const faniqueLimited = [
  {
    heading: 'Fanique限定コミュニティ参加',
    image: '/assets/images/rank-details/discord.png',
    subheading: '選手も参加するメンバーシップ限定コミュニティに参加いただけます。',
  },
];

const ticketData = [
  {
    id: 1,
    title: 'Fanique Zoneシート\nチケット (毎年1枚)',
    modalContent:
      '予め決められたFanique Zoneから席を選び、観戦できます。\n\nFanique ZoneはFaniqueがとり寄せる、一般販売されないブロックとなっております。Faniqueホルダーであるファンと共に試合をお楽しみください！\n\n※Fanique Zoneは一階指定席の特定位置となります。\n※Fanique Zoneの位置が随時変わることがございますのでご了承ください。\n※1枚のシートチケットにつき1試合の観戦となります。チケットの譲渡を可とし、転売を不可とします。\n※2023年度プレイオフでの使用は不可とします。',
  },
  // {
  //   id: 2,
  //   title: 'コートサイドシート\n購入権利(条件付き販売)',
  //   modalContent:
  //     'シーズンシート・Faniqueダイヤモンド・\nプラチナ・ゴールド会員、その他の販売\n後に残席がある場合のみ販売いたします。\nなお一般販売は2023-24シーズンの\nレギュラーシーズンは実施いたしません。\n\n【枚数制限】1会員様1試合につき3枚まで',
  // },
  // {
  //   id: 3,
  //   title: 'コートエンドシート\n購入権利(条件付き販売)',
  //   modalContent:
  //     'シーズンシート・Faniqueダイヤモンド・\nプラチナ・ゴールド会員、その他の販売\n後に残席がある場合のみ販売いたします。\nなお一般販売は2023-24シーズンの\nレギュラーシーズンは実施いたしません。\n\n【枚数制限】1会員様1試合につき3枚まで',
  // },
];

const event1Data: EventData[] = [
  {
    heading: '【抽選】<br />エスコートキッズ入場権',
    // alertHeading: 'Coming soon',
    subheading:
      'エスコートキッズとして選手と入場いただけます。<br />※対象は小中学生とさせて頂きます。<br />※希望者多数の場合は抽選を行います。',
  },
  // {
  //   heading: '【抽選】<br />ウォーミングアップ時の<br />リバウンド体験',
  //   alertHeading: 'Coming soon',
  //   subheading:
  //     'ウォーミングアップの時間中にリバウンド体験をすることができます。<br /> ※実施時には対象メンバーの皆様へご連絡いたします。',
  // },
  {
    heading: '試合終了後の<br />オンライン選手zoom会<br />(視聴のみ可)',
    subheading:
      '試合終了後のオンライン選手zoom会に参加いただけます。<br />※ 選手への質問はできませんのであらかじめご了承ください。',
  },
  {
    heading: '【抽選】<br />試合後選手と写真撮影<br />サイン会への参加権利',
    alertHeading: 'Coming soon',
    image: '/assets/images/rank-details/sign.jpg',
    subheading:
      '試合終了後に予め決められた選手２名と写真撮影・直接サインがもらえます。<br />実施時には対象のメンバー皆様へご連絡いたします。',
  },
];

const event2Data: EventData[] = [
  {
    heading: '【抽選】<br />選手からのバースデー<br />メッセージビデオ',
    // alertHeading: 'Coming soon',
    subheading: '選手からバースデーメッセージビデオをお届けします。',
  },
  {
    heading: '【抽選】<br />チームからのバースデーメッセージビデオ',
    // alertHeading: 'Coming soon',
    image: '/assets/images/rank-details/birthday.png',
    subheading: 'お誕生月にチームからメッセージをお届けいたします。',
  },
  // {
  //   heading: 'チームからの<br />バースデーメッセージ',
  //   subheading: 'お誕生月にチームからメッセージをお届けいたします。',
  // },
  {
    heading: '【抽選】<br />ファン感謝デー参加権利',
    image: '/assets/images/rank-details/teamwork.png',
    subheading: '抽選でファン感謝デーに参加いただけます。',
  },
];

const RedRankDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const goBack = () => {
    history.push({
      pathname: '/membership-rank',
      search: search,
    });
  };

  return (
    <>
      <div className="space">
        <TopSection cardName="Red" src="/assets/images/rank-details/red.png" />
      </div>
      <div className="space">
        <TicketSection data={ticketData} />
      </div>
      <div className="space">
        <Goods mainHeading="Goods①" mainSubheading="グッズ（入会時）" data={goods1Data} />
      </div>
      <div className="space">
        <Goods mainHeading="Goods②" mainSubheading="グッズ（毎年）" data={goods2Data} />
      </div>
      <div className="space">
        <Goods
          mainHeading="Community"
          mainSubheading="Fanique限定コミュニティ"
          data={faniqueLimited}
        />
      </div>
      <div className="space">
        <RankDetailsVote
          mainHeading="Vote"
          mainSubheading="投票企画"
          subHeading="試合のMVP投票など一部投票に参加できます。"
        />
      </div>
      <div className="space">
        <RankDetailEvent
          mainHeading="Event①"
          mainSubheading="イベント (試合当日)"
          data={event1Data}
        />
      </div>
      <div className="space">
        <RankDetailEvent
          mainHeading="Event②"
          mainSubheading="イベント (試合以外)"
          data={event2Data}
        />
      </div>
      <div className="space">
        <Button className="btn" size="large" onClick={() => goBack()}>
          {t('Back to membership list')}
        </Button>
      </div>
    </>
  );
};

export { RedRankDetail };
