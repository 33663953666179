import React from 'react';
import styled from 'styled-components';

interface Props {
  color: string;
  prev_level: number;
  next_level: number;
}

const Level = styled.div`
  font-size: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin: 40px 0;
  margin-bottom: 45px;
  color: #4d4d4d;

  .triangle-right {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-left: 20px solid ${(props) => (props.color ? props.color : '#b80629')};
    margin: 0 25px 0 40px;
  }

  .next-level {
    font-size: 50px;
    font-weight: 700;
    color: ${(props) => (props.color ? props.color : '#4d4d4d')};
  }
`;

export const LevelChange: React.FC<Props> = ({ color, prev_level, next_level }) => {
  return (
    <Level color={color}>
      {prev_level}
      <div className="triangle-right"></div>
      <span className="next-level">{isNaN(next_level) ? '' : next_level}</span>
    </Level>
  );
};
