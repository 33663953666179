import { heart, heartOutline } from 'ionicons/icons';
import styled from 'styled-components';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { IonImg, IonCard, IonCardContent } from '@ionic/react';

import { Button } from '../../atoms';
import { AuthContext } from '../../../utils/AuthContext';
import { UserContext } from '../../../utils/UserContext';
import { convertToNDigits } from '../../../utils';

interface Props {
  data: {
    id?: string;
    image?: string;
    teamName?: string;
    teamLogo?: string;
    title?: string;
    price?: string;
    quantity?: string;
    likesNumber?: number;
    teamId?: string;
    favorites?: { userId?: string }[];
    tokenId?: string;
  };
  isSlider?: boolean;
  onLikeClick?: (nftMasterId?: string, teamId?: string) => void;
  isLiking?: boolean;
  likingCardId?: string;
  setLikingCardId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  showLikeNumber?: boolean;
  showLikeButton?: boolean;
  myCollection?: boolean;
  isRecommended?: boolean;
}

const IonCardContainer = styled(IonCard)<{
  isSlider?: boolean;
  isLoggedIn?: boolean;
  showLikeNumber?: boolean;
}>`
  background: transparent;
  box-shadow: none;
  margin: 0;
  width: ${(props) => (props.isSlider ? '138px' : '156px')};
  ion-card-content {
    margin: 3.5px;
    padding: ${(props) => (props.isSlider ? '9px 7px' : '11px 9px')};
    min-height: ${(props) =>
      !props.isLoggedIn
        ? props.isSlider
          ? '245px'
          : '266px'
        : props.isSlider
        ? '266px'
        : '291px'};
    height: auto;
    border-radius: 6px;
    overflow: hidden;
    .clickable {
      cursor: pointer;
    }
    .upper-part {
      display: flex;
      justify-content: space-between;
    }
    .border {
      height: 1px;
      width: 100%;
      margin: ${(props) => (props.isSlider ? '12px 0 6px 0' : '14px 0 7px 0')};
    }
    .bottom-part {
      color: #4d4d4d;
      text-align: left;
      p {
        font-size: ${(props) => (props.isSlider ? '6px' : '7px')};
        font-weight: 400;
      }
      .title {
        font-size: ${(props) => (props.isSlider ? '8px' : '9px')};
        font-weight: 700;
      }

      .pricing {
        margin-bottom: ${(props) => (props.isSlider ? '8px' : '9px')};
        .price {
          font-size: ${(props) => (props.isSlider ? '13px' : '15px')};
          font-weight: 700;
        }
      }

      .quantity {
        .amount {
          font-size: ${(props) => (props.isSlider ? '10px' : '11px')};
        }
      }
    }
    .button-wrapper {
      width: ${(props) => (props?.showLikeNumber ? '46px' : '32px')};
      font-size: ${(props) => (props.isSlider ? '5px' : '6px')};
      margin: 0px;
      margin-top: ${(props) => (props.isSlider ? '12px' : '14px')};
      color: #4d4d4d;
      font-weight: 400;

      ion-icon {
        font-size: 10px;
        color: #29c8d2;
        // This is the default given by ionic: "-0.3em 0.3em". Overriding to make icon centered.
        margin-inline: ${(props) => (props?.showLikeNumber ? '-0.3em 0.3em' : '0px')};
      }

      ::part(native) {
        padding: 1.5px 0 !important;
      }
    }
    ion-img {
      width: ${(props) => (props.isSlider ? '75px' : '84px')};
      height: ${(props) => (props.isSlider ? '105px' : '118px')};
    }
    .logo-img {
      width: ${(props) => (props.isSlider ? '35px' : '39px')};
      height: ${(props) => (props.isSlider ? '32px' : '36px')};
    }
  }
`;

export const MarketCard: React.FC<Props> = ({
  data,
  isSlider = false,
  onLikeClick,
  isLiking,
  likingCardId,
  setLikingCardId,
  showLikeNumber = true,
  showLikeButton = true,
  myCollection = false,
  isRecommended = false,
}) => {
  const { push } = useHistory();
  const { authenticated } = useContext(AuthContext);
  const { isFirstStepCompleted, userProfileData } = useContext(UserContext);

  // Asynchronous Like Count
  const foundUser = data?.favorites?.find((d) => d?.userId === userProfileData?.id);
  const foundCard = data?.favorites?.find((cardId) => cardId === data?.id);

  const [likeCount, setLikeCount] = useState<number>(data?.likesNumber ?? 0);
  const [liked, setLiked] = useState<boolean>(
    isRecommended ? (foundCard ? true : false) : foundUser ? true : false
  );

  const handleRoute = (id?: string, tokenId?: string) => {
    if (!myCollection) {
      push(`/market/detail/${id}?fetch=true`);
    } else {
      push(`/my-card?card_id=${id}&token_id=${tokenId}`);
    }
  };

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (onLikeClick) {
      if (setLikingCardId) setLikingCardId(nftMasterId);
      // Call Like API
      onLikeClick(nftMasterId, teamId);
      // Statically Update like count in UI (We don't have to wait for like api to resolve)
      setLiked(!liked);
      setLikeCount(liked ? likeCount - 1 : likeCount + 1);
    }
  };

  const checkDisabled = (likingCardId?: string, cardId?: string, isLiking?: boolean) => {
    return likingCardId === cardId && isLiking;
  };

  return (
    <IonCardContainer
      isSlider={isSlider}
      isLoggedIn={authenticated && isFirstStepCompleted}
      showLikeNumber={showLikeNumber}
    >
      <IonCardContent className="neomorphs-shadow-out">
        <div className={'clickable'} onClick={() => handleRoute(data?.id, data?.tokenId)}>
          <div className="upper-part">
            <IonImg className="logo-img" src={data?.teamLogo} />
            <IonImg src={data?.image} />
          </div>
          <div className="border neomorphs-shadow-in"></div>
          <div className="bottom-part">
            <div className="title-wrapper">
              <p>{data?.teamName}</p>
              <p className="title">{data?.title}</p>
            </div>
            <div className="pricing">
              <p className="price">{data?.price}</p>
            </div>
            <div className="quantity">
              <p className="amount">
                {myCollection ? 'シリアルNo.' : '枚数'}：{data?.quantity}
              </p>
            </div>
            <div className="id">
              <p>出品者 {data?.teamName}</p>
            </div>
          </div>
        </div>
        {authenticated && isFirstStepCompleted && showLikeButton && (
          <Button
            className={`button-wrapper ${
              checkDisabled(likingCardId, data?.id, isLiking) ? 'like-disabled' : ''
            }`}
            icon={liked ? heart : heartOutline}
            size="small"
            onClick={() => handleLike(data?.id, data?.teamId)}
            disabled={checkDisabled(likingCardId, data?.id, isLiking)}
          >
            {showLikeNumber ? convertToNDigits(likeCount, 4) : null}
          </Button>
        )}
      </IonCardContent>
    </IonCardContainer>
  );
};
