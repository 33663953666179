import { IMultipleVotingData } from '../../components';

export const toVotingPollData = (
  selections?: string,
  selectionType?: 'SingleSelect' | 'MultipleSelect' | 'ImageSelect',
  results?: { [key: string]: number }
): IMultipleVotingData[] => {
  if (selections) {
    const dictionary: { [key: string]: string } = JSON.parse(selections);
    const result = Object.entries(dictionary)?.map(([key, value]) => {
      if (results) {
        const totalVotes = Object.entries(results)?.reduce((acc, [key, value]) => {
          if (key !== 'notVoted') {
            return acc + value;
          } else {
            return acc;
          }
        }, 0);

        const percentage = totalVotes !== 0 ? (results[key] / totalVotes) * 100 : 0;

        if (selectionType === 'ImageSelect') {
          return {
            id: key,
            image: value,
            percentage: +percentage.toFixed(2),
          };
        } else {
          return {
            id: key,
            name: value,
            percentage: +percentage.toFixed(2),
          };
        }
      }
      if (selectionType === 'ImageSelect') {
        return {
          id: key,
          image: value,
          percentage: 0,
        };
      } else {
        return {
          id: key,
          name: value,
          percentage: 0,
        };
      }
    });
    return result;
  } else return [];
};
