import { API } from './api';
import { IMarketList } from '../types/market';
import { QueryFunctionContext } from 'react-query';

interface IPurchaseNFTCard {
  nftMasterId: string;
  userId: string;
}

interface MarketParams {
  page: string;
  sortDirection?: string;
  sortField?: string;
  playerName?: string;
  tag?: string;
  nftFileType?: string;
  saleStatus?: string;
}

export const fetchMarketList = ({ queryKey }: any) => {
  const isMainSearch = queryKey[6];
  const params = {} as MarketParams;

  params.page = queryKey[1];
  params.sortDirection = queryKey[2];
  params.sortField = queryKey[3];
  params.playerName = isMainSearch
    ? queryKey[4]
    : queryKey[5]?.find((d: any) => d?.name === 'playerName')?.value;

  params.tag = isMainSearch ? '' : queryKey[5]?.find((d: any) => d?.name === 'tag')?.value;
  params.nftFileType = isMainSearch
    ? ''
    : queryKey[5]?.find((d: any) => d?.name === 'nftFileType')?.value;
  params.saleStatus = isMainSearch
    ? ''
    : queryKey[5]?.find((d: any) => d?.name === 'saleStatus')?.value == 'all'
    ? ''
    : queryKey[5]?.find((d: any) => d?.name === 'saleStatus')?.value;

  return API.get<IMarketList>(`/utilitynft/master/list`, {
    params,
  });
};

export const fetchMarketBanners = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  return API.get(`/utilitynft/banner/list`, { params: { teamId } });
};

export const likeMarketCard = ({
  nftMasterId,
  userId,
  teamId,
}: {
  nftMasterId?: string;
  userId?: string;
  teamId: string;
}) => {
  return API.post(`/user/utilitynft/like`, {}, { params: { nftMasterId, userId, teamId } });
};

export const fetchMarketFavouriteListAPI = ({ queryKey }: QueryFunctionContext) => {
  const userid = queryKey[1];
  const teamid = queryKey[2];
  const page = queryKey[3];
  return API.get(`/user/utilitynft/favorites?userId=${userid}&teamId=${teamid}&page=${page}`);
};

export const utilityMasterDetailData = ({ queryKey }: QueryFunctionContext) => {
  const nftMasterId = queryKey[1];
  return API.get(`/utilitynft/master?id=${nftMasterId}`);
};

export const purchasedCardDetailAPI = ({ queryKey }: QueryFunctionContext) => {
  const nftMasterId = queryKey[1];
  return API.get(`/utilitynft?id=${nftMasterId}`);
};

export const buyUtilityNftCard = (values: IPurchaseNFTCard) => {
  const { nftMasterId, userId } = values;
  return API.post(`/utilitynft/purchase?id=${nftMasterId}&userId=${userId}`);
};

export const fetchRecommendedNFTCard = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const userId = queryKey[2];
  return API.get(`/utilitynft/master/recommended?teamId=${teamId}&userId=${userId}`);
};

export const fetchRecommendedNFTCardNotLoggedIn = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  return API.get(`/utilitynft/master/recommended?teamId=${teamId}`);
};

export const fetchPurchasedCardData = ({ queryKey }: QueryFunctionContext) => {
  const sessionId = queryKey[1];
  return API.get(`/user/purchase/item?id=${sessionId}`);
};

export const fetchMyCollectionDetailData = ({ queryKey }: QueryFunctionContext) => {
  const cardId = queryKey[1];
  const tokenId = queryKey[2];
  return API.get(`/utilitynft?masterId=${cardId}&tokenId=${tokenId}`);
};
