import { t } from 'i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { IonIcon, useIonViewWillEnter } from '@ionic/react';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, TeamCardComponent } from '../components';
import { queryClient } from '..';

const HomeWrapper = styled.div`
  margin: 0 35px;
  text-align: center;
  min-height: 70vh;
  .image {
    filter: drop-shadow(3px 5px 4px rgba(0, 0, 0, 0.4));
    margin-bottom: 35px;
    margin-top: 10px;
  }
  .btn-status-up {
    margin-top: 50px;
  }
  .btn-back {
    margin: 25px 0;
    width: 140px;
    font-size: 12px;
    ::part(native) {
      --padding-top: 0.3rem;
    }
  }

  .alert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    ion-icon {
      width: 25px;
      height: 25px;
      color: #b80629;
    }

    span {
      margin-left: 22px;
      font-weight: 700;
      font-size: 12px;
      color: #4d4d4d;
    }
  }
`;

const StatusUpComplete = () => {
  const history = useHistory();
  const { teamDetails, update } = useContext(UserContext);

  useIonViewWillEnter(() => {
    async function fetch() {
      queryClient.invalidateQueries('fetchUserData');
      await update();
    }
    fetch();
  }, []);

  return (
    <Wrapper authenticated={true}>
      <HomeWrapper>
        <div className="image">
          <TeamCardComponent />
        </div>
        <TitleComponent
          title={true}
          heading={t('Status Upgrade!!')}
          subHeading={t('Status up completed')}
          color={teamDetails?.team?.teamColor}
        />

        <div className="alert">
          <IonIcon icon={alertCircleOutline} />
          <span>{t('It may take some time to reflect.')}</span>
        </div>

        <Button
          size="regular"
          className="btn-back"
          expand="block"
          color={teamDetails?.team?.teamColor}
          onClick={() => history.push('/home')}
        >
          {t('Back')}
        </Button>
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(StatusUpComplete);
