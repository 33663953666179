import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { Button, TitleComponent } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { AuthContext } from '../utils/AuthContext';

const Container = styled.div`
  text-align: center;
  min-height: 35em;

  .page-title {
    font-weight: 350;
    font-size: 16px;
  }
  .title-container {
    line-height: 0px !important;
  }
  .title {
    font-weight: 800;
    margin-top: -0.3em;
    font-size: 30px !important;
  }
  .logout-button {
    margin: 30px 33px;
    font-weight: 700;
    font-size: 18px;
  }
  .logout-confirmation {
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    margin: 3.5em 0em;
  }
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 0em;
  }
  .back-button {
    width: fit-content;
    font-weight: 700;
    font-size: 12px;
  }
`;

const Logout: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { teamDetails } = useContext(UserContext);
  const { logout } = useContext(AuthContext);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <Wrapper authenticated={true}>
      <Container>
        <div className="title-container">
          <p className="page-title">{t('Logout')}</p>
          <TitleComponent
            title={true}
            heading="Logout"
            className="title"
            color={teamDetails?.team?.teamColor}
          />
        </div>
        <p className="logout-confirmation">{t('Do you want to logout ?')}</p>
        <Button
          size="large"
          className="logout-button"
          onClick={() => handleLogout()}
          color={teamDetails?.team?.teamColor}
        >
          {t('Logout')}
        </Button>
        <div className="button-container">
          <Button
            size="regular"
            className="back-button"
            onClick={() => history.push('/settings')}
            color={teamDetails?.team?.teamColor}
          >
            {t('Back to setting page')}
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default PrivateRoute(Logout);
