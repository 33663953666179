import { IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<any>`
  --background-hover-opacity: 0;
  ion-select {
    --padding-start: 1rem;
    --padding-end: 1rem;
    width: 100%;
    height: 28px;
    border-radius: 1.3em;
    font-size: 12px;
    font-weight: 700;
    background: var(--ion-color-primary);
    box-shadow: var(--shadow-up);
    color: #29c8d2;
    border-radius: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin: 2px 3px 0px 0px;
  }
  .select-expanded::part(icon) {
    color: #cccccc;
  }
  .select-expanded {
    color: #cccccc;
    box-shadow: var(--shadow-down);
  }
`;

interface Props {
  onChange: (e: any) => any;
  options: Option[];
  value?: string | number;
}

interface Option {
  value: string | number;
  label: string | number;
}

export const Dropdown: React.FC<Props> = ({ onChange, options, value }) => {
  return (
    <Wrapper lines="none">
      <IonSelect
        placeholder={'Select'}
        onIonChange={onChange}
        className="dropdown"
        interface="action-sheet"
        interfaceOptions={{ mode: 'ios', cssClass: 'select-sheet' }}
        value={value}
      >
        {(options || []).map((item) => (
          <IonSelectOption value={item.value} key={item.label}>
            {item.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </Wrapper>
  );
};
