import React from 'react';
import { IonImg } from '@ionic/react';

import { Divider } from '../../atoms';
import { ImageGallery } from '../../molecules';

const Structure = () => {
  return (
    <div className="neomorphs-shadow-out concept structure">
      <p className="inner-header">仕組み</p>
      <p className="context">
        Faniqueを通じて、メンバーシップNFT
        <br />
        を発行できます。ファンはメンバーシップ
        <br />
        を保有することでチームから特別な
        <br />
        特典や重要な意思決定に関与することが
        <br />
        可能になります。
      </p>
      <IonImg src="/assets/images/about/structure.png" />
      <Divider className="divider1 divider" />
      <p className="context">
        メンバーシップNFTステータスに応じた
        <br />
        様々な特典を提供します
      </p>
      {/* <IonImg src="/assets/images/about/benefits.png" /> */}
      <div className="benefit-image">
        <ImageGallery IMAGE_URL="/assets/images/about/benefits.png" />
      </div>
    </div>
  );
};

export default Structure;
