import styled from 'styled-components';
import { IonModal } from '@ionic/react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect } from 'react';

import { levelUp } from '../services';
import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  Toast,
  Button,
  Loader,
  LevelChange,
  TitleComponent,
  TeamCardComponent,
} from '../components';

import { AuthContext } from '../utils/AuthContext';
import Web3 from 'web3';

const Contain = styled.div`
  padding: 0 35px;

  .img-wrapper {
    margin: 30px 0;
  }

  .loader {
    margin-top: 50%;
  }

  .level-up-loader {
    margin: -5px 0px !important;
  }

  .back-button-wrapper {
    display: flex;
    justify-content: center;

    .back-btn {
      margin-top: 25px;
      width: 140px;
      text-align: center;
    }
  }

  .approve-btn {
    margin-bottom: 15px;
  }

  .confirmation-message {
    font-size: 15px;
    font-weight: 350;
    text-align: center;
    margin-bottom: 35px;
  }
`;

const IonModalComponent = styled(IonModal)`
  --width: 326px;
  --height: 262px;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.2);

  .ion-delegate-host {
    background-color: #efefef;
  }

  .body {
    padding: 33px 30px;

    .warning {
      height: 42%;
      text-align: center;

      p {
        white-space: pre-line;
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p {
      font-weight: 400;
      font-size: 14px;
    }

    .btn {
      width: 265px;
      color: ${(props) => (props?.color ? props?.color : '#29c8d2')};
    }

    .cancel {
      font-size: 18px;
      cursor: pointer;
    }
  }
`;

const LevelUp = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { upbondServices, logout } = useContext(AuthContext);

  const [error, setError] = useToast('');
  const [showToast, setShowToast] = useToast(false);
  const [amount, setamount] = useState(100);
  const [transactionData, setTransactionData] = useState<any>(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const closeModal = () => {
    setShowLogoutModal(false);
  };

  const {
    teamDetails,
    membershipId,
    status,
    userProfileData,
    isLoading,
    totalTeamCoin,
    update,
    userDataRefetch,
  } = useContext(UserContext);

  const PREV_LEVEL = teamDetails?.level;
  const NEXT_LEVEL = teamDetails?.level + 1;
  const COLOR = teamDetails?.team?.teamColor;

  const { mutate, isLoading: mutationLoading } = useMutation(levelUp, {
    onSuccess: async () => {
      const now = new Date();
      localStorage.setItem('levelUpdatedAt', JSON.stringify(now.getTime()));
      setTimeout(function () {
        localStorage.removeItem('levelUpdatedAt');
      }, 60000);

      await update();
      await userDataRefetch();
      history.push('/level-up-success');
    },
    onError: (err: any) => {
      if (err?.response.data.message.includes('invalid signature')) {
        setError('invalid signature');
      } else {
        setError(err?.response.data.message);
      }
      setShowToast(true);
    },
  });

  const levelUpgrade = async () => {
    try {
      const web3 = new Web3(upbondServices?.upbond.provider);
      const address: any = await web3.eth.getAccounts();

      if (address && address.length === 0 && showLogoutModal === false) {
        setShowLogoutModal(true);
      }

      const permitTransaction = await upbondServices.permit(amount);
      setTransactionData(permitTransaction);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      history.go(0);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (transactionData) {
      mutate({
        membershipId: membershipId,
        userId: userProfileData?.id,
        data: { ...transactionData, value: transactionData.value.toString() },
      });
    }
  }, [transactionData]);

  useEffect(() => {
    if (teamDetails?.requiredToken) {
      setamount(teamDetails?.requiredToken);
    }
  }, [teamDetails]);

  return (
    <Wrapper authenticated>
      {showToast && <Toast message={t(error)} type={'Error'} />}

      <IonModalComponent
        isOpen={showLogoutModal}
        onDidDismiss={closeModal}
        color={teamDetails?.team?.teamColor}
      >
        <div className="body">
          <div className="warning">
            <p>
              セッションが切れています。
              <br />
              お手数ですが、再度ログイン後、
              <br />
              実行をお願いします。
            </p>
          </div>
          <div className="button-wrapper">
            <Button size="large" className="btn" onClick={handleLogout}>
              {t('Re-login')}
            </Button>
            <p className="cancel" onClick={closeModal}>
              {t('Cancel')}
            </p>
          </div>
        </div>
      </IonModalComponent>

      <Contain>
        <Loader isLoading={isLoading} className="loader">
          <TitleComponent heading="Level Up" subHeading="Level up" title color={COLOR} />
          <div className="img-wrapper">
            <TeamCardComponent />
          </div>

          <LevelChange color={COLOR} prev_level={PREV_LEVEL} next_level={NEXT_LEVEL} />

          <div>
            <p className="confirmation-message">
              {t('Are you sure ?')}
              <br />※{teamDetails?.requiredToken.toLocaleString('en-US')}coin{' '}
              {t('will be deducted')}
            </p>
          </div>

          <Button
            size="large"
            color={COLOR}
            onClick={() => levelUpgrade()}
            disabled={teamDetails?.requiredToken > totalTeamCoin || mutationLoading}
            // disabled // prod
          >
            <Loader className="level-up-loader" isLoading={mutationLoading}>
              <span>{t('Level UP')}</span>
            </Loader>
          </Button>
          <div className="back-button-wrapper">
            <Button
              disabled={mutationLoading}
              className="back-btn"
              size="small"
              color={COLOR}
              onClick={() => history.goBack()}
            >
              {t('Back')}
            </Button>
          </div>
        </Loader>
      </Contain>
    </Wrapper>
  );
};

export default PrivateRoute(LevelUp);
