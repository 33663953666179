import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  membershipType?: string | null;
  amount: string | undefined;
}

const TeamDetail = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 42px;
  width: 85%;
  margin: auto;

  .detail {
    font-weight: 350;
    font-size: 15px;
    color: #000000;
  }

  @media (max-width: 430px) {
    padding: 0px 25px;

    .detail {
      font-size: 13px;
    }
  }

  @media (max-width: 360px) {
    width: 95%;
  }
`;

export const PurchaseDetail: React.FC<Props> = ({ membershipType, amount }) => {
  const { t } = useTranslation();
  return (
    <TeamDetail>
      <div className="detail">
        <p>{t('Membership rank')}</p>
        <p>{t('Amount')}</p>
      </div>
      <div className="detail">
        <p>{membershipType ?? 'RED'}</p>
        <p>{amount ?? '5,500'}</p>
      </div>
    </TeamDetail>
  );
};
