import React, { useState, useEffect, createContext, useContext, useRef } from 'react';

import Upbond from '@upbond/upbond-embed';

import { Auth, Hub } from 'aws-amplify';
import { useHistory } from 'react-router';

import upbondServices from '../lib/UpbondEmbed';

type ContextProps = {
  loading?: boolean;
  user: any | null;
  setUser: any;
  authenticated: boolean;
  login?: any;
  upInit: boolean;
  cognitoUser: any;
  logout?: any;
  setLoading?: any;
  upbondServices?: any;
  upbondProviders?: Upbond;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

type AuthProviderProps = {
  user?: any | null;
  setUser?: any;
  loading?: boolean;
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const [user, setUser] = useState<any>(null);
  const [upInit, setUpInit] = useState(false);
  // NULL = NOT AUTH, UNDEFINED = NOT LOADED
  const [cognitoUser, setCongnitoUser] = useState<any>();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useRef({ pathname: '', search: '' });

  const [cognitoLoggedIn, setCognitoLoggedIn] = useState(false);
  const _upbond = upbondServices.upbond.provider;

  const init = async () => {
    setUpInit(false);
    try {
      if (history.location.search)
        location.current = { pathname: history.location.pathname, search: history.location.search };
      await upbondServices.init();
      if (location.current.search) {
        history.push({
          pathname: location.current.pathname,
          search: location.current.search,
        });
      }
      setUpInit(true);
    } catch (error) {
      console.error(`Error initialization: `, error);
    }
  };

  useEffect(() => {
    if (_upbond) {
      _upbond.on('connect', (ev) => {
        if (!cognitoLoggedIn) {
          setCognitoLoggedIn(true);
        }
      });
    }
  }, [upbondServices.upbond.provider]);

  const login = async () => {
    setLoading(true);

    try {
      if (upbondServices.upbond && upbondServices.upbond.isLoggedIn) {
        await upbondServices.logout();
      }
      // if (!upbondProviders.isInitialized) {
      //   await init();
      // }
      // wait until upbondProvider finished initialization
      if (!upbondServices?.upbond?.isLoggedIn) {
        const login = await upbondServices.login();
        if (login !== null) {
          setLoading(false);
          return login?.data;
        }
      }
    } catch (error: any) {
      setLoading(false);
      return error.message;
    } finally {
      setLoading(false);
    }
  };

  const logoutOfUpbond = async () => {
    localStorage.removeItem('account');
    localStorage.removeItem('user-info');
    await upbondServices.logout();
  };

  const logout = async () => {
    try {
      await logoutOfUpbond();
      await Auth.signOut();
      // Clear cache
      // window.location.reload();
      // Clear storage_userStore-info
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
    } catch (err: any) {
      if (err.message === 'User has not logged in yet') {
        if (cognitoUser) {
          await Auth.signOut();
        }
        window.location.href = '/top';
      }
      return '';
    }
  };

  useEffect(() => {
    if (cognitoUser === undefined) {
      setLoading(true);
      Auth.currentAuthenticatedUser()
        .then((user: any): void => {
          setCongnitoUser(user);
          setLoading(false);
        })
        .catch((err) => {
          setCongnitoUser(null);
          setLoading(false);
        });
    }
    // Add Auth Event listener
  }, [
    upbondServices?.upbond?.isLoggedIn,
    upbondServices?.upbond?.isInitialized,
    upbondServices?.upbond?.currentVerifier,
  ]);

  useEffect(() => {
    init(); //initial upbond embed firstly after refresh or open web

    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signOut':
          setUser(null);
          setCongnitoUser(null);
          break;
        case 'signIn':
          setCongnitoUser(data);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        cognitoUser,
        login,
        logout,
        upInit,
        setUser,
        loading: loading || cognitoUser === undefined,
        setLoading,
        upbondProviders: upbondServices?.upbond,
        upbondServices,
        authenticated: cognitoUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const authContext = () => useContext(AuthContext);