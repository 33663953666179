import styled from 'styled-components';
import React, { useContext } from 'react';

import { Loader } from '../../atoms';
import { RotatingCard } from '../../molecules';
import { UserContext } from '../../../utils/UserContext';

interface Props {
  rotating?: boolean;
  name?: string;
  id?: string;
}

const Container = styled.div`
  .card-loader {
    min-height: 210px;
    display: flex;
    align-items: center;
  }
`;

export const TeamCardComponent: React.FC<Props> = ({ rotating = false, name = '', id }) => {
  const { userProfileData, isLoading } = useContext(UserContext);

  if (rotating) {
    return (
      <Container>
        <Loader
          isLoading={isLoading || !userProfileData?.membershipStatus?.items[0]?.imageUrlFront}
          className="card-loader"
        >
          <RotatingCard
            name={name}
            front={`${
              userProfileData?.membershipStatus?.items[0]?.imageUrlFront
            }?lastmod=${Date.now()}`}
            back={`${
              userProfileData?.membershipStatus?.items[0]?.imageUrlBack
            }?lastmod=${Date.now()}`}
            id={id}
            font={userProfileData?.membership_fontname}
          />
        </Loader>
      </Container>
    );
  } else {
    return (
      <Container>
        <Loader
          isLoading={isLoading || !userProfileData?.membershipStatus?.items[0]?.imageUrlFront}
          className="card-loader"
        >
          <img
            src={`${
              userProfileData?.membershipStatus?.items[0]?.imageUrlFront
            }?lastmod=${Date.now()}`}
            className="img"
          />
        </Loader>
      </Container>
    );
  }
};
