import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IonLabel, IonRadio } from '@ionic/react';

interface RadioProps {
  label?: string;
  value?: string;
  disabled?: boolean;
}

const RadioWrapper = styled.div<any>`
  --background-hover: none;
  --border-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  ion-radio {
    --color-checked: #29c8d2;
    --border-width: 0px;
    background-color: #efefef;
    box-shadow: var(--shadow-up);
    border-radius: 50%;
    margin-right: 5px;
  }
  ion-label {
    color: ${(props: RadioProps) => (props?.disabled ? '#9a9a9a' : '#4d4d4d')};
    font-size: 15px;
    font-weight: 400;
  }
  ion-radio.ios {
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    border-radius: 50%;
  }
  .radio-checked.ios {
    border-color: #6815ec;
  }
`;

export const FilterRadio: React.FC<RadioProps> = ({ label, value, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <RadioWrapper disabled={disabled}>
      <IonLabel>{`${label && t(label)}`}</IonLabel>
      <IonRadio value={value} disabled={disabled}></IonRadio>
    </RadioWrapper>
  );
};
