import styled from 'styled-components';
import { IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';

import { UNIFORM_SIZE } from '../../../utils';
import { UserContext } from '../../../utils/UserContext';
import { BackCard, Button, ImageGallery, Select } from '../../../components';

// export interface IFontOptions {
//   label: string;
//   value: string;
// }

interface IBackCardGeneratorProps {
  image: string;
  // fontOptions: IFontOptions[];
  color: string;
  mutate?: (values: any) => void;
  isLoading?: boolean;
  giftMutate?: (values: any) => void;
  masterId?: { uniformMasterId: string; tshirtMasterId: string };
  setShirtSize?: any;
  shirtSize?: string;
  uniformDetail?: { size: string; name: string; number: string };
  setUniformDetail?: any;
}

const Wrapper = styled.div`
  .back-card-wrapper {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
  }
  .form-wrapper {
    padding: 0 30px;
    ion-text {
      width: 11em;
    }

    ion-select,
    ion-input {
      max-width: 80%;
      margin-right: 5px;

      @media (max-width: 410px) {
        max-width: 75%;
      }
      @media (max-width: 340px) {
        max-width: 70%;
      }
    }

    .preview-link {
      padding-left: 1rem;
      padding-bottom: 10px;
      @media (max-width: 390px) {
        .text-link {
          font-size: 10px;
        }
      }
    }
  }
`;

const InputWrapper = styled(IonItem)`
  font-size: 15px;
  min-height: var(--min-height);

  span {
    font-size: 13px !important;
    font-weight: 350;
    display: inline-block;
    width: 9em;
    margin-right: 1em;
  }
  input {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 5px;
    caret-color: black;
    border-radius: 1.3em;
    font-size: 13px;
    height: 2.5em;
    width: 85%;
    box-shadow: var(--shadow-down);
    -webkit-appearance: none;
    border: none;
    background-color: #eee;
    outline: none;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    .validation-message {
      color: rgba(0, 0, 0, 0.5);
      font-size: 10px;
      font-weight: 400;
      margin: 3px 0 0;
      margin-left: 8px;
      line-height: 16px;
    }

    input {
      width: 95%;
      margin-left: 8px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 35px;
`;

const BackCardGenerator: React.FC<IBackCardGeneratorProps> = ({
  image,
  color,
  isLoading,
  // fontOptions,
  mutate,
  shirtSize,
  setShirtSize,
  setUniformDetail,
  uniformDetail,
}) => {
  const [name, setName] = useState('');

  // const [fontFace, setFontFace] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [dataToSend, setDataToSend] = useState<any>({});

  const { userProfileData, status } = useContext(UserContext);

  const onSubmitHandler = () => {
    if (mutate) {
      mutate({
        userId: userProfileData?.id,
        // details: { ...dataToSend, username: name, membership_fontname: fontFace },
        details: {
          ...dataToSend,
          username: name,
          membership_fontname: dataToSend?.membership_fontname
            ? dataToSend?.membership_fontname
            : `'Dancing Script', cursive`,
        },
      });
    }
  };

  // useEffect(() => {
  //   if (name === '' || fontFace === '') {
  //     setDisableButton(true);
  //     return;
  //   }
  //   setDisableButton(false);
  // }, [fontFace, name]);

  useEffect(() => {
    if (status?.statusNameEnglish === 'GOLD') {
      if (name === '' || shirtSize === '') {
        setDisableButton(true);
        return;
      }
    } else if (status?.statusNameEnglish === 'RED' || status?.statusNameEnglish === 'SILVER') {
      if (name === '') {
        setDisableButton(true);
        return;
      }
    } else {
      if (
        name === '' ||
        uniformDetail?.size === '' ||
        uniformDetail?.number === '' ||
        shirtSize === ''
      ) {
        setDisableButton(true);
        return;
      }
    }

    setDisableButton(false);
  }, [name, shirtSize, uniformDetail]);

  useEffect(() => {
    if (userProfileData) {
      const { membershipStatus, teams, watchLogs, __typename, ...newData } = userProfileData;
      setDataToSend(newData);
    }
  }, [userProfileData]);

  const { t } = useTranslation();

  const handleUniformDetailFields = (name: string, value: string) => {
    setUniformDetail({ ...uniformDetail, [name]: value });
  };

  return (
    <Wrapper>
      <div className="back-card-wrapper">
        {/* <BackCard name={name} fontFace={fontFace} image={image} /> */}
        <BackCard
          name={name}
          image={image}
          fontFace={
            dataToSend?.membership_fontname !== null
              ? dataToSend?.membership_fontname
              : `'Dancing Script', cursive`
          }
        />
      </div>

      <div className="form-wrapper">
        <InputWrapper lines="none">
          <span>NAME</span>
          <input
            placeholder=""
            maxLength={20}
            name="name"
            value={name}
            autoComplete="off"
            /* For when we accept Japanese, English Alphabets and Spaces except Numbers and Special Characters */
            // onChange={(e) => {
            //   // Allow Japanese, English Alphabets and Spaces except Numbers and Special Characters
            //   // eslint-disable-next-line no-useless-escape
            //   const pattern = /[^\u3040-\u309F\u30A0-\u30FFa-zA-Z\s]/g;
            //   // In Mobile Browsers, maxLength might not work due to autosuggestion features.
            //   if (e?.target?.value?.length <= 20) {
            //     setName(e?.target?.value.replace(pattern, ''));
            //   }
            // }}
            onChange={(e) => {
              setName(e?.target?.value.replace(/[^A-Za-z\s]/g, ''));
              // In Mobile Browsers, maxLength might not work due to autosuggestion features.
              if (e?.target?.value?.length <= 20) {
                setName(e?.target?.value.replace(/[^A-Za-z\s]/g, ''));
              }
            }}
            onKeyDown={(e: any) => {
              if (!/[a-zA-Z ]$/i.test(e.key)) e.preventDefault();
            }}
          />
        </InputWrapper>

        {/* <Select
          placeholder={t('Select')}
          label="FONT"
          name="font"
          value={fontFace}
          options={fontOptions}
          onChange={(e) => setFontFace(e.detail.value)}
        /> */}

        {status?.statusNameEnglish !== 'RED' && status?.statusNameEnglish !== 'SILVER' && (
          <>
            <Select
              label={t('T-shirt size')}
              name="shirtSize"
              value={shirtSize}
              options={UNIFORM_SIZE}
              onChange={(e) => setShirtSize(e.detail.value)}
            />
            {status?.statusNameEnglish === 'GOLD' && (
              <div className="preview-link">
                <ImageGallery
                  textOpener="＊Click here for size info"
                  IMAGE_URL="/assets/images/uniform-size.png"
                />
              </div>
            )}
          </>
        )}

        {status?.statusNameEnglish !== 'RED' &&
          status?.statusNameEnglish !== 'SILVER' &&
          status?.statusNameEnglish !== 'GOLD' && (
            <div className="top-field-wrapper">
              <>
                <Select
                  label={t('Uniform size')}
                  name="size"
                  value={uniformDetail?.size}
                  options={UNIFORM_SIZE}
                  onChange={(e) => handleUniformDetailFields('size', e.detail.value)}
                />
                <div className="preview-link">
                  <ImageGallery
                    textOpener="＊Click here for size info"
                    IMAGE_URL="/assets/images/uniform-size.png"
                  />
                </div>
              </>
              <InputWrapper lines="none">
                <span>{t('Uniform no')}</span>
                <div className="input-wrapper">
                  <input
                    placeholder=""
                    maxLength={20}
                    name="number"
                    value={uniformDetail?.number}
                    autoComplete="off"
                    onChange={(e) => {
                      if (/^(?:\d{0,2}|123)$/.test(e?.target?.value)) {
                        handleUniformDetailFields('number', e?.target?.value);
                      }
                    }}
                  />
                  <p className="validation-message">{t('*Enter numbers from 0-99 & 123.')}</p>
                </div>
              </InputWrapper>
              <InputWrapper lines="none">
                <span>{t('Uniform name')}</span>
                <div className="input-wrapper">
                  <input
                    placeholder=""
                    maxLength={20}
                    name="name"
                    value={uniformDetail?.name}
                    autoComplete="off"
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 15) {
                        handleUniformDetailFields(
                          'name',
                          e?.target?.value.replace(/[^A-Z\s]/g, '')
                        );
                      }
                    }}
                    onKeyDown={(e: any) => {
                      if (!/[A-Z ]$/i.test(e.key)) e.preventDefault();
                    }}
                  />
                  <p className="validation-message">
                    {t('*Capital alphabets only within 15 chars , Allow space')}
                  </p>
                </div>
              </InputWrapper>
            </div>
          )}

        <ButtonWrapper>
          <Button
            disabled={disableButton || isLoading}
            expand="full"
            size="large"
            color={disableButton ? '#ccc' : color}
            onClick={onSubmitHandler}
          >
            {t('Confirm')}
          </Button>
        </ButtonWrapper>
      </div>
    </Wrapper>
  );
};

export { BackCardGenerator };
