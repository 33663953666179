import styled from 'styled-components';
import { IonText } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from '../../../utils/UserContext';

interface Props {
  color?: string;
}

const Wrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ion-text {
    color: ${({ color }) => color || '#4d4d4d'};
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
  }
  .play-triangle {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #b80629;
    margin: 0 20px;
    height: 20px;
  }
`;

const differentCards: string[] = ['Red', 'Silver', 'Gold', 'Platinum', 'Platinum fire', 'Diamond'];

export const RankTitle: React.FC<Props> = ({ color }) => {
  const [nextCard, setNextCard] = useState<string>('');
  const [currentCard, setCurrentCard] = useState<string>('');

  const { status } = useContext(UserContext);

  useEffect(() => {
    if (status) {
      const capitalizeWord =
        status?.statusNameEnglish.charAt(0).toUpperCase() +
        status?.statusNameEnglish.slice(1).toLowerCase();

      const indexOfCard = differentCards.indexOf(`${capitalizeWord}`);
      const nextCard = differentCards[indexOfCard + 1];

      setCurrentCard(capitalizeWord);
      setNextCard(nextCard);
    }
  }, [status]);

  return (
    <Wrapper color={color}>
      <IonText>{currentCard}</IonText>
      {status?.statusNameEnglish !== 'DIAMOND' && <span className="play-triangle" />}
      <IonText>{nextCard}</IonText>
    </Wrapper>
  );
};
