import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { TitleComponent, Button } from '../../atoms';

const Container = styled.div`
  padding: 0 32px;

  .button {
    margin-top: 28px;
  }
`;

export const Request = () => {
  const history = useHistory();

  return (
    <Container>
      <TitleComponent title={true} heading="Request" subHeading="チーム関係者様" />
      <Button className="button" size="large" onClick={() => history.push('/publish-team')}>
        掲載お申し込み
      </Button>
    </Container>
  );
};
