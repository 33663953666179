import { t } from 'i18next';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, RankTitle, TeamCardComponent } from '../components';

const HomeWrapper = styled.div`
  margin: 0 35px;
  text-align: center;
  min-height: 73vh;
  .image {
    margin: 35px 0 38px;
  }
  .btn-status-up {
    margin-top: 50px;
  }
  .btn-back {
    margin: 30px 0;
    width: 140px;
    font-size: 12px;
    ::part(native) {
      --padding-top: 0.3rem;
    }
  }
`;

const StatusUp = () => {
  const history = useHistory();
  const { teamDetails } = useContext(UserContext);

  return (
    <Wrapper authenticated={true}>
      <HomeWrapper>
        <TitleComponent
          title={true}
          heading={t('Status Upgrade')}
          subHeading={t('Status up')}
          color={teamDetails?.team?.teamColor}
        />
        {/* <IonImg src="/assets/images/cards/gold.png" className="image" /> */}
        <div className="image">
          <TeamCardComponent />
        </div>
        <RankTitle />
        <Button
          size="large"
          className="btn-status-up"
          color={teamDetails?.team?.teamColor}
          onClick={() => history.push('/status-up-confirm')}
        >
          {t('Status Up')}
        </Button>
        <Button
          size="regular"
          className="btn-back"
          expand="block"
          color={teamDetails?.team?.teamColor}
          onClick={() => history.push('/home')}
        >
          {t('Back')}
        </Button>
      </HomeWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(StatusUp);
