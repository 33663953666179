import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { Button, TitleComponent } from '../components';
import { mobileContext } from '../utils/MobileContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const ContentWrapper = styled.div`
  padding: 20px;
  padding-top: 0px;
  .divider-wrapper {
    padding: 0px 15px;
    hr {
      margin: 0px;
    }
  }
  .box {
    margin-top: 40px;
    border-radius: 50px;
    .each-list-wrapper {
      padding: 30px 43px;
      text-align: center;
    }
  }
  .buttons-wrapper {
    margin-top: 35px;
    padding: 0px 15px;
    margin-bottom: 15px;
    .cancel-button {
      margin-top: 22px;
    }
  }
`;

const COLOR = '#E41840';

const WithdrawlCarefulPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { isMobile } = mobileContext();

  return (
    <Wrapper authenticated>
      <ContentWrapper>
        <TitleComponent title heading="Warning" subHeading="Caution" color={COLOR} />
        <div className="neomorphs-shadow-in box">
          <div className="each-list-wrapper">
            {isMobile ? (
              <span>
                各チーム毎の退会ではなく、
                <br />
                Faniqueからの退会となります。
                <br />
                誤って退会した場合においても
                <br />
                データの復旧はできませんので
                <br />
                ご了承ください。
                <br />
                よろしいですか？
              </span>
            ) : (
              <span>
                各チーム毎の退会ではなく、Faniqueからの退会となります。
                <br />
                誤って退会した場合においてもデータの復旧はできませんのでご了承ください。
                <br />
                よろしいですか？
              </span>
            )}
          </div>
        </div>
        <div className="buttons-wrapper">
          <Button size="large" onClick={() => history.push('/withdrawl-confirmation')}>
            {t('Unsubscribe from Fanique')}
          </Button>
          <Button size="large" className="cancel-button" onClick={() => history.push('/profile')}>
            {t('Cancel')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(WithdrawlCarefulPage);
