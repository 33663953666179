import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';
import { useHistory } from 'react-router';
import { UserContext } from './utils/UserContext';
import { AuthContext } from './utils/AuthContext';
import React, { useContext, useEffect } from 'react';
import { IonPageComponent } from './components';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const PrivateRoute = (AuthComponent: any) => {
  function PrivateComponent({ children }: any) {
    const { authenticated, user, loading } = useContext(AuthContext);
    const { userProfileData, isLoading } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
      if (!loading && !isLoading) {
        if (!authenticated) {
          history.push({
            pathname: '/top',
          });
          return;
        } else if (
          authenticated &&
          userProfileData &&
          userProfileData?.membershipStatus?.items?.length === 0
        ) {
          history.push({
            pathname: '/team-list',
          });
          return;
        } else if (
          authenticated &&
          userProfileData &&
          userProfileData?.membershipStatus?.items?.length > 0 &&
          !userProfileData?.username
        ) {
          history.push('/nft-generation');
        }
      }
    }, [authenticated, loading, user, isLoading]);

    if (loading || isLoading) {
      return (
        <IonPageComponent>
          <LoaderWrapper>
            <IonSpinner name="circles" className="loader" />
          </LoaderWrapper>
        </IonPageComponent>
      );
    }
    return <>{authenticated && <> {children} </>} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
