import React from 'react';
import { IonText } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';

const Wrapper = styled.div`
  padding: 1rem 1.2rem;
  border-radius: 3rem;
  text-align: center;

  p {
    margin: 0px;
    font-size: 15px;
    white-space: pre-line;
  }
  h1 {
    font-weight: 700;
    font-size: 25px;
    margin: 10px 0px;
  }

  .button-group {
    > * {
      margin: 1.5rem 0px;
    }
  }

  @media (max-width: 420px) {
    padding: 1rem 0.8rem;
    p {
      font-size: 14px;
    }
  }
`;

const text1 =
  'NFTとファンクラブ機能を合わせた、新しい形のファンクラブサービスです。Faniqueでは、「Support and Earn」をコンセプトとしており、試合を観戦してチームを応援することで、メンバーシップNFTのステータスを上げることができます。';

const text2 = '好きなチームを選んで、応援してチームの力になり、それを価値に変えていきましょう。';

const OwnerSection: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const formatText = (string: string) => {
    return string.split('、').join(`、\n`).split('。').join('。\n');
  };

  return (
    <Wrapper className="neomorphs-shadow-in">
      <IonText color="dark">
        <h1>{t('What is Fanique')}</h1>
      </IonText>
      <IonText color="dark">
        <p>{formatText(text1)}</p>
        <p>{formatText(text2)}</p>
      </IonText>
      <div className="button-group">
        <Button expand="full" size="large" onClick={() => history.push('/about')}>
          {/* <Button
          expand="full"
          size="large"
          onClick={() =>
            window.open(
              'https://scalagrp.notion.site/About-f207ef52f7c54223abe7a78337b5a016',
              '_blank'
            )
          }
        > */}
          {t('Know more about Fanique')}
        </Button>
        <Button expand="full" size="large" onClick={() => history.push('how-to-use')}>
          {t('See how to use')}
        </Button>
      </div>
    </Wrapper>
  );
};

export { OwnerSection };
