import React from 'react';
import styled from 'styled-components';
import { IonPageComponent } from '../components';
import { IonHeader, IonImg } from '@ionic/react';

const StyledIonHeader = styled(IonHeader)<any>`
  display: flex;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
  z-index: 999;
  background: #eeeeee;
  ion-img {
    width: 105px;
  }
  @media (max-width: 380px) {
    ion-img {
      width: 95px;
    }
  }
`;

const Content = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 18px 20px 0px 20px;
  height: 100%;

  .image-wrapper {
    width: 125px;
    height: 125px;
    background: #fefbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99%;
    margin-bottom: 5px;
    ion-img {
      width: 56px;
      height: 56px;
    }
  }
  .heading {
    margin: 0px 0px 11px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #4d4d4d;
  }
  .message {
    margin: 0px 5px 11px 5px;
    text-align: center;
    font-weight: 500;
    color: #4d4d4d;
    @media screen and (max-width: 340px) and (min-height: 800px) {
      padding-bottom: 20px;
    }
  }
`;

const Footer = styled.footer<any>`
  @media (min-height: 800px) {
    position: absolute;
    bottom: 18px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 340px) and (min-height: 800px) {
    position: absolute;
    bottom: 0px;
  }
  width: 100%;
  text-align: center;
  margin-bottom: 18px;
  .content {
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
  }
`;

const Maintenance: React.FC = () => {
  return (
    <IonPageComponent background="#ffffff">
      <StyledIonHeader className="ion-no-border" id="sticky-header">
        <IonImg src="./assets/images/logo.png" />
      </StyledIonHeader>
      <Content>
        <div className="image-wrapper">
          <IonImg src="./assets/icon/tools-icon.svg" alt="Icon showing Tools" />
        </div>
        <p className="heading">メンテナンスのお知らせ</p>
        <p className="message">
          いつもご利用いただき、
          <br />
          誠にありがとうございます。
          <br />
          <br />
          現在アクセス頂いたページは
          <br />
          メンテナンス中となります。
          <br />
          <br />
          数日中にリリース予定ですので
          <br />
          もうしばらくお待ちください。
          <br />
          <br />
          お問い合わせ先： <br />
          Fanique運営事務局 <br />
          <br />
          メールアドレス：
          <br />
          support@fanique.io
          <br />
          ご理解とご協力をお願いいたします。
          <br />
        </p>
        <Footer>
          <span className="content">COPYRIGHT SCALA, INC. ALL RIGHTS RESERVED.</span>
        </Footer>
      </Content>
    </IonPageComponent>
  );
};

export default Maintenance;
