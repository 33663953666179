import React from 'react';
import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';
import { closeSharp } from 'ionicons/icons';

interface ButtonProps {
  className?: string;
  onClick?: any;
  color?: string;
  disabled?: boolean;
}

const StyledButton = styled(IonButton)<any>`
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --box-shadow: var(--shadow-up);
  --color: ${(props: ButtonProps) =>
    props.color ? props.color : props?.disabled ? '#CCCCCC' : '#29c8d2'};
  --border-radius: 50%;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  margin: 0px;
  opacity: 1;
  width: 42px;
  height: 42px;
  ion-icon {
    width: 30px;
    height: 36px;
  }
  text-transform: none;
  font-weight: 700;
  :hover {
    --box-shadow: 1px 1px 2px #c1c1c1, -2px -2px 1px #ffffff, inset -3px -3px 5px -4px #a7a4a4,
      inset 3px 4px 9px -7px #ffffff;
  }
  &.ion-activated,
  :active {
    border-radius: var(--border-radius);
    color: #cccccc;
    --box-shadow: var(--shadow-down);
  }
  &.active-menu {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }
`;

const CrossButton: React.FC<ButtonProps> = ({ className, onClick, color, disabled }) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      shape="round"
      iconSlot="icon-only"
      color={color}
      disabled={disabled}
    >
      <IonIcon slot={'icon-only'} src={closeSharp} />
    </StyledButton>
  );
};

export { CrossButton };
