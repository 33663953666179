import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IListWithTitleProps {
  title: string;
  data: string[];
}

const Wrapper = styled.div`
  h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #4d4d4d;
    padding: 0px;
    margin: 0px;
  }
  ul {
    margin: 0px;
    margin-left: 32px;
    padding: 15px 0px;
  }
  li {
    font-weight: 350;
    font-size: 15px;
    line-height: 22px;
    color: #4d4d4d;
    padding: 0px;
    margin: 0px;
  }
`;

const ListWithTitle: React.FC<IListWithTitleProps> = ({ title, data }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div>
        <h5>{t(title)}</h5>
      </div>
      <div>
        <ul>
          {data?.map((each) => (
            <li key={each}>{t(each)}</li>
          ))}
        </ul>
      </div>
    </Wrapper>
  );
};

export { ListWithTitle };
