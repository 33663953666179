import React from 'react';
import styled from 'styled-components';
import { RankTitleComponent } from '../../atoms';

export interface EventData {
  heading?: string;
  alertHeading?: string;
  image?: string;
  subheading?: string;
}

interface Props {
  mainHeading: string;
  mainSubheading?: string;
  data?: EventData[];
}

const Wrapper = styled.div`
  .content-wrapper {
    padding: 0px 16px;
  }
  .heading {
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-bottom: 20px;
    .alert {
      color: #b80629;
    }
  }
  .wrap-image {
    display: flex;
    justify-content: center;
    .image-wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      .image {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .subheading {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 350;
    line-height: 22px;
    text-align: justify;
    margin-top: 20px;
    padding-bottom: 20px;
  }
`;

const RankDetailEvent: React.FC<Props> = ({ mainHeading, mainSubheading, data }: Props) => {
  return (
    <Wrapper className="neomorphs-shadow-in card-wrapper">
      <RankTitleComponent heading={mainHeading} subHeading={mainSubheading} />
      {data &&
        data?.map((d, idx) => (
          <div className="content-wrapper" key={idx}>
            <p
              className="heading"
              dangerouslySetInnerHTML={{
                __html:
                  d?.alertHeading && d?.heading
                    ? `${d?.heading}<br /><span class="heading alert">${d?.alertHeading}</span>`
                    : d?.heading ?? '',
              }}
            />
            {d?.image && (
              <div className="wrap-image">
                <div className="image-wrapper">
                  <img src={d?.image} className="image" />
                </div>
              </div>
            )}
            <p className="subheading" dangerouslySetInnerHTML={{ __html: d?.subheading ?? '' }} />
          </div>
        ))}
    </Wrapper>
  );
};

export { RankDetailEvent };
