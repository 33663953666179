import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { IonCol, IonRow, IonText } from '@ionic/react';

import { useValidation } from '../utils';
import { contactPost } from '../services';
import { useMutation } from 'react-query';
import { useToast } from '../hooks/useToast';
import RestrictedRoute from '../withRestrictedRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, Divider, TextField, TitleComponent, Toast, Select } from '../components';

interface TermsProps {
  title?: string;
  descriptions?: string;
}

interface Props {
  TermsConditions?: Array<TermsProps>;
}

const HomeWrapper = styled.div`
  font-weight: 350;

  * {
    font-size: 13px;
  }

  padding: 0.5em 1em;
  margin: 0.5em 1.4em;
  border-radius: 3rem;
  .title {
    margin-top: 2em;
    margin-bottom: 1em;
    span {
      font-size: 22px;
    }
  }
  .form-label {
    font-size: 13px;
  }
  @media (max-width: 375px) {
    .form-label {
      font-size: 10px;
    }
  }
  .text-input {
    margin-right: 0.8em;
  }
  .button-wrapper {
    margin: 2em 0em;
  }
  .divider1 {
    margin: 2px 0px;
  }
  .space-bottom {
    margin-bottom: 1em;
  }
  .space-top {
    margin-top: 1.2em;
  }
  .text-area {
    font-size: 13px;
  }

  ion-label {
    font-weight: 350;
  }
  * {
    font-size: 13px;
  }
  ion-col > span:before {
    content: '※';
    margin-right: 2px;
    display: inline;
    color: red;
  }

  ion-row {
    margin: -0.4em 0.5em;
    padding: 0px 6px;
    ion-col:first-child {
      display: flex;
      align-items: center;
      padding-left: 4px;
    }
  }
  ion-input {
    border-radius: 4px !important;
  }
  ion-note {
    margin-top: -10px;
  }
  ion-item {
    --ion-safe-area-right: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }

  ion-col > span:before {
    margin-right: 4px;
    display: inline;
    color: red;
  }
  .textarea {
    padding: 8px;
  }
  .required-row {
    color: #b80629;
    padding-top: 12px;
  }
  .prefilled {
    border: none !important;
    background: transparent;
    disabled: true;
    font-size: 12px;
    font-weight: 300;

    :before {
      content: '';
    }
  }
`;

const BackButton = styled.div`
  margin: 2em 0em;
  display: flex;
  justify-content: center;
  .back {
    width: 140px;
  }
`;

const REQUIRED_FIELDS = ['subject', 'name_sei', 'name_mei', 'content'];

const ValidationSchemas = [
  {
    name: 'email',
    validate: (email: string) => {
      return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
    },
    message: 'Invalid email address',
  },
];

const InquiryPage: React.FC = () => {
  const { t } = useTranslation();
  const location: any = useLocation();

  const searchParams = new URLSearchParams(location?.search);
  const cardName = searchParams.get('card');

  const history = useHistory();
  const [submitStatus, setSubmitStatus] = useState<boolean>(false);
  const [showToast, setShowToast] = useToast(false);
  const [error, setError] = useState<any>();
  const [formDetails, setFormDetails] = useState<any>({
    subject: cardName === 'DIAMOND' ? 'ダイヤモンドメンバーシップ ご購入に関する相談' : '',
    name_sei: '',
    name_mei: '',
    email: '',
    content: '',
  });
  const errors = useValidation({ schema: ValidationSchemas, value: formDetails });

  const subjectOptions = [
    {
      label: t('About Saitama broncos fanclub'),
      value: 'さいたまブロンコスファンクラブについて',
    },
    {
      label: t('About membership'),
      value: '会員登録について',
    },
    {
      label: t('Defect'),
      value: '不具合',
    },
    {
      label: t('Others'),
      value: t('その他'),
    },
  ];

  const changeValues = (e: any) => {
    setFormDetails((formDetails: any) => ({
      ...formDetails,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let status = true;
    Object.keys(errors).forEach((key) => {
      if (formDetails.email == '' || !!errors[key]) status = false;
    });
    REQUIRED_FIELDS.forEach((item) => {
      if (formDetails[item] == '') status = false;
    });
    setSubmitStatus(status);
  }, [formDetails, errors]);

  const { mutate, isLoading } = useMutation(contactPost, {
    onSuccess: () => {
      setFormDetails({
        subject: '',
        name_sei: '',
        name_mei: '',
        email: '',
        content: '',
      });

      history.push({
        pathname: `/inquiry-sent`,
      });
    },
    onError: (err: string) => {
      setError(t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  const submitForm = () => {
    mutate(formDetails);
  };

  return (
    <Wrapper>
      {showToast && <Toast message={error} type={'Error'} />}
      <HomeWrapper className="neomorphs-shadow-in">
        <div className="title">
          <TitleComponent heading={t('Inquiry')} color="#4D4D4D" />
        </div>
        <div className="form-wrapper">
          <IonRow>
            <IonCol size="2.5" sizeXs="3">
              <span className="form-label">{t('Title')}</span>
            </IonCol>
            <IonCol size="9">
              {cardName === 'DIAMOND' ? (
                <span className="prefilled">
                  ダイヤモンドメンバーシップ <br /> ご購入に関する相談
                </span>
              ) : (
                <Select
                  onChange={(e) => changeValues(e)}
                  name={'subject'}
                  mode="white"
                  value={formDetails.subject}
                  options={subjectOptions}
                />
              )}
            </IonCol>
          </IonRow>
          <Divider className="divider1" />
          <IonRow>
            <IonCol size="2.5" sizeXs="3">
              <span className="form-label">{t('Name')}</span>
            </IonCol>
            <IonCol>
              <TextField
                onChange={(e) => changeValues(e)}
                name={'name_sei'}
                mode="white"
                value={formDetails.name_sei}
              />
            </IonCol>
            <IonCol>
              <TextField
                onChange={(e) => changeValues(e)}
                name={'name_mei'}
                mode="white"
                value={formDetails.name_mei}
              />
            </IonCol>
          </IonRow>
          <Divider className="divider1" />
          <IonRow>
            <IonCol size="2.5" sizeXs="3">
              <span className="form-label">{t('Email')}</span>
            </IonCol>
            <IonCol>
              <TextField
                onChange={(e) => changeValues(e)}
                name={'email'}
                mode="white"
                value={formDetails.email}
                error={errors.email}
              />
            </IonCol>
          </IonRow>
          <Divider className="divider1 space-bottom" />
          <IonRow>
            <IonCol size="7">
              <span className="form-label">{t('Inquiry details')}</span>
            </IonCol>
            <IonCol size="12">
              <TextField
                className="textarea"
                onChange={(e) => changeValues(e)}
                name={'content'}
                mode="white"
                textarea={true}
                value={formDetails.content}
                maxlength={1000}
              />
            </IonCol>
          </IonRow>
          <Divider />
          <IonRow className="required-row">
            <IonCol>
              <IonText>{t('※are required items')}</IonText>
            </IonCol>
          </IonRow>
        </div>
        <Divider className="divider1 space-top" />
        <div className="button-wrapper">
          <Button size="large" onClick={() => submitForm()} disabled={!submitStatus || isLoading}>
            {t('Submit')}
          </Button>
        </div>
      </HomeWrapper>
      <BackButton>
        <Button size="small" className="back" onClick={() => history.push('/top')}>
          {t('Go to Top Page')}
        </Button>
      </BackButton>
    </Wrapper>
  );
};

export default RestrictedRoute(InquiryPage);
