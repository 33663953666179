import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { ITableColumns, Table } from '../components/molecules/Table';
import { Button, Loader, TitleComponent, Pagination } from '../components';
import { fetchTradingHistoryList, fetchTransactionHistory } from '../services';

const HCOLS: ITableColumns[] = [
  {
    Header: '日付',
    accessor: '日付',
    Filter: () => null,
  },
  {
    Header: 'コイン数',
    accessor: 'コイン数',
    Filter: () => null,
  },
  {
    Header: '内容',
    accessor: '内容',
    Filter: () => null,
  },
];

const PCOLS: ITableColumns[] = [
  {
    Header: '購入日',
    accessor: '購入日',
    Filter: () => null,
  },
  {
    Header: 'カード名',
    accessor: 'CardName',
    Filter: () => null,
  },
  {
    Header: '取引額',
    accessor: '取引額',
    Filter: () => null,
  },
];

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0px;

  .btn {
    width: 140px;
  }
  .switch-btn {
    margin: 0px 10px;
    width: 160px;
  }
`;

const TableWrapper = styled.div<{ maxHeight?: string }>`
  height: calc(100vh - 390px);
  border-radius: 50px;
  margin: 10px 20px;
  overflow: hidden;
  padding: 20px;

  .main-table {
    height: 100%;
    max-height: ${(props) => props.maxHeight};
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    .loader {
      height: 100px;
      margin-top: 50px;
    }
  }

  .pagination {
    margin: 20px 0;
  }

  .total-wrapper {
    text-align: center;
    p {
      display: flex;
      justify-content: center;

      span.total-text {
        color: ${(props) => props.color};
        margin-right: 10px;
        font-weight: 700;
      }
      span.point-text {
        margin-left: 2px;
        font-size: 13px;
      }
      span.total-value {
        font-size: 25px;
        font-weight: 700;
        color: #4d4d4d;
      }
    }
  }
  .no-transaction {
    height: 40px;
    text-align: center;
    margin: 30px 15px;
    p {
      font-size: 14px;
    }
  }
`;

const TransactionHistoryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [historyData, setHistoryData] = useState<any>({});
  const [totalPoints, setTotalPoints] = useState<any>(0);
  const [currentTab, setCurrentTab] = useState<string>('history');
  const [currentPage, setCurrentPage] = useState(1);
  const { userProfileData, teamDetails } = useContext(UserContext);
  const [tradingTotalPages, setTradingTotalPages] = useState('1');
  const [tradingDataList, setTradingDataList] = useState<
    { CardName?: string; 取引額?: number | string; 購入日?: string }[] | []
  >([]);

  const { data, isLoading: ishistoryLoading } = useQuery(
    ['fetchTransactionHistory', userProfileData?.id, teamDetails?.team?.id, currentPage],
    fetchTransactionHistory,
    {
      enabled: !!userProfileData?.id && !!teamDetails?.team?.id,
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess({ data }) {
        let historyTransaction: any = [];
        let res = Object.values(data?.history);

        res.forEach((data: any) => {
          let chunk = {
            日付: data?.createdAt?.split('T')[0].replaceAll('-', '/') ?? '-',
            コイン数: `${data?.transaction ?? 0}coin`,
            内容: data?.description ?? '-',
          };
          historyTransaction.push(chunk);
        });
        setTotalPoints(data?.totalCoin ? data?.totalCoin?.toLocaleString('en-US') : 0);
        setHistoryData(historyTransaction);
      },
    }
  );

  const { isLoading: loadingTrading, isFetching: fetchingTrading } = useQuery(
    ['fetchhhhhh', userProfileData?.id, teamDetails?.team?.id, currentPage],
    fetchTradingHistoryList,
    {
      enabled: currentTab === 'purchase',
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess({ data }) {
        if (data) {
          setTradingTotalPages(data?.total);
          let tradingHistory: any = [];
          let res = Object.values(data?.tradingHitories);
          res.forEach((data: any) => {
            let chunk = {
              購入日: data?.createdAt?.split('T')[0].replaceAll('-', '/') ?? '-',
              取引額: data?.price ?? '-',
              CardName: data?.utilityNftMaster?.cardName ?? '-',
            };
            tradingHistory.push(chunk);
          });
          setTradingDataList(tradingHistory);
        } else {
          setTradingTotalPages('1');
          setTradingDataList([]);
        }
      },
    }
  );

  return (
    <Wrapper authenticated={true}>
      <TitleComponent
        title
        heading="History"
        subHeading="取引/コイン獲得履歴"
        color={teamDetails?.team.teamColor}
      />
      <ButtonWrapper>
        <Button
          color={teamDetails?.team.teamColor}
          size="small"
          className={`switch-btn ${currentTab === 'history' ? 'active-menu' : ''}`}
          onClick={() => setCurrentTab('history')}
        >
          {'コイン'}
        </Button>
        <Button
          color={teamDetails?.team.teamColor}
          size="small"
          className={`switch-btn ${currentTab === 'purchase' ? 'active-menu' : ''}`}
          onClick={() => setCurrentTab('purchase')}
        >
          {t('Transaction History')}
        </Button>
      </ButtonWrapper>
      <TableWrapper
        className="neomorphs-shadow-in"
        color={teamDetails?.team.teamColor}
        maxHeight={currentTab === 'history' ? '85%' : '100%'}
      >
        <div className="main-table">
          {currentTab === 'history' && (
            <Loader className="loader" isLoading={ishistoryLoading}>
              {historyData?.length ? (
                <Table columns={HCOLS} data={historyData} />
              ) : (
                <div className="no-transaction">
                  <p>{t('There are no transaction data for coins')}</p>
                </div>
              )}

              <>
                <div className="pagination">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={data?.data?.totalPage}
                  />
                </div>
              </>
            </Loader>
          )}
          {currentTab === 'purchase' && (
            <Loader className="loader" isLoading={loadingTrading || fetchingTrading}>
              {tradingDataList?.length ? (
                <Table columns={PCOLS} data={tradingDataList} />
              ) : (
                <div className="no-transaction">
                  <p>{t('There are no transaction data')}</p>
                </div>
              )}

              <div className="pagination">
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={tradingTotalPages}
                />
              </div>
            </Loader>
          )}
        </div>
        {currentTab === 'history' && (
          <div className="total-wrapper">
            <p>
              <span className="total-text">Total</span>
              <span>
                <span className="total-value">{totalPoints ?? '-'}</span>
                <span className="point-text">coin</span>
              </span>
            </p>
          </div>
        )}
      </TableWrapper>
      <ButtonWrapper>
        <Button
          color={teamDetails?.team.teamColor}
          size="small"
          className="btn"
          onClick={() => history.push('/my-page')}
        >
          {t('戻る')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(TransactionHistoryPage);
