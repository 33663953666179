import React from 'react';
import styled from 'styled-components';
import { IonImg, IonText } from '@ionic/react';

import { mobileContext } from '../../../utils/MobileContext';

interface IBackCardProps {
  image: string;
  name: string;
  fontFace?: string;
}

const Wrapper = styled.div<any>`
  position: relative;
  padding: 0px 40px;

  .name {
    position: absolute;
    width: 220px;
    top: 34%;
    left: 24%;
    font-size: 18px;
    /* padding: 0px 10px; */
    font-family: ${(props: any) => props.fontFace};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media (max-width: 480px) {
      top: 35%;
      left: 26%;
    }
    @media (max-width: 450px) {
      top: 35%;
      left: 25%;
    }
    @media (max-width: 440px) {
      left: 25%;
    }
    @media (max-width: 430px) {
      top: 34%;
      left: 24%;
    }
    @media (max-width: 420px) {
      left: 23%;
    }
    @media (max-width: 400px) {
      left: 22%;
    }
    @media (max-width: 390px) {
      top: 33%;
      left: 21%;
    }
    @media (max-width: 380px) {
      left: 20%;
    }
    @media (max-width: 360px) {
      left: 19%;
    }
    @media (max-width: 340px) {
      top: 32%;
      left: 17%;
    }
    @media (max-width: 330px) {
      left: 16%;
    }
  }

  @media (min-width: 450px) {
    width: ${(props) => props.isMobile && '450px'};
  }
`;

const BackCard: React.FC<IBackCardProps> = ({
  image,
  name,
  fontFace = `'Dancing Script', cursive`,
}) => {
  const { isMobile } = mobileContext();
  return (
    <Wrapper fontFace={fontFace} isMobile={isMobile}>
      <IonImg src={image} className="image" />
      <div className="name">
        <IonText>{name}</IonText>
      </div>
    </Wrapper>
  );
};

export { BackCard };
