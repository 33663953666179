import { API } from './api';
import { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';

interface BodyProps {
  masterId: string;
  userId: string;
  choice: {
    size?: string;
    number?: string;
    name?: string;
  };
}

interface UpdateBodyProps {
  id: string;
  userId: string;
  choice: {
    size?: string;
    number?: string;
    name?: string;
  };
}

export const getUserGiftList = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  return API.get(`/team/gift/list?teamId=${teamId}`);
};

export const postGift = (body: BodyProps) => {
  return API.post('/user/gift', body);
};

export const updateUserGiftInfo = (body: UpdateBodyProps) => {
  return API.put(`/user/gift`, body);
};

export const fetchUserGiftList = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/user/gift/list?userId=${queryKey[1]}&teamId=${queryKey[2]}`);
};

export const fetchUserGift = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/user/gift?id=${queryKey[1]}`);
};
