import React, { useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useIonViewDidEnter } from '@ionic/react';

import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TitleComponent, CardInformation } from '../components';
import { LevelInformation } from '../components/molecules/LevelInformation';
import { MembershipInformation } from '../components/molecules/MembershipInformation';

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .card-wrapper {
    margin: 0 1rem;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: 50px;
  }

  .bottom-card {
    margin: 0 1rem;
    padding: 1.6rem 1.9rem 0 1.9rem;
    border-radius: 50px;
    padding-bottom: 0.2rem;

    .small-para {
      font-size: 12px;
      text-align: center;
    }

    .image {
      filter: drop-shadow(3px 5px 4px rgba(0, 0, 0, 0.4));
    }

    .title {
      margin-top: 30px;
    }
  }

  .icon {
    font-size: 80px;
    width: 100%;
    color: #29c8d2;
  }

  .button-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 30px;
  }

  .space {
    margin-bottom: 50px;
    width: 98%;
  }

  .drop-shadow {
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.25));
  }
`;

const HowToUse = () => {
  const levelRef: any = useRef(null);
  const contentRef: any = useRef(null);
  const { search } = useLocation();

  useIonViewDidEnter(() => {
    if (search !== '') {
      levelRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      contentRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [search]);

  return (
    <Wrapper noScrollToTop>
      <InnerWrapper>
        <TitleComponent
          title
          heading="How To"
          subHeading="See how to use"
          className="title"
          ref={contentRef}
        />
        <div className="space">
          <CardInformation />
        </div>
        <div className="space">
          <MembershipInformation />
        </div>
        <div className="space">
          <LevelInformation ref={levelRef} />
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default HowToUse;
