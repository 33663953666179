import { IonLabel } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  label?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: any) => void;
}

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  ion-label {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 400;
  }
  .checkbox-container {
    width: 20px;
    display: block;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      opacity: 0;
      z-index: 99;
      cursor: pointer;
      padding-bottom: 4px;
      width: 18px;
      height: 18px;
      bottom: -10px;
      left: -3px;
    }
  }
  .checkmark {
    z-index: 1;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -8px;
    left: -4px;
    background-color: #efefef;
    border-radius: 5px;
    box-shadow: var(--shadow-up);
  }

  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox-container .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: #29c8d2;
    border: 1px solid #29c8d2;
    border-radius: 50%;
    box-sizing: border-box;
  }
`;

export const FilterCheckbox: React.FC<Props> = ({ label, value, onChange, disabled, checked }) => {
  const { t } = useTranslation();
  return (
    <CheckboxWrapper>
      <IonLabel>{`${label && t(label)}`}</IonLabel>
      <div className="checkbox-container">
        <input
          type="checkbox"
          onChange={onChange}
          value={value}
          disabled={disabled}
          checked={checked}
        />
        <span className="checkmark"></span>
      </div>
    </CheckboxWrapper>
  );
};
