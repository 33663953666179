import styled from 'styled-components';
import React, { useContext } from 'react';
import { TitleComponent } from '../../../components';
import { UserContext } from '../../../utils/UserContext';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const Container = styled.div`
  margin-bottom: 50px;
  .card {
    background: #eeeeee;
    box-shadow: none !important;
  }
  .title {
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
  }
  .team-news {
    margin-top: 30px;
  }
`;

export const TeamNews: React.FC<any> = () => {
  const { teamDetails } = useContext(UserContext);

  return (
    <Container>
      <div>
        <h1 className="title">ニュース</h1>
        <TitleComponent color={teamDetails?.team?.teamColor} heading="Team News" title={true} />
        <div className="team-news">
          {teamDetails && (
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={`${teamDetails?.team.newsId}`}
              options={{ height: 690 }}
              transparent
              noScrollbar={true}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
