import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';
import React, { useContext, useEffect } from 'react';

import { AuthContext } from './utils/AuthContext';
import { UserContext } from './utils/UserContext';
import { mobileContext } from './utils/MobileContext';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const Wrapper = styled.div<{ isMobile: any }>`
  max-width: ${(props) => (props.isMobile ? '100%' : '414px')};
  margin: auto;
  position: relative;
  min-height: 100vh;
  background-color: var(--ion-color-primary);

  @media (max-width: 480px) {
    min-width: 100%;
  }
`;

const Content = styled.div`
  width: 100vw;
  overflow: auto;
  --ion-background-color: none;
  &.no-scroll {
    --overflow: hidden;
  }
`;

const RestrictedRoute = (AuthComponent: any) => {
  function RestrictedComponent({ children }: any) {
    const history = useHistory();

    const { authenticated, loading } = useContext(AuthContext);
    const { userProfileData, isLoading } = useContext(UserContext);

    const [isAuthLoading, setIsAuthLoading] = React.useState<boolean>(true);

    const { isMobile } = mobileContext();

    useEffect(() => {
      if (!loading && !isLoading) {
        if (
          authenticated &&
          userProfileData &&
          userProfileData?.membershipStatus?.items?.length > 0 &&
          !userProfileData?.username
        ) {
          history.push('/nft-generation');
          window.location.reload();
        } else if (!authenticated) {
          setIsAuthLoading(false);
        } else {
          setIsAuthLoading(false);
        }
      }
    }, [authenticated, loading, isLoading]);

    if (loading || isAuthLoading) {
      return (
        <Content>
          <Wrapper isMobile={isMobile}>
            <LoaderWrapper>
              <IonSpinner name="circles" className="loader" />
            </LoaderWrapper>
          </Wrapper>
        </Content>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      );
    }
  };
};

export default RestrictedRoute;
