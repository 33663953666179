import { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../utils/AuthContext';
import { UserContext } from '../utils/UserContext';
import { fetchRecommendedNFTCard, fetchRecommendedNFTCardNotLoggedIn } from '../services';

const useRecommendedNFTCard = (teamId: string, userId: string, enabled?: boolean) => {
  const { authenticated } = useContext(AuthContext);
  const { isFirstStepCompleted } = useContext(UserContext);

  let queryKey, fetchFunction;

  if (authenticated && isFirstStepCompleted) {
    queryKey = ['fetchRecommendedData', teamId, userId];
    fetchFunction = fetchRecommendedNFTCard;
  } else {
    queryKey = ['fetchData', teamId];
    fetchFunction = fetchRecommendedNFTCardNotLoggedIn;
  }

  const { data, isLoading, refetch } = useQuery(queryKey, fetchFunction, {
    enabled: enabled,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select(response) {
      return response.data;
    },
  });

  return { data, isLoading, refetch };
};
export { useRecommendedNFTCard };
