import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IonPage, IonContent, useIonViewDidEnter } from '@ionic/react';
import { mobileContext } from '../../../utils/MobileContext';

const Wrapper = styled.div<{ background: string; isMobile: any }>`
  max-width: ${(props) => (props.isMobile ? '100%' : '414px')};
  margin: auto;
  position: relative;
  min-height: 100vh;

  background-color: ${(props) =>
    props.background ? props.background : 'var(--ion-color-primary)'};

  @media (max-width: 480px) {
    min-width: 100%;
  }
`;

const Content = styled(IonContent)`
  width: 100vw;
  overflow: auto;
  --ion-background-color: none;
  &.no-scroll {
    --overflow: hidden;
  }
`;

export const IonPageComponent = ({
  children,
  background,
  noScrollToTop = false,
  scrollTop = false,
  setScrollTop,
}: any) => {
  const { isMobile } = mobileContext();
  const contentRef: any = useRef();

  useIonViewDidEnter(() => {
    if (!noScrollToTop) {
      contentRef.current?.scrollToTop(400);
    }
  }, []);

  useEffect(() => {
    if (scrollTop) {
      contentRef.current?.scrollToTop(400);
      if (setScrollTop) setScrollTop(false);
    }
  }, [scrollTop]);

  return (
    <IonPage>
      <Content ref={contentRef}>
        <Wrapper background={background} isMobile={isMobile}>
          {children}
        </Wrapper>
      </Content>
    </IonPage>
  );
};
