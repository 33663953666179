import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-size: 16px;
    line-height:22px;
  }

  button {
    cursor: pointer;
  }
  body{
    line-height:normal ;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .neomorphs-shadow-out {
    box-shadow: var(--shadow-up)
  }

  .neomorphs-shadow-in {
    box-shadow: var(--shadow-down)
  }
  ion-action-sheet {
    max-width: 414px;
    margin: auto;

  h1, .heading {
    font-size: 2rem;
    font-weight: 900; 
    margin: 0;
  }
  #ReactSimpleImageViewer {
    z-index: 1000;
  }
`;

export { GlobalStyles };
