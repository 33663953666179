import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IonPageComponent, Button } from '../components';

const Content = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
  height: 100vh;
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .return-home {
    width: 90%;
    font-size: 12px;
  }
  .description {
    margin-top: -5px;
    font-weight: 500;
  }
`;

const ClearCognitoCache = () => {
  const { t } = useTranslation();

  const handleClick = async () => {
    localStorage.clear();
    window.location.replace(
      'https://3eaba876.auth.upbond.io/v2/logout?returnTo=https://fanique.io&client_id=2ihQ0X80TrxI_S9GIqPgK'
    );
  };

  return (
    <IonPageComponent>
      <Content>
        <div className="message">
          <p className="description">{t('Click the button below to delete Fanique cookies')}</p>
          <div className="button-wrapper">
            <Button className="return-home" size="regular" onClick={() => handleClick()}>
              {t('Clear cache and go to top page')}
            </Button>
          </div>
        </div>
      </Content>
    </IonPageComponent>
  );
};

export default ClearCognitoCache;
