import styled from 'styled-components';
import { search } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import { Button } from '../../atoms';

interface Props {
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage?: any;
  closeModal?: () => void;
  textToShow?: string;
  setIsMainSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
}

const StyledIonSearchBarWrapper = styled.div`
  position: relative;
  padding: 25px 30px;
  .custom-input {
    border-radius: 35px;
  }
  input {
    padding-left: 25px;
    padding-right: 80px;
    height: 50px;
    color: black;
    border: none;
    outline: none;
    background-color: #eee;
    width: 100%;
  }
  .button-wrapper {
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 10;
    ion-icon {
      position: absolute;
      top: -2px;
      color: #29c8d2;
      font-size: 19px;
      font-weight: bold;
    }
    .custom-search-button {
      width: 35px;
      height: 35px;
    }
  }
`;

export const SearchBar: React.FC<Props> = ({
  setSearchText,
  setCurrentPage,
  closeModal,
  textToShow,
  setIsMainSearch,
  searchText,
}) => {
  const [searchInput, setSearchInput] = useState<any>('');

  const handleSearch = () => {
    if (searchInput !== searchText) {
      if (setSearchText) setSearchText(searchInput);
      if (setCurrentPage) setCurrentPage(1);
      if (setIsMainSearch) {
        setIsMainSearch(true);
      }

      if (closeModal) closeModal();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  };

  useEffect(() => {
    if (textToShow) {
      setSearchInput(textToShow);
    }
  }, [textToShow]);

  return (
    <StyledIonSearchBarWrapper>
      <input
        className="custom-input neomorphs-shadow-in"
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
        value={searchInput}
      />
      <div className="button-wrapper">
        <Button
          className="custom-search-button"
          size="regular"
          slot="icon-only"
          icon={search}
          onClick={handleSearch}
        />
      </div>
    </StyledIonSearchBarWrapper>
  );
};
