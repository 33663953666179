import React from 'react';
import styled from 'styled-components';

interface Props {
  handleClick: () => void;
}

const Triangle = styled.div`
  width: 42;
  height: 42;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 32px solid #29c8d2;
  filter: drop-shadow(2px 2px 2px #cccccc) drop-shadow(-4px -2px 1px #ffffff) blur(0.5px);
  margin: 12px 0;
  cursor: pointer;
`;

const DownTriangle = ({ handleClick }: Props) => {
  return <Triangle onClick={handleClick}></Triangle>;
};

export default DownTriangle;
