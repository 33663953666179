import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TitleComponent, Header, Footer, Loader } from '../components';
import { AuthContext } from '../utils/AuthContext';
import { IonPage, useIonViewDidEnter } from '@ionic/react';
import { mobileContext } from '../utils/MobileContext';
import { UserContext } from '../utils/UserContext';
import { useHistory } from 'react-router';
import RestrictedRoute from '../withRestrictedRoute';

interface PrivacyProps {
  title?: string;
  descriptions?: string;
}

interface Props {
  PrivacyPolicy?: Array<PrivacyProps>;
}

const Contain = styled.div`
  width: 100vw;
  overflow: auto;
  --ion-background-color: none;
  &.no-scroll {
    --overflow: hidden;
  }
`;

const Container = styled.div<any>`
  max-width: ${(props) => (props.isMobile ? '100%' : '414px')};
  margin: auto;
  position: relative;
  min-height: 100vh;

  background-color: var(--ion-color-primary);

  @media (max-width: 480px) {
    min-width: 100%;
  }
  #sticky-header {
    position: sticky;
    top: 0;
    background-color: #eeeeee;
    z-index: 999;
  }

  .footer-wrapper {
    bottom: -44px !important;
    padding: ${(props) => (props.isAuthenticated ? '3px 0' : null)};
    position: ${(props) => (props.loggedin && props.isFirstStepCompleted ? 'sticky' : null)};
    bottom: ${(props) => (props.isAuthenticated ? '0' : null)};
    width: ${(props) => (props.isAuthenticated ? '414px' : null)};
    @media (max-width: 480px) {
      width: ${(props) => (props.isAuthenticated ? '100%' : null)};
    }
    background: transparent;
    z-index: 100;
  }
`;

const HomeWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0.5em 1.5em;
  margin: 0.5em 1.4em;
  border-radius: 3rem;
  height: 70vh;
  overflow: auto;
  .title {
    margin: 2em 0em;
  }
  .privacy-policy {
    text-align: left;
    margin: 2.2em 0em;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      margin-top: -2px;
      text-align: justify;
    }
  }
  ul {
    padding-inline-start: 20px !important;
  }
  .end-title {
    margin: 1em 0em;
  }
`;

const BackButton = styled.div`
  margin: 2em 0em;
  display: flex;
  justify-content: center;
  .back {
    width: 13em;
    background: var(--ion-color-primary);
    background-hover: var(--ion-color-primary);
    box-shadow: var(--shadow-up);
    color: ${(props) => (props.color ? props.color : '#29c8d2')};
    border-radius: 2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    opacity: 1;

    :active {
      border-radius: 50px;
      color: #cccccc;
      box-shadow: var(--shadow-down);
    }
  }
`;

const EndRight = styled.div`
  text-align: right;
`;

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
`;

const Privacy: any = [
  {
    title: '取得する情報',
    subtitle: '当社は、ユーザーから以下の情報を取得する場合があります。',
    descriptions: {
      one: '連絡先情報等',
      two: '利用履歴等',
      three: 'クッキー等',
      four: 'その他本サービスのご利用に際して、当社への提供が必要となる事項',
    },
  },
  {
    title: '個人情報の利用目的',
    subtitle:
      '当社は、取得した個人情報を以下の目的のために利用します。なお、個人情報を提供いただいた際に以下の目的と異なる目的についても別途ご同意いただいた場合には、その目的の範囲内でも利用します。',
    description: {
      one: '本サービスに関する商品・サービス等の提供・実施、それらに関連する連絡、案内や各種情報の提供のため',
      two: '本サービスに関する商品・サービス等の開発、システムメンテナンス、不具合の原因究明、改善および向上に役立てるため',
      three: '本サービスに関する商品・サービス等の広告の開発、配信・提供およびその成果確認のため',
      four: '本サービスに関連するイベント、アンケート、懸賞、キャンペーンなどの提供・実施、それらに関連する連絡、案内や各種情報の提供のため',
      five: 'ユーザーの本人確認または本人の代理人であることの確認ならびに認証サービスの実施のため',
      six: '本サービスに関連するマーケティング調査、統計または分析のため',
      seven: 'ユーザーからの各種問い合わせ、要望または依頼等への対応のため',
      eight:
        '本サービスに関する不正または違法行為への対応、及び違法または不正の可能性のある行為を防止するため',
      nine: '本規約等の執行やその変更等の重要な通知のため',
      ten: '本サービスが定める利用目的の達成に必要な範囲において第三者提供を行うため',
      eleven: 'その他、本サービスが提供する個々の商品・サービス等において定める目的のため',
    },
  },
  {
    title: '個人データの第三者提供',
    subtitle:
      '当社は、法令で定められる場合を除いて、事前にユーザーご本人の同意を得ることなく個人データを第三者に提供しません',
  },
  {
    title: '外部委託',
    subtitle:
      '当社は、データ分析、メール送信、ホスティングサービス、カスタマーサービス、マーケティングサポート、保守サービス、本サービスに関する商品・サービスの開発および提供等の本サービスに関する業務の全部または一部を外部に委託することがあります。これに伴い、当社は、利用目的の達成に必要な範囲内で、個人データの取り扱いの全部または一部を委託先に提供することがあります。この場合、当社は、個人データの安全管理が図られるよう、適切な個人情報保護水準を確保している委託先を選定します。また、委託先と契約を締結し、当該委託先に対して適切な個人データの安全管理を義務付け、委託先を適切に監督します。',
  },
  {
    title: '個人データの共同利用',
    subtitle: '当社は、以下のとおり個人データを共同利用することがあります。',
    descriptions: {
      one: {
        title: '共同利用する者の範囲（以下「共同利用者」といいます。）',
        description: {
          one: '株式会社スカラおよびその関係会社',
          two: '本サービスに関係するスポーツクラブおよび当該スポーツクラブの運営会社',
        },
      },
      two: {
        title: '共同利用する個人データの項目',
        description: { one: '取得する情報」記載の各情報' },
      },
      three: {
        title: '共同利用の目的',
        description: {
          one: '本サービスまたは共同利用者の商品・サービス等の提供・実施、それらに関連する連絡、案内や各種情報の提供のため',
          two: '本サービスまたは共同利用者に関する商品・サービス等の開発、改善および向上に役立てるため',
          three:
            '本サービスまたは共同利用者に関する商品・サービス等の広告の開発、配信・提供およびその成果確認のため',
          four: '本サービスまたは共同利用者に関連するイベント、アンケート、懸賞、キャンペーンの提供・実施、それらに関連する連絡、案内や各種情報の提供のため',
          five: 'ユーザーの本人確認または本人の代理人であることの確認ならびに認証サービスの実施ため',
          six: ' 本サービスまたは共同利用者に関連するマーケティング調査、統計、分析のため',
          seven: '本サービスを提供するためのシステムメンテナンスや不具合の原因究明のため',
          eight: 'ユーザーからの各種問い合わせ、要望または依頼等への対応のため',
          nine: '本サービスに関する不正または違法な行為への対応、及び違法または不正の可能性のある行為を防止するため',
          ten: '本サービスまたは共同利用者の規約等の執行やその変更等の重要な通知のため',
          eleven:
            'その他、本サービスまたは共同利用者が提供する個々の商品・サービス等において定める利用目的のため',
        },
      },
      four: {
        title: '共同利用の管理責任者',
        description: {
          one: '事業者名：株式会社スカラ',
          two: '住　所：東京都渋谷区渋谷2-21-1 渋谷ヒカリエ32F',
          three: '代表者：代表執行役　新田　英明',
        },
      },
    },
  },
  {
    title: '個人データの保護および安全管理措置',
    subtitle:
      '個人データの漏えい、滅失または毀損、盗用や悪用、改ざん等を防ぐため、適切な組織的、人的、物理的・技術的安全管理措置を講じ、運用します。安全管理措置の詳細に関しては、https://fanique.io/inquiryからお問い合わせください。',
  },
  {
    title: '保有個人データの開示等の請求手続',
    subtitle:
      'ユーザーは、個人情報保護法に基づき以下のとおり当社に対して保有個人データまたは第三者提供記録の開示および保有個人データの訂正、追加、削除、利用停止等または第三者提供の停止（以下、総称して「開示等」といいます。）を請求することができます。この場合、当社は本人からのご請求であること、および、同法に定める要件を満たしていることを確認の上、遅滞なく開示し、または必要な範囲で開示等を行います。ただし、同法その他の法令により、当社がこれらの義務を負わない場合は、この限りではありません。',
    descriptions: {
      one: {
        title: '保有個人データまたは第三者提供記録の開示',
        description: {
          one: '保有個人データのご本人は、当社に対し、個人情報保護法において認められる範囲内において、電磁的記録の提供または書面の交付による方法のうちご本人が指定する方法により、保有個人データまたは第三者提供記録の開示を請求することができます。なお、指定する方法での開示が困難である場合は、当社はご本人に対して遅滞なく通知します。',
        },
      },
      two: {
        title: '訂正・追加・削除',
        description: {
          one: '保有個人データのご本人は、当社に対し、個人情報保護法の定めるところに従って、保有個人データの訂正・追加・削除を請求することができます。その場合、当社は、利用目的の達成に必要な範囲内で遅滞なく調査を行い、同法において認められる範囲内において、当該保有個人データの訂正・追加・削除を行います。',
        },
      },
      three: {
        title: '利用停止等または第三者提供の停止',
        description: {
          one: '保有個人データのご本人は、当社に対し、個人情報保護法の定めるところに従って、保有個人データの利用の停止もしくは消去または第三提供の停止を請求することができます。その場合、当社は、同法に従って適切に対応します。',
        },
      },
      four: {
        title: '手続',
        description: {
          one: 'ユーザーは、開示等の請求を行う場合には、当社所定の請求書に所定事項を記入の上、当社の指定する資料を添付して、当社の指定する送付先までご郵送いただきます。また、利用目的の通知および保有個人データまたは第三者提供の開示については、所定の手数料をいただきます。なお、不開示および利用目的の非通知の場合でも、所定の手数料をいただきます。請求をご希望される方は、お問い合わせフォームよりご連絡ください。',
        },
      },
    },
  },
  {
    title: '取得した個人データの廃棄等',
    subtitle:
      '取得した個人データは、当社による通常の事業運営および利用目的に照らして、合理的に不要と判断される時点で遅滞なく廃棄、削除または消去します。',
  },
  {
    title: '個人情報取扱いに関する相談や苦情の連絡先',
    subtitle:
      '当社の個人情報の取扱いに関するご質問やご不明点、苦情、その他のお問い合わせは本サービスに関するお問い合わせフォームよりご連絡ください。',
  },
  {
    title: 'プライバシーポリシーの変更',
    subtitle:
      '当社は、本ポリシーを随時変更する場合があり、変更した場合には、ウェブサイト上に掲載します。ユーザーは、ウェブサイト上に掲載される最新の本ポリシーの内容を十分にご確認ください。',
  },
  { endTitleRight: '以上', endTitleLeft: '制定日：2023年3月16日' },
];

const PrivacyPolicyPage: React.FC<Props> = ({ PrivacyPolicy = Privacy }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { isMobile } = mobileContext();
  const { teamDetails, isLoading, isFirstStepCompleted } = useContext(UserContext);

  const { authenticated, loading }: any = useContext(AuthContext);

  useIonViewDidEnter(() => {
    document.title = 'プライバシーポリシー - Fanique';
    if (contentRef) {
      contentRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (containerRef) {
      containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [window.location.pathname]);

  return (
    <IonPage>
      <Contain ref={containerRef}>
        <Container
          isMobile={isMobile}
          loggedin={authenticated}
          isAuthenticated={authenticated}
          color={teamDetails?.team?.teamColor}
          isFirstStepCompleted={isFirstStepCompleted}
        >
          <LoaderComponent isLoading={loading || isLoading} className="loader">
            <Header
              authenticated={false}
              color={authenticated ? teamDetails?.team?.teamColor : ''}
            />
            <HomeWrapper
              className="neomorphs-shadow-in"
              style={{ height: '70vh', overflow: 'auto' }}
              ref={contentRef}
            >
              <div className="title">
                <TitleComponent heading="Faniqueプライバシーポリシー" color="#4D4D4D" />
              </div>
              <div className="details">
                <p>
                  株式会社スカラ（以下「当社」といいます。）は、当社が運営するNFTストア「Fanique」上にて提供するサービス(以下「本サービス」といいます。)においてユーザーから当社が取得するユーザー情報およびユーザーデータに含まれる個人情報の取扱いについて、以下のとおり「Faniqueプライバシーポリシー」（以下「本ポリシー」といいます。）を定めます。
                  <br />{' '}
                  本ポリシーにおいて使用する用語の意味は、当社が別途定める「Fanique利用規約」（以下「本規約」といいます。）および個人情報の保護に関する法律（平成15年法律第57号、以下「個人情報保護法」といいます。）に準拠するものとします。
                </p>
              </div>
              {PrivacyPolicy.map((item: any, index: any) => (
                <div className={item?.endTitleRight ? 'end-title' : 'privacy-policy'} key={index}>
                  <h5>{item.title}</h5>
                  {item?.subtitle && <p>{item.subtitle}</p>}
                  {item.descriptions && (
                    <ul>
                      {Object.keys(item?.descriptions).map((value, id) => {
                        const object = item.descriptions[value];
                        if (object.title) {
                          return (
                            <li key={id}>
                              <>
                                <p>{object.title}</p>
                                <ul>
                                  {Object.keys(object.description).map((value, id) => {
                                    const subobj = object.description[value];
                                    if (subobj.title) {
                                      <>
                                        <p>{subobj.title}</p>
                                        <ul>
                                          {Object.keys(subobj.description).map(
                                            (subvalue: any, idx: number) => (
                                              <li key={id}>{subobj.description[subvalue]}</li>
                                            )
                                          )}
                                        </ul>
                                      </>;
                                    } else {
                                      return <li key={id}>{object.description[value]}</li>;
                                    }
                                  })}
                                </ul>
                              </>
                            </li>
                          );
                        } else {
                          return <li key={id}>{object}</li>;
                        }
                      })}
                    </ul>
                  )}
                  {item?.endTitleRight && (
                    <EndRight>
                      <strong>
                        <small>{item?.endTitleRight}</small>
                      </strong>
                    </EndRight>
                  )}
                  {item?.endTitleLeft && (
                    <div>
                      <strong>
                        <small>{item?.endTitleLeft}</small>
                      </strong>
                    </div>
                  )}
                </div>
              ))}
            </HomeWrapper>
            <BackButton>
              <button className="back" onClick={() => history.push('/top')}>
                {t('Go to Top page')}
              </button>
            </BackButton>
            <div className="footer-wrapper">
              <Footer authenticated={authenticated} color={teamDetails?.team?.teamColor} />
            </div>
          </LoaderComponent>
        </Container>
      </Contain>
    </IonPage>
  );
};

export default RestrictedRoute(PrivacyPolicyPage);
