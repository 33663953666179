import axios from 'axios';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import React, { useContext, useEffect, useState } from 'react';

import { useToast } from '../hooks/useToast';
import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import { fetchUserData, updateProfile } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, Loader, PersonalInformationForm, TitleComponent, Toast } from '../components';

const StyledWrapper = styled.div`
  .header {
    padding-bottom: 1rem;
  }
  .personal-information-form {
    padding: 1rem 13.5px 25px 13.5px;
    border-radius: 50px;
    margin: 20px;
    font-weight: 350px;
    & ion-row:first-child {
      padding-left: 1rem;
    }
  }
  .bottom-btn-section {
    margin: auto;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.6rem;

    .prev-btn {
      width: 140px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px;
    ion-button {
      width: 140px;
    }
  }
`;

const ProfileForm: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useToast(false);
  const { userProfileData, teamDetails } = useContext(UserContext);
  const [fieldData, setFieldData] = useState<any>(null);

  const {
    data,
    isLoading: isDataLoading,
    isFetching: isDataFetching,
    refetch,
  } = useQuery(['fetchUserData', userProfileData?.id], fetchUserData, {
    enabled: !!userProfileData?.id,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    select: ({ data }) => {
      return data;
    },
  });

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      history.push('/settings');
    },
    onError: (error: any) => {
      setShowToast(true);
    },
  });

  const COLOR = teamDetails?.team.teamColor;

  useEffect(() => {
    setFieldData({
      name_mei: data?.name_mei,
      name_sei: data?.name_sei,
      iconUrl: data?.iconUrl,
      furigana_mei: data?.furigana_mei,
      furigana_sei: data?.furigana_sei,
      email: data?.email,
      birthday: data?.birthday,
      postcode: data?.postcode,
      mailPostcode: data?.mailPostcode,
      address: data?.address,
      address1: data?.address1,
      mailAddress: data?.mailAddress,
      mailAddress1: data?.mailAddress1,
      phone: data?.phone,
      nickname: data?.nickname,
      gender: data?.gender,
      occupation: data?.occupation,
      uniformSize: data?.uniformSize,
      termsAndCondition: '同意する',
    });
  }, [data]);

  return (
    <Wrapper authenticated>
      {showToast && <Toast message={t('Something went wrong. Please try again.')} type={'Error'} />}
      <Loader
        isLoading={isDataLoading || isDataFetching || userProfileData?.id === undefined}
        className="home-loader"
      >
        <StyledWrapper>
          <div className="header">
            <TitleComponent
              title
              heading="Edit Profile"
              subHeading="個人情報変更(入力)画面"
              color={COLOR}
            />
          </div>
          <div className="personal-information-form neomorphs-shadow-in">
            <PersonalInformationForm
              authenticated={true}
              mutate={mutate}
              isLoading={isLoading}
              userData={fieldData}
            />
          </div>

          <div className="button-wrapper">
            <Button size="small" color={COLOR} onClick={() => history.push('/settings')}>
              設定画面へ戻る
            </Button>
          </div>
        </StyledWrapper>
      </Loader>
    </Wrapper>
  );
};
export default PrivateRoute(ProfileForm);
