import { IonText } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../atoms';
import { MarketCard } from '../MarketCard';
import { UserContext } from '../../../utils/UserContext';
import { useMembershipExpiration } from '../../../hooks/useMembershipExpiration';

const Wrapper = styled.div`
  .list-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-row-gap: 17px;
    grid-column-gap: 5px;
    margin-bottom: 30px;

    ion-card-content {
      min-height: 270px;
    }

    ion-card {
      width: 100%;
    }
  }
  .my-collection {
    margin-left: 1rem;
    color: ${(props: any) => props.color};
    font-size: 14px;
  }
  .buy-other-card {
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    ion-button {
      width: 100%;
    }

    .favourite-btn {
      margin-top: 15px;
      width: 131px;
    }
  }
`;
const Message = styled.span`
  text-align: center;
  font-size: 15px;
  padding: 50px;
`;
interface Props {
  data?: Array<any>;
  color: string;
  teamName?: string; //Remove after confirmation
  teamLogo?: any;
}

const MyCollections: React.FC<Props> = ({ data = [], color, teamLogo, teamName }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { isExpired } = useMembershipExpiration();
  const { userProfileData } = useContext(UserContext);

  return (
    <Wrapper color={color}>
      <IonText>
        <h2 className="my-collection">
          {t('My Collection')} ({userProfileData?.UtilityNfts?.items.length}枚)
        </h2>
      </IonText>
      <div className="list-wrapper">
        {data.map((card: any) => (
          <MarketCard
            data={{
              // image: card?.masterData?.templateFileUrl,
              image: userProfileData?.UtilityNfts?.items?.find((data: any) => data?.id === card?.id)
                ?.fileUrl,
              title: card?.masterData?.cardName,
              price: `${card?.masterData?.price?.toLocaleString()}円`,
              // quantity: `${card?.masterData?.currentSoldNumber?.toString()}/${card?.masterData?.maxSalesNumber?.toString()}`,
              quantity: userProfileData?.UtilityNfts?.items?.find(
                (data: any) => data?.id === card?.id
              )?.number,
              id: card?.masterData?.id,
              teamName: teamName, //Remove after confirmation as it is taken from teamDetail of context
              teamLogo: teamLogo,
              tokenId: card?.tokenId,
            }}
            showLikeNumber={false}
            key={card?.id}
            showLikeButton={false}
            myCollection
          />
        ))}
      </div>
      <div className="buy-other-card">
        {data && data.length === 0 ? (
          <>
            <Message>{t('You don’t have anything to display in your collection.')}</Message>
          </>
        ) : (
          <Button size="large" color={color} onClick={() => history.push('/market')}>
            {t('Buy other cards')}
          </Button>
        )}
        {isExpired ? (
          <Button disabled className="favourite-btn" size="small" color={color}>
            {t('Favorite list')}
          </Button>
        ) : (
          <Button
            className="favourite-btn"
            size="small"
            color={color}
            onClick={() => history.push('/market/favourite-list')}
          >
            {t('Favorite list')}
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export { MyCollections };
