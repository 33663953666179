import { API } from './api';
import { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';

export const fetchUserMembership = async ({
  queryKey,
}: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/user/membership?membershipId=${queryKey[1]}&userId=${queryKey[2]}`);
};

export const fetchSeasonData = async ({
  queryKey,
}: QueryFunctionContext): Promise<AxiosResponse> => {
  const teamId = queryKey[1];
  return API.get(`/team/currentSeason?teamId=${teamId}`);
};

export const fetchUserWatch = (queryKey: any): Promise<AxiosResponse> => {
  const { userId, teamId, gameId } = queryKey;
  const headers = {
    userId: userId,
    gameId: gameId,
    teamId: teamId,
  };
  return API.post(`/user/watch`, headers);
};

export const fetchNotifications = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/notification/list?page=1&userId=${queryKey[1]}`);
};

export const fetchNotificationDetails = ({
  queryKey,
}: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/notification?notificationId=${queryKey[1]}`);
};

export const fetchTransactionHistory = ({
  queryKey,
}: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(
    `/user/coin/history?userId=${queryKey[1]}&teamId=${queryKey[2]}&page=${queryKey[3]}`
  );
};

export const fetchTradingHistory = ({ queryKey }: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(`/utilitynft/tradingHistory?id=${queryKey[1]}&page=${queryKey[2]}`);
};

export const fetchTradingHistoryList = ({
  queryKey,
}: QueryFunctionContext): Promise<AxiosResponse> => {
  return API.get(
    `/utilitynft/tradingHistory/list?userId=${queryKey[1]}&teamId=${queryKey[2]}&page=${queryKey[3]}`
  );
};
