import { IonImg } from '@ionic/react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';

interface Props {
  data: string[];
  onClick: (e: string) => void;
}

const Wrapper = styled.div`
  ion-img {
    padding: 1.5rem 0;
    max-width: 60%;
    margin: 0 auto;
    ::part(image) {
      width: min-content;
      height: auto;
      max-width: 100%;
      margin: auto;
    }
  }
  ion-button {
    width: 35px;
    height: 35px;
    --padding-start: 4px;
    --padding-end: 4px;
    position: relative;
    top: 23px;
    left: 44%;
  }
  .view-more {
    display: block;
    position: relative;
    margin-top: -1rem;

    ion-icon {
      color: #4d4d4d;
    }
  }
  ion-icon {
    color: black;
  }
  hr {
    margin-left: 31px;
    margin-right: 31px;
  }
`;

const TeamList: React.FC<Props> = ({ data, onClick }: Props) => {
  const [isVisible, setVisibility] = useState(false);
  const handleVisibility = () => setVisibility((prev: boolean) => !prev);
  return (
    <Wrapper>
      {data[0] && <IonImg src={data[0]} onClick={() => onClick(data[0])} />}
      {isVisible &&
        (data.slice(1) || []).map((imgSrc, i) => (
          <IonImg key={i} src={imgSrc} onClick={() => onClick(imgSrc)} />
        ))}
      {TeamList.length > 1 ? (
        <div className="view-more" onClick={handleVisibility}>
          <Button
            icon={isVisible ? caretUpOutline : caretDownOutline}
            slot="icon-only"
            size="large"
            className="icon"
          />
          <Divider />
        </div>
      ) : (
        <Divider />
      )}
    </Wrapper>
  );
};

export { TeamList };
