import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  bgImg: string;
  logo?: string;
  heading?: string;
  children?: any;
}

const Wrapper = styled.div`
  position: relative;
  background-color: #eeeeee;
  height: 600px;
  @media (max-width: 380px) {
    height: 500px;
  }

  .bg-img {
    height: 100%;
    object-fit: cover;
  }

  .logo {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
  }

  .text {
    position: absolute;
    bottom: 55px;
    text-align: center;
    width: 100%;

    .title {
      font-size: 28px;
      font-weight: 700;
    }

    .paragraph {
      font-size: 15px;
      margin-top: 25px;
    }

    @media (max-width: 370px) {
      bottom: 40px;

      .title {
        font-size: 25px;
      }
    }
  }

  @media (max-width: 370px) {
    .logo {
      width: 180px;
    }
  }
`;

export const TopBackgroundComponent: React.FC<Props> = ({ bgImg, logo, heading, children }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <IonImg src={bgImg} className="bg-img" />
      <IonImg src={logo} className="logo" />
      <div className="text">
        <p className="title">{t(`${heading}`)}</p>
        <p className="paragraph">{children}</p>
      </div>
    </Wrapper>
  );
};
