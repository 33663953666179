import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, TeamCardComponent } from '../components';

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
  padding: 0 32px;

  .title {
    margin: 15px 0;
  }

  .bottom-text {
    text-align: center;
    font-size: 13px;
    font-weight: 350;
    margin-top: 18px;
  }

  .button {
    width: 100%;
    margin-top: 10px;
  }
`;

const Welcome = () => {
  const { teamDetails, userProfileData } = useContext(UserContext);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Body>
        <TitleComponent
          title
          heading="Welcome !!"
          color={teamDetails?.team?.teamColor}
          className="title"
        />
        <TeamCardComponent rotating={true} name={userProfileData?.username} id="welcome" />
        <p className="bottom-text">
          {t('Thank you for your purchase')}
          <br />
          {t('Welcome to FANIQUE')}
        </p>
        <Button
          color={teamDetails?.team?.teamColor}
          size="large"
          className="button"
          onClick={() => history.push('/home')}
        >
          {t('To members page')}
        </Button>
      </Body>
    </Wrapper>
  );
};

export default PrivateRoute(Welcome);
