import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../utils/AuthContext';
import { UserContext } from '../utils/UserContext';
import RestrictedRoute from '../withRestrictedRoute';
import { useGetTeamDetail } from '../hooks/useGetTeamDetail';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TopCard, TitleComponent, Button, Loader } from '../components';

const Container = styled.div<{ isLoggedin?: boolean }>`
  .loader {
    margin-top: 40%;
  }

  .top-card-wrapper {
    padding: 0px 20px;
    padding-top: 5px;
  }

  .title-wrapper {
    margin: 20px 0;
  }

  .property-wrapper {
    padding: 0 20px;

    .divider {
      border: 1px solid #cccccc;
      filter: blur(1px);
      box-shadow: 0px 1px 1px 1px white;
      margin: 25px 10px;
    }

    .title-component {
      padding: 0 10px;
    }
  }

  .button-group {
    margin: 35px 0px 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 35px;

    position: sticky;
    bottom: ${(props) => (props?.isLoggedin ? '90px' : '20px')};
    .rank-button {
      width: 100%;
      background-color: var(--ion-color-primary);
    }

    @media (max-width: 370px) {
      .rank-button {
        font-size: 16px;
      }
    }
  }

  .bottom-button-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .bottom-button {
      width: 140px;
    }
  }
`;

const TeamDetail: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id }: any = useParams();
  const { data: teamData, isLoading: teamLoading, isError: isTeamDataError } = useGetTeamDetail(id);

  const [data, setData] = useState<any>([]);
  const { authenticated } = useContext(AuthContext);
  const { isFirstStepCompleted, userProfileData } = useContext(UserContext);

  const membershipStatus = userProfileData?.membershipStatus?.items?.length === 0;

  useEffect(() => {
    if (isTeamDataError) {
      history.replace('/404');
    } else if (teamData) {
      setData([
        {
          subHeading: 'さいたまブロンコスとは？',
          title: 'Feature',
          detail: teamData?.feature,
        },
        {
          subHeading: 'プライマリーロゴ',
          title: 'Primary Logo',
          imgSrc: teamData?.primaryLogoImagePath,
          detail: teamData?.primaryLogoMessage?.split('\\n').join('\n'),
        },
        {
          subHeading: 'チームスローガン',
          title: 'Team Slogan',
          detail: teamData?.teamSlogan,
        },
        {
          subHeading: '成し遂げたいこと',
          title: 'Mission',
          detail: teamData?.mission,
        },
        {
          subHeading: 'メッセージ',
          title: 'Message',
          detail: teamData?.message,
        },
      ]);
    }
  }, [teamData, isTeamDataError]);

  return (
    <Wrapper>
      <Container isLoggedin={authenticated && isFirstStepCompleted && !membershipStatus}>
        <Loader isLoading={teamLoading} className="loader">
          <div className="top-card-wrapper">
            <TopCard image={teamData?.image} logo={teamData?.logo} />
          </div>
          <div className="title-wrapper">
            <TitleComponent
              subHeading={teamData?.subHeading}
              heading={teamData?.name}
              color={teamData?.color}
            />
          </div>
          {data?.map((property: any) => (
            <div key={property?.title} className="property-wrapper">
              <div className="divider" />
              <TitleComponent
                subHeading={property?.subHeading}
                heading={property?.title}
                paragraph={property?.detail}
                title
                className="title-component"
                color={teamData?.color}
                imgSrc={property?.imgSrc}
              />
            </div>
          ))}
          <div className="button-group">
            <Button
              size="large"
              className="rank-button"
              color={teamData?.color}
              onClick={() => history.push(`/membership-rank?teamId=${id}`)}
            >
              {t('Select membership rank')}
            </Button>
          </div>
          <div className="bottom-button-wrapper">
            <Button
              className="bottom-button"
              size="small"
              onClick={() => history.push('/team-list')}
            >
              チーム一覧へ戻る
            </Button>
          </div>
        </Loader>
      </Container>
    </Wrapper>
  );
};

export default RestrictedRoute(TeamDetail);
