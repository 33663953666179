import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../components';
import { useHistory } from 'react-router';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const HomeWrapper = styled.div`
  padding: 0.5em 1.5em;
  margin: 0.5em 1.4em;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  .title {
    margin-bottom: 5em;
    margin-top: 4em;
    width: 11em;
  }
  .inquiry-title {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #4d4d4d;
  }
`;

const BackButton = styled.div`
  padding: 0em 2em;
  margin-bottom: 5em;
  width: 100%;
`;

const InquiryConfirmation: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleConfirmation = async () => {
    history.push('/top');
  };
  return (
    <Wrapper>
      <HomeWrapper>
        <div className="title">
          <p className="inquiry-title">{t('Inquiry submitted successfully')}</p>
        </div>
      </HomeWrapper>
      <BackButton>
        <Button size="large" className="back" onClick={() => handleConfirmation()}>
          {t('Go to Top Page')}
        </Button>
      </BackButton>
    </Wrapper>
  );
};

export default InquiryConfirmation;
