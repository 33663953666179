import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

import Home from './pages/Home';
import TopPage from './pages/Top';
import About from './pages/About';
import Logout from './pages/Logout';
import Welcome from './pages/Welcome';
import Profile from './pages/Profile';
import LevelUp from './pages/LevelUp';
import Inquiry from './pages/Inquiry';
import Settings from './pages/Settings';
import HowToUse from './pages/HowToUse';
import TeamList from './pages/TeamList';
import StatusUp from './pages/StatusUp';
import NFTMarket from './pages/NFTMarket';
import NFTMarketDetail from './pages/NFTMarketDetail';
import NotFoundPage from './pages/404page';
import TeamDetail from './pages/TeamDetail';
import VotingList from './pages/VotingList';
import MyTeamDetails from './pages/my-team';
import RankDetails from './pages/RankDetails';
import ProfileForm from './pages/ProfileForm';
import NftGeneration from './pages/NftGeneration';
import LevelUpSuccess from './pages/LevelUpSuccess';
import { GlobalStyles } from './utils/GlobalStyles';
import MembershipRank from './pages/MembershipRank';
import PublishTeamPage from './pages/PublishTeamPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import StatusUpConfirm from './pages/StatusUpConfirm';
import StatusUpComplete from './pages/StatusUpComplete';
import QrCodeGeneration from './pages/QrCodeGeneration';
import NotificationPage from './pages/NotificationDetail';
import TermsAndCondtions from './pages/TermsAndConditions';
import InquiryConfirmation from './pages/InquiryConfirmation';
import MarketFavouriteList from './pages/MarketFavouriteList';
import LevelUpConfirmation from './pages/LevelUpConfirmation';
import VotingDistributionPage from './pages/VotingDistribution';
import WithdrawlCarefulPage from './pages/WithdrawlCarefulPage';
import WithdrawlCompletedPage from './pages/WithdrawlCompletedpage';
import MembershipCardInfoPage from './pages/MembershipCardInfoPage';
import TransactionHistoryPage from './pages/TransactionHistoryPage';
import MembershipConfirmation from './pages/MembershipConfirmation';
import SubmissionCompletedPage from './pages/SubmissionCompletedPage';
import PersonalInformationPage from './pages/PersonalInformationPage';
import AccountDeleteConfirmation from './pages/AccountDeleteConfirmation';
import WithdrawlConfirmationPage from './pages/WithdrawlConfirmationPage';
import MarketPurchaseCompletePage from './pages/MarketPurchaseCompletePage';
import MarketPurchaseConfirmationPage from './pages/MarketPurchaseConfirmationPage';
import SpecifiedCommercialTransactions from './pages/SpecifiedCommercialTransactions';
import MembershipCardChargeCompletePage from './pages/MembershipCardChargeCompletePage';
import PersonalInformationConfirmationPage from './pages/PersonalInformationConfirmation';

import LoginFail from './pages/LoginFail';
import ClearCognitoCache from './pages/ClearCognitoCache';
import ClearLocalStorage from './pages/ClearLocalStorage';

import { Amplify } from 'aws-amplify';

import { Toast } from './components';
import { useToast } from './hooks/useToast';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from './utils/AuthContext';
import AuthProgressPage from './pages/AuthProgressPage';
import UpbondSuccess from './pages/UpbondSuccess';

import Maintenance from './pages/Maintenance';
import OtherInformation from './pages/OtherInformation';

setupIonicReact({
  rippleEffect: false,
  mode: 'md',
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}/login-progress`,
      redirectSignOut:
        'https://3eaba876.auth.upbond.io/v2/logout?returnTo=https://fanique.io&client_id=2ihQ0X80TrxI_S9GIqPgK',
      responseType: 'code',
    },
  },
});

const App: React.FC = () => {
  const { t } = useTranslation();
  const [showToast] = useToast(false);

  return (
    <IonApp>
      {showToast && (
        <Toast message={t('You cannot login. Please contact your administrator')} type={'Error'} />
      )}
      <GlobalStyles />
      <IonReactRouter>
        <AuthProvider>
          <IonRouterOutlet>
            <Route exact path="/top">
              <Home />
            </Route>
            <Route exact path="/status-up">
              <StatusUp />
            </Route>
            <Route exact path="/status-up-confirm">
              <StatusUpConfirm />
            </Route>
            <Route exact path="/status-up-complete">
              <StatusUpComplete />
            </Route>
            <Route exact path="/welcome">
              <Welcome />
            </Route>
            <Route exact path="/home">
              <TopPage />
            </Route>
            <Route exact path="/how-to-use">
              <HowToUse />
            </Route>
            <Route exact path="/membership-rank">
              <MembershipRank />
            </Route>
            <Route exact path="/team-list">
              <TeamList />
            </Route>
            <Route exact path="/terms-and-conditions">
              <TermsAndCondtions />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route exact path="/inquiry">
              <Inquiry />
            </Route>
            <Route exact path="/inquiry-sent">
              <InquiryConfirmation />
            </Route>
            <Route exact path="/">
              <Redirect to="/top" />
            </Route>
            <Route exact path="/nft-generation">
              <NftGeneration />
            </Route>
            <Route exact path="/membership-confirmation">
              <MembershipConfirmation />
            </Route>
            <Route exact path="/voting-list">
              <VotingList />
            </Route>
            <Route exact path="/voting-distribution">
              <VotingDistributionPage />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/my-page">
              <MyTeamDetails />
            </Route>
            <Route exact path="/membership-card-info">
              <MembershipCardInfoPage />
            </Route>
            <Route exact path="/membership-card-purchase-success">
              <MembershipCardChargeCompletePage />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/personal-information-confirmation">
              <PersonalInformationConfirmationPage />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/qr-code">
              <QrCodeGeneration />
            </Route>
            <Route exact path="/transaction-history">
              <TransactionHistoryPage />
            </Route>
            <Route exact path="/profile/:id">
              <ProfileForm />
            </Route>
            <Route exact path="/personal-information">
              <PersonalInformationPage />
            </Route>
            <Route exact path="/specified-commercial-transactions">
              <SpecifiedCommercialTransactions />
            </Route>
            <Route exact path="/level-up-success">
              <LevelUpSuccess />
            </Route>
            <Route exact path="/level-up">
              <LevelUp />
            </Route>
            <Route exact path="/level-up-confirmation">
              <LevelUpConfirmation />
            </Route>
            <Route exact path="/account-delete-confirmation">
              <AccountDeleteConfirmation />
            </Route>
            <Route exact path="/withdrawl-confirmation">
              <WithdrawlConfirmationPage />
            </Route>
            <Route exact path="/withdrawl-completed">
              <WithdrawlCompletedPage />
            </Route>
            <Route exact path="/withdrawl-careful">
              <WithdrawlCarefulPage />
            </Route>
            <Route exact path="/publish-team">
              <PublishTeamPage />
            </Route>
            <Route exact path="/notification/:id">
              <NotificationPage />
            </Route>
            <Route exact path="/team-list/:id">
              <TeamDetail />
            </Route>
            <Route exact path="/submission-completed">
              <SubmissionCompletedPage />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/login-fail">
              <LoginFail />
            </Route>
            <Route exact path="/clear-local-storage">
              <ClearLocalStorage />
            </Route>
            <Route exact path="/clear-cache">
              <ClearCognitoCache />
            </Route>
            <Route exact path="/404">
              <NotFoundPage />
            </Route>
            <Route exact path="/upbond-success">
              <UpbondSuccess />
            </Route>
            <Route exact path="/login-progress">
              <AuthProgressPage />
            </Route>
            <Route exact path="/rank-details/:id">
              <RankDetails />
            </Route>
            <Route exact path="/under-maintenance">
              <Maintenance />
            </Route>
            <Route exact path="/market">
              <NFTMarket />
            </Route>
            <Route exact path="/market/purchase-confirmation/:id">
              <MarketPurchaseConfirmationPage />
            </Route>
            <Route exact path="/market/purchase-complete">
              <MarketPurchaseCompletePage />
            </Route>
            <Route exact path="/market/favourite-list">
              <MarketFavouriteList />
            </Route>
            <Route exact path="/market/detail/:id">
              <NFTMarketDetail />
            </Route>
            <Route exact path="/my-card">
              <MarketPurchaseCompletePage isMyCard />
            </Route>
            <Route exact path="/other-information">
              <OtherInformation />
            </Route>
            <Redirect to="/404" />
          </IonRouterOutlet>
        </AuthProvider>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
