import React from 'react';
import styled from 'styled-components';
import { Button } from '../components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';

const ContentWrapper = styled.div`
  .text-wrapper {
    padding: 110px 20px;
    p {
      text-align: center;
      margin: 0px;
      color: #4d4d4d;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
    }
  }
  .button-wrapper {
    padding: 0px 30px;
    margin-bottom: 40px;
  }
`;

const WithdrawlCompletedPage = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  return (
    <Wrapper>
      <ContentWrapper>
        <div className="text-wrapper">
          <p>{t('Your withdrawal has been completed.')}</p>
          <p>{t('Thank you for using')}</p>
          <p>{t('Thank you very much')}</p>
        </div>
        <div className="button-wrapper">
          <Button size="large" onClick={() => push('/top')}>
            {t('Go to Top page')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(WithdrawlCompletedPage);
