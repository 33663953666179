import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.hr`
  box-shadow: -2px -0.8px 2px #cec5c5;
`;

interface Props {
  className?: string;
}
export const Divider: React.FC<Props> = ({ className }) => <StyledDivider className={className} />;
