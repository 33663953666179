import { Auth } from 'aws-amplify';
import { ethers } from 'ethers';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { IonPageComponent, Loader } from '../components';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { AuthContext } from '../utils/AuthContext';
import styled from 'styled-components';

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const UpbondSuccess: React.FC = () => {
  const { upbondProviders, upbondServices } = useContext(AuthContext);
  const history = useHistory();

  const fetch = async () => {
    const prov = new ethers.providers.Web3Provider(upbondProviders?.provider as any);
    const signer = prov.getSigner(); //get wallet address from upbond provider
    const address = await signer.getAddress();
    localStorage.setItem('account', address); // to be used in personal form submission
    const user = await upbondServices.getUserInfo();
    localStorage.setItem('user-info', JSON.stringify(user));
    Auth.federatedSignIn({ provider: 'UBWallet' as any });
  };
  useEffect(() => {
    if (
      upbondProviders?.isInitialized &&
      upbondProviders?.isLoggedIn &&
      upbondProviders?.currentVerifier
    ) {
      fetch();
    }
    if (upbondProviders?.isInitialized && !upbondProviders?.isLoggedIn) {
      history.push('login-fail');
    }
    // let timeout = setTimeout(() => history.push('/login-fail'), 10000);
    // () => clearTimeout(timeout);
  }, [upbondProviders?.isInitialized, upbondProviders?.isLoggedIn]);

  return (
    <IonPageComponent>
      <LoaderComponent isLoading={true}>
        <Wrapper />
      </LoaderComponent>
    </IonPageComponent>
  );
};

export default UpbondSuccess;
