import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';

import { UserContext } from '../utils/UserContext';

const useMembershipExpiration = () => {
  const { expiryDate } = useContext(UserContext);
  const [isExpired, setIsExpired] = useState<boolean | null>(null);

  const [isCardExpiringSoon, setIsCardExpiringSoon] = useState<boolean>(false);

  useEffect(() => {
    if (expiryDate && expiryDate < moment(new Date()).format('YYYY-MM-DD')) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }

    if (expiryDate !== '2025-06-30') {
      setIsCardExpiringSoon(true);
    } else {
      setIsCardExpiringSoon(false);
    }
  }, [expiryDate]);

  return { isExpired, isCardExpiringSoon };
};

export { useMembershipExpiration };
