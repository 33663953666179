import React, { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { fetchAllTeam, fetchMarketList, likeMarketCard } from '../services';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import {
  Divider,
  MarketCarouselSection,
  OwnerSection,
  TeamSelect,
  Roadmap,
  Request,
  Toast,
  TopBackgroundComponent,
} from '../components';
import { AuthContext } from '../utils/AuthContext';
import RestrictedRoute from '../withRestrictedRoute';
import { UserContext } from '../utils/UserContext';
import { useToast } from '../hooks/useToast';
import { useLikingCardId } from '../hooks/useLikingCardId';
import { useIonViewDidEnter } from '@ionic/react';

const RoadmapWrapper = styled.div`
  .divider {
    margin: 2rem 2.5rem;
  }
`;

const RequestWrapper = styled.div`
  margin-top: 2rem;

  .divider {
    margin: 3rem 2.5rem;
  }
`;

const HOME_PAGE_MAIN_BG = '/assets/images/bg_1.png';
const LOGO = '/assets/images/logo.png';
const HEADING = 'ファンを超えて、その先へ';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { upbondProviders, cognitoUser } = useContext(AuthContext);
  const { userProfileData } = useContext(UserContext);
  const [showToast, setShowToast] = useToast(false);

  const { data, isLoading, isFetching } = useQuery(['allTeams', 1], fetchAllTeam, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    select: ({ data }) => {
      return {
        teams: data.teams.map((team: any) => ({
          id: team.id,
          name: team.teamName,
          subHeading: team.tag,
          image: team.teamImagePath,
        })),
        total: data.total,
      };
    },
  });

  // NFT Market API
  const {
    data: marketList,
    isFetching: loadingMarketList,
    refetch,
  } = useQuery(['fetchMarketList', 1, 'desc', 'createdAt'], fetchMarketList, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  // Like Market Card API
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard);

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  useEffect(() => {
    (async function () {
      if (
        cognitoUser === null &&
        upbondProviders &&
        upbondProviders.isInitialized &&
        upbondProviders.isLoggedIn &&
        upbondProviders.currentVerifier
      ) {
        await upbondProviders.cleanUp();
      }
    })();
  }, [
    upbondProviders,
    upbondProviders?.isInitialized,
    upbondProviders?.isLoggedIn,
    upbondProviders?.currentVerifier,
  ]);

  useEffect(() => {
    const emailStatus = localStorage.getItem('emailStatus');
    if (emailStatus && emailStatus === 'false') {
      setShowToast(true);
      localStorage.removeItem('emailStatus');
    }
  }, [localStorage]);

  // Refetch to get new likes
  useIonViewDidEnter(() => {
    refetch();
  }, []);
  return (
    <Wrapper authenticated={false}>
      {showToast && <Toast message={t('Email address is missing')} type={'Error'} />}
      <TopBackgroundComponent bgImg={HOME_PAGE_MAIN_BG} logo={LOGO} heading={HEADING}>
        メンバーシップNFTでファンとチームの
        <br />
        新次元の繋がりと価値を育てよう
      </TopBackgroundComponent>
      <div id="team-select-section">
        <TeamSelect data={data} isLoading={isLoading || isFetching} />
      </div>
      <Divider className="divider" />

      <MarketCarouselSection
        data={marketList?.data?.utilityNfts?.map((d) => {
          // after randomUtilityNfts has items field
          /* let finalImage: string = '';
          if (d?.isRandomTemplate === true) {
            finalImage = d?.randomUtilityNfts?.items![0].templateFileUrl ?? '';
          } else {
            finalImage = d?.templateFileUrl ?? '';
          } */
          return {
            teamName: d?.team?.teamName,
            teamLogo: d?.team?.primaryLogoImagePath,
            // after randomUtilityNfts has items field
            /* image: finalImage, */
            image: d?.templateFileUrl,
            title: d?.cardName,
            price: `${d?.price?.toLocaleString()}円`,
            quantity: `${d?.currentSoldNumber?.toString()}/${d?.maxSalesNumber?.toString()}`,
            id: d?.id,
            likesNumber: d?.favorites?.items ? d?.favorites?.items?.length : 0,
            favorites: d?.favorites?.items,
            teamId: d?.team?.id,
          };
        })}
        loading={loadingMarketList}
        onLikeClick={handleLike}
        isLiking={isLiking}
        likingCardId={likingCardId}
        setLikingCardId={setLikingCardId}
      />
      <div id="owner-section">
        <OwnerSection />
      </div>
      <RoadmapWrapper>
        <Divider className="divider" />
        <Roadmap />
      </RoadmapWrapper>
      <Divider className="divider" />
      <RequestWrapper>
        <Request />
        <Divider className="divider" />
      </RequestWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(Home);
