import React from 'react';
import styled from 'styled-components';
import { RankTitleComponent } from '../../atoms';

export interface GoodsData {
  heading?: string;
  image?: string;
  imageSize?: 'md' | 'sm';
  caption?: string;
  subheading?: string;
}

interface Props {
  mainHeading: string;
  mainSubheading?: string;
  data?: GoodsData[];
}

const Wrapper = styled.div`
  .goods-item {
    padding: 0px 16px;
  }
  .heading {
    color: #4d4d4d;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
  }
  .wrap-image {
    display: flex;
    justify-content: center;
    .image-wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      .image {
        width: 100%;
        object-fit: cover;
      }
      .w-sm {
        width: 200px !important;
      }
    }
  }
  .subheading {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 350;
    line-height: 22px;
    text-align: justify;
    padding-bottom: 20px;
  }
  .caption {
    margin: 5px 0px 0px;
    padding-bottom: 0px;
  }
`;

const Goods: React.FC<Props> = ({ mainHeading, mainSubheading, data }: Props) => {
  return (
    <Wrapper className="neomorphs-shadow-in card-wrapper">
      <RankTitleComponent heading={mainHeading} subHeading={mainSubheading} />
      {data &&
        data?.map((d, idx) => (
          <div className="goods-item" key={idx}>
            <p className="heading" dangerouslySetInnerHTML={{ __html: d?.heading ?? '' }} />
            <div className="wrap-image">
              <div className="image-wrapper">
                <img src={d?.image} className={`image ${d?.imageSize === 'sm' ? 'w-sm' : ''}`} />
              </div>
            </div>
            {d?.caption && (
              <p
                className="subheading caption"
                dangerouslySetInnerHTML={{ __html: d?.caption ?? '' }}
              />
            )}
            <p className="subheading" dangerouslySetInnerHTML={{ __html: d?.subheading ?? '' }} />
          </div>
        ))}
    </Wrapper>
  );
};

export { Goods };
