import React from 'react';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { useMembershipExpiration } from '../../../hooks/useMembershipExpiration';

const Wrapper = styled.div<{ isExpired?: boolean | null }>`
  display: flex;
  align-items: ${(props) => (props.isExpired ? 'flex-start' : '')};
  ion-img {
    height: 77px;
    width: 77px;
    ::part(image) {
      border-radius: 50%;
    }
  }
  .avatar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .username-section {
    flex-grow: 1;
    p {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.8rem;
      padding-left: 0.5rem;
    }

    .warning-message {
      font-size: 14px;
      font-weight: normal;
    }
  }
`;

interface Props {
  avatar?: string;
  username?: string;
  color?: string;
}

const UsernameCard: React.FC<Props> = ({
  avatar = './assets/avatar/user-avatar.png',
  username,
  color,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { isExpired } = useMembershipExpiration();

  return (
    <Wrapper isExpired={isExpired}>
      <div className="avatar-section">
        <IonImg src={avatar} />
      </div>
      <div className="username-section">
        <p> {username ?? t('User full name')}</p>
        <Button expand="full" size="small" color={color} onClick={() => history.push('/settings')}>
          {t('Settings')}
        </Button>

        {isExpired && (
          <>
            <p className="warning-message">
              {t('Your membership validity period has been expired. Please renew')}
            </p>
            <Button
              size="small"
              expand="full"
              className="options-button"
              color={color}
              onClick={() => history.push('/membership-card-info')}
            >
              {t('Membership card info')}
            </Button>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export { UsernameCard };
