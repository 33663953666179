import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button, Loader, TitleComponent } from '../../atoms';
import { MarketCarousel, MarketCarouselData } from '../../molecules';

const Wrapper = styled.div`
  .market-carousel-title {
    margin: 1.9rem 0;
  }
  .market-carousel-btn {
    margin: 40px 32px;
  }
`;

interface MarketCarouselSectionProps {
  color?: string;
  heading?: string;
  subHeading?: string;
  detailBtnTitle?: string;
  detailBtnRoute?: string;
  data?: MarketCarouselData[];
  loading?: boolean;
  onLikeClick?: (nftMasterId?: string) => void;
  isLiking?: boolean;
  likingCardId?: string;
  setLikingCardId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  showLikeNumber?: boolean;
}

export const MarketCarouselSection: React.FC<MarketCarouselSectionProps> = ({
  color,
  heading = 'NFT Market',
  subHeading = 'Buy player NFT cards!',
  detailBtnTitle = 'See other cards',
  detailBtnRoute = '/market',
  data,
  loading = false,
  onLikeClick,
  isLiking,
  likingCardId,
  setLikingCardId,
  showLikeNumber = true,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Wrapper>
      <TitleComponent
        title={true}
        heading={heading}
        subHeading={t(subHeading)}
        className="market-carousel-title"
        color={color}
      />
      <Loader isLoading={loading}>
        <MarketCarousel
          data={data}
          color={color}
          onLikeClick={onLikeClick}
          isLiking={isLiking}
          likingCardId={likingCardId}
          setLikingCardId={setLikingCardId}
          showLikeNumber={showLikeNumber}
        />
      </Loader>
      {detailBtnTitle !== '' && (
        <Button
          size="large"
          className="market-carousel-btn"
          color={color}
          onClick={() => history.push(detailBtnRoute)}
        >
          {t(detailBtnTitle)}
        </Button>
      )}
    </Wrapper>
  );
};
