import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';

import { TitleComponent } from '../../atoms';

const RoadmapContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .roadmap {
    margin-top: 30px;
    padding: 30px 40px;

    .wrapper {
      position: relative;
      width: 330px;
      .group {
        position: absolute;
        top: -20px;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detail {
          margin-left: 27px;
          display: flex;
          flex-direction: column;
          color: #4d4d4d;

          .time {
            font-weight: 350;
            font-size: 12px;
            line-height: 17px;
          }

          .feature {
            font-weight: 900;
            font-size: 15px;
            line-height: 22px;
            color: #b3b3b3;
            white-space: pre;
          }

          .feature-color {
            color: #4d4d4d;
          }
        }
      }

      .group-reverse {
        right: -10px;
        width: 78%;
        flex-direction: row-reverse;

        .detail {
          margin-left: 0px;
        }
      }

      .count {
        height: 43px;
        width: 43px;
        border-radius: 50%;
        box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
          inset 3px 5px 9px -7px #f9e6e6;
        justify-content: center;
        display: flex;
        padding: 9px;
        color: #b3b3b3;
        font-weight: 900;
        font-size: 25px;
        margin-right: 10px;
        background-color: #eee;
      }

      .color {
        color: #29c8d2;
      }

      .icon {
        width: 100%;
        height: 100px;
        filter: drop-shadow(-2px -2px 1px #fbfbfb);
      }
    }
    @media (max-width: 400px) {
      padding: 30px 0;

      .wrapper {
        width: 280px;
        display: flex;
        justify-content: center;

        .group-left {
          left: -10px;
        }

        .group-reverse {
          right: -20px;
          width: 82%;
        }

        .icon {
          width: 95%;
        }
      }
    }
  }
`;

const data = [
  { id: 1, feature: 'Fanique ローンチ', time: '2023.Spring', color: true },
  { id: 2, feature: 'Voting機能', time: '2023.Fall', color: true },
  {
    id: 3,
    feature: 'NFTデジタルコレクション\n(ユーティリティNFT)ローンチ',
    time: '2024.Spring',
    color: true,
  },
  { id: 4, feature: 'Faniqueマーケットプレイス', time: '2024.Summer' },
];

export const Roadmap = () => {
  return (
    <RoadmapContainer>
      <TitleComponent title={true} heading="Roadmap" subHeading="開発予定" />
      <div className="roadmap">
        {data.map((item) => (
          <div key={item.id} className="wrapper">
            <div className={`group ${+item.id % 2 === 0 ? 'group-reverse' : 'group-left'}`}>
              <div className={`count ${item.color && 'color'}`}>{item.id}</div>
              <div className="detail">
                <span className="time">{item.time}</span>
                <span className={`feature ${item.color && 'feature-color'}`}>{item.feature}</span>
              </div>
            </div>
            {+item.id % 2 !== 0 ? (
              <IonIcon src="/assets/icon/roadmap/to-right.svg" className="icon" />
            ) : (
              <IonIcon src="/assets/icon/roadmap/to-left.svg" className="icon" />
            )}
          </div>
        ))}
      </div>
    </RoadmapContainer>
  );
};
