import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { useIonViewDidEnter } from '@ionic/react';

import { UserContext } from '../utils/UserContext';
import { AuthContext } from '../utils/AuthContext';
import RestrictedRoute from '../withRestrictedRoute';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { useTradingHistory } from '../hooks/useTradingHistory';
import { Table } from '../components/molecules/Table';
import { likeMarketCard, utilityMasterDetailData } from '../services';
import { useRecommendedNFTCard } from '../hooks/useRecommendedNFTCards';
import {
  Loader,
  MarketCard,
  TitleComponent,
  MarketAccordion,
  MarketDetailPlayerCard,
  Pagination,
  Toast,
} from '../components';
import { useToast } from '../hooks/useToast';
import { useLikingCardId } from '../hooks/useLikingCardId';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

const StyledLoader = styled(Loader)`
  position: absolute;
  margin: 50% 50%;
`;

const ContentWrapper = styled.div`
  padding: 0px 20px;
  margin-bottom: 40px;
  .pb {
    padding-bottom: 13px;
  }
  .note {
    white-space: pre-line;
  }
`;

const ImageWrapper = styled.div`
  height: 345px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 32px 0px 46px 0px;
  img {
    width: 227px;
    height: 318px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
  }
  .swiper-pagination-bullet {
    margin-right: 16px;
    width: 12px;
    height: 12px;
    background: #29c8d2;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #01a8b4;
  }
  // Overriding Swiperjs's "display: none !important;"
  // Allow showing pagination when only total data/pages is only 1.
  .swiper-pagination-bullet:only-child {
    display: inline-block !important;
  }
`;

const ShadowWrapper = styled.div`
  box-shadow: var(--shadow-down);
  margin: 0px 20px 40px 20px;
  padding: 27px;
  border-radius: 3rem;
  p {
    margin: 0;
  }
  .title {
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .subtitle {
    color: #4d4d4d;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 23px;
  }
`;

const Item = styled.div`
  line-height: 20px;
  display: flex;

  span {
    color: #4d4d4d;
    font-size: 12px;
    font-weight: 400;
  }
  .first {
    min-width: 30%;
    display: inline-block;
    padding-right: 17px;
  }
  .second {
    min-width: 60%;
  }
`;

const RecommendedWrapper = styled.div`
  position: relative;
  padding-top: 23px;
  .loader {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
`;

const FavouriteListWrapper = styled.div`
  padding: 50px 26px 32px 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-row-gap: 17px;
  grid-column-gap: 5px;

  ion-card {
    width: 100%;
  }
`;

const TableWrapper = styled.div`
  height: 303px;
  overflow: hidden;
  .table-title {
    position: sticky;
    top: 0;
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 700;
    padding: 2px 0px 18px 15px;
  }
  .main-table {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 27px;
    ::-webkit-scrollbar {
      display: none;
    }
    .loader {
      height: 100px;
      margin-top: 50px;
    }
    .t-row {
      box-shadow: none;
      td {
        font-size: 12px;
        font-weight: 400;
        color: #4d4d4d;
      }
      @media (max-width: 395px) {
        td {
          padding: 14px 12px;
        }
      }
    }
  }

  .pagination {
    margin: 20px 0;
  }

  .no-data {
    height: 40px;
    text-align: center;
    margin: 30px 15px;
    p {
      font-size: 14px;
    }
  }
`;
const Wrappers = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

SwiperCore.use([Autoplay, Pagination]);

const NFTMarketDetail = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { id } = useParams<{ id?: string; token?: string }>();
  const { userProfileData } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useToast(false);
  const [toastType, setToastType] = useState<'Error' | 'Success'>('Error');
  const [toastMessage, setToastMessage] = useState<any>('Something went wrong. Please try again.');
  const [banners, setBanners] = useState<string[] | null>(null);
  const { authenticated } = useContext(AuthContext);
  const { isFirstStepCompleted } = useContext(UserContext);
  const location: any = useLocation();

  const searchParams = new URLSearchParams(location?.search);
  const isDetail = searchParams.get('fetch');

  const COLS = [
    {
      Header: '日付',
      accessor: '日付',
      Filter: () => null,
    },
    {
      Header: '取引',
      accessor: '取引',
      Filter: () => null,
    },
    {
      Header: '価格',
      accessor: '価格',
      Filter: () => null,
    },
    {
      Header: '出品',
      accessor: '出品',
      Cell: (value: any) =>
        value.value !== null ? (
          <div
            style={{ cursor: 'copy' }}
            onClick={() => {
              navigator.clipboard.writeText(value.value);
              setToastType('Success');
              setShowToast(true);
              setToastMessage('出品者 wallet addressをクリップボードにコピーしました');
            }}
          >
            ...
          </div>
        ) : (
          <div>-</div>
        ),
      Filter: () => null,
    },
  ];

  const {
    data,
    isLoading: utilityMasterDetailDataLoading,
    refetch,
    isRefetching: dataRefetching,
  } = useQuery(['fetchutilityMasterDetailData', id], utilityMasterDetailData, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: (response) => {
      return {
        ...response.data,
        tokenId: response?.data?.currentSoldNumber + 1,
      };
    },
    onSuccess: (data) => {
      let multiImage: string[] = [];
      if (data?.isRandomTemplate === true) {
        data?.randomUtilityNfts?.items.forEach((item: any) =>
          multiImage.push(item?.templateFileUrl)
        );
      } else {
        multiImage.push(data?.templateFileUrl);
      }
      setBanners(multiImage);
    },
  });

  const {
    purchaseData: tradingData,
    isLoading: loadingTrading,
    isFetching: fetchingTrading,
    total: tradingTotalPage,
  } = useTradingHistory(id || '', id ? true : false, currentPage);

  const {
    data: recommendedData,
    isLoading: recommendedLoading,
    refetch: recommendedDataRefetch,
  } = useRecommendedNFTCard(data?.team?.id, userProfileData?.id, data?.team?.id ? true : false);

  // Like NFT Card
  const { likingCardId, setLikingCardId } = useLikingCardId();
  const { mutate: likeCard, isLoading: isLiking } = useMutation(likeMarketCard, {
    onSuccess: () => {
      setLikingCardId('');
    },
  });

  const handleLike = (nftMasterId?: string, teamId?: string) => {
    if (setLikingCardId) setLikingCardId(nftMasterId);
    if (userProfileData?.id)
      likeCard({ nftMasterId, userId: userProfileData?.id, teamId: teamId! });
  };

  // Refetch to get new likes
  useIonViewDidEnter(() => {
    refetch();
    if (data?.team?.id) recommendedDataRefetch();
  }, [data?.team?.id]);

  return (
    <Wrapper>
      {showToast && <Toast message={t(toastMessage)} type={toastType} />}
      <StyledLoader
        isLoading={
          isDetail === 'true'
            ? utilityMasterDetailDataLoading || dataRefetching
            : utilityMasterDetailDataLoading
        }
      >
        <TitleComponent
          heading="NFT Market"
          subHeading="NFT Market"
          title
          color="#29C8D2"
          hasCrossBtn
          crossOnClick={() => push('/market')}
        />

        <ImageWrapper>
          {banners && (
            <Swiper
              speed={500}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              loop={banners?.length === 1 ? false : true}
              pagination={{ clickable: true }}
            >
              {banners?.map((data, index) => (
                <SwiperSlide key={index}>
                  <Wrappers>
                    <img src={data} />
                  </Wrappers>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </ImageWrapper>

        <ContentWrapper>
          <MarketDetailPlayerCard
            // data={data?.masterData}
            data={data}
            setToastType={setToastType}
            setShowToast={setShowToast}
            setToastMessage={setToastMessage}
          />
        </ContentWrapper>

        <ShadowWrapper>
          <p className="title">{t('Generate information')}</p>
          <p className="subtitle">Fanique NFT</p>
          <Item>
            <span className="first">出品者</span>
            {/* static value for all */}
            <span className="second">{data?.team?.teamName}</span>
          </Item>
          <Item>
            <span className="first">Token ID</span>
            <span className="second">{data?.tokenId}</span>
          </Item>
        </ShadowWrapper>

        {/* TO LOOK: static for now, will implement API once available */}
        <ShadowWrapper>
          <TableWrapper>
            <p className="table-title">{t('Transaction History')}</p>
            <div className="main-table">
              <Loader className="loader" isLoading={loadingTrading || fetchingTrading}>
                {tradingData && tradingData?.length ? (
                  <Table columns={COLS} data={tradingData} />
                ) : (
                  <div className="no-data">
                    <p>{t('No Data Available')}</p>
                  </div>
                )}
                <div className="pagination">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={tradingTotalPage}
                  />
                </div>
              </Loader>
            </div>
          </TableWrapper>
        </ShadowWrapper>

        {/* TO LOOK: HIDE FOR NOW */}
        {/* <ContentWrapper>
          <MarketAccordion title={t('Details')}>
            <div className="pb">
              <Item>
                <span className="first">提供者</span>
                <span className="second">{data?.details?.provider}</span>
              </Item>
              <Item>
                <span className="first">マーケット ID</span>
                <span className="second">{data?.details?.marketId}</span>
              </Item>
            </div>
          </MarketAccordion>
        </ContentWrapper>
        */}

        <ContentWrapper>
          <MarketAccordion title={t('Careful Notes')}>
            <p className="note">{data?.notes?.replaceAll('\\n', '\n')}</p>
          </MarketAccordion>
        </ContentWrapper>

        <RecommendedWrapper>
          <TitleComponent heading="Recommend" subHeading={t('Recommend')} title color="#29C8D2" />
          <FavouriteListWrapper>
            <Loader isLoading={recommendedLoading} className="loader">
              {authenticated && isFirstStepCompleted
                ? recommendedData?.recommendItems?.map((card: any) => {
                    return (
                      <MarketCard
                        data={{
                          teamName: card?.team?.teamName,
                          teamLogo: card?.team?.primaryLogoImagePath,
                          image: card?.templateFileUrl,
                          title: card?.cardName,
                          price: `${card?.price?.toLocaleString()}円`,
                          quantity: `${card?.currentSoldNumber?.toString()}/${card?.maxSalesNumber?.toString()}`,
                          id: card?.id,
                          likesNumber: card?.favorites ? card?.favorites : 0,
                          favorites: recommendedData?.userFaviritesItems ?? [],
                          teamId: card?.team?.id,
                        }}
                        key={card?.id}
                        onLikeClick={handleLike}
                        isLiking={isLiking}
                        likingCardId={likingCardId}
                        setLikingCardId={setLikingCardId}
                        isRecommended
                      />
                    );
                  })
                : recommendedData?.map((card: any) => {
                    return (
                      <MarketCard
                        data={{
                          teamName: card?.team?.teamName,
                          teamLogo: card?.team?.primaryLogoImagePath,
                          image: card?.templateFileUrl,
                          title: card?.cardName,
                          price: `${card?.price?.toLocaleString()}円`,
                          quantity: `${card?.currentSoldNumber?.toString()}/${card?.maxSalesNumber?.toString()}`,
                          id: card?.id,
                          likesNumber: card?.favorites ? card?.favorites : 0,
                          favorites: recommendedData?.userFaviritesItems ?? [],
                          teamId: card?.team?.id,
                        }}
                        key={card?.id}
                        onLikeClick={handleLike}
                        isLiking={isLiking}
                        likingCardId={likingCardId}
                        setLikingCardId={setLikingCardId}
                        isRecommended
                      />
                    );
                  })}
            </Loader>
          </FavouriteListWrapper>
        </RecommendedWrapper>
      </StyledLoader>
    </Wrapper>
  );
};

export default RestrictedRoute(NFTMarketDetail);
