import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TitleComponent } from '../../atoms';
import { PublishTeamForm } from '../../molecules';

interface Props {
  isLoading?: boolean;
  onSubmitHandler: any;
  data?: any;
  setData?: any;
}

const StepContainer = styled.div`
  .step-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
    }

    .triangle-down {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 25px solid #eeeeee;
      filter: drop-shadow(2px 2px 2px #cccccc) drop-shadow(-4px -2px 1px #ffffff) blur(0.5px);
      margin: 20px 0 16px;
    }
  }
  .form {
    margin-top: 50px;
    padding: 0 20px;
    margin-bottom: 20px;
  }
`;

export const StepComponent: React.FC<Props> = ({ isLoading, onSubmitHandler, data, setData }) => {
  const { t } = useTranslation();

  return (
    <StepContainer>
      <TitleComponent title heading="Step" subHeading="Step to post" />
      <div className="step-down">
        <span>{t('Form entry')}</span>
        <div className="triangle-down" />
        <span>{t('Contact the screening team')}</span>
        <div className="triangle-down" />
        <span>{t('Start recruiting owners')}</span>
      </div>
      <div className="form">
        <PublishTeamForm
          onSubmitHandler={onSubmitHandler}
          isLoading={isLoading}
          data={data}
          setData={setData}
        />
      </div>
    </StepContainer>
  );
};
