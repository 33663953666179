import React from 'react';
import styled from 'styled-components';

import { Divider, TitleComponent } from '../../atoms';

interface Props {
  heading: string;
  subHeading?: string;
}

const Wrapper = styled.div`
  width: 100%;
  .divide {
    margin: 35px 0px 20px;
  }
`;

const RankTitleComponent: React.FC<Props> = ({ heading, subHeading }) => {
  return (
    <Wrapper>
      <TitleComponent heading={heading} subHeading={subHeading} title />

      <Divider className="divide" />
    </Wrapper>
  );
};

export { RankTitleComponent };
