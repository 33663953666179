import styled from 'styled-components';
import { search } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { IonRadioGroup, IonInput } from '@ionic/react';

import { FilterType } from '../../organisms';
import { FilterCheckbox, FilterRadio, Button } from '../../atoms';

interface Props {
  type: 'select-multiple' | 'select-single' | 'no-select';
  filterName: string;
  title?: string;
  options?: Option[];
  hasSearch?: boolean;
  onSearch?: React.Dispatch<React.SetStateAction<string>>;
  selectedRadioValue?: string | string[];
  selectedCheckboxValues?: string | string[];
  handleFilters?: (f: FilterType) => void;
  resetFilters?: boolean;
  setResetFilters?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: () => void;
  searchedValue?: string;
  filter?: () => void;
}

interface Option {
  label?: string;
  value?: string;
}

interface CheckboxProps {
  name?: string;
  values: string[];
}

interface RadioProps {
  name?: string;
  value: string;
}

const StyledIonSearchBarWrapper = styled.div`
  position: relative;
  padding: 25px 30px;
  .custom-input {
    border-radius: 35px;
    input {
      padding-left: 25px;
      padding-right: 80px;
      height: 50px;
      color: black;
    }
  }
  .button-wrapper {
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 10;
    ion-icon {
      position: absolute;
      top: -2px;
      color: #29c8d2;
      font-size: 19px;
      font-weight: bold;
    }
    .custom-search-button {
      width: 35px;
      height: 35px;
    }
  }
`;

const Section = styled.div`
  width: 100%;
  .title {
    font-size: 17px;
    font-weight: 900;
    color: #4d4d4d;
  }
  .filter-search {
    > div {
      padding: 25px 0px;
    }
    .button-wrapper {
      right: 12px;
    }
  }
`;

export const FilterSection: React.FC<Props> = ({
  type,
  title,
  filterName,
  options,
  hasSearch,
  selectedRadioValue,
  selectedCheckboxValues,
  handleFilters,
  resetFilters = false,
  setResetFilters,
  searchedValue,
  filter,
}) => {
  const { t } = useTranslation();
  const [checkboxValues, setCheckboxValues] = useState<CheckboxProps>({
    name: filterName,
    values: selectedCheckboxValues
      ? Array.isArray(selectedCheckboxValues)
        ? selectedCheckboxValues
        : []
      : [],
  });

  const [radioValue, setRadioValue] = useState<RadioProps>({
    name: filterName,
    value: selectedRadioValue ? (Array.isArray(selectedRadioValue) ? '' : selectedRadioValue) : '',
  });

  const handleCheckbox = (e: any) => {
    if (e?.target?.checked) {
      setCheckboxValues({
        name: filterName,
        values: [...checkboxValues.values, e?.target?.value],
      });
    } else {
      setCheckboxValues({
        name: filterName,
        values: checkboxValues?.values?.filter((d: string) => d !== e?.target?.value),
      });
    }
  };

  const handleRadio = (e: any) => {
    if (e?.target?.value) {
      setRadioValue({ name: filterName, value: e?.target?.value });
    }
  };

  useEffect(() => {
    if (handleFilters) {
      if (type === 'select-single') {
        handleFilters(radioValue);
      } else if (type === 'select-multiple') {
        handleFilters({ name: checkboxValues?.name, value: checkboxValues?.values });
      }
    }
  }, [checkboxValues, radioValue]);

  useEffect(() => {
    if (resetFilters && setResetFilters) {
      // Reset radio to initial values
      setRadioValue({
        name: filterName,
        value: selectedRadioValue
          ? Array.isArray(selectedRadioValue)
            ? ''
            : selectedRadioValue
          : '',
      });
      // Reset checkbox to initial values
      setCheckboxValues({
        name: filterName,
        values: selectedCheckboxValues
          ? Array.isArray(selectedCheckboxValues)
            ? selectedCheckboxValues
            : []
          : [],
      });
      // Set Filters to false
      setResetFilters(false);
    }
  }, [resetFilters]);

  return (
    <Section>
      <p className="title">{`${title && t(title)}`}</p>
      {hasSearch && (
        <div className="filter-search">
          <StyledIonSearchBarWrapper>
            <IonInput
              className="custom-input neomorphs-shadow-in"
              name={filterName}
              value={searchedValue}
              onIonChange={(e) =>
                handleFilters &&
                handleFilters({ name: filterName, value: e?.target?.value as string })
              }
            />
            <div className="button-wrapper">
              <Button
                className="custom-search-button"
                size="regular"
                slot="icon-only"
                icon={search}
                onClick={filter}
              />
            </div>
          </StyledIonSearchBarWrapper>
        </div>
      )}
      {type === 'select-multiple' ? (
        <>
          {options?.map((d, idx) => (
            <FilterCheckbox
              label={d?.label}
              value={d?.value}
              onChange={handleCheckbox}
              key={idx}
              checked={checkboxValues?.values?.includes(d?.value || '')}
              disabled={d?.label === 'Basketball' || d?.label === 'Saitama Broncos'} // remove afterwards
            />
          ))}
        </>
      ) : type === 'select-single' ? (
        <IonRadioGroup name="test" value={radioValue?.value} onIonChange={handleRadio}>
          {options?.map((d, idx) => (
            <FilterRadio
              label={d?.label}
              value={d?.value}
              key={idx}
              disabled={d?.label === 'Videos'}
            />
          ))}
        </IonRadioGroup>
      ) : null}
    </Section>
  );
};
