import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { IonCol, IonImg, IonRadioGroup, IonRow, IonText } from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getDate } from '../../../utils';
import { UserContext } from '../../../utils/UserContext';
import {
  Button,
  Checkbox,
  Divider,
  ImageUpload,
  Radio,
  Select,
  SelectWithRef,
  TextField,
} from '../../atoms';
import ImageCropModal from './ImageCropModal';
import { UploadImageUrlResponse } from '../../../types/image-upload';
import { getUploadImageUrl, uploadImage } from '../../../services';
import { useToast } from '../../../hooks/useToast';
import { Toast } from '../Toast';

interface Props {
  isLoading?: boolean;
  mutate?: (values: any) => void;
  userData?: any;
  authenticated?: boolean;
}

const Wrapper = styled.div`
  font-weight: 350;
  ion-note {
    margin-top: -10px;
  }
  ion-label {
    font-weight: 350;
  }
  * {
    font-size: 10px;
  }
  @media only screen and (min-width: 390px) {
    * {
      font-size: 12px;
    }
  }

  ion-row {
    padding-right: 1rem;
    ion-col:first-child {
      display: flex;
      align-items: center;
      padding-left: 4px;
    }
  }
  .no-margin {
    padding-left: 0px !important;
    padding-right: 0rem !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    col {
      margin: 0px;
    }
  }
  .po-box-address-row {
    margin-top: -20px;
  }
  hr {
    margin: 0;
  }
  .justify-content-end {
    justify-content: end;
  }
  .address {
    align-self: baseline;
    padding-top: 22px;
  }
  .caption {
    padding-top: 15px;
  }
  .address-row {
    ion-item:first-child {
      height: 36px;
      overflow: visible;
    }
    /* ion-item:last-child {
      margin-top: -3px;
    } */
  }
  .avatar {
    height: 77px;
    width: 77px;
    margin-bottom: 0.5rem;
  }
  ion-input {
    border-radius: 4px !important;
  }
  ion-item {
    --ion-safe-area-right: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }
  .terms-and-condition-row,
  .required-row {
    color: #b80629;
  }
  .err-msg {
    color: red;
    font-weight: 300 !important;
  }
  .info-msg {
    color: green;
    font-weight: 300 !important;
    font-size: 11px;
    display: inline-block;
  }
  .required-row {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .terms-and-condition-row {
    font-weight: 700;
    position: relative;
    .err-msg {
      position: absolute;
      bottom: 0px;
      left: 40px;
    }
  }
  ion-col > span:before {
    content: '※';
    margin-right: 4px;
    display: inline;
    color: red;
  }
  .optional-section {
    ion-row > ion-col:first-child {
      padding-left: 17px;
    }
  }
  .gender-options {
    padding: 0 !important;
    ion-col {
      padding: 0 !important;
    }
    ion-item {
      --border-width: 0px;
      --border-style: none;
      ::part(native) {
        padding: 0.25rem 0;
        min-height: min-content;
      }
    }
    ion-checkbox,
    ion-label {
      margin: 0;
      margin-right: 5px;
    }
  }
  .profile-image-column {
    margin-left: 10px;
  }
  .avatar,
  ion-img {
    height: 77px;
    width: 77px;
    margin-bottom: 0.5rem;
    border-radius: 50%;
    object-fit: cover;
    input {
      display: none;
    }
  }
  .avatar {
    overflow: hidden;
  }
  .profile-image {
    position: relative;
    .upload-section {
      top: 0em;
      border-radius: 50%;
      position: absolute;
      height: 77px;
      width: 77px;
      cursor: pointer;
    }
  }
  .add-button {
    position: absolute;
    bottom: 0;
    left: 57px;
    ion-img {
      border-radius: 50%;
      background-color: white;
      height: 1.2em;
      width: 1.2em;
    }
    .icon-section {
      top: 0em;
      position: absolute;
      height: 1em;
      width: 1em;
      cursor: pointer;
    }
  }
  .confirm-button {
    font-size: 18px;
    font-weight: 700;
  }

  ion-col {
    .terms {
      color: #000000;
      margin-left: 10px;

      span {
        color: #b80629;
      }
    }

    .email-para {
      font-size: 13px;
      font-weight: 400;
      word-break: break-word;
    }
  }
  .radio-group {
    width: 100%;
    display: flex;
  }
  .discord-username {
    font-size: 10px;
    line-height: 13px;
  }
  .uniform-size {
    ion-row {
      padding-left: 0 !important;
    }
    ion-col {
      padding-bottom: 0 !important;
      ion-select {
        margin-top: 0px;
      }
    }
    .preview-link {
      padding-left: 1rem;
      padding-bottom: 10px;
      @media (max-width: 390px) {
        .text-link {
          font-size: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .discord-username {
      font-size: 8px;
    }
  }
  @media only screen and (max-width: 575px) {
    .profile-image-column {
      margin-left: 27px;
    }
  }
`;

interface InitialValues {
  name_sei: string;
  name_mei: string;
  furigana_sei: string;
  furigana_mei: string;
  email: string;
  year: string;
  month: string;
  day: string;
  postcode: string;
  address: string;
  address1: string;
  mailPostcode: string;
  mailAddress: string;
  mailAddress1: string;
  extPhone: string;
  firstPhone: string;
  secondPhone: string;
  nickname: string;
  gender: string;
  occupation: string;
  iconUrl: string;
}

const validationSchema = Yup.object().shape({
  name_sei: Yup.string().required('Enter last name'),
  name_mei: Yup.string().required('Enter first name'),
  furigana_sei: Yup.string()
    .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
    .required('Enter last name furigana'),
  furigana_mei: Yup.string()
    .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
    .required('Enter first name furigana'),
  email: Yup.string()
    .matches(/\S+@\S+\.\S+/, { message: 'Invalid email address' })
    .required('Enter email'),
  year: Yup.string().required('Enter date of birth'),
  month: Yup.string().required('Enter date of birth'),
  day: Yup.string().required('Enter date of birth'),
  postcode: Yup.string()
    .matches(/^\d{7}$/, { message: '郵便番号は正しくありません' })
    .required('Enter post box no'),
  address: Yup.string().required('Enter address'),
  mailPostcode: Yup.string()
    .nullable()
    .matches(/^\d{7}$/, { message: '郵便番号は正しくありません' }),
  extPhone: Yup.string()
    .matches(/^[0-9]+$/, '番号のみ許可')
    .required('Enter phone number'),
  firstPhone: Yup.string()
    .matches(/^[0-9]+$/, '番号のみ許可')
    .required('Enter phone number'),
  secondPhone: Yup.string()
    .matches(/^[0-9]+$/, '番号のみ許可')
    .required('Enter phone number'),
});

const PersonalInformationForm: React.FC<Props> = ({
  mutate,
  isLoading,
  userData,
  authenticated = false,
}) => {
  const { t } = useTranslation();
  const [isImgUploading, setIsImgUploading] = useState<boolean>(false);
  const [imgErr, setImgErr] = useState<string>('');
  const [showToast, setShowToast] = useToast(false);
  const [showUploadMsg, setShowUploadMsg] = useState<boolean>(false);

  useEffect(() => {
    if (showUploadMsg) {
      setTimeout(() => {
        setShowUploadMsg(false);
      }, 20000);
    }
  }, [showUploadMsg]);

  const birthdate = userData?.birthday ? userData?.birthday.split('/') : '';

  const [data, setData] = useState<any>(
    userData
      ? {
          iconUrl: userData?.iconUrl,
          ...userData,
          year: birthdate[0],
          month: birthdate[1],
          day: birthdate[2],
          address: userData?.address,
          address1: userData?.address1,
          mailAddress: userData?.mailAddress,
          mailAddress1: userData?.mailAddress1,
          extPhone: userData?.phone !== null && userData?.phone?.substring(0, 3),
          firstPhone: userData?.phone !== null && userData?.phone?.substring(3, 7),
          secondPhone: userData?.phone !== null && userData?.phone?.substring(7),
        }
      : ''
  );
  const [initialValues, setInitialValues] = useState<InitialValues>({
    name_sei: '',
    name_mei: '',
    furigana_sei: '',
    furigana_mei: '',
    email: '',
    year: '',
    month: '',
    day: '',
    postcode: '',
    address: '',
    address1: '',
    mailPostcode: '',
    mailAddress: '',
    mailAddress1: '',
    extPhone: '',
    firstPhone: '',
    secondPhone: '',
    nickname: '',
    gender: '',
    occupation: '',
    iconUrl: '',
  });

  const [isTermsChecked, setTermsStatus] = useState(false);
  const [showTermsErr, setShowTermsErr] = useState(false);
  const [isSubmitActive, setSubmitStatus] = useState(false);
  const { userProfileData, teamDetails } = useContext(UserContext);
  const [image, setImage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let isSubmitActive = !authenticated ? true && isTermsChecked : true;
    setSubmitStatus(isSubmitActive);
  }, [data, isTermsChecked]);

  // For Edit
  useEffect(() => {
    if (userData) {
      setInitialValues({
        ...userData,
        year: birthdate[0],
        month: birthdate[1],
        day: birthdate[2],
        address: userData?.address,
        address1: userData?.address1,
        mailAddress: userData?.mailAddress,
        mailAddress1: userData?.mailAddress1,
        extPhone: userData?.phone !== null && userData?.phone?.substring(0, 3),
        firstPhone: userData?.phone !== null && userData?.phone?.substring(3, 7),
        secondPhone: userData?.phone !== null && userData?.phone?.substring(7),
      });
    }
  }, [userData]);

  // For Scrolling into view on error
  const nameSeiRef = useRef<any>(null);
  const nameMeiRef = useRef<any>(null);
  const furiganaSeiRef = useRef<any>(null);
  const furiganaMeiRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const yearRef = useRef<any>(null);
  const monthRef = useRef<any>(null);
  const dayRef = useRef<any>(null);
  const postCodeRef = useRef<any>(null);
  const addressRef = useRef<any>(null);
  const address1Ref = useRef<any>(null);
  const mailPostCodeRef = useRef<any>(null);
  const mailAddressRef = useRef<any>(null);
  const mailAddress1Ref = useRef<any>(null);
  const extPhoneRef = useRef<any>(null);
  const firstPhoneRef = useRef<any>(null);
  const secondPhoneRef = useRef<any>(null);
  // Make sure the "name" key has the same name as REQUIRED_FIELDS items.
  const inputRefs = [
    {
      name: 'name_sei',
      ref: nameSeiRef,
    },
    {
      name: 'name_mei',
      ref: nameMeiRef,
    },
    {
      name: 'furigana_sei',
      ref: furiganaSeiRef,
    },
    {
      name: 'furigana_mei',
      ref: furiganaMeiRef,
    },
    {
      name: 'email',
      ref: emailRef,
    },
    {
      name: 'year',
      ref: yearRef,
    },
    {
      name: 'month',
      ref: monthRef,
    },
    {
      name: 'day',
      ref: dayRef,
    },
    {
      name: 'postcode',
      ref: postCodeRef,
    },
    {
      name: 'address',
      ref: addressRef,
    },
    {
      name: 'address1',
      ref: address1Ref,
    },
    {
      name: 'mailPostcode',
      ref: mailPostCodeRef,
    },
    {
      name: 'mailAddress',
      ref: mailAddressRef,
    },
    {
      name: 'mailAddress1',
      ref: mailAddress1Ref,
    },
    {
      name: 'extPhone',
      ref: extPhoneRef,
    },
    {
      name: 'firstPhone',
      ref: firstPhoneRef,
    },
    {
      name: 'secondPhone',
      ref: secondPhoneRef,
    },
  ];

  const findRef = (name: string) => {
    const found = inputRefs?.find((d) => d?.name === name);
    if (found) return found?.ref;
    else return undefined;
  };

  const phoneChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { key } = e as unknown as KeyboardEvent;

    if (e.target.name === 'extPhone' && e.target.value.length === 2) {
      if ('key' in e && key !== 'Backspace') {
        setTimeout(() => {
          findRef('firstPhone')?.current?.setFocus();
        }, 100);
      }
    } else if (e.target.name === 'firstPhone') {
      if (e.target.value.length === 3) {
        if ('key' in e && key !== 'Backspace') {
          setTimeout(() => {
            findRef('secondPhone')?.current?.setFocus();
          }, 100);
        }
      } else if (e.target.value.length === 0) {
        if ('key' in e && key === 'Backspace') {
          findRef('extPhone')?.current?.setFocus();
        }
      }
    } else if (e.target.name === 'secondPhone' && e.target.value.length === 0) {
      if ('key' in e && key === 'Backspace') {
        findRef('firstPhone')?.current?.setFocus();
      }
    }
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Form Validation
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isSubmitActive) {
        mutate &&
          mutate({
            userId: userProfileData?.id,
            details: {
              name_sei: values?.name_sei,
              name_mei: values?.name_mei,
              furigana_sei: values?.furigana_sei,
              furigana_mei: values?.furigana_mei,
              email: values?.email,
              birthday: `${values?.year}/${values?.month}/${values?.day}`,
              postcode: values?.postcode,
              address: values?.address,
              address1: values?.address1,
              mailPostcode: values?.mailPostcode,
              mailAddress: values?.mailAddress,
              mailAddress1: values?.mailAddress1,
              phone: `${values?.extPhone}${values?.firstPhone}${values?.secondPhone}`,
              nickname: values?.nickname,
              gender: values?.gender,
              occupation: values?.occupation,
              lineName: JSON.parse(localStorage.getItem('user-info') ?? '{}')?.name,
              walletAddress: localStorage.getItem('account'),
              // image: values?.image,
            },
          });
      } else {
        setShowTermsErr(true);
      }
    },
  });

  const handleErr = (touched?: boolean, error?: string) => {
    if (touched && error) return error;
    else return undefined;
  };

  useEffect(() => {
    if (formik.isSubmitting) {
      const formikErrs = formik?.errors;
      if (formikErrs) {
        const foundErrKey = Object.keys(formik?.errors).find((key) => {
          // If errors exist in that key.
          if (key in formikErrs) {
            return key;
          }
        });
        if (foundErrKey) {
          const foundRef = findRef(foundErrKey);
          if (foundRef) {
            foundRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      } else {
        formik.handleSubmit();
      }
    }
  }, [formik.isSubmitting, formik?.errors]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Image Upload
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, fileSrc: any) => {
    setImgErr('');
    setImage(fileSrc);
    setIsModalOpen(true);
  };

  const handleImageUpload = (file: File, croppedImage: any) => {
    if (userProfileData?.id) {
      setIsImgUploading(true);
      getUploadImageUrl(userProfileData?.id, file?.type)
        .then((res) => handleImgFormData(res?.data, file, croppedImage))
        .catch((err) => {
          closeModal();
          setIsImgUploading(false);
          if (err?.response?.data?.error === 'File format not supported') {
            setImgErr('File should not be more than 2 MB');
          } else {
            setShowToast(true);
          }
        });
    }
  };

  const handleImgFormData = (data: UploadImageUrlResponse, file: File, croppedImage: any) => {
    const formData = new FormData();
    if (data?.fields) {
      formData.append('key', data?.fields?.key ?? '');
      formData.append('bucket', data?.fields?.bucket ?? '');
      formData.append('X-Amz-Algorithm', data?.fields['X-Amz-Algorithm'] ?? '');
      formData.append('X-Amz-Credential', data?.fields['X-Amz-Credential'] ?? '');
      formData.append('X-Amz-Date', data?.fields['X-Amz-Date'] ?? '');
      formData.append('X-Amz-Signature', data?.fields['X-Amz-Signature'] ?? '');
      formData.append('X-Amz-Security-Token', data?.fields['X-Amz-Security-Token'] ?? '');
      formData.append('Policy', data?.fields['Policy'] ?? '');
      formData.append('Content-Type', data?.fields['Content-Type'] ?? '');
      formData.append('file', file);
      uploadImage({ url: data?.url, data: formData })
        .then((res) => {
          setIsImgUploading(false);
          formik.setFieldValue('iconUrl', croppedImage);
          setShowUploadMsg(true);
          closeModal();
        })
        .catch((err) => {
          closeModal();
          setIsImgUploading(false);
          setShowToast(true);
        });
    }
  };

  return (
    <>
      {showToast && <Toast message={t('Something went wrong. Please try again.')} type={'Error'} />}
      <ImageCropModal
        image={image}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleUpload={handleImageUpload}
        uploading={isImgUploading}
      />
      <Wrapper>
        <IonRow>
          <IonCol size="2.4">{t('Image')}</IonCol>
          <IonCol className="profile-image-column">
            <div className="profile-image">
              <div className="avatar">
                <IonImg
                  src={
                    formik?.values?.iconUrl
                      ? formik?.values?.iconUrl?.startsWith('https')
                        ? `${formik.values?.iconUrl}?${Date.now()}`
                        : formik.values?.iconUrl
                      : './assets/avatar/user-avatar.png'
                  }
                />
              </div>
              <div className="add-button">
                <ImageUpload
                  onChange={handleImageChange}
                  className="icon-section"
                  value={image}
                  setError={setImgErr}
                />
                <IonImg src="./assets/icon/plus-icon.svg" />
              </div>
            </div>
            {imgErr && <IonText className="err-msg">{t(imgErr)}</IonText>}
            {showUploadMsg && (
              <IonText className="info-msg">
                {t('Wait a moment while the profile image is reflected')}
              </IonText>
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-xs="4" size-sm="3.4">
            <span>{t('Name')}</span>
          </IonCol>
          <IonCol>
            <TextField
              name="name_sei"
              mode="white"
              ref={findRef('name_sei')}
              value={formik.values.name_sei}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.name_sei, formik.errors.name_sei)}
            />
          </IonCol>
          <IonCol>
            <TextField
              name="name_mei"
              mode="white"
              ref={findRef('name_mei')}
              value={formik.values.name_mei}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.name_mei, formik.errors.name_mei)}
            />
          </IonCol>
        </IonRow>
        <Divider />
        <IonRow>
          <IonCol size-xs="4" size-sm="3.4">
            <span>{t('Furigana')}</span>
          </IonCol>
          <IonCol>
            <TextField
              name="furigana_sei"
              mode="white"
              ref={findRef('furigana_sei')}
              value={formik.values.furigana_sei}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.furigana_sei, formik.errors.furigana_sei)}
            />
          </IonCol>
          <IonCol>
            <TextField
              name="furigana_mei"
              mode="white"
              ref={findRef('furigana_mei')}
              value={formik.values.furigana_mei}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.furigana_mei, formik.errors.furigana_mei)}
            />
          </IonCol>
        </IonRow>
        <Divider />
        <IonRow>
          <IonCol size-xs="4" size-sm="3.4">
            <span>{t('Email')}</span>
          </IonCol>
          <IonCol>
            {userData?.email ? (
              <p className="email-para" translate="no">
                {userData?.email}
              </p>
            ) : (
              <TextField
                name="email"
                mode="white"
                ref={findRef('email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={handleErr(formik.touched.email, formik.errors.email)}
              />
            )}
          </IonCol>
        </IonRow>
        <Divider />
        <IonRow className="ion-nowrap">
          <IonCol size-xs="4" size-sm="3.4">
            <span translate="no">{t('DOB')}</span>
          </IonCol>
          <IonCol translate="no">
            <SelectWithRef
              name={'year'}
              mode="white"
              options={getDate('year')}
              ref={findRef('year')}
              value={formik.values.year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.year, formik.errors.year)}
            />
          </IonCol>
          <IonCol size="3">
            <SelectWithRef
              name={'month'}
              mode="white"
              options={getDate('month')}
              ref={findRef('month')}
              value={formik.values.month}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.month, formik.errors.month)}
            />
          </IonCol>
          <IonCol>
            <SelectWithRef
              name={'day'}
              mode="white"
              options={getDate('day').reverse()}
              ref={findRef('day')}
              value={formik.values.day}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.day, formik.errors.day)}
            />
          </IonCol>
        </IonRow>
        <Divider />
        <IonRow>
          <IonCol size-xs="4" size-sm="3.4">
            <span>{t('Po. box')}</span>
          </IonCol>
          <IonCol size="7">
            <TextField
              name={'postcode'}
              mode="white"
              inputMode="numeric"
              ref={findRef('postcode')}
              value={formik.values.postcode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.postcode, formik.errors.postcode)}
            />
          </IonCol>
        </IonRow>
        <Divider />
        <IonRow className="ion-align-items-center justify-content-end address-row">
          <IonCol size-xs="4" size-sm="3.4" className="address">
            <span>{t('Address')}</span>
          </IonCol>
          <IonCol size-xs="8" size-sm="8.6">
            <TextField
              name={'address'}
              mode="white"
              ref={findRef('address')}
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.address, formik.errors.address)}
            />
            <TextField
              name={'address1'}
              mode="white"
              ref={findRef('address1')}
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </IonCol>
        </IonRow>
        <Divider />
        <div className="optional-section">
          <IonRow>
            <IonText className="caption" translate="no">
              {t('If the delivery address is different')}
            </IonText>
          </IonRow>
          <IonRow>
            <IonCol size-xs="4" size-sm="3.4">
              {t('Po. box')}
            </IonCol>
            <IonCol size="7">
              <TextField
                name="mailPostcode"
                mode="white"
                inputMode="numeric"
                ref={findRef('mailPostcode')}
                value={formik.values.mailPostcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={handleErr(formik.touched.mailPostcode, formik.errors.mailPostcode)}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center justify-content-end address-row po-box-address-row">
            <IonCol size-xs="4" size-sm="3.4" className="address">
              {t('Address')}
            </IonCol>
            <IonCol size-xs="8" size-sm="8.6">
              <TextField
                name="mailAddress"
                mode="white"
                ref={findRef('mailAddress')}
                value={formik.values.mailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextField
                name={'mailAddress1'}
                mode="white"
                ref={findRef('mailAddress1')}
                value={formik.values.mailAddress1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </IonCol>
          </IonRow>
          <Divider />
        </div>
        <IonRow>
          <IonCol size-xs="4" size-sm="3.4">
            <span>{t('Phone')}</span>
          </IonCol>
          <IonCol size-xs="2.65" size-sm="2.86">
            <TextField
              name={'extPhone'}
              mode="white"
              maxlength={3}
              ref={findRef('extPhone')}
              className="text"
              inputMode="numeric"
              value={formik.values.extPhone}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
                phoneChangeHandler(e);
              }}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.extPhone, formik.errors.extPhone)}
            />
          </IonCol>
          <IonCol size-xs="2.65" size-sm="2.86">
            <TextField
              name={'firstPhone'}
              mode="white"
              maxlength={4}
              ref={findRef('firstPhone')}
              inputMode="numeric"
              value={formik.values.firstPhone}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
                phoneChangeHandler(e);
              }}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.firstPhone, formik.errors.firstPhone)}
            />
          </IonCol>
          <IonCol size-xs="2.65" size-sm="2.86">
            <TextField
              name={'secondPhone'}
              mode="white"
              maxlength={4}
              ref={findRef('secondPhone')}
              inputMode="numeric"
              value={formik.values.secondPhone}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
                phoneChangeHandler(e);
              }}
              onBlur={formik.handleBlur}
              error={handleErr(formik.touched.secondPhone, formik.errors.secondPhone)}
            />
          </IonCol>
        </IonRow>
        <div className="optional-section">
          <Divider />
          {formik?.values?.nickname && (
            <IonRow>
              <IonCol size-xs="4" size-sm="3.4" className="discord-username" translate="no">
                Discord
                <br />
                ユーザーネーム
              </IonCol>
              <IonCol>
                <p className="email-para" translate="no">
                  {formik?.values?.nickname}
                </p>
              </IonCol>
            </IonRow>
          )}
          <Divider />
          <IonRow>
            <IonCol size-xs="4" size-sm="3.4" translate="no">
              {t('Gender')}
            </IonCol>
            <IonCol size-xs="8" size-sm="8.6">
              <IonRow className="gender-options">
                <IonRadioGroup
                  className="radio-group"
                  name="gender"
                  value={formik.values.gender}
                  onIonChange={formik.handleChange}
                >
                  <IonCol>
                    <Radio label={t('Male')} value="男" />
                  </IonCol>
                  <IonCol>
                    <Radio label={t('Girl')} value="女" />
                  </IonCol>
                  <IonCol translate="no">
                    <Radio label={t('No answer')} value="無回答" />
                  </IonCol>
                </IonRadioGroup>
              </IonRow>
            </IonCol>
          </IonRow>
          <Divider />
          <IonRow>
            <IonCol size-xs="4" size-sm="3.4">
              {t('Occupation')}
            </IonCol>
            <IonCol>
              <TextField
                name={'occupation'}
                mode="white"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </IonCol>
          </IonRow>
        </div>
        <Divider />
        {!authenticated && (
          <IonRow className="terms-and-condition-row" style={{ position: 'relative' }}>
            <IonCol size="auto">
              <Checkbox
                label={''}
                name={'termsAndConditions'}
                checked={isTermsChecked}
                onChange={(e) => setTermsStatus(e.target.checked)}
              />
              <p className="terms">
                <span>
                  <Link to="/terms-and-conditions" target="_blank">
                    利用規約
                  </Link>
                </span>
                に同意してお進みください
              </p>
            </IonCol>
            {!isTermsChecked && showTermsErr && (
              <IonText className="err-msg">利用規約に同意してください'</IonText>
            )}
          </IonRow>
        )}
        <Divider />
        <IonRow className="required-row">
          <IonCol>
            <IonText>{t('※are required items')}</IonText>
          </IonCol>
        </IonRow>
        <Button
          color={teamDetails?.team?.teamColor}
          size="large"
          className="confirm-button"
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
        >
          {t('Confirm')}
        </Button>
      </Wrapper>
    </>
  );
};

export { PersonalInformationForm };
