import { API } from './api';

interface IPurchaseMembership {
  statusId: any;
  userId: any;
}

interface IUpdateMembership {
  membershipId: any;
  userId: any;
}
export const purchaseMembership = async (values: IPurchaseMembership) => {
  const { statusId, userId } = values;

  return API.post(`/user/membership?statusId=${statusId}&userId=${userId}`);
};

export const updateMembership = async (values: IUpdateMembership) => {
  const { membershipId, userId } = values;

  return API.put(`/user/membership?membershipId=${membershipId}&userId=${userId}`);
};

export const levelUp = async ({ membershipId, userId, data }: any) => {
  return API.post(`/user/membership/levelup?membershipId=${membershipId}&userId=${userId}`, data);
};

export const statusUp = async ({ membershipId, statusId, userId }: any) => {
  return API.post(
    `/user/membership/statusup?membershipId=${membershipId}&userId=${userId}&statusId=${statusId}`
  );
};

export const contactPost = async (values: any) => {
  return API.post(`/contact`, values);
};
