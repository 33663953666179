import styled from 'styled-components';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, TitleComponent, ValidUntil, TeamCardComponent } from '../components';

const ContentWrapper = styled.div`
  padding: 0 35px;
  .card-wrapper {
    margin-top: 35px;
  }
  .valid-until-wrapper {
    padding: 20px 25px;
  }
  .button-wrapper {
    .back-button {
      margin: auto;
      width: 140px;
      height: 28px;
    }
  }
`;

const MembershipCardChargeCompletePage = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { teamDetails, expiryDate } = useContext(UserContext);

  // const getExpiryDate = (date: any) => {
  //   let d = new Date(date);
  //   let year = d.getFullYear();
  //   let month = d.getMonth();
  //   let day = d.getDate();
  //   let c = new Date(year + 1, month, day);
  //   return new Date(c);
  // };

  return (
    <Wrapper authenticated>
      <TitleComponent
        title
        heading={t('Complete')}
        subHeading={t('Charge Completed')}
        color={teamDetails?.team?.teamColor}
      />
      <ContentWrapper>
        <div className="card-wrapper">
          <TeamCardComponent />
        </div>
        <div className="valid-until-wrapper">
          <ValidUntil
            color={teamDetails?.team?.teamColor}
            value={new Date(expiryDate)}
            label={t('Valid until')}
            valueIsDate
          />
        </div>
        <div className="button-wrapper">
          <Button
            color={teamDetails?.team?.teamColor}
            size="small"
            className="back-button"
            onClick={() => push('/settings')}
          >
            {t('Back to settings')}
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(MembershipCardChargeCompletePage);
