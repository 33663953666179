import { useEffect, useState } from 'react';

const MONTH = [
  { label: '1', value: '01' },
  { label: '2', value: '02' },
  { label: '3', value: '03' },
  { label: '4', value: '04' },
  { label: '5', value: '05' },
  { label: '6', value: '06' },
  { label: '7', value: '07' },
  { label: '8', value: '08' },
  { label: '9', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

export const getDate = (type: 'day' | 'month' | 'year') => {
  if (type === 'month') return MONTH;
  let value = [];
  let startValue = new Date().getFullYear();
  let endValue = 1940;
  if (type === 'day') {
    startValue = 31;
    endValue = 1;
  }
  for (let i = startValue; i >= endValue; i--) {
    value.push({ label: i, value: i.toString().length === 1 ? `0${i}` : `${i}` });
  }
  return value;
};

interface Props<T> {
  schema: {
    name: string;
    validate: (val: string) => boolean;
    message: string;
  }[];
  value: T | any;
}

export function useValidation<T>({ schema, value }: Props<T>) {
  const [errors, setState] = useState<any>({});

  useEffect(() => {
    schema.forEach((obj) => {
      let isInValid = value[obj.name] && !obj.validate(value[obj.name]);
      setState((prev: any) => ({ ...prev, [obj.name]: !isInValid ? undefined : obj.message }));
    });
  }, [value]);
  return errors;
}

export function numberToStringer(amount: number): string {
  if (Number.MAX_SAFE_INTEGER < amount) {
    return String(BigInt(amount));
  } else {
    return String(amount);
  }
}

export function toWei(amount: number): number {
  return amount * 10 ** 18;
}

export const UNIFORM_SIZE = [
  {
    label: '150',
    value: '150',
  },
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'O',
    value: 'O',
  },
  {
    label: 'XO',
    value: 'XO',
  },
  {
    label: '2XO',
    value: '2XO',
  },
  {
    label: '3XO',
    value: '3XO',
  },
];

export const convertToNDigits = (
  num?: number,
  digits: number = 6,
  preceedingNum: string = '0'
): string => {
  const paddedNum = num?.toString().padStart(digits, preceedingNum);
  return paddedNum ?? '-';
};
