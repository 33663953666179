import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { home, person } from 'ionicons/icons';
import { IonIcon, IonLabel } from '@ionic/react';

import { mobileContext } from '../../../utils/MobileContext';
import { useMembershipExpiration } from '../../../hooks/useMembershipExpiration';

interface IFooterMenu {
  color: string;
}

const Wrapper = styled.div<any>`
  display: flex;
  background: #eeeeee;
  justify-content: space-between;
  padding: 0px 15px;
  padding-bottom: 5px;
  padding-top: 12px;
  border-radius: 35px;
  overflow: hidden;
  div {
    display: flex;
    width: 50px;
    flex-direction: column;
    ion-label {
      font-size: 8px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;

      font {
        font-size: 8px;
      }
    }
    ion-icon {
      align-self: center;
      font-size: 33px;
      fill: ${(props: any) => props.color};
      cursor: pointer;
    }
  }

  .disabled-whole,
  .disabled {
    position: relative;
    cursor: not-allowed;
    ::after {
      content: '';
      padding: 20px 0;
      position: absolute;
      top: -15px;
      left: -10px;
      width: 70px;
      height: 30px;
      background-color: #e2e1e1;
      opacity: 0.7;
    }
  }

  .disabled-whole {
    z-index: 2;

    ion-icon {
      cursor: not-allowed;
    }
    ::after {
      left: -15px;
      width: 76px;
    }
  }

  @media (max-width: 365px) {
    div {
      ion-icon {
        font-size: 27px;
      }
    }
  }

  @media (min-width: 450px) {
    width: ${(props) => props.isMobile && '400px'};
  }
`;

const FooterMenu: React.FC<IFooterMenu> = ({ color }) => {
  const { push } = useHistory();
  const { isMobile } = mobileContext();
  const { isExpired } = useMembershipExpiration();

  const goToDiscord = () => {
    window.open('https://discord.gg/XHkPfw4qFv', '_blank');
  };
  return (
    <Wrapper className="neomorphs-shadow-out" color={color} isMobile={isMobile}>
      {isExpired ? (
        <>
          <div className="disabled-whole">
            <div>
              <IonIcon icon={home} />
              <IonLabel>HOME</IonLabel>
            </div>
          </div>
          <div className="disabled-whole">
            <div>
              <IonIcon src="./assets/icon/vote-icon.svg" />
              <IonLabel>VOTE</IonLabel>
            </div>
          </div>
        </>
      ) : (
        <>
          <div onClick={() => push('/home')}>
            <IonIcon icon={home} />
            <IonLabel>HOME</IonLabel>
          </div>
          <div onClick={() => push('/voting-list?list=ongoingVote')}>
            {/* <div className="disabled"> */}
            <IonIcon src="./assets/icon/vote-icon.svg" />
            <IonLabel>VOTE</IonLabel>
          </div>
        </>
      )}
      <div onClick={() => push('/my-page')}>
        <IonIcon icon={person} />
        <IonLabel>MY PAGE</IonLabel>
      </div>

      <div onClick={() => push('/market')}>
        {/* <div className="disabled-whole"> */}
        <IonIcon src="./assets/icon/market-icon.svg" />
        <IonLabel>MARKET</IonLabel>
      </div>
      <div onClick={goToDiscord}>
        <IonIcon src="./assets/icon/discord-logo.svg" />
        <IonLabel>CHAT</IonLabel>
      </div>
    </Wrapper>
  );
};

export { FooterMenu };
