import React from 'react';
import styled from 'styled-components';

interface IValidUntilProps {
  value: string | Date;
  color?: string;
  label: string;
  valueIsDate?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .text-until {
    width: 100px;
    color: ${(props) => props.color};
    font-weight: 700;
    margin-right: 20px;
    @media (max-width: 405px) {
      margin-right: 0px;
    }
    @media (max-width: 382px) {
      margin-right: 10px;
    }
    @media (max-width: 333px) {
      margin-right: 5px;
    }
    line-height: 36px;
    p {
      font-size: 13px;
      color: ${(props) => props.color};
    }
  }
  .date-wrapper {
    p {
      color: #4d4d4d;
      font-weight: 700;
      span {
        font-size: 23px;

        @media (max-width: 352px) {
          font-size: 15px;
        }
        @media (max-width: 334px) {
          font-size: 14px;
        }
      }
    }
    sub {
      padding: 0 3px;
      @media (max-width: 383px) {
        padding: 0 1px;
      }
    }
  }
`;

const ValidUntil: React.FC<IValidUntilProps> = ({
  value,
  color = '#4d4d4d',
  label,
  valueIsDate,
}) => {
  const parseDate = (value: any) => {
    const month = value?.getUTCMonth() + 1;
    const day = value?.getUTCDate();
    const year = value?.getUTCFullYear();
    return { year: year || '', month: month || '', day: day || '' };
  };

  return (
    <Wrapper color={color}>
      <div className="text-until">
        <p>{label}</p>
      </div>
      <div className="date-wrapper">
        <p>
          {valueIsDate ? (
            <>
              <span>{parseDate(value).year}</span>
              <sub>年</sub>
              <span>{parseDate(value).month}</span>
              <sub>月</sub>
              <span>{parseDate(value).day}</span>
              <sub>日</sub>
            </>
          ) : (
            <>
              <span>{value}</span>
              <sub>円</sub>
            </>
          )}
        </p>
      </div>
    </Wrapper>
  );
};

export { ValidUntil };
