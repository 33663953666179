import styled from 'styled-components';
import { IonItem, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';

import { Toast } from '../Toast';
import { UNIFORM_SIZE } from '../../../utils';
import { useToast } from '../../../hooks/useToast';
import { Button, Divider, Loader } from '../../atoms';
import { UserContext } from '../../../utils/UserContext';
import { ImageGallery, Select } from '../../../components';
import { useGetUserGiftList } from '../../../hooks/useGetUserGIftList';
import { fetchUserGiftList, updateUserGiftInfo, postGift, fetchUserGift } from '../../../services';

const FormWrapper = styled.div<any>`
  border-radius: 50px;
  padding: 0 13px;
  padding-bottom: 0px;
  padding-top: 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  ion-select {
    padding: 4px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: white;
    box-shadow: none;
    border-radius: 4px;
    height: 21px;
  }

  ion-text {
    width: 13em;
  }

  .each-row {
    display: flex;
    padding: 0 15px;
    margin: 0px;
    .align-self-center {
      align-self: center;
    }
  }
  .title {
    width: 120px;
    padding-right: 10px;
    font-weight: 350;
    font-size: 13px;
    line-height: 19px;
  }
  .value {
    width: 75%;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    padding-left: 24px;
    span {
      font-size: 13px;
      margin-right: 10px;
    }

    @media (max-width: 385px) {
      .value {
        width: 150px;
        white-space: pre-line;
      }
    }
  }

  .back-btn {
    padding-bottom: 1.7em;
    display: flex;
    justify-content: center;
    ion-button {
      width: 140px;
    }
  }
`;
const EditButtonWrapper = styled.div`
  padding: 30px 0px 20px 0px;
  display: flex;
  justify-content: center;
  ion-button {
    width: 100%;
  }
`;
const InputWrapper = styled(IonItem)`
  font-size: 15px;
  min-height: var(--min-height);

  span {
    font-size: 13px !important;
    font-weight: 350;
    display: inline-block;
    width: 11em;
    margin-right: 1em;
  }

  input {
    padding: 4px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 5px;
    caret-color: black;
    border-radius: 4px;
    font-size: 13px;
    height: 2.5em;
    -webkit-appearance: none;
    border: none;
    background: #fff;
    outline: none;
    height: 21px;
    width: 95%;
    margin-left: 8px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    .validation-message {
      color: rgba(0, 0, 0, 0.5);
      font-size: 10px;
      font-weight: 400;
      margin: 3px 0 0;
      margin-left: 8px;
      line-height: 16px;
    }
  }
`;

interface Props {
  color?: string;
}

const OtherInformationData: React.FC<Props> = ({ color }) => {
  const { status, teamDetails, userProfileData } = useContext(UserContext);
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [showToast, setShowToast] = useToast(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState<string>('');
  const [disableButton, setDisableButton] = useState(true);
  const [formDetails, setFormDetails] = useState<any>({
    shirtSize: '',
    uniformSize: '',
    uniformNumber: '',
    uniformName: '',
  });
  const [temporaryDataSave, setTemporaryDataSave] = useState<any>({
    shirtSize: '',
    uniformSize: '',
    uniformNumber: '',
    uniformName: '',
  });
  const [tshirtId, setTshirtID] = useState<string>('');
  const [uniformId, setUniformID] = useState<string>('');

  const {
    data: userGiftData,
    isLoading: loadingUserGiftList,
    refetch,
  } = useQuery(['getUserGiftList', userProfileData?.id, teamDetails?.team?.id], fetchUserGiftList, {
    enabled: teamDetails?.team?.id ? true : false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
  });

  const { isLoading: loadingGiftSize } = useQuery(
    ['userGiftSize', userGiftData?.data?.gifts![0]?.id],
    fetchUserGift,
    {
      enabled: userGiftData?.data?.gifts![0]?.id !== undefined ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess(res) {
        if (
          status.statusNameEnglish !== 'RED' &&
          status.statusNameEnglish !== 'SILVER' &&
          status.statusNameEnglish !== 'GOLD'
        ) {
          if (res?.data?.choice?.includes('number')) {
            setUniformID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNumber: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
            setTemporaryDataSave((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNumber: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
          } else {
            setTshirtID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
            setTemporaryDataSave((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
          }
        } else if (status.statusNameEnglish === 'GOLD') {
          setTshirtID(res?.data?.id);
          setFormDetails((prevState: any) => {
            return {
              ...prevState,
              shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
            };
          });
          setTemporaryDataSave((prevState: any) => {
            return {
              ...prevState,
              shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
            };
          });
        }
      },
    }
  );

  const { isLoading: loadingGiftForm } = useQuery(
    [
      'userGiftForm',
      userGiftData?.data?.gifts![1]?.id !== undefined ? userGiftData?.data?.gifts![1].id : 0,
    ],
    fetchUserGift,
    {
      enabled: userGiftData?.data?.gifts![1]?.id !== undefined ? true : false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      onSuccess(res) {
        if (
          status.statusNameEnglish !== 'RED' &&
          status.statusNameEnglish !== 'SILVER' &&
          status.statusNameEnglish !== 'GOLD'
        ) {
          if (res?.data?.choice?.includes('number')) {
            setUniformID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNumber: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
            setTemporaryDataSave((prevState: any) => {
              return {
                ...prevState,
                uniformSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
                uniformNumber: res?.data?.choice ? JSON.parse(res?.data?.choice)?.number : '-',
                uniformName: res?.data?.choice ? JSON.parse(res?.data?.choice)?.name : '-',
              };
            });
          } else {
            setTshirtID(res?.data?.id);
            setFormDetails((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
            setTemporaryDataSave((prevState: any) => {
              return {
                ...prevState,
                shirtSize: res?.data?.choice ? JSON.parse(res?.data?.choice)?.size : '-',
              };
            });
          }
        }
      },
    }
  );

  const changeValues = (name: string, value: string) => {
    setFormDetails((formDetails: any) => ({
      ...formDetails,
      [name]: value,
    }));
  };

  // For POST API (this is for already existed user who will not go through first form during initial login)
  const { masterId } = useGetUserGiftList(
    teamDetails?.team?.id,
    userProfileData?.id && userGiftData?.data?.gifts?.length === 0 ? true : false
  );

  const { mutate: postGiftMutate, isLoading: postGiftLoading } = useMutation(postGift, {
    onSuccess: async () => {
      if (status?.statusNameEnglish === 'GOLD') {
        refetch();
        history.push({ pathname: `/settings` });
      } else {
        if (formDetails?.uniformName === '') {
          postUniformMutate({
            masterId: masterId?.uniformMasterId ?? '',
            userId: userProfileData?.id,
            choice: {
              size: formDetails?.uniformSize,
              number: formDetails?.uniformNumber,
            },
          });
        } else {
          postUniformMutate({
            masterId: masterId?.uniformMasterId ?? '',
            userId: userProfileData?.id,
            choice: {
              size: formDetails?.uniformSize,
              number: formDetails?.uniformNumber,
              name: formDetails?.uniformName,
            },
          });
        }
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  const { mutate: postUniformMutate, isLoading: postUniformLoading } = useMutation(postGift, {
    onSuccess: async () => {
      refetch();
      history.push({ pathname: `/settings` });
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message ?? t('Something went wrong. Please try again.'));
      setShowToast(true);
    },
  });

  // For Update PUT API
  const { mutate: uniformGiftMutation, isLoading: uniformGiftloading } = useMutation(
    updateUserGiftInfo,
    {
      onSuccess: () => {
        history.push({ pathname: `/settings` });
      },
      onError: (err: any) => {
        setError(err?.response?.data?.message || `${t('Something went wrong. Please try again.')}`);
        setShowToast(true);
      },
    }
  );
  const { mutate: giftMutation, isLoading } = useMutation(updateUserGiftInfo, {
    onSuccess: () => {
      if (status?.statusNameEnglish === 'GOLD') {
        history.push({ pathname: `/settings` });
      } else if (
        status?.statusNameEnglish !== 'RED' &&
        status?.statusNameEnglish !== 'SILVER' &&
        status?.statusNameEnglish !== 'GOLD'
      ) {
        if (formDetails?.uniformName === '') {
          uniformGiftMutation({
            id: uniformId,
            userId: userProfileData?.id,
            choice: {
              size: formDetails?.uniformSize,
              number: formDetails?.uniformNumber,
            },
          });
        } else {
          uniformGiftMutation({
            id: uniformId,
            userId: userProfileData?.id,
            choice: {
              size: formDetails?.uniformSize,
              number: formDetails?.uniformNumber,
              name: formDetails?.uniformName,
            },
          });
        }
      }
    },
    onError: (err: any) => {
      setError(err?.response?.data?.message || `${t('Something went wrong. Please try again.')}`);
      setShowToast(true);
    },
  });

  const submitForm = async () => {
    if (userGiftData?.data?.gifts?.length === 0) {
      postGiftMutate({
        masterId: masterId?.tshirtMasterId ?? '',
        userId: userProfileData?.id,
        choice: {
          size: formDetails?.shirtSize,
        },
      });
    } else {
      giftMutation({
        id: tshirtId,
        userId: userProfileData?.id,
        choice: {
          size: formDetails?.shirtSize,
        },
      });
    }
  };

  const renderTshirtSizeField = () => {
    if (status?.statusNameEnglish !== 'RED' && status?.statusNameEnglish !== 'SILVER') {
      return (
        <>
          {edit ? (
            <>
              <Select
                label={t('T-shirt size')}
                name="shirtSize"
                value={formDetails.shirtSize}
                options={UNIFORM_SIZE}
                onChange={(e) => {
                  changeValues('shirtSize', e?.target?.value);
                }}
              />
              {status?.statusNameEnglish === 'GOLD' && (
                <div className="preview-link" style={{ paddingBottom: '7px', marginLeft: '13px' }}>
                  <ImageGallery
                    textOpener="＊Click here for size info"
                    IMAGE_URL="/assets/images/uniform-size.png"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="each-row">
                <p className="title">{t('T-shirt size')}</p>
                <p className="value">{formDetails?.shirtSize}</p>
              </div>
            </>
          )}
          <Divider />
        </>
      );
    }
  };

  const renderOtherField = () => {
    if (
      status?.statusNameEnglish !== 'RED' &&
      status?.statusNameEnglish !== 'SILVER' &&
      status?.statusNameEnglish !== 'GOLD'
    ) {
      return (
        <>
          {edit ? (
            <>
              <Select
                label={t('Uniform size')}
                name="uniformSize"
                value={formDetails?.uniformSize}
                options={UNIFORM_SIZE}
                onChange={(e) => {
                  changeValues('uniformSize', e?.target?.value);
                }}
              />
              <div className="preview-link" style={{ paddingBottom: '7px', marginLeft: '13px' }}>
                <ImageGallery
                  textOpener="＊Click here for size info"
                  IMAGE_URL="/assets/images/uniform-size.png"
                />
              </div>
              <Divider />
              <InputWrapper lines="none">
                <span>{t('Uniform no')}</span>
                <div className="input-wrapper">
                  <input
                    placeholder=""
                    maxLength={20}
                    name="uniformNumber"
                    value={formDetails?.uniformNumber}
                    autoComplete="off"
                    onChange={(e) => {
                      if (/^(?:\d{0,2}|123)$/.test(e?.target?.value)) {
                        changeValues('uniformNumber', e?.target?.value);
                      }
                    }}
                  />
                  <p className="validation-message">{t('*Enter numbers from 0-99 & 123.')}</p>
                </div>
              </InputWrapper>
              <Divider />
              <InputWrapper lines="none">
                <span>{t('Uniform name')}</span>
                <div className="input-wrapper">
                  <input
                    placeholder=""
                    maxLength={20}
                    name="uniformName"
                    value={formDetails?.uniformName}
                    autoComplete="off"
                    onChange={(e) => {
                      if (e?.target?.value?.length <= 15) {
                        changeValues('uniformName', e?.target?.value.replace(/[^A-Z\s]/g, ''));
                      }
                    }}
                    onKeyDown={(e: any) => {
                      if (!/[A-Z ]$/i.test(e.key)) e.preventDefault();
                    }}
                  />
                  <p className="validation-message">
                    {t('*Capital alphabets only within 15 chars , Allow space')}
                  </p>
                </div>
              </InputWrapper>
            </>
          ) : (
            <>
              <div className="each-row">
                <p className="title">{t('Uniform size')}</p>
                <p className="value">
                  <span>{formDetails?.uniformSize}</span>
                </p>
              </div>
              <Divider />
              <div className="each-row">
                <p className="title">{t('Uniform no')}</p>
                <p className="value">{formDetails?.uniformNumber}</p>
              </div>
              <Divider />
              <div className="each-row">
                <p className="title">{t('Uniform name')}</p>
                <p className="value">{formDetails?.uniformName}</p>
              </div>
              <Divider />
            </>
          )}
        </>
      );
    }
  };

  const dataResetBackButtonHandler = () => {
    history.push('/other-information');
    setFormDetails(temporaryDataSave);
  };

  useEffect(() => {
    const _isEditTrue =
      search?.split('?')[1]?.split('=')[0] === 'is-edit' &&
      search?.split('?')[1]?.split('=')[1] === 'true';
    if (_isEditTrue) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [search]);

  useEffect(() => {
    if (status?.statusNameEnglish === 'GOLD') {
      if (formDetails.shirtSize === '') {
        setDisableButton(true);
        return;
      }
    } else if (
      status?.statusNameEnglish !== 'RED' &&
      status?.statusNameEnglish !== 'SILVER' &&
      status?.statusNameEnglish !== 'GOLD'
    ) {
      if (
        formDetails?.uniformNumber === '' ||
        formDetails?.shirtSize === '' ||
        formDetails?.uniformSize === ''
      ) {
        setDisableButton(true);
        return;
      }
    }

    setDisableButton(false);
  }, [formDetails]);

  useIonViewDidEnter(() => {
    if (userProfileData?.id !== undefined && teamDetails?.team?.id !== undefined) {
      refetch();
    }
  }, []);

  return (
    <Loader
      className="home-loader"
      isLoading={loadingUserGiftList || loadingGiftForm || loadingGiftSize}
    >
      <FormWrapper className="neomorphs-shadow-in" color={color}>
        {showToast && <Toast message={error} type={'Error'} />}
        {renderTshirtSizeField()}
        {renderOtherField()}
        <EditButtonWrapper>
          <Button
            disabled={
              edit
                ? disableButton ||
                  isLoading ||
                  uniformGiftloading ||
                  postGiftLoading ||
                  postUniformLoading
                : isLoading || uniformGiftloading
            }
            size="large"
            color={color}
            onClick={() => {
              setEdit(true);
              if (edit === true) {
                submitForm();
              } else {
                history.push('/other-information?is-edit=true');
              }
            }}
          >
            {edit ? t('Confirm') : t('Edit Information')}
          </Button>
        </EditButtonWrapper>
        <div className="back-btn">
          {edit === true ? (
            <Button size="small" color={color} onClick={dataResetBackButtonHandler}>
              {t('Back')}
            </Button>
          ) : (
            <Button size="small" color={color} onClick={() => history.push('/settings')}>
              {t('Back to setting')}
            </Button>
          )}
        </div>
      </FormWrapper>
    </Loader>
  );
};

export { OtherInformationData };
