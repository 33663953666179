import { t } from 'i18next';
import moment from 'moment';
import styled from 'styled-components';
import { IonModal } from '@ionic/react';
import { useMutation } from 'react-query';
import React, { useContext, useState } from 'react';

import { Toast } from '../Toast';
import { useToast } from '../../../hooks/useToast';
import { updateMembership } from '../../../services';
import { UserContext } from '../../../utils/UserContext';
import { Button, Loader, ValidUntil } from '../../atoms';

interface IChargeAnnualFeeComponentProps {
  date: string | Date;
  fee: number;
  color?: string;
  statusId?: string;
  valueIsDate?: boolean;
  extendDate?: string;
  extendDateLoading: boolean;
}

const Wrapper = styled.div`
  border-radius: 50px;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 45px;
  .confirm-button-wrapper {
    margin-top: 25px;
  }
  @media (max-width: 383px) {
    padding: 30px 20px;
  }
`;

const IonModalComponent = styled(IonModal)`
  --width: 326px;
  --height: auto;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.2);

  .ion-delegate-host {
    background-color: #efefef;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px;

    p {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      margin-top: 5px;
    }
  }
`;

const CustomButton = styled(Button)`
  width: 265px;
  margin-bottom: 10px;
`;

const ChargeAnnualFeeComponent: React.FC<IChargeAnnualFeeComponentProps> = ({
  date,
  fee,
  statusId,
  color,
  valueIsDate,
  extendDate,
  extendDateLoading,
}) => {
  const { userProfileData } = useContext(UserContext);
  const [showToast, setShowToast] = useToast(false);

  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);

  // temporary disable
  const { mutate, isLoading } = useMutation(updateMembership, {
    onSuccess: ({ data }) => {
      window.open(data?.url, '_self');
    },
    onError: (err: string) => {
      setShowToast(true);
    },
  });

  const getExpiryDate = (date: any) => {
    // May need in future so commenting for time being
    /* let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    let c = new Date(year + 1, month - 3, day + 1);
    return new Date(c); */
    return new Date('2025-06-30');
  };

  // temporary disable
  const handleMembershipCheckout = async () => {
    mutate({
      userId: userProfileData.id,
      membershipId: statusId,
    });
  };

  const closeModal = () => {
    setShowMessageModal(false);
  };

  return (
    <Wrapper className="neomorphs-shadow-in">
      <IonModalComponent isOpen={showMessageModal} onDidDismiss={closeModal}>
        <div className="body">
          <p>
            年会費支払い後にステータスアップ <br />
            した場合は、 翌シーズンの特典は
            <br />
            ステータスアップ前の
            <br />
            特典となります。
            <br />
            年会費をチャージしますか？
            <br />
          </p>
          <CustomButton onClick={handleMembershipCheckout} disabled={isLoading}>
            {t('Yes')}
          </CustomButton>
          <CustomButton onClick={() => closeModal()}>{t('No')}</CustomButton>
        </div>
      </IonModalComponent>
      {showToast && <Toast message={t('Something went wrong. Please try again.')} type={'Error'} />}
      <Loader isLoading={extendDateLoading}>
        <div>
          <ValidUntil
            value={new Date(moment(extendDate, 'YYYYMMDD').format('YYYY-MM-DD'))}
            color={color}
            label={t('Exp. after charge')}
            valueIsDate={valueIsDate}
          />
        </div>
        <div>
          <ValidUntil value={String(fee)} label={t('Annual fee')} />
        </div>
      </Loader>
      <div className="confirm-button-wrapper">
        {date === '2025-06-30' ? (
          <Button color={color} size="large" disabled>
            決済する
          </Button>
        ) : (
          <Button
            color={color}
            size="large"
            // temporary disable
            onClick={() => setShowMessageModal(true)}
            disabled={isLoading}
            // disabled
          >
            決済する
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export { ChargeAnnualFeeComponent };
