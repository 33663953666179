import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import PrivateRoute from '../withPrivateRoute';
import { UserContext } from '../utils/UserContext';
import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { TitleComponent, LevelProgressBar, Button, Loader, TeamCardComponent } from '../components';

const ContentWrapper = styled.div`
  padding: 10px 35px 0 35px;

  .loader {
    margin-top: 50%;
  }

  .title {
    margin: 25px 0 35px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .back-btn {
      margin-top: 40px;
      width: 140px;
    }
  }
`;

const LevelUpSuccess = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { teamDetails, isLoading, totalTeamCoin } = useContext(UserContext);

  const RECEIVED_POINT = totalTeamCoin;
  const LEVEL = teamDetails?.level;
  const COLOR = teamDetails?.team?.teamColor;
  const TOTAL_POINTS = teamDetails?.requiredToken;

  const VALUE =
    (+RECEIVED_POINT / TOTAL_POINTS) * 100 >= 100 ? 100 : (+RECEIVED_POINT / TOTAL_POINTS) * 100;

  return (
    <Wrapper authenticated>
      <ContentWrapper>
        <Loader isLoading={isLoading} className="loader">
          <TeamCardComponent />
          <TitleComponent
            className="title"
            heading="Level Up!!"
            subHeading="Level up completed"
            title
            color={COLOR}
          />
          <LevelProgressBar
            level={LEVEL}
            value={VALUE}
            totalPoint={TOTAL_POINTS}
            receivedPoint={+RECEIVED_POINT}
            color={COLOR}
          />
          <div className="btn-wrapper">
            <Button
              size="small"
              className="back-btn"
              color={COLOR}
              onClick={() => history.push('/home')}
            >
              {t('Back')}
            </Button>
          </div>
        </Loader>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PrivateRoute(LevelUpSuccess);
