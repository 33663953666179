export const generateCropImage = async (imageSrc: string, croppedAreaPixels: any) => {
  try {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    return croppedImage;
  } catch (e) {
    console.error(e);
  }
};

export default function getCroppedImg(imageSrc: string, croppedAreaPixels: any) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const roundedRadius = croppedAreaPixels.width / 2; // Adjust the rounded corner radius as needed
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
      ctx.beginPath();
      ctx.moveTo(roundedRadius, 0);
      ctx.lineTo(canvas.width - roundedRadius, 0);
      ctx.quadraticCurveTo(canvas.width, 0, canvas.width, roundedRadius);
      ctx.lineTo(canvas.width, canvas.height - roundedRadius);
      ctx.quadraticCurveTo(
        canvas.width,
        canvas.height,
        canvas.width - roundedRadius,
        canvas.height
      );
      ctx.lineTo(roundedRadius, canvas.height);
      ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - roundedRadius);
      ctx.lineTo(0, roundedRadius);
      ctx.quadraticCurveTo(0, 0, roundedRadius, 0);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(
        image,
        croppedAreaPixels.x * scaleX,
        croppedAreaPixels.y * scaleY,
        croppedAreaPixels.width * scaleX,
        croppedAreaPixels.height * scaleY,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      // Extract type from dataURL src.
      const imgType = image?.src?.split(':')[1]?.split(';')[0];
      canvas.toBlob((blob: any) => {
        resolve(URL.createObjectURL(blob));
      }, imgType || 'image/png');
    });
    image.addEventListener('error', (error) => reject(error));
  });
}
