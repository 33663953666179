import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import { RankTitleComponent, Button } from '../../atoms';
import DetailModal from './DetailModal';
import { ImageGallery } from '../RankBenefitImage';

export interface ModalData {
  id: number;
  title: string;
  modalContent: string;
  modalTitle?: string;
}

interface Props {
  data: ModalData[];
}

const Wrapper = styled.div`
  margin: 35px 0px;
  border-radius: 50px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .detail-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 8px;

    p {
      color: #4d4d4d;
      font-weight: 700;
      font-size: 18px;
      white-space: pre-line;
    }

    .detail-btn {
      font-size: 12px;
      font-weight: 700;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      color: #b80629;
    }
  }

  .ticket-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    img {
      padding: 0px;
    }
    ion-img {
      padding: 0px;
    }

    .ticket-img-btn {
      width: 140px;
      color: #b80629;
      margin: 35px 0 15px;
    }
  }
`;

const TicketSection: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const onClickHandler = (id: number) => {
    const result = data?.filter((row: ModalData) => row?.id === id);
    setModalData(result[0]);
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      document.querySelector('ion-content')?.classList.add('no-scroll');
    } else {
      document.querySelector('ion-content')?.classList.remove('no-scroll');
    }
  }, [showModal]);

  return (
    <Wrapper className="neomorphs-shadow-in">
      <RankTitleComponent heading="Ticket" subHeading="チケット" />

      <DetailModal showModal={showModal} setShowModal={setShowModal} modalData={modalData} />

      {data?.map((row: ModalData) => {
        return (
          <div className="detail-wrapper" key={row?.id}>
            <p>{row?.title}</p>

            <Button className="detail-btn" onClick={() => onClickHandler(row?.id)}>
              {t('Details')}
            </Button>
          </div>
        );
      })}

      {/* <div className="ticket-image-wrapper">
        <Button className="ticket-img-btn" size="small" disabled>
          {t('所沢市民体育館')}
        </Button>
        <ImageGallery
          IMAGE_URL="/assets/images/rank-details/ticket_1.png"
          enableCaptionButtons
          buttonLink="http://www.city.tokorozawa.saitama.jp/shisetu/sportskoen/sports/simintaiikukan/index.html"
        />
        <Button className="ticket-img-btn" size="small" disabled>
          {t('浦和駒場体育館')}
        </Button>
        <ImageGallery
          IMAGE_URL="/assets/images/rank-details/ticket_2.png"
          enableCaptionButtons
          buttonLink="https://urawakomatai.com/access/"
        />
      </div> */}
    </Wrapper>
  );
};

export { TicketSection };
