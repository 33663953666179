import { IonIcon } from '@ionic/react';
import React from 'react';
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { arrowUp, arrowDown } from 'ionicons/icons';
import styled from 'styled-components';
export interface ITableInfo {
  [index: string]: string;
}

interface ITableProps {
  data: any;
  columns: any;
}

export interface ITableColumns {
  Header: string;
  accessor: string;
  disableSortBy?: string;
  Filter: () => null;
}

const StyledTable = styled.table`
  min-width: 100%;

  .header {
    position: sticky;
    z-index: 1;
    width: fit-content;
    top: 0;
    background-color: #eeeeee;
    ::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      border-bottom: 1px solid transparent;
      box-shadow: var(--shadow-down);
    }
    th {
      font-size: 13px;
      padding: 0px 15px 8px 15px;
      text-align: left;
      color: #4d4d4d;
    }
  }

  td {
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 350;
    line-height: 19px;
    max-width: 100px;
  }

  .t-row {
    box-shadow: 0px 3px 2px -2px #cec5c5;
    td {
      padding: 15px;
    }
  }
`;

export const Table: React.FC<ITableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <StyledTable {...getTableProps()}>
      <thead className="header">
        {headerGroups.map((headerGroup: any, index: number) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column: any) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column?.id}>
                {column.render('Header')}
                <div style={{ display: 'inline-block' }}>
                  {column?.canFilter ? column.render('Filter') : null}
                </div>
                <span>
                  {column?.isSorted ? (
                    column?.isSortedDesc ? (
                      <IonIcon icon={arrowUp} />
                    ) : (
                      <IonIcon icon={arrowDown} />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row?.id} className="t-row">
              {row.cells.map((cell: any, idx: number) => {
                return (
                  <td key={idx} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
