import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { alertCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';

type ToastType = 'Error' | 'Success';

interface IToastProps {
  message: string;
  type: ToastType;
}

const Wrapper = styled.div<any>`
  position: fixed;
  top: 43px;
  width: 350px;
  left: calc(50% - 175px);

  @media (max-width: 378px) {
    width: 300px;
    left: calc(50% - 150px);
  }
  right: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  border-radius: 14px;
  border: 1px solid ${(props) => (props.type === 'Error' ? 'rgba(184, 6, 41, 0.6)' : '#03a832')};
  padding: 0px 16px;
  background: ${(props) => (props.type === 'Error' ? '#FDF1F1' : '#f1fdf1')};
  .icon {
    min-width: 35px;
    font-size: 35px;
    color: ${(props) => (props.type === 'Error' ? '#b80629' : '#06b862')};
    margin-right: 10px;
    font-weight: 300;
  }
  p {
    max-width: calc(100% - 35px);
    font-size: 14px !important;
    font-weight: 300 !important;
  }
`;

const Toast: React.FC<IToastProps> = ({ message, type }) => {
  const renderIcon = (type: ToastType) => {
    if (type === 'Error') {
      return <IonIcon icon={alertCircleOutline} className="icon" />;
    }
    if (type === 'Success') {
      return <IonIcon icon={checkmarkCircleOutline} className="icon" />;
    }
  };
  return (
    <Wrapper type={type}>
      {renderIcon(type)}
      <p dangerouslySetInnerHTML={{ __html: `${message}` }} />
    </Wrapper>
  );
};

export { Toast };
