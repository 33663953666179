import React from 'react';
import styled from 'styled-components';

import { Divider } from '../../atoms';
import DownTriangle from '../../atoms/DownTriangle';

const Wrapper = styled.div`
  padding-bottom: 15px;

  .header {
    text-align: center;

    .heading {
      font-size: 28px;
      font-weight: 900;
      line-height: 40px;
      color: #4d4d4d;
    }
  }

  .wrap {
    display: flex;
    padding: 10px 0px 0px 10px;
    margin-bottom: 10px;

    .count {
      height: 46px;
      min-width: 46px;
      border-radius: 50%;
      box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
        inset 3px 5px 9px -7px #f9e6e6;
      justify-content: center;
      display: flex;
      padding: 9px;
      color: #29c8d2;
      font-weight: 900;
      font-size: 31px;
      margin: 5px 10px 0 0;
    }
  }
  .inner-title {
    color: #4d4d4d;
    padding: 0 10px;
    .list-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
    }
  }
  .inner-para {
    color: #4d4d4d;
    padding: 0px 10px;
    .list-para {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      padding-bottom: 10px;
      margin: 0;
    }
  }
  .arrow-wrap {
    display: flex;
    justify-content: center;
  }
`;

const USELIST = [
  {
    id: 1,
    title: '新規メンバーシップ登録を<br />する',
    description: `メンバーシップNFTの購入には、<br />会員登録が必要になります。<br />Upbond Walletのログインシステムから <br />ご登録ください。`,
  },
  {
    id: 2,
    title: '好きなチームを選択する',
    description:
      'チームによって、得られる特典・サービス内容が <br />異なりますので、各チームの詳細をご確認ください',
  },
  {
    id: 3,
    title: 'チームのメンバーシップ<br />ステータスを選択する',
    description: 'ステータスによって特典・ポイント還元率が <br />異なるので、詳細をご確認ください',
  },
  {
    id: 4,
    title: 'メンバーシップNFTの<br />購入のお手続き・お支払い',
    description:
      'チーム・メンバーシップステータスを選択して、<br />入会金・年会費をご確認の上、決済お手続きに <br />お進みください',
  },
  {
    id: 5,
    title: 'Faniqueサービスを楽しむ',
    description: 'メンバーシップNFTが発行されると、<br />Faniqueサービスが使えるようになります。',
  },
];

export const CardInformation = () => {
  const step1ref: any = React.useRef(null);
  const step2ref: any = React.useRef(null);
  const step3ref: any = React.useRef(null);
  const step4ref: any = React.useRef(null);
  const step5ref: any = React.useRef(null);
  const stepRefs = [step1ref, step2ref, step3ref, step4ref, step5ref];

  const handleClick = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className="neomorphs-shadow-in card-wrapper">
      <Wrapper>
        <div className="header">
          <p className="heading">
            メンバーシップNFT <br />
            購入までの流れ
          </p>
        </div>

        <Divider />

        {USELIST.map((list, index) => (
          <div key={list.id} ref={stepRefs[index]}>
            <div className="wrap">
              <p className="count">{list.id}</p>
              <div className="inner-title">
                <p
                  className="list-title"
                  dangerouslySetInnerHTML={{
                    __html: list.title || '',
                  }}
                />
              </div>
            </div>
            <div className="inner-para">
              <p
                className="list-para"
                dangerouslySetInnerHTML={{
                  __html: list.description || '',
                }}
              />
            </div>
            {index === USELIST?.length - 1 ? null : (
              <div className="arrow-wrap">
                <DownTriangle handleClick={() => handleClick(stepRefs[index + 1])} />
              </div>
            )}
          </div>
        ))}
      </Wrapper>
    </div>
  );
};
