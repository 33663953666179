import React from 'react';
import styled from 'styled-components';
import { Divider } from '../Divider';

interface Props {
  value?: number;
  color?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .progressbar-container {
    width: 100%;
    margin: 0em 0.5em;
  }
  .progress-bar {
    position: relative;
  }
  .progress {
    position: absolute;
    top: -0.2em;
    background-color: ${(props: Props) => props.color};
    cursor: pointer;
    height: 0.5em;
    width: ${(props: Props) => (props.value ? props.value + '%' : '0%')};
    display: flex;
    flex-direction: row-reverse;
  }
  .thumb {
    position: absolute;
    right: -1em;
    top: -0.46em;
    width: 1.3em;
    height: 1.3em;
    background-color: ${(props: Props) => props.color};
    border: none;
    border-radius: 50%;
    p {
      position: absolute;
    }
  }
`;

const Percentage = styled.div`
  margin-left: 20px;
  margin-top: -5px;
  font-weight: 700;
  color: #4d4d4d;
  p {
    font-size: 25px;
  }
`;

export const ProgressBar: React.FC<Props> = ({ value, color = '#4D4D4D' }) => {
  return (
    <Wrapper value={value} color={color}>
      <div className="progressbar-container">
        <div className="progress-bar">
          <Divider className="divider1 track" />
          <div className="progress">
            <div className="thumb"></div>
          </div>
        </div>
      </div>
      <Percentage>
        <p>
          {value}
          <span>%</span>
        </p>
      </Percentage>
    </Wrapper>
  );
};
