import styled from 'styled-components';
import { useHistory } from 'react-router';
import React, { useRef, useState } from 'react';
import { IonSlides, IonSlide } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

import { TeamCard } from '../TeamCard';
import { useTranslation } from 'react-i18next';
import { Button, Loader, TitleComponent } from '../../atoms';

interface dataType {
  id: string;
  image: string;
  name: string;
  subHeading: string;
}
interface Props {
  data:
    | {
        teams: dataType[];
        total: number;
      }
    | undefined;
  isLoading?: boolean;
}

const ComponentWrapper = styled.div`
  .container {
    width: 100%;
    position: relative;
    margin-bottom: 1.9rem;
  }
  .section-header {
    margin-bottom: 1.9rem;
  }
  .team-select-button {
    margin: 1.9rem 0;
  }
`;

const Wrapper = styled.div<any>`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-img {
    width: 100%;
  }

  .title {
    margin-top: ${(props) => (props.subHead ? '15px' : '25px')};
    margin-bottom: ${(props) => !props.subHead && '10px'};
  }
`;

const Bottom = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 20;

  .button {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 380px) {
    .button {
      width: 40px;
      height: 40px;
    }
  }
`;

const SLIDE_OPTS = {
  initialSlide: 0,
  speed: 400,
  spaceBetween: 40,
};

export const TeamSelect: React.FC<Props> = ({ data, isLoading = false }) => {
  const mySlides = useRef<null | any>(null);
  const { t } = useTranslation();
  const history = useHistory();

  const [disablePrevBtn, setDisablePrevBtn] = useState(true);
  const [disableNextBtn, setDisableNextBtn] = useState(false);

  const handleSlideChange = async () => {
    const swiper = await mySlides.current.getSwiper();
    setDisablePrevBtn(swiper.isBeginning);
    setDisableNextBtn(swiper.isEnd);
  };

  const onButtonClick = async (direction: string) => {
    const swiper = await mySlides.current.getSwiper();

    if (direction === 'next') {
      swiper.slideNext();
    } else if (direction === 'prev') {
      swiper.slidePrev();
    }
  };

  return (
    <ComponentWrapper>
      <div className="container">
        <TitleComponent
          heading="Select Team"
          subHeading={t('Select the team to purchase memberships for')}
          className="section-header"
          title={true}
        />
        <Loader isLoading={isLoading}>
          {data && (
            <IonSlides
              pager={false}
              options={SLIDE_OPTS}
              ref={mySlides}
              onIonSlideDidChange={handleSlideChange}
            >
              {data?.teams.map((team: any) => {
                return (
                  <IonSlide key={team?.name}>
                    <Wrapper subHead={team?.subHeading}>
                      <TeamCard
                        team={team}
                        nextRoute={`/team-list/${team.id}`}
                        showShadow
                        smallWidth
                      />
                    </Wrapper>
                  </IonSlide>
                );
              })}
            </IonSlides>
          )}
        </Loader>
        {data && data?.total !== 1 && (
          <Bottom>
            <Button
              onClick={() => onButtonClick('prev')}
              slot="icon-only"
              icon={chevronBackOutline}
              className="button"
              disabled={disablePrevBtn}
            />
            <Button
              onClick={() => onButtonClick('next')}
              slot="icon-only"
              icon={chevronForwardOutline}
              className="button"
              disabled={disableNextBtn}
            />
          </Bottom>
        )}
      </div>
      {data && data?.total !== 1 && (
        <Button
          size="large"
          className="team-select-button"
          onClick={() => history.push('/team-list')}
        >
          {t('See all teams')}
        </Button>
      )}
    </ComponentWrapper>
  );
};
