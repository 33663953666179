import { IonImg } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

import { TitleComponent } from '../../atoms';

interface Props {
  cardName: 'Diamond' | 'Platinum' | 'Gold' | 'Silver' | 'Red';
  src: string;
}

const differentRanks: any = {
  diamond: '#000000',
  platinum: '#666666',
  gold: '#A67C52',
  silver: '#999999',
  red: '#F00F00',
};

const Wrapper = styled.div<{ cardName: string }>`
  .name {
    width: 280px;
    margin: 25px auto;
    background-color: ${(props: any) => differentRanks[`${props.cardName.toLowerCase()}`]};
    height: 50px;
    border-radius: 34px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 26px;
      font-weight: 800;
    }
  }

  .image-section {
    padding: 0px 32px;

    p {
      font-weight: 400;
      font-size: 15px;
      color: #4d4d4d;
      text-align: center;
    }
  }
`;

const TopSection: React.FC<Props> = ({ cardName, src }) => {
  return (
    <Wrapper cardName={cardName}>
      <TitleComponent heading="Saitama BRONCOS" subHeading="さいたまブロンコス" title />

      <div className="name">
        <span>{cardName}</span>
      </div>

      <div className="image-section">
        <p>会員証ステータス</p>
        <IonImg src={src} />
      </div>
    </Wrapper>
  );
};

export { TopSection };
