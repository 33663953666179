import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../atoms';

const Wrapper = styled.div`
  padding-bottom: 15px;

  .header {
    text-align: center;

    .heading {
      font-size: 28px;
      font-weight: 900;
      line-height: 40px;
      color: #4d4d4d;
    }
  }

  .list-section {
    padding: 10px 0px 15px 0px;
  }

  .wrap {
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px 10px;
    margin-bottom: 10px;
  }
  .inner-title {
    color: #4d4d4d;
    padding: 0 10px;
    .list-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      margin: 0;
    }
  }
  .inner-para {
    color: #4d4d4d;
    padding: 0px 10px;
    .list-para {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      padding-bottom: 10px;
      margin: 0;
    }
  }
  .arrow-wrap {
    display: flex;
    justify-content: center;
  }
  .highlight-heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #29c8d2;
    padding: 15px 0px 10px;
    margin: 0;
  }
  .wrap-image {
    display: flex;
    justify-content: center;
  }
  .w-75 {
    width: 75% !important;
  }
  .image-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 10px;

    .image {
      width: 100%;
      object-fit: cover;
    }
  }
  .p-l {
    padding: 20px 30px !important;
  }
`;

export const LevelInformation = React.forwardRef((_, ref: any) => {
  return (
    <div className="neomorphs-shadow-in card-wrapper">
      <Wrapper>
        <div className="header">
          <p className="heading" ref={ref}>
            レベル・ステータス <br />
            とは？
          </p>
        </div>
        <div className="inner-para">
          <p className="list-para">
            Faniqueは”Support and Earn” をコンセプトにした
            <br />
            ファンがスポーツチームの運営に参加できるサービス<br />
            です。メンバーシップNFTを保有するファンが
            <br />
            チームをSupportすることでコインを獲得ができ、
            <br />
            メンバーシップを育ててメンバーシップステータス
            <br />
            をアップさせることができます。
          </p>
        </div>

        <Divider />

        {/* 1st Section */}
        <div className="list-section">
          <p className="highlight-heading">レベルアップについて</p>
          <div className="wrap">
            <div className="inner-title">
              <p className="list-title">応援してコイン獲得</p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              ホームゲーム開催日にファンクラブブースにて
              <br />
              メンバーシップをスタッフに提示してコインを
              <br />
              貯めることができます。
              <br />
              グッズ・NFTの購入でもコインを獲得できます。
            </p>
          </div>
          <div className="wrap-image">
            <div className="image-wrapper w-75">
              <img src={'/assets/images/how-to-use/howToMobileClick.png'} className="image" />
            </div>
          </div>
          <div className="image-wrapper">
            <img src={'/assets/images/how-to-use/howToGetCoins.png'} className="image" />
          </div>
          <div className="wrap">
            <div className="inner-title">
              <p className="list-title">コイン貯めてレベルアップ</p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              コインを一定量獲得すると、
              <br />
              メンバーシップがレベルアップします
            </p>
          </div>
          <div className="image-wrapper p-l">
            <img
              src={'/assets/images/how-to-use/howToLevelUpCard.png'}
              className="image drop-shadow"
            />
          </div>
        </div>
        <Divider />
        <div className="list-section">
          <p className="highlight-heading">ステータスアップについて</p>
          <div className="wrap">
            <div className="inner-title">
              <p className="list-title">
                規定レベルに到達すると
                <br />
                ステータスアップ
              </p>
            </div>
          </div>
          <div className="inner-para">
            <p className="list-para">
              規定レベルになると、ステータスを
              <br />
              アップグレードすることができます。
              <br />
              ステータスが上がると、受けれる特典なども
              <br />
              ランクアップ
            </p>
          </div>
          <div className="image-wrapper p-l">
            <img
              src={'/assets/images/how-to-use/howToStatusUpCard.png'}
              className="image drop-shadow"
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
});
