import React, { useRef } from 'react';
import styled from 'styled-components';

import Concept from './Concept';
import Feature from './Feature';
import Structure from './Structure';
import TopSection from './TopSection';
import MainFeatures from './MainFeatures';
import SupportAndEarn from './SupportAndEarn';

const HomeWrapper = styled.div`
  text-align: center;
  margin: 0 22px;

  .top-divider {
    margin: 1.7em 0.5em;
  }

  .inner-header {
    font-weight: 900;
    font-size: 28px;
    color: #4d4d4d;
    margin: 8px 0px;
    margin-bottom: 18px;
  }
  .context {
    font-weight: 400;
    font-size: 15px;
    color: #4d4d4d;
    padding: 0px 5px;
    margin-bottom: 28px;
  }

  .section-divider {
    margin-top: 2.6rem;
  }
  .title {
    margin-top: -0.8em;
    margin-bottom: 5px;
  }
  .header {
    margin-top: 0.5em;
    font-weight: 350;
    font-size: 15px;
  }

  .top-section {
    margin: 25px 0px;
    color: #4d4d4d;
    p {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 28px;
    }
  }

  .triangle-down {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 32px solid #29c8d2;
    filter: drop-shadow(2px 2px 2px #cccccc) drop-shadow(-4px -2px 1px #ffffff) blur(0.5px);
    margin: 25px 0;
    cursor: pointer;
  }

  .concept {
    padding: 25px 16px;
    border-radius: 3rem;
    margin-bottom: 32px;
  }

  .structure {
    .divider {
      margin: 1.7em 0.5em;
    }

    .benefit-image {
      cursor: pointer;
    }
  }

  .back-button {
    margin: 2em 1em;
  }
  .divider {
    margin: 1.7em 0.5em;
  }

  .feature {
    padding: 25px 25px;

    .divider {
      margin: 1.7em 0em;
    }
  }

  .support-earn {
    padding: 25px 10px;

    .divider {
      margin: 1.7em 0.5em;
    }
  }

  .list-container {
    .button-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      .button {
        white-space: pre;
        width: 200px;
      }
    }
  }

  .list-wrapper {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 33px;

    .support-image {
      width: 250px;
    }

    .title-container {
      margin-bottom: 16px;
    }

    .title-wrapper {
      display: flex;
      align-items: flex-start;

      .count {
        min-height: 43px;
        min-width: 43px;
        border-radius: 50%;
        box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
          inset 3px 5px 9px -7px #f9e6e6;
        justify-content: center;
        display: flex;
        padding: 9px;
        color: #29c8d2;
        font-weight: 900;
        font-size: 25px;
        margin-right: 10px;
        background-color: #eee;
      }

      .header {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-top: 0px;
        color: #4d4d4d;
        text-align: left;
        margin-left: 10px;
      }
    }
    .detail {
      font-weight: 400;
      font-size: 12px;
      color: #4d4d4d;
      text-align: left;
    }
    .img-centered-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const AboutPage = () => {
  const supportRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    supportRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <HomeWrapper>
      <TopSection />
      <Concept />
      <Structure />
      <Feature handleClick={handleClick} />
      <SupportAndEarn ref={supportRef} />
      <MainFeatures />
    </HomeWrapper>
  );
};

export { AboutPage };
