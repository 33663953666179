import React, { FC } from 'react';
import styled from 'styled-components';

interface IRadioSelect {
  selected: boolean;
  handleSelect: () => void;
  color?: string;
}

const Wrapper = styled.div<any>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-self: center;
  position: relative;
  margin-right: 20px;
  .selected {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 10px;
    height: 10px;
    background: ${(props) => props.color};
    border-radius: 50%;

    ::after {
      content: ' ';
    }
  }
`;

const RadioSelect: FC<IRadioSelect> = ({ selected, handleSelect, color = 'black' }) => {
  return (
    <Wrapper className="neomorphs-shadow-in" onClick={handleSelect} color={color}>
      {selected ? <div className="selected" /> : <></>}
    </Wrapper>
  );
};

export { RadioSelect };
