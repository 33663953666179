import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../../../components';

interface IPaginationProps {
  currentPage: number;
  setCurrentPage: any;
  totalPages: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  ion-button {
    width: 35px;
    height: 35px;
    font-size: 15px;
  }
`;

const Pagination: React.FC<IPaginationProps> = ({
  currentPage = 1,
  setCurrentPage,
  totalPages,
}) => {
  const [pageIndex, setPageIndex] = useState({
    firstPage: currentPage,
    secondPage: currentPage + 1,
    thirdPage: currentPage + 2,
  });

  const handlePageChange = (page: number) => {
    document.getElementById('title')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    if (page <= +totalPages && page >= 1) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    if (currentPage === +totalPages - 1) {
      setPageIndex({
        firstPage: currentPage - 1,
        secondPage: currentPage,
        thirdPage: currentPage + 1,
      });
      return;
    }
    if (currentPage === +totalPages) {
      setPageIndex({
        firstPage: currentPage - 2,
        secondPage: currentPage - 1,
        thirdPage: currentPage,
      });
      return;
    }
    setPageIndex({
      firstPage: currentPage,
      secondPage: currentPage + 1,
      thirdPage: currentPage + 2,
    });
  }, [currentPage, totalPages]);

  const shouldDisableButton = useCallback(
    (pageNo: number): boolean => {
      if (currentPage === pageNo) {
        return true;
      }
      return false;
    },
    [currentPage, +totalPages]
  );

  if ((totalPages && +totalPages === 1) || +totalPages === 0) return null;
  return (
    <Wrapper>
      <Button
        size="small"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        color={currentPage === 1 ? '#CCCCCC' : ''}
      >
        前
      </Button>
      {pageIndex.firstPage > 0 && (
        <Button
          size="small"
          onClick={() => handlePageChange(pageIndex?.firstPage)}
          disabled={shouldDisableButton(pageIndex?.firstPage)}
          color={shouldDisableButton(pageIndex?.firstPage) ? '#CCCCCC' : ''}
        >
          {pageIndex.firstPage}
        </Button>
      )}
      {pageIndex.secondPage > 0 && (
        <Button
          size="small"
          onClick={() => handlePageChange(pageIndex?.secondPage)}
          disabled={shouldDisableButton(pageIndex?.secondPage)}
          color={shouldDisableButton(pageIndex?.secondPage) ? '#CCCCCC' : ''}
        >
          {pageIndex.secondPage}
        </Button>
      )}
      {pageIndex.thirdPage <= +totalPages && (
        <Button
          size="small"
          onClick={() => handlePageChange(pageIndex?.thirdPage)}
          disabled={shouldDisableButton(pageIndex?.thirdPage)}
          color={shouldDisableButton(pageIndex?.thirdPage) ? '#CCCCCC' : ''}
        >
          {pageIndex.thirdPage}
        </Button>
      )}
      <Button
        size="small"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === +totalPages}
        color={currentPage === +totalPages ? '#CCCCCC' : ''}
      >
        次
      </Button>
    </Wrapper>
  );
};

export { Pagination };
