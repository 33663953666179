import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useIonViewDidLeave } from '@ionic/react';

interface Props {
  src: string;
  color?: string;
}

const Wrapper = styled.div`
  position: relative;
  video {
    background: ${(props) => props.color};
  }
  .video-cover {
    width: 100%;
    height: auto;
  }
  .controls {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  .play-btn,
  .pause-btn {
    height: 40px;
    width: 40px;
    background-color: white;
    border-radius: 50%;
  }
  .play-btn::after,
  .pause-btn::after {
    position: absolute;
    content: '';
  }
  .play-btn::after {
    width: 0;
    height: 0;
    left: 15px;
    top: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid ${(props) => props.color};
  }
  .pause-btn::after {
    width: 0;
    height: 0;
    top: 11px;
    left: 11px;
    height: 17px;
    border-style: double;
    border-width: 0 0 0 17px;
    border-color: ${(props) => props.color};
  }
`;

export const VideoCover: React.FC<Props> = ({ src = '', color = 'red' }) => {
  const ref: any = useRef(null);
  const [isPlay, setPlayPause] = useState(true);
  const [isMute, setMute] = useState(true);

  useIonViewDidLeave(() => {
    setPlayPause(false);
    setMute(true);
  });

  const handlePlayPauseClick = () => {
    setPlayPause((prev) => {
      if (prev) ref.current.pause();
      else ref.current.play();
      return !prev;
    });
    setMute(false);
  };

  return (
    <Wrapper color={color}>
      <video autoPlay className="video-cover" muted={isMute} loop={true} ref={ref}>
        <source src={src} type="video/mp4" />
      </video>
      <div className="controls" onClick={handlePlayPauseClick}>
        {isPlay ? <div className="pause-btn" /> : <div className="play-btn" />}
      </div>
    </Wrapper>
  );
};
