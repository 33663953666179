import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import Wrapper from '../components/organisms/Wrapper/Wrapper';
import { Button, Divider, TitleComponent } from '../components';
import { AuthContext } from '../utils/AuthContext';
import RestrictedRoute from '../withRestrictedRoute';

const ContentWrapper = styled.div`
  padding: 5px 20px;
  .description {
    padding: 0px 4px;
    text-align: center;
    p {
      font-size: 14px;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;

    .route-btn {
      margin: 35px 0 25px 0;
      width: 140px;
    }
  }

  .inner-div {
    padding: 1px 14px;
    padding-top: 34px;
    border-radius: 50px;

    .title {
      padding: 0 65px;
    }

    .detail-info {
      margin: 40px 0 30px 0;

      .text {
        padding: 0 12px;
        display: flex;
        width: 100%;

        p {
          margin-top: 6px;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: 400;
        }

        .link {
          text-decoration: none;
          color: #29c8d2;
          cursor: pointer;
          word-break: break-all;

          p {
            font-size: 12px;
            line-height: 25px;
          }
        }

        .label {
          min-width: 114px;
          font-size: 13px;
          line-height: 25px;
        }

        .data {
          white-space: pre-line;
        }
      }
    }

    .static {
      padding: 0 11px;
      text-align: justify;

      h5 {
        margin-bottom: 0px !important;
        font-size: 15px;
        font-weight: 700;
        margin-top: 30px;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        margin-top: 8px;
      }
    }

    @media (max-width: 400px) {
      .title {
        padding: 0 15%;
      }
    }
  }
`;

const DATA: any = {
  運営事業者名: '株式会社スカラ',
  運営統括責任者: '代表執行役　新田　英明',
  所在地: '東京都渋谷区渋谷2-21-1 渋谷ヒカリエ32F',
  URL: 'https://fanique.io/inquiry',
  電話: '03-6418-3976（カスタマーサポート）　平日10時～17時',
  メールアドレス: 'support@fanique.io',
  販売価格: '購入手続きの際に画面に表示されます。消費税は、内税として表示しております。',
  販売価格以外の必要料金:
    '本サービスのページの閲覧、商品購入等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。また、本サービスのご利用にあたって、ブロックチェーンを利用する必要がある場合は、ブロックチェーンのネットワーク手数料をお客様にてご用意いただく場合があります。',
  お支払い時期: 'クレジットカード決済：ご注文時にお支払いが確定します。',
  お支払い方法: 'クレジット決済(Visa , Mastercard)',
  商品の提供時期: '購入手続き完了後、お客様のアカウントに商品をご提供いたします。',
  '返品・キャンセル等': '商品の特性上、返品・キャンセル等は一切お受けできません。',
  '特別の販売・提供条件': 'お客様が未成年者の場合は、保護者の方の同意を得てから購入してください。',
  動作環境:
    '＜推奨ブラウザ＞iOS ＆ Android：Safari、Chrome パソコン：Safari、Chrome、Edge（Windows)',
};

const SpecifiedCommercialTransactions = () => {
  const history = useHistory();
  const { authenticated } = useContext(AuthContext);

  return (
    <Wrapper>
      <ContentWrapper>
        <div className="neomorphs-shadow-in inner-div">
          <TitleComponent
            heading={`特定商取引法に\n基づく表示`}
            className="title"
            color="#4d4d4d"
          />
          <div className="description">
            <p>
              NFTストア「Fanique」（以下「本サービス」）では、「特定商取引に関する法律」第11条（通信販売についての広告）に基づき、以下のとおり表示します。
            </p>
          </div>
          <div className="detail-info">
            {Object.keys(DATA).map((item, index) => {
              return (
                <div key={index}>
                  <div className="text">
                    <p className="label">{item}</p>
                    {item !== 'URL' ? (
                      <p className="data">{DATA[item]}</p>
                    ) : (
                      <a href="/inquiry" target="blank" className="link">
                        <p>{DATA[item]}</p>
                      </a>
                    )}
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>

        <div className="button-wrapper">
          <Button
            className="route-btn"
            size="small"
            onClick={() => (authenticated ? history.push('/top') : history.push('/team-list'))}
          >
            TOPへ戻る
          </Button>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default RestrictedRoute(SpecifiedCommercialTransactions);
