import React from 'react';
import styled from 'styled-components';
import { IonButton, IonIcon } from '@ionic/react';

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  icon?: string;
  avatar?: string;
  onClick?: any;
  size?: 'regular' | 'small' | 'large';
  expand?: 'block' | 'full';
  slot?: 'start' | 'end' | 'icon-only';
  color?: string;
  disabled?: boolean;
}

const StyledButton = styled(IonButton)<any>`
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --box-shadow: var(--shadow-up);
  --color: ${(props: ButtonProps) => (props.color ? props.color : '#29c8d2')};
  --border-radius: 2rem;
  --padding-top: 0.6rem;
  --padding-bottom: 0.6rem;
  opacity: 1;

  --color: ${(props: ButtonProps) => props.disabled && '#CCCCCC'};
  ${(props: ButtonProps) =>
    props.size === 'large' &&
    `
    --padding-top: 1rem;
    --padding-bottom: 1rem;
  `}

  height: auto;
  font-size: 18px;
  ${(props: ButtonProps) =>
    props.size === 'small' &&
    `
    --padding-start: 12px;
    --padding-end: 12px; 
    font-size: 12px;
    --padding-top: 0.5rem;
    font-weight: 500;
    --padding-bottom: 0.5rem;
  `}
  ${(props: any) =>
    props.iconSlot === 'icon-only' &&
    `
    height: 3rem;
    --padding-start: 11px;
    --padding-end: 11px; 

    ion-icon{
      font-size: 30px;
    }
  `};

  ${(props: ButtonProps) => props.avatar && '--padding-start: 0.75rem;'};

  text-transform: none;
  font-weight: 700;
  :hover {
    --box-shadow: 1px 1px 2px #c1c1c1, -2px -2px 1px #ffffff, inset -3px -3px 5px -4px #a7a4a4,
      inset 3px 4px 9px -7px #ffffff;
  }
  &.btn-img {
    width: 100%;
    height: auto;
  }
  &.ion-activated,
  :active {
    border-radius: var(--border-radius);
    color: #cccccc;
    --box-shadow: var(--shadow-down);
  }
  &.active-menu {
    border-radius: var(--border-radius);
    --box-shadow: var(--shadow-down);
  }
`;

const Image = styled.div<any>`
  background: ${(props: ButtonProps) => `url(${props.avatar})`};
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  background-size: cover;
  box-shadow: 2px 1px 2px white, -1.6px -1px 3px #cdcdcd;
`;
export const Button: React.FC<ButtonProps> = ({
  className,
  onClick,
  children,
  icon,
  slot = 'start',
  expand = 'full',
  avatar,
  size = 'regular',
  color,
  disabled,
}) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      shape="round"
      iconSlot={slot}
      expand={expand === 'full' ? expand : undefined}
      avatar={avatar}
      size={size}
      color={color}
      disabled={disabled}
    >
      {slot !== 'icon-only' && children}
      {avatar && <Image slot="start" avatar={avatar} />}
      {icon && <IonIcon slot={slot} icon={icon} />}
    </StyledButton>
  );
};
